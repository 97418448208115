import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

class AreaOnMap extends React.Component {

    componentDidMount() {
        //this.shouldComponentUpdate(this.props);
    }

    componentWillUnmount() {
        if (this.poly) {
            window.google.maps.event.clearInstanceListeners(this.poly.getPath());
            this.poly.setMap(null);
            delete this.poly;
        }
    }

    /*
    shouldComponentUpdate(nextProps) {

        debugger;
        return false;
    }
    */

    render() {
        const { area, akey, map, selected } = this.props;

        //console.log('render area', selected, akey);
        let zoomtoarea = false; let attatchlisteners;
        if (!this.poly) {
            this.poly = new window.google.maps.Polygon({
                paths: area.area.map(p => new window.google.maps.LatLng(p.latitude, p.longitude)),
                map: map,
                editable: selected,
                geodesic: true,
            });
            zoomtoarea = selected;
        } else {
            //console.log('should update area');
            const path = this.poly.getPath();
            if (area.area.length !== path.getLength()
                || area.area.find((ap, idx) => { const pp = path.getAt(idx); return (ap.latitude !== pp.lat() || ap.longitude !== pp.lng()) }) !== undefined) {
                //console.log('polygon changed');
                this.poly.setPath(area.area.map(p => new window.google.maps.LatLng(p.latitude, p.longitude)));
                attatchlisteners = true;
            }
            if (selected !== this.poly.getEditable()) {
                this.poly.setEditable(selected);
                zoomtoarea = selected;
                if (!selected) {
                    //console.log('removing listeners');
                    window.google.maps.event.clearInstanceListeners(this.poly.getPath());
                }
            }
        }

        if (zoomtoarea) {
            var bounds = new window.google.maps.LatLngBounds()
            this.poly.getPath().forEach(function (element, index) { bounds.extend(element) })
            window.bigmap.map.map.map_.panTo(bounds.getCenter());

            attatchlisteners = true;
        }

        if (attatchlisteners) {
            let sendAreaUpdate = (e) => {
                let firestore = firebase.firestore();
                let path = this.poly.getPath().getArray().map(e => new firebase.firestore.GeoPoint(e.lat(), e.lng()));
                let updateobject = {
                    ['speedareas.' + akey.id + '.area']: path
                };
                //console.log('update path', akey, this.props);
                if (akey.owner) {
                    firestore.collection('speedareas').doc(akey.owner).feedbackupdate(updateobject);
                } else {
                    firestore.collection('events').doc(this.props.eventId).feedbackupdate(updateobject);
                    if (this.props.bound) {
                        let parts = akey.id.split(':');
                        firestore.collection('speedareas').doc(parts[0]).feedbackupdate({
                            ['speedareas.' + parts[1] + '.area']: path
                        });
                    }
                }
            }

            //console.log('installing listeners');
            const path = this.poly.getPath();
            window.google.maps.event.addListener(path, 'insert_at', sendAreaUpdate);
            window.google.maps.event.addListener(path, 'set_at', sendAreaUpdate);
            window.google.maps.event.addListener(path, 'remove_at', sendAreaUpdate);
        }

        return null;
    }
}

/*
const mapStateToProps = (state, ownProps) => {
    debugger;
    return {
    area: state.speedAreas[ownProps.akey.owner][ownProps.akey.id],
}}
*/

const mapStateToProps = (state, ownProps) => ({
    eventId: state.eventId,
    area: (ownProps.akey.owner ?
        state.speedAreas[ownProps.akey.owner][ownProps.akey.id] :
        state.eventSpeedAreas[ownProps.akey.id]),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaOnMap);