import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';

import moment from 'moment';
import StartBeeper from '../utils/StartBeeper';
import { Typography } from '@material-ui/core';
import NoSleep from 'nosleep.js';

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		height: 140,
		width: 100,
	},
	card: {
		maxWidth: false,
	},
	textField: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	headerPaper: {
		padding: theme.spacing(3, 2),
	},
	timetostart: {
		fontSize: 50,
		textAlign: 'center'
	},
	starttime: {
		fontSize: '20pt'
	},
	tostart: {
		fontSize: '20pt'
	},
	teamname: {
		fontSize: '20pt'
	},
	next: {
		fontSize: '40pt',
	},
	nextstarted: {
		backgroundColor: '#44ea46'
	},
	next10s: {
		backgroundColor: 'red'
	},
	nextminute: {
		backgroundColor: 'yellow'
	}
});

class StartListDialog extends Component {


	componentDidMount = () => {
		this.stopTimers = false;

		let setupUpdate = () => {
			let ct = new Date().getTime() + global.fbOffsetRef;
			setTimeout(() => {
				if (this.stopTimers)
					return;
				this.forceUpdate();
				setupUpdate();
			}, (1000 - ct % 1000) % 1000);
		}
		setupUpdate();

		this.noSleep = new NoSleep();
		this.noSleep.enable();
	}

	componentWillUnmount = () => {
		this.stopTimers = true;
		this.noSleep.disable();
	}

	render() {
		const { classes, handleClose, teamsList } = this.props;
		const timetoshowafterestart = 5000;
		let ct = new Date().getTime() + global.fbOffsetRef;
		let startorder = Object.keys(teamsList).filter(tid => !teamsList[tid].disabled).sort((a, b) => teamsList[a].starttime - teamsList[b].starttime);
		let nextstarter = startorder.find(tid => teamsList[tid].starttime > ct - timetoshowafterestart);
		let nextstartms = 0;
		let nexcol = '';
		let fct = ct - ct % 1000;
		if (nextstarter !== undefined) {
			nextstartms = teamsList[nextstarter].starttime;
			let s = (nextstartms - fct)
			if (s <= 0)
				nexcol = classes.nextstarted;
			else if (s <= 10000)
				nexcol = classes.next10s;
			else if (s <= 60000)
				nexcol = classes.nextminute;
		}
		return (
			<Dialog
				open={true}
				maxWidth={false}
				fullScreen={true}
			>
				{nextstarter !== undefined && (
					<DialogTitle>
						<Paper elevation={2} className={classes.headerPaper + ' ' + nexcol}>
							<Grid
								container
								spacing={0}
								cols="1"
								alignItems="center"
								justify="center"
							>
								{nextstartms && (
									<TextField
										className={classes.textField}
										color="primary"
										label="Järgmine startija"
										value={teamsList[nextstarter].name}
										InputProps={{
											classes: {
												input: classes.timetostart
											},
											readOnly: true,
										}}
										variant="outlined"
									/>
								)}
								{nextstartms && (
									<TextField
										className={classes.textField}
										color="primary"
										label="Aega stardini"
										value={nextstartms <= fct ? "Start" : moment(nextstartms - fct).utc().format("HH:mm:ss")}
										InputProps={{
											classes: {
												input: classes.timetostart
											},
											readOnly: true,
										}}
										variant="outlined"
									/>
								)}
							</Grid>
						</Paper>
					</DialogTitle>)}
				<DialogContent>
					<Typography variant="h4">
						{moment(ct).format("hh:mm:ss")}
					</Typography>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Stardiaeg</TableCell>
								<TableCell>Aega stardini</TableCell>
								<TableCell>Team</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{startorder.filter(tid => teamsList[tid].starttime > ct - 30000).map(tid =>
								(<TableRow key={tid}>
									<TableCell className={classes.starttime}>{moment(teamsList[tid].starttime).format("HH:mm:ss")}</TableCell>
									<TableCell className={classes.tostart}>{teamsList[tid].starttime <= fct ? "Startinud" :
										moment(teamsList[tid].starttime - fct).utc().format("HH:mm:ss")}</TableCell>
									<TableCell className={classes.teamname}>{teamsList[tid].name}</TableCell>
								</TableRow>)
							)}

						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
          </Button>
				</DialogActions>
				<StartBeeper />
			</Dialog>
		);
	}
}

const mapStateToProps = state => ({
	eventdata: state.currentEvent,
	teamsList: state.teamsList,
})


export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(StartListDialog));
