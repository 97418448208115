import { Button, Dialog, DialogActions, DialogContent, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GoogleMap from './GoogleMap';
import { estoniaCoords } from '../utils/SmallUtils';
import KPIcon from './KPIconNext';


const styles = theme => ({
});



class RestoreDeletedKPs extends React.Component {

    state = {
        selectedkp: null
    }

    render() {
        const { deletedkps } = this.props;
        return (
            <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={9} xs={12}>
                            <div style={{ height: '40vh', width: '100%', padding: '1' }}>
                                <GoogleMap
                                    defaultCenter={estoniaCoords.center}
                                    defaultZoom={estoniaCoords.zoom}
                                >
                                    {Object.entries(deletedkps).map(([id, kp]) => (

                                        <KPIcon key={id} color="secondary" kp={kp.kp} lat={kp.data.loc.lat} lng={kp.data.loc.lng} fontSize="large"
                                            onClick={() => {
                                                this.setState({ selectedkp: id });
                                            }}
                                        />
                                    ))}
                                </GoogleMap>
                            </div>
                        </Grid>
                        <Grid item sm={3}>
                            <Paper style={{ maxHeight: '40vh', width: '100%', overflow: 'auto' }}>
                                <List aria-label="kplist">
                                    {Object.entries(deletedkps).map(([id, kp]) => (
                                        <ListItem key={id} button onClick={() => {
                                            this.setState({ selectedkp: id });
                                        }} selected={this.state.selectedkp === id} autoFocus={this.state.selectedkp === id}>
                                            <ListItemIcon>
                                                <KPIcon color="primary" kp={kp.kp} nocenter={false} fontSize="large" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={kp.kp.nr.toString() + (kp.kp.descripton || "")}
                                                secondary={kp.data.longdesc}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={!this.state.selectedkp} onClick={() => {
                        const kp = deletedkps[this.state.selectedkp];
                        this.props.eventref.child('kp').child(kp.id).set(kp.kp);
                        if (kp.data)
                            this.props.eventref.child('kpdata').child(kp.id).set(kp.data);
                        if (kp.answer)
                            this.props.eventref.child('kpanswer').child(kp.id).set(kp.answer);
                        this.props.eventref.child('deletedkps').child(this.state.selectedkp).remove();
                        this.props.onClose();
                    }} variant="contained" color="primary">
                        Restore
                    </Button>
                    <Button autoFocus variant="contained" onClick={this.props.onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(RestoreDeletedKPs);
