import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SimpleTextInput extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      inputvalue: props.value || ""
    }
  }

  handleChange = (e) => {
    this.setState({ inputvalue: e.target.value });
  }
  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.handleClose(null)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.desc}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="input"
              type={this.props.type}
              value={this.state.inputvalue}
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.handleClose(this.state.inputvalue)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
