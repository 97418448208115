import * as types from './actionTypes'

export function getEvents() {
  return { type: types.GET_EVENTS };
}

export function setColorList(list) {
  return { type: types.SET_COLOR_LIST, list: list };
}

export function eventAccess(acc) {
  return { type: types.EVENT_ACCESS, acc: acc };
}

export function setEventId(eid) {
  return { type: types.SET_EVENT_ID, eid: eid };
}

export function currentEventClear() {
  return { type: types.CURRENT_EVENT_CLEAR };
}

export function setEventName(e) {
  return { type: types.SET_EVENT_NAME, name: e };
}

export function setKpList(list) {
  return { type: types.SET_KP_LIST, list: list };
}

export function setTeamTracks(tid, tracks) {
  return { type: types.SET_TEAM_TRACKS, tid: tid, tracks: tracks };
}

export function setTeamList(list) {
  return { type: types.SET_TEAM_LIST, list: list };
}

export function setKPProp(kpid, name, value) {
  return { type: types.SET_KP_PROP, kpid: kpid, name: name, value: value };
}

export function addRaCoef(racoef) {
  return { type: types.ADD_RACOEF, racoef: racoef };
}

export function rmRaCoef(aste) {
  return { type: types.RM_RACOEF, aste: aste };
}

export function addKlass(tahis, name, klassid) {
  return { type: types.ADD_KLASS, tahis: tahis, name: name, klassid: klassid };
}

export function rmKlass(klassid) {
  return { type: types.RM_KLASS, klassid: klassid };
}

export function addValikAeg(classname, aeg, penalty, per, ord) {
  return { type: types.ADD_VALIKAEG, classname: classname, aeg: aeg, penalty: penalty, per: per, ord: ord };
}

export function rmValikAeg(id) {
  return { type: types.RM_VALIKAEG, id: id };
}

export function updateValikAeg(editid, aeg, penalty, per) {
  return { type: types.UPDATE_VALIKAEG, valikaegid: editid, aeg: aeg, penalty: penalty, per: per };
}

export function setLYPoints(lyid, teamid, points) {
  return { type: types.SET_LY_POINTS, lyid: lyid, teamid: teamid, points: points };
}

export function setEditKPId(kpid, kplist) {
  return { type: types.SET_EDIT_KP_ID, kpid: kpid, kplist: kplist };
}

export function setEditTeamId(teamid) {
  return { type: types.SET_EDIT_TEAM_ID, teamid: teamid };
}

export function setViewTeamResult(teamid) {
  return { type: types.SET_VIEW_TEAM_RESULT, teamid: teamid }

}
export function toggleOverlay(id) {
  return { type: types.TOGGLE_OVERLAY, id: id };
}

export function setEditOverlayId(id) {
  return { type: types.EDIT_OVERLAY_ID, id: id };
}

export function hideTeam(tid, hide) {
  return { type: types.HIDE_TEAM, id: tid, hide: hide };
}

export function setHiddenTeams(teamids) {
  return { type: types.SET_HIDDEN_TEAMS, teamids: teamids };
}

export function zoomToTrack(tid, devid) {
  return { type: types.ZOOM_TO_TRACK, tid: tid, devid: devid };
}

export function setShowKPs(showkps) {
  return { type: types.SET_SHOW_KPS, showKPs: showkps };
}

export function setAdjustOverlayLocState(state) {
  return { type: types.ADJUST_OVERLAY_STATE, state: state };
}

export function saveOverlayChanges(id, changes) {
  return { type: types.SAVE_OVERLAY_CHANGES, id: id, changes: changes };
}

export function addKPResponse(kpid, response) {
  return { type: types.ADD_KP_RESPONSE, kpid: kpid, response: response };
}

export function rmKPResponse(respid) {
  return { type: types.RM_KP_RESPONSE, id: respid };
}

export function setTrackTailLength(l) {
  return { type: types.SET_TRACK_TAIL_LENGTH, length: l }
}

export function setReplayType(t) {
  return { type: types.SET_REPLAY_TYPE, replaytype: t }
}

export function showSpeedingTicket(ticket) {
  return { type: types.SHOW_SPEEDING_TICKET, ticket: ticket }
}

export function showSingleTrack(tid, devid) {
  return { type: types.SHOW_SINGLE_TRACK, tid: tid, devid: devid }
}

export function stopShowSingleTrack() {
  return { type: types.SHOW_SINGLE_TRACK, stop: true }
}

export function setTrackType(t) {
  return { type: types.SET_TRACK_TYPE, tracktype: t }
}

export function addLocationData(tid, devid, points) {
  return { type: types.LOCATION_DATA, tid: tid, devid: devid, points: points }
}

export function clearLocationData() {
  return { type: types.LOCATION_DATA_CLEAR }
}

export function addFinalTrackData(tid, devid, points) {
  return { type: types.FINAL_LOCATION_DATA, tid: tid, devid: devid, points: points }
}

export function addTrackSegment(tid, devid, points) {
  return { type: types.TRACK_SEGMENT, tid: tid, devid: devid, points: points }
}

export function addLocationPoint(tid, devid, t, point) {
  return { type: types.LOCATION_POINT, tid: tid, devid: devid, time: t, point: point }
}

export function updateSpeedAreaDoc(key, doc) {
  return { type: types.UPDATE_SPEEDAREA_DOC, key: key, doc: doc }
}

export function setSelectedArea(akey) {
  return { type: types.SET_SELECTED_AREA, akey: akey }
}

export function setEventDoc(doc) {
  return { type: types.SET_EVENT_DOC, doc: doc }
}

export function openDialog(name) {
  return { type: types.OPEN_DIALOG, name: name }
}

export function closeDialog(name) {
  return { type: types.CLOSE_DIALOG, name: name }
}

export function openSendMsg(teamid) {
  return { type: types.OPEN_SEND_MSG, teamid: teamid }
}
