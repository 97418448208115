import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import { DialogContent, List, ListItem, ListItemText, DialogTitle } from '@material-ui/core';
import { restoreTeam } from '../utils/backups';
import { addSaveInProgress, rmSaveInProgress } from '../redux/actionsEventResult';

const styles = theme => ({
  card: {
    maxWidth: false,
  },
});

class RestoreTeamDialog extends Component {

  state = {
    items: []
  }
  componentDidMount() {
    firebase.storage().ref('nutilogi/' + this.props.eventId + '/deletedteams').listAll().then(listing => {
      let promlist = [];
      let metas = [];
      listing.items.forEach(it => {
        promlist.push(it.getMetadata().then(meta => {
          if (meta.customMetadata && meta.customMetadata.id && meta.customMetadata.tfix)
            metas.push(meta);
        }))
      });
      Promise.all(promlist).then(() => {
        console.log(metas);
        this.setState({ items: metas });
      })
    });
  }

  restoreTeam = (it) => () => {
    this.props.addSaveInProgress();
    const rmSaveInProgress = this.props.rmSaveInProgress;
    restoreTeam(this.props.eventId, it.customMetadata.id, it.customMetadata.tfix).then(() => {
      rmSaveInProgress();
    });
    this.props.handleClose();


  }

  render() {
    const { handleClose } = this.props;
    return (
      <Dialog
        open={true}
        maxWidth={false}
      >
        <DialogTitle>{this.state.items.length === 0 ? 'No saved teams' : 'Choose team to restore'}</DialogTitle>
        <DialogContent>
          <List>
            {this.state.items.map(it => (
              <ListItem button key={it.name} onClick={this.restoreTeam(it)}>
                <ListItemText primary={it.customMetadata.team} secondary={it.timeCreated} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  eventId: state.eventId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addSaveInProgress: () => dispatch(addSaveInProgress()),
  rmSaveInProgress: () => dispatch(rmSaveInProgress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(RestoreTeamDialog));
