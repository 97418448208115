import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import PlayControls from './PlayControls';

const styles = theme => ({
    playcontrolscontainer: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        height: theme.playControls.height,
    }
});

class PlayControlsWrapper extends React.Component {

    render() {
        const { classes, teamsList, eventStart, eventEnd, trackType } = this.props;

        if (trackType !== 'replay' || !eventStart) // Playcontrols should not be enabled unlesss an event is opened
            return null;
        let startms = null;
        let endms = null;
        let maxlen = 0;
        for (let tid in teamsList) {
			if (teamsList[tid].disabled)
				continue;
			if (teamsList[tid].starttime) {
            	if (startms === null || teamsList[tid].starttime < startms)
                	startms = teamsList[tid].starttime
			}
			if (teamsList[tid].finishtime) {
            	if (endms === null || teamsList[tid].finishtime > endms)
                	endms = teamsList[tid].finishtime
            }
            if (teamsList[tid].starttime && teamsList[tid].finishtime) {
                const tlen = teamsList[tid].finishtime - teamsList[tid].starttime;
                if (tlen > maxlen)
                    maxlen = tlen;
            }
        }
        if (startms === null)
            startms = eventStart;
        if (endms === null)
            endms = eventEnd;
        if (this.props.massstart && maxlen > 0)
            endms = startms + maxlen;

        return (
            <div className={classes.playcontrolscontainer}>
                <PlayControls startms={startms} endms={endms}></PlayControls>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    eventStart: state.currentEvent.starttime,
    eventEnd: state.currentEvent.endtime,
    teamsList: state.teamsList,
    trackType: state.appState.trackType,
    massstart: state.appState.replayType === 'massstart',
})

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PlayControlsWrapper));
