import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from "@material-ui/core/TextField";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app'
import { Autocomplete } from '@material-ui/lab';
import { argouid } from '../utils/SmallUtils';
import { Box } from '@material-ui/core';

const styles = theme => ({
  spacing: 8
});

class EditAccessRightsDialog extends Component {

  state = {
    multi: [],
    users: {},
    admins: [],
    owner: "",
    inputvalue: ""
  };

  componentDidMount() {
    this.adminref = firebase.database().ref('events').child(this.props.eventId).child('admins');
    this.adminref.on('value', s => {
      if (s.exists()) {
        let owner = "";
        const admins = Object.entries(s.val()).filter(([k, v]) => {
          if (v === 'owner')
            owner = k;
          return v !== 'owner'
        }).map(([k, _]) => k);
        this.setState({ admins: admins, owner: owner });
        if (admins.length > 0)
          this.getUsers(undefined, admins);
      }
    });
  }

  componentWillUnmount() {
    this.adminref.off();
  }

  handleChange = (_, value, reason) => {
    if (reason === "select-option") {
      value.forEach(v => {
        if (typeof v === "string")
          return;
        let val = (v.displayName || v.email || v.phoneNumber)
        this.adminref.child(v.uid).set(val);
        firebase.firestore().collection('events').doc(this.props.eventId).update({ ['admins.' + v.uid]: val }).catch(e => {
          /* istanbul ignore next */
          console.error('got error', e);
        })
      });
      this.setState({ inputvalue: "" });
    } else if (reason === "remove-option" || reason === "clear") {
      this.state.admins.forEach(a => {
        if (reason === "clear" || !value.includes(a)) {
          this.adminref.child(a).set(null);
          firebase.firestore().collection('events').doc(this.props.eventId).update({ ['admins.' + a]: firebase.firestore.FieldValue.delete() })
        }
      })
    } else {
      /* istanbul ignore next */
      console.error('unhandled:', reason, value);
    }
  }

  getUsers = (search, uids) => {
    let self = this;
    const addToState = (addusers) => {
      self.setState((state, props) => {
        addusers.forEach((u) => {
          if (u.uid === self.state.owner)
            return;
          state.users[u.uid] = u;
        });
        return state;
      })
    }
    this.props.authUser.getIdToken().then(token => {
      var url = new URL('https://europe-west1-nutilogi.cloudfunctions.net/api/getusers');
      if (search !== undefined)
        url.searchParams.append('s', search);
      else if (uids !== undefined)
        url.searchParams.append('uids', uids);
      fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'X-EventId': this.props.eventId
        }
      }).then(data => {
        return data.json();
      }).then(data => {
        if (data.users !== undefined) {
          if (data.notFound && data.notFound.length !== 0) {
            console.warn('Not found users. Should never request for users that do not exist.', data.notFound);
          }
          addToState(data.users);
        } else
          addToState(data);
      }).catch(e => {
        /* istanbul ignore next */
        console.error('got error on fetch', e);
      })
    });
  }

  inputChange = (_, value, reason) => {
    if (reason !== "input")
      return;
    this.setState({ inputvalue: value });
    if (value && value !== "") {
      if (this.inputtimer) {
        clearTimeout(this.inputtimer);
        this.inputtimer = undefined;
      }
      this.inputtimer = setTimeout(() => {
        this.inputtimer = undefined;
        this.getUsers(value, undefined);
      }, 500);
    }
  }

  labelForItem = (u) => {
    if (typeof u === 'string')
      u = this.state.users[u];
    if (u === undefined) {
      return "*** Unknown ***";
    }
    return u.displayName ?
      u.displayName + " / " + u.email + " / " + (u.phoneNumber || '') : (u.phoneNumber ?
        u.phoneNumber : u.email);
  }

  render() {
    const { handleClose, authUser } = this.props;
    const { users, admins, owner } = this.state;

    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle id="simple-dialog-title">Allow admin access</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            id="admin-users"
            options={Object.values(users).sort()}
            getOptionLabel={(option) => this.labelForItem(option)}
            getOptionSelected={(option, value) => {
              if (typeof value === "string")
                return option.uid === value;
              /* istanbul ignore next */
              return option.uid === value.uid;
            }}
            value={admins}
            onChange={this.handleChange}
            inputValue={this.state.inputvalue}
            filterSelectedOptions
            onInputChange={this.inputChange}

            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Admin users"
              />
            )}
          />
          {(argouid === authUser.uid && !admins.includes(argouid) && owner !== argouid) && (
            <Box p={1}>
              <Button onClick={() => {
                this.handleChange(undefined, [{ uid: argouid, displayName: 'Argo' }], "select-option")
              }} variant="contained" color="primary">Add me</Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.authUser,
  eventId: state.eventId,
})


export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(EditAccessRightsDialog));
