import React, { Component, Fragment } from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import firebase from 'firebase/app';
import * as actions from '../redux/actionsEvents';
import CheapRuler from 'cheap-ruler';
//var zlib = require('zlib');
import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import {ArchiveReader} from '../utils/ArchiveReader';



class BaseDataListener extends Component {
    static contextType = ReactReduxContext;

    listenrefs = []

    firebaseObjectSync = (dbref, prefix, { onInitialComplete = null, onChildAdded = null } = {}) => {
        this.listenrefs[prefix] = dbref;
        let initialcomplete = false;
        const { dispatch } = this.context.store;

        dbref.on('child_added', s => {
            const vval = (onChildAdded ? onChildAdded(s, initialcomplete) : s.val());
            if (initialcomplete)
                dispatch({ type: prefix + '_added', k: s.key, v: vval });
        })
        dbref.on('child_changed', s => {
            if (initialcomplete)
                dispatch({ type: prefix + '_changed', k: s.key, v: s.val() });
        })
        dbref.on('child_removed', s => {
            if (initialcomplete)
                dispatch({ type: prefix + '_removed', k: s.key, v: s.val() });
        })
        dbref.once('value', (snap) => {
            initialcomplete = true;
            const vval = (onInitialComplete ? onInitialComplete(snap) : snap.val());
            //console.log(prefix, vval);
            dispatch({ type: prefix + '_value', v: vval });
        }, (e) => {
            initialcomplete = true;
            console.log(`Failed to query ${prefix}, ${e}`);
        });
    }

    componentWillUnmount() {
        //console.log('Base did unmount');
        this.listenrefs.forEach(lref => lref.off());
    }

    render() {
        return null;
    }
}

class MainDataListeners extends BaseDataListener {
    static contextType = ReactReduxContext;

    componentDidMount() {
        //console.log('MaintDataListener did mount: ', this.props);

        const { eventId } = this.props;
        const { dispatch, getState } = this.context.store;
        dispatch(actions.currentEventClear());
        let db = firebase.database();
        const { firebaseObjectSync } = this;
        firebaseObjectSync(db.ref('events').child(eventId), 'eventlist');
        firebaseObjectSync(db.ref('eventsdata').child(eventId).child('data'), 'eventdata');
        firebaseObjectSync(db.ref('eventsdata').child(eventId).child('kp'), 'kpList');
        firebaseObjectSync(db.ref('teams').child(eventId).child('spdtickets'), 'spdtickets');
        firebaseObjectSync(db.ref('teams').child(eventId).child('answerresult'), 'answerResult');
        let colorlist = getState().colorList.values();
        const nextColorForTeam = () => {
            let n = colorlist.next();
            while (n.done || !n.value) {
                if (n.done)
                    colorlist = getState().colorList.values();
                n = colorlist.next();
            }
            return n.value;
        }

        firebaseObjectSync(db.ref('teams').child(eventId).child('list'), 'teamsList', {
            onChildAdded: (snap, initialcomplete) => {
                if (!initialcomplete)
                    return;
                let team = snap.val();
                if (team)
                    team.color = nextColorForTeam();
                return team;
            },
            onInitialComplete: (snap) => {
                let teams = snap.val();
                if (teams) {
                    Object.values(teams).forEach(t => {
                        t.color = nextColorForTeam();
                    });
                }
                return teams;
            }
        });
    }
}

class TracksDataListener extends BaseDataListener {
    static contextType = ReactReduxContext;

    declocdata = (buf) => {
        let idx = 0;
        let jend = 0;
        let entries = []

        while ((jend = buf.indexOf(0, idx)) !== -1) {
            const h = JSON.parse(new TextDecoder("utf-8").decode(buf.slice(idx, jend)));
            //console.log(jend);
            //console.log(h)
            idx = jend + 1;
            h.tidx = idx + h.tstart;
            h.sidx = idx + h.sstart;
            h.aidx = idx + h.astart;
            h.lidx = idx + h.lstart;
            h.gidx = idx + h.gstart;
            h.lonidx = idx + h.lonstart;
            h.latidx = idx + h.latstart;
            let getnum = (f) => {
                let b = 0;
                let v = 0;
                let pos = 0;
                do {
                    b = buf[h[f + 'idx']]
                    if (b !== 0)
                        h[f + 'idx']++;
                    v = v | ((b & 0x7F) << (7 * pos));
                    pos++;

                } while (b & 0x80);
                if (v === 0) {
                    const zc = (buf[h[f + 'idx'] + 1] -= 1);
                    if (zc === 0)
                        h[f + 'idx'] += 2
                }
                if (v & 0x1)
                    v = ~v;
                v = v >> 1;
                //console.log(v/h[f+'prec']);
                return (v / h[f + 'prec']);
            }
            let objects = [];
            let prevob = Object.assign(new window.google.maps.LatLng(0, 0), { t: Number(h.firstt - 1000), s: 0, a: 0, g: 0, l: 0 });
            for (let i = 0; i < h.count; i++) {
                const nob = Object.assign(new window.google.maps.LatLng(
                    prevob.lat() + getnum('lat'),
                    prevob.lng() + getnum('lon')), {
                    t: prevob.t + getnum('t') + 1000,
                    s: prevob.s + getnum('s'),
                    a: prevob.a + getnum('a'),
                    g: prevob.g + getnum('g'),
                    l: prevob.l + getnum('l'),
                });
                objects.push(nob);
                prevob = nob;
            }
            idx = h.lonidx;

            entries.push(Object.assign(h, { points: objects }));
        };
        return entries;
    }


    getusepoints = (entry, calcdst) => {
        const usepoints = entry.points.filter(p => p.a < 30);
        if (calcdst && usepoints.length > 0) {
            // TODO Move distance caluclatoni to place where final track is calculated
            let ruler = null;
            usepoints[0].d = 0;
            for (let i = 1; i < usepoints.length; i++) {
                if (!ruler)
                    ruler = new CheapRuler(usepoints[0].lat(), 'meters');
                usepoints[i].d = usepoints[i - 1].d + ruler.distance([usepoints[i - 1].lng(), usepoints[i - 1].lat()], [usepoints[i].lng(), usepoints[i].lat()]);
            }
        }
        return usepoints;
    }

    getTrack = (fref) => {
        return fref.getDownloadURL().then(url => {
            return fetch(url).then(r => r.arrayBuffer()).then(fdata => {
                let fview = new Uint8Array(fdata);
                const locdata = this.declocdata(fview);
                let ruler = null; // Distance calculation can be removed when we add distance calculation already to encoding.
                locdata.forEach(ent => {
                    const points = ent.points;
                    if (points.length === 0 || points[0].d !== undefined)
                        return;
                    points[0].d = 0;
                    for (let i = 1; i < points.length; i++) {
                        if (!ruler)
                            ruler = new CheapRuler(points[0].lat(), 'meters');
                        points[i].d = points[i - 1].d + ruler.distance([points[i - 1].lng(), points[i - 1].lat()], [points[i].lng(), points[i].lat()]);
                    }
                });
                return locdata;
            });
        });
    }


    componentDidMount() {
        //console.log('EventDataListener did mount: ', this.props);
        this.readInitalTracks();
    }

    readInitalTracks = async () => {
        const { eventId } = this.props;
        const { dispatch } = this.context.store;
        let db = firebase.database();
        this.tracklisteners = {};
        // TODO If component will unmount before this function completes then tracklisteners won't be cleared probably.


        // == Tracks
        let loadedTracks = {};
        const listing = await firebase.storage().ref('tracks/' + eventId).listAll();
        let finaltracksref = listing.items.find((ent) => ent.name === 'finaltracks.data');
        if (finaltracksref !== undefined) {
            await finaltracksref.getDownloadURL().then((url) => {
                fetch(url).then(response => response.arrayBuffer())
                    .then(data => {
                        //console.log('pref buf', Date.now() - startd);
                        var view = new Uint8Array(data);
                        //console.log('got data', Date.now(   ) - startd);
                        const locdata = this.declocdata(view);

                        //console.log('got decoded data', Date.now() - startd);

                        locdata.forEach(en => {
                            const usepoints = en.points.filter(p => p.a < 30);
                            if (usepoints.length > 0) {
                                // TODO Move distance caluclatoni to place where final track is calculated
                                let ruler = null;
                                usepoints[0].d = 0;
                                for (let i = 1; i < usepoints.length; i++) {
                                    if (!ruler)
                                        ruler = new CheapRuler(usepoints[0].lat(), 'meters');
                                    usepoints[i].d = usepoints[i - 1].d + ruler.distance([usepoints[i - 1].lng(), usepoints[i - 1].lat()], [usepoints[i].lng(), usepoints[i].lat()]);
                                }
                            }
                            if (!(en.tid in loadedTracks))
                                loadedTracks[en.tid] = {}
                            loadedTracks[en.tid][en.devid] = true;
                            dispatch(actions.addFinalTrackData(en.tid, en.devid, usepoints));
                        });

                    }).catch((e) => {
                        console.log('failed to fetch data', e);
                        debugger;
                    });
            });
        }

        let promlist = [];
        //console.log(loadedTracks);
        listing.items.forEach(lit => {
            console.log(lit, lit.fullPath, lit.name);
            if (lit.name.endsWith('-finished.data')) {
                promlist.push(this.getTrack(lit).then(locdata => {
                    locdata.forEach(ent => {
                        if (!(ent.tid in loadedTracks))
                            loadedTracks[ent.tid] = {}
                        if (loadedTracks[ent.tid][ent.devid]) // Already from finaltracks.data
                            return;
                        loadedTracks[ent.tid][ent.devid] = true;
                        dispatch(actions.addFinalTrackData(ent.tid, ent.devid, this.getusepoints(ent, false)));

                    })
                }));
            }

        })
        await Promise.all(promlist);
        listing.prefixes.forEach((teamref) => {
            //console.log(teamref.name);
            teamref.listAll().then(tlisting => {
                let partpromlist = [];
                tlisting.items.forEach(tpart => {
                    //console.log(tpart.name);
                    const found = tpart.name.match(/(.*)-([0-9]+).data/);
                    if (!found)
                        return;
                    if ((loadedTracks[teamref.name] || {})[found[1]])
                        return;
                    partpromlist.push(this.getTrack(tpart).then(locdata => {
                        //console.log(locdata);
                        locdata.forEach(ent => {
                            dispatch(actions.addTrackSegment(ent.tid, ent.devid, ent.points));
                        })
                    }));
                    //console.log(loadedTracks);
                    //console.log(found);
                })
            });
        })
        //console.log('all finished processed');

        //console.log('dispatched all', Date.now() - startd);


        /* zlib compression based
        var buf = new Buffer(data.byteLength);
        var view = new Uint8Array(data);
        for (let i = 0; i < buf.length; i++) {
            buf[i] = view[i];
        }
        zlib.inflate(buf, (error, decdata) => {
            console.log('converting to obj', Date.now() - startd);
            let dataobj = JSON.parse(decdata);
            console.log('start dispatch timers', Date.now() - startd);
            Object.entries(dataobj).forEach(([tid, teamdata]) => {
                Object.entries(teamdata).forEach(([devid, data]) => {
                    dispatch(actions.addFinalTrackData(tid, devid, data));
                })
 
            })
            console.log('all dispatched', Date.now() - startd);
        });
        */
        //let decdata = JSON.parse(zlib.inflateSync(buf).toString());
        //debugger;

        //dispatch(actions.addFinalTrackData(tid, devid, decdata));






        /*
            let teamTracksAddedOrChanged = snap => {
      Object.entries(snap.val()).forEach(([k, v]) => {
        dispatch(actions.addLocationData(snap.key, k, v));
      });
    }
    */
        let tl = this.tracklisteners;
        this.trackref = db.ref('teams').child(eventId).child('track');
        this.trackref.on('child_added', (teamsnap) => {
            //console.log('trackeref child_added', teamsnap.key, teamsnap.val());
            tl[teamsnap.key] = { ref: teamsnap.ref, devrefs: {} };
            teamsnap.ref.on('child_added', (teamdevsnap) => {
                //Object.entries(teamsnap.val()).forEach(([k, v]) => {
                //dispatch(actions.addLocationData(snap.key, k, v));

                tl[teamsnap.key].devrefs[teamdevsnap.key] = { ref: teamdevsnap.ref, crefs: [] };
                //console.log('teamdev added', teamdevsnap.key, teamdevsnap.val());
                teamdevsnap.ref.on('child_added', (timegroupsnap) => {
                    //console.log('timegroup added ', timegroupsnap.key, timegroupsnap.val());
                    let initialcomplete = false;
                    timegroupsnap.ref.on('child_added', (locentry) => {
                        if (!initialcomplete)
                            return;
                        //console.log('new entry', teamdevsnap.key, timegroupsnap.key, locentry.key, locentry.val());
                        dispatch(actions.addLocationPoint(teamsnap.key, teamdevsnap.key, timegroupsnap.key + locentry.key, locentry.val()));
                    });
                    timegroupsnap.ref.once('value', (pointssnap) => {
                        //console.log('initial value for', this.props, teamdevsnap.key, timegroupsnap.key, pointssnap.val(), initialcomplete);
                        dispatch(actions.addLocationData(teamsnap.key, teamdevsnap.key, { [timegroupsnap.key]: pointssnap.val() }))
                        initialcomplete = true;
                    });

                    tl[teamsnap.key].devrefs[teamdevsnap.key].crefs.push(timegroupsnap.ref);
                    //console.log('after adding new');
                    //this.dumplisteners();
                    //console.log('listeners', this.tracklisteners);
                });
                teamdevsnap.ref.on('child_removed', (timegroupsnap) => {
                    //console.log('removing timegroup', timegroupsnap.key);
                    tl[teamsnap.key].devrefs[teamdevsnap.key].crefs = tl[teamsnap.key].devrefs[teamdevsnap.key].crefs.filter(e => e.key !== timegroupsnap.key);
                    //this.dumplisteners();
                    timegroupsnap.ref.off();
                })
            });
            teamsnap.ref.on('child_removed', (teamdevsnap) => {
                if (teamsnap.key in tl && teamdevsnap.key in tl[teamsnap.key].devrefs) {
                    tl[teamsnap.key].devrefs[teamdevsnap.key].crefs.forEach((timegroupref) => {
                        timegroupref.off();
                    })
                    tl[teamsnap.key].devrefs[teamdevsnap.key].ref.off();
                    delete tl[teamsnap.key].devrefs[teamdevsnap.key];
                }
                //console.log('after removing dev:', teamdevsnap.key);
                //this.dumplisteners();
            })
        })
        this.trackref.on('child_removed', (teamsnap) => {
            if (teamsnap.key in tl) {
                Object.values(tl[teamsnap.key].devrefs).forEach((teamdev) => {
                    teamdev.crefs.forEach((timegroupref) => {
                        timegroupref.off();
                    })
                    teamdev.ref.off();
                });
                tl[teamsnap.key].ref.off();
                delete tl[teamsnap.key];
            }
            // console.log('after removing team:', teamsnap.key);
            //this.dumplisteners();

        })
        //console.log(this.tracklisteners);
    }

    dumplisteners() {
        console.log('LISTENERES');
        Object.entries(this.tracklisteners).forEach(([teamkey, teamentry]) => {
            console.log('Teamkey: ', teamkey);
            Object.entries(teamentry.devrefs).forEach(([devkey, teamdeventry]) => {
                console.log('  teamdev: ', devkey)
                teamdeventry.crefs.forEach((timegroupref) => {
                    console.log('    timegroup: ', timegroupref.key);
                });
            });
        })
    }
    componentWillUnmount() {
        super.componentWillUnmount();


        Object.values(this.tracklisteners).forEach((teamentry) => {
            teamentry.ref.off();
            Object.values(teamentry.devrefs).forEach((teamdeventry) => {
                teamdeventry.crefs.forEach((timegroupref) => {
                    timegroupref.off();
                });
                teamdeventry.ref.off();
            });
        })
        if (this.trackref)
            this.trackref.off();
    }
}


class AdminDataListenerBase extends BaseDataListener {
    static contextType = ReactReduxContext;

    componentDidMount() {

        const { eventId } = this.props;
        const { dispatch } = this.context.store;
        let db = firebase.database();
        const { firebaseObjectSync } = this;

        firebaseObjectSync(db.ref('teams').child(eventId).child('data'), 'teamsData', {
            onChildAdded: (snap, initialcomplete) => {
                if (initialcomplete) {
                    const team = snap.val();
                    const teamname = (team.regdata ? team.regdata.teamname : team.displayName);
                    this.props.enqueueSnackbar('New team joined - ' + teamname, {
                        variant: 'info',
                        action: (par) => (
                            <Fragment>
                                <Button onClick={() => { dispatch(actions.setEditTeamId(snap.key)) }}>View</Button>
                                <Button onClick={() => { this.props.closeSnackbar(par) }}>Dismiss</Button>
                            </Fragment>
                        )
                    });
                }
                return snap.val();
            }
        }); // Should do only if admin
    }
}
let AdminDataListener = withSnackbar(AdminDataListenerBase)

class FBListener extends BaseDataListener {
    componentDidMount() {
        //console.log('FBlistener did mount', this.props.prefix);

        this.firebaseObjectSync(this.props.dbref, this.props.prefix);
    }
}

class ConditionalListenersBase extends Component {
    render() {
        const { eventAccess, currentEvent, eventId } = this.props;
        let elements = [];
        let eventended = currentEvent.endtime < Date.now();
        if (!eventended && currentEvent.endtime && !eventAccess) {
            if (this.endtimer)
                clearTimeout(this.endtimer);
            this.endtimer = setTimeout(() => {
                this.forceUpdate();
            }, currentEvent.endtime - Date.now())
        }
        const fdb = firebase.database();
        if (eventAccess || eventended || (currentEvent.eventdataready && !currentEvent.kpdataclosed))
            elements.push((
                <FBListener key="kpdata" dbref={fdb.ref('eventsdata').child(eventId).child('kpdata')} prefix="kpData" />
            ))
        if (eventAccess || eventended) {
            elements.push((
                <FBListener key="kpanswers" dbref={fdb.ref('eventsdata').child(eventId).child('kpanswer')} prefix="kpAnswers" />
            ));
            elements.push((
                <FBListener key="teamAnswers" dbref={fdb.ref('teams').child(eventId).child('kpanswers')} prefix="teamAnswers" />
            ))
        }

        return elements;
    }

}
const mapStateToPropsConditional = state => ({
    currentEvent: state.currentEvent,
    authUser: state.authUser,
    eventAccess: state.eventAccess,
})

let ConditionalListeners = connect(mapStateToPropsConditional)(ConditionalListenersBase);

class EventDataListener extends Component {

    render() {
        if (this.props.eventId === null)
            return null;
        let elements = [];
        if (this.props.arch)
            elements.push(<ArchiveReader archive={this.props.arch} eventId={this.props.eventId} />);
        elements.push(<MainDataListeners key={this.props.eventId + "main"} eventId={this.props.eventId} />);
        elements.push(<ConditionalListeners key={this.props.eventId + "cond"} eventId={this.props.eventId} />);
        if (this.props.eventAccess)
            elements.push((<AdminDataListener key={this.props.eventId + "admin"} eventId={this.props.eventId} />))
        if (!this.props.resultonly) {
            elements.push(<TracksDataListener
                key={this.props.eventId + "track"}
                eventId={this.props.eventId}
            />);
        }

        return elements;
    }
}

const mapStateToProps = state => ({
    eventId: state.eventId,
    arch: state.currentEvent.arch,
    eventAccess: state.eventAccess
})

export default connect(mapStateToProps)(EventDataListener);
