import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import { List as VirtList, AutoSizer } from "react-virtualized";
import { setReplayTime } from '../redux/actionsEventResult';
import moment from 'moment';

const styles = theme => ({
    list: {
        width: 200,
        height: "100%"
    },
    over: {
        background: "pink",
    },
    ticket: {
        background: "red",
    },
    lit: {
        padding: 0,
    },
});

class RightDrawer extends React.Component {
    state = {
        scrollToIndex: undefined,
    };

    componentDidMount() {
        if (this.props.ticket) {
            if (!this.props.ticket.ticketIndex) {
                this.props.ticket.ticketIndex = this.props.locationData.findIndex(i => i.t >= this.props.ticket.t);
            }
            this.setState({ scrollToIndex: this.props.ticket.ticketIndex });
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.ticket) {
            if (!nextProps.ticket.ticketIndex) {
                nextProps.ticket.ticketIndex = nextProps.locationData.findIndex(i => i.t >= nextProps.ticket.t);
            }
            if (nextProps.ticket !== this.props.ticket) {
                this.setState({ scrollToIndex: nextProps.ticket.ticketIndex });
            }
        }

        if (nextProps.replayTime !== this.props.replayTime && nextProps.locationData) {
            let idx = nextProps.locationData.findIndex(i => i.t >= nextProps.replayTime);
            this.setState({ scrollToIndex: idx });
        }
        return true;
    }

    renderTrackItem = ({ index, key, style, ld }) => {
        let timestr = moment(this.props.locationData[index].t).format("HH:mm:ss");
        let area = Object.values(this.props.speedAreas).find(s => window.google.maps.geometry.poly.containsLocation(this.props.locationData[index], s.poly));
        let secondstr = "";
        if (this.props.secondrow === "speedareas") {
            if (area !== undefined) {
                let name = (area.name.length > 7 ? area.name.substr(0, 7) + "..." : area.name);
                secondstr = name + " (" + (area.limit) + " km/h)";
            }
        } else if (this.props.secondrow === "accuracy") {
            secondstr = parseInt(this.props.locationData[index].a)
            if (index > 0) {
                const timedst = this.props.locationData[index].t - this.props.locationData[index - 1].t;
                if (timedst > 1000)
                    secondstr += " / " + parseInt(timedst / 1000).toString();
            }
        } else if (this.props.secondrow === "missinggps") {
            if (index > 0) {
                const timedst = this.props.locationData[index].t - this.props.locationData[index - 1].t;
                if (timedst > 1000)
                    secondstr = parseInt(timedst / 1000);
            }
        } else if (this.props.secondrow === "timestamp") {
			secondstr = this.props.locationData[index].t;
        } else if (this.props.secondrow === "loc") {
			secondstr = this.props.locationData[index].lat()+", "+this.props.locationData[index].lng()
        }
        let cl = undefined;
        console.log('spppp', this.props.locationData[index].s * 3600 / 1000, this.props.speedlimit, this.props.over1);
        if (this.props.ticket && index === this.props.ticket.ticketIndex)
            cl = this.props.classes.ticket;
        else if (Math.floor(this.props.locationData[index].s * 3600 / 1000) > (area === undefined ? this.props.speedlimit + this.props.over1 : area.limit + this.props.over1))
            cl = this.props.classes.over;
        //console.log(this.props.locationData[index]);
        return (
            <ListItem
                selected={this.props.replayTime === this.props.locationData[index].t}
                button
                onClick={() => { this.props.setReplayTime(this.props.locationData[index].t) }}
                key={key}
                style={style}
                className={cl}>
                <ListItemText className={this.props.classes.lit} primary={timestr + " - " + Math.floor(this.props.locationData[index].s * 3600 / 1000) + " km/h"} secondary={secondstr} />
            </ListItem>
        );
    }

    render() {
        const { classes, locationData } = this.props;

        if (!locationData)
            return null;
        console.log('render drawer');
        return (
            <Drawer
                anchor="right"
                variant="persistent"
                open={true}
            >
                <Toolbar />
                <div className={classes.list}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <VirtList
                                width={width}
                                height={height}
                                rowHeight={55}
                                rowRenderer={this.renderTrackItem}
                                rowCount={locationData.length}
                                scrollToIndex={this.state.scrollToIndex}
                                replayTime={this.props.replayTime}
                                data={this.props.secondrow}
                            />
                        )}
                    </AutoSizer>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    speedlimit: Number(state.speedConf.limit),
    over1: state.speedConf.over1,
    locationData: ((state.locationData[ownProps.tid] || {})[ownProps.devid] || {}).points,
    speedAreas: state.eventSpeedAreas,
    ticket: state.appState.showSpeedingTicket,
    replayTime: state.replayData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setReplayTime: (t) => dispatch(setReplayTime(t)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(RightDrawer));
