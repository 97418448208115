import React, { Component } from 'react';
import { connect } from 'react-redux';
import OpenEventDialog from './OpenEventDialog';
import EventResult from './EventResult';
import DialogFactory from '../components/DialogFactory';
import EventDataListener from '../utils/EventDataListener';
import { setEventId } from '../redux/actionsEvents';


class Results extends Component {

  constructor(props, context) {
    super(props, context)
    this.handleCloseOpenEventDialog = this.handleCloseOpenEventDialog.bind(this);
  }

  handleCloseOpenEventDialog = (event) => {
    if (!event)
      return;
    this.props.history.push('/ao/' + event.id.toString());
    setEventId(this.props.match.params.id);
  }
  render() {
    const { eventId } = this.props;
    return (
      <div>
        <DialogFactory />
        <EventDataListener resultonly />
        {eventId
          ? <EventResult />
          : <OpenEventDialog handleClose={this.handleCloseOpenEventDialog} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventId: state.eventId
})

const mapDispatchToProps = (dispatch) => ({
  setEventId: (id) => dispatch(setEventId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
