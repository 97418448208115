import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BasicEventDataEditor from './BasicEventDataEditor'
import EventClassesEditor from './EventClassesEditor'
import ValikTimesEditor from './ValikTimesEditor'
import RaCoefitsentEditor from './RaCoefitsentEditor'
import firebase from 'firebase/app';


const styles = theme => ({
  root: {
    width: '90%',
  },
  cancelbutton: {
    position: "relative",
    float: "right"
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class CreateEventDialog extends React.Component {

  commonSteps = ['New event data', 'Classes'];
  valikSteps = ['Times', 'KP coefitsents'];

  constructor(props) {
    super(props);

    var startms = new Date();
    startms.setHours(0, 0, 0);
    var endms = new Date(startms);
    endms.setDate(endms.getDate() + 1)
    console.log(startms);
    console.log(endms);
    this.state = {
      activeStep: 0,
      steps: this.commonSteps.concat(this.valikSteps),
      event: {
        ajad: {},
        type: 'valik',
        name: '',
        shortlink: '',
        starttime: startms.getTime(),
        endtime: endms.getTime(),
        klassid: {},
        rakoef: [],
      },
      shortlinkerror: null
    };
  }
  autoshortlink = true;
  valikajadnewid = 100;

  deleteValikAeg = (id, it) => {
    console.log(id, it);
    this.setState(prevState => {
      delete prevState.event.ajad[id][it];
      return Object.assign({}, prevState);
    });
  }

  editValikAeg = (klass, id, aeg, penalty, per) => {
    console.log(aeg);

    this.setState(prevState => {
      prevState.event.ajad[klass][id].time = aeg;
      prevState.event.ajad[klass][id].penalty = penalty;
      prevState.event.ajad[klass][id].per = per;

      return Object.assign({}, prevState);
    })
  }

  addValikAeg = (classname, aeg, penalty, per, ord) => {
    console.log(classname);
    console.log(aeg);
    console.log(ord);
    let newitem = {
      time: aeg,
      penalty: penalty,
      per: per,
      order: ord
    }
    this.setState(prevState => {
      let newval = Object.assign(prevState.event);
      if (newval.ajad[classname] === undefined) {
        newval.ajad[classname] = [newitem];
      } else {
        newval.ajad[classname].push(newitem);
      }
      return { event: newval };
    })
  }

  addClass = (classname) => {
    this.setState(prevState => {
      let lyhlen = 1;
      let tahis = ''
      do {
        tahis = classname.substring(0, lyhlen);
        lyhlen++;
      } while (tahis in prevState.event.klassid);
      prevState.event.klassid[tahis] = classname;
      return Object.assign({}, prevState);

    });
  }
  removeClass = (classid) => {
    this.setState(prevState => {
      delete prevState.event.klassid[classid];
      return Object.assign({}, prevState);
    });
  }
  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (<BasicEventDataEditor
          newevent={true}
          type={this.state.event.type}
          name={this.state.event.name}
          shortlink={this.state.event.shortlink}
          starttime={this.state.event.starttime}
          endtime={this.state.event.endtime}
          hideresults={this.state.event.hideresults}
          fieldChanged={this.fieldChanged}
          shortlinkhelper={this.state.shortlinkerror}
        />);
      case 1:
        return (<EventClassesEditor
          klassid={this.state.event.klassid}
          autojoinclass={this.state.event.autojoinclass}
          teamchoosesclass={this.state.event.teamchoosesclass}
          fieldChanged={this.fieldChanged}
          addClass={this.addClass}
          removeClass={this.removeClass}
        />);
      case 2:
        if (this.state.event.type === "valik" || this.state.event.type === "suund")
          return (<ValikTimesEditor addValikAeg={this.addValikAeg} editValikAeg={this.editValikAeg} deleteValikAeg={this.deleteValikAeg} data={this.state.event.ajad} klassid={this.state.event.klassid} />)
        else
          return 'Not yet implemented!';
      case 3:
        if (this.state.event.type === "valik" || this.state.event.type === "suund")
          return (<RaCoefitsentEditor addKPValue={this.addKPValue} removeKPValue={this.removeKPValue} data={this.state.event.rakoef} />)
        else
          return "Not yet implemented.";
      default:
        return 'Uknown stepIndex';
    }
  }
  fieldChanged = (name, value) => {
    let self = this;
    console.log(this.state.event);
    if (name === 'name') {
      let sh = value.replace(/ /g, '');
      if (this.autoshortlink && sh.length > 0) {
        firebase.database().ref('events').child(sh).once('value', s => {
          if (!s.exists()) {
            self.setState({ event: Object.assign({}, this.state.event, { shortlink: sh }) })
          }
        })
      }
    }
    if (name === 'shortlink') {
      if (value.length === 0)
        this.autoshortlink = true;
      else
        this.autoshortlink = false;
      if (value.length > 0) {
        firebase.database().ref('events').child(value).once('value', s => {
          if (s.exists()) {
            console.log('Shortlink error');
            self.setState({ shortlinkerror: 'võistluse lühilink on juba kasutuses' });
          } else {
            self.setState({ shortlinkerror: null });
          }
        });
      }

      console.log('its shortlink', value);
    }
    if (name === "type") {
      if (value === "valik" || value === "suund")
        this.setState({ steps: this.commonSteps.concat(this.valikSteps) });
      if (value === "track" || value === "paevak")
        this.setState({ steps: this.commonSteps.slice(0, 1) });
    }
    this.setState(prevState => {
      let newval = Object.assign(prevState.event);
      newval[name] = value;
      return { event: newval };
    });
  }
  handleNext = () => {
    const { activeStep } = this.state;
    if (this.state.activeStep + 1 === this.state.steps.length) {
      const db = firebase.database();
      let event = {
        name: this.state.event.name,
        starttime: this.state.event.starttime,
        endtime: this.state.event.endtime,
        admins: {
          [firebase.auth().currentUser.uid]: 'owner'
        },
      }
      if (this.state.event.private)
        event.private = true;
      let eventdata = Object.assign({ speedpenalty: 5, type: this.state.event.type }, this.state.event);
      if (this.state.event.autojoinclass)
        eventdata.autojoinclass = true;
      if (this.state.event.teamchoosesclass)
        eventdata.teamchoosesclass = true
      let eventid = eventdata.shortlink;
      delete eventdata.starttime; delete eventdata.endtime; delete eventdata.name; delete eventdata.private; delete eventdata.shortlink;
      let eventdoc = {
        admins: event.admins,
        speed: {
          delay1: 10,
          delay2: 5,
          over1: 2,
          over2: 10,
          limit: 50
        }
      }
      let self = this;
      db.ref('events').child(eventid).set(event, () => {
        db.ref('eventsdata').child(eventid).child('data').set(eventdata, () => {
          firebase.firestore().collection('events').doc(eventid).set(eventdoc).then(() => {
            self.props.OpenEvent(eventid);
          });
        });
      });

      this.props.handleClose();
    }
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  validateStep = (activeStep) => {
    if (activeStep === 0 && (this.state.event.name === "" || this.state.event.shortlink === "" || this.state.shortlinkerror))
      return true;
    else if (activeStep === 1 && Object.keys(this.state.event.klassid).length < 1)
      return true;
    else if (activeStep === 2 && (this.state.event.type === 'valik' || this.state.event.type === 'suund') && Object.keys(this.state.event.ajad).length === 0)
      return true;
    return false;
    /* TODO For valik ajad step 2 testing could be better to have in every class at least one time */
  }

  addKPValue = (kp, newvalue) => {
    this.setState(prevState => {
      prevState.event.rakoef[kp] = newvalue;
      return Object.assign({}, prevState);
    });
  }

  removeKPValue = (kp) => {
    this.setState(prevState => {
      delete prevState.event.rakoef[kp];
      return Object.assign({}, prevState);
    });
  }

  render() {
    const { classes } = this.props;
    const { activeStep, steps } = this.state;


    console.log("too");
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        onClose={this.props.handleClose}
        aria-labelledby="open-event-title"
      >
        <DialogTitle id="open-event-title">Create New event</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&quot;re finished
              </Typography>
                <Button onClick={this.handleReset}>Reset</Button>
              </div>
            ) : (
                <div>
                  <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.backButton}
                    >
                      Back
                </Button>
                    <Button variant="contained" color="primary" disabled={this.validateStep(activeStep)} onClick={this.handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    <Button variant="contained" onClick={this.props.handleClose} color="primary" className={classes.cancelbutton}>
                      Cancel
                </Button>
                  </div>
                </div>
              )}
          </div>
        </DialogContent>
      </Dialog>

    );
  }
}

CreateEventDialog.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(CreateEventDialog);
