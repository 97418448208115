import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogContent, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import moment from 'moment';

const styles = theme => ({
  card: {
    maxWidth: false,
  },
});

class GetStartProtocolDialog extends Component {

  state = {
    url: "",
    times: null
  };

  doAction = () => {
    if (this.state.times) {
      this.state.times.forEach(entry => {
        let teamid = Object.keys(this.props.teamsList).find(t => this.props.teamsList[t].eolcode === entry.eolcode);
        if (!teamid)
          return;
        this.props.setTeamProp(teamid, "startms", entry.start.valueOf());
        this.props.setTeamProp(teamid, "finishms", entry.finish.valueOf());
      })

      this.props.handleClose();
      return;
    }
    let reqobj = {
      type: 'timesbyeolcode',
      eolcodes: {},
      url: this.state.url
    }
    Object.keys(this.props.teamsList).forEach(tid => {
      let eolcode = this.props.teamsList[tid].eolcode;
      if (eolcode && eolcode !== "")
        reqobj.eolcodes[eolcode] = true;
    });
    /*
    ws.requestWithCallback(JSON.stringify(reqobj), (d) => {
      console.log('Got response');
      console.log(d);
      let settimes = [];
      // TODO Show used date and allow modifying it.
      //const date = (d.date ? moment(d.date, 'DD.MM.YYYY') : moment(this.props.eventData.startms));
      const date = moment(this.props.eventData.startms);

      Object.keys(d.times).forEach(eolcode => {
        let start = moment(date.format("YYYY-MM-DD") + ' ' + d.times[eolcode].start);
        var parts = d.times[eolcode].time.split(":");
        let finish = start.clone();
        finish.add(parts[0], 'hours');
        finish.add(parts[1], 'minutes');
        finish.add(parts[2], 'seconds');
        settimes.push({
          eolcode: eolcode,
          start: start,
          finish: finish
        })
      })
      
      this.setState({ times: settimes, useddate: date });
    });
    */
  }

  render() {
    const { handleClose, teamsList } = this.props;
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogContent>
          <TextField
            id="outlined-full-width"
            label="Tulemuste URL"
            style={{ margin: 8 }}
            value={this.state.url}
            onChange={(event) => this.setState({ url: event.target.value })}
            placeholder="URL tulemuste splittidele"
            fullWidth
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {this.state.times && (
            <Table>
              <TableHead><TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Praegune start</TableCell>
                <TableCell>Uus start</TableCell>
                <TableCell>Praegune finish</TableCell>
                <TableCell>Uus finish</TableCell>
              </TableRow></TableHead>
              <TableBody>
                {this.state.times.map(entry => {
                  let teamid = Object.keys(teamsList).find(t => teamsList[t].eolcode === entry.eolcode);
                  if (!teamid)
                    return null;
                  let team = teamsList[teamid];
                  return (<TableRow key={entry.eolcode}>
                    <TableCell>{team.name}</TableCell>
                    <TableCell>{moment(team.startms).format('HH:mm:ss')}</TableCell>
                    <TableCell>{entry.start.format("HH:mm:ss")}</TableCell>
                    <TableCell>{moment(team.finishms).format('HH:mm:ss')}</TableCell>
                    <TableCell>{entry.finish.format("HH:mm:ss")}</TableCell>
                  </TableRow>)
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.doAction} color="primary">
            {this.state.times ? "Save" : "Fetch"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  eventData: state.currentEventData,
  teamsList: state.teamsList,
})


export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(GetStartProtocolDialog));
