import React from 'react';
import { connect } from 'react-redux';
import AreaOnMap from './AreaOnMap';


class AreasVisualizer extends React.Component {


    /* Could improve performance, to only update when teams and devices are added or removed **
        shouldComponentUpdate(nextProps, nextState) {
            if (Object.keys(nextProps.teamsList).length != Object.keys(this.props.teamsList).length)
                return true;
            for(let i=0;i<nextProps.teamsList.length;i++) {
                if (nextProps.teamsList[])
            }
    
        }
        */

    render() {
        const { map, selectedArea } = this.props;

        let arealist = [];
        if (selectedArea)
            arealist.push(selectedArea);

        return arealist.map( e => (<AreaOnMap 
            key={e.owner+e.id} 
            akey={e} 
            map={map}
            selected={selectedArea && e.owner === selectedArea.owner && e.id === selectedArea.id}/>))
        /*
        if (spdTicket !== null)
            return (
                <SpeedTrackOnMap map={map} key={spdTicket.tid+'-'+spdTicket.devices_id} devid={spdTicket.devices_id} teamid={spdTicket.tid} speedTrack />
            );
        let firststart = null;
		Object.keys(teamsList).forEach(t => {
			let sms = teamsList[t].starttime;
			if (firststart) {
				if (sms < firststart)
					firststart = sms;
			} else
				firststart = sms;
        })
        return Object.keys(teamsList).map(tid => (
            <TeamTracks key={tid} team={teamsList[tid]} map={map} teamid={tid} firststart={firststart}/>
        ));
        */
    }
}


const mapStateToProps = state => ({
    speedAreas: state.speedAreas,
    selectedArea: state.appState.selectedArea,
});


export default connect(mapStateToProps)(AreasVisualizer);
