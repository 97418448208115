import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { addUndo } from '../redux/actionsEventResult';
import { DialogContent, Paper, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { getVisitStats } from "../utils/ValikResultCalc";
import { download } from '../utils/SmallUtils';


import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import DummyValikCalculator from '../utils/DummyValikCalculator';


const styles = theme => ({
    card: {
        maxWidth: false,
    },
});

class KPStatDialog extends Component {

    state = {
        klass: null,
        results: {},
        view: "visit"
    }
    setTeamResult = (tid) => (result) => {
        console.log('got team result', result);
        this.setState(state => {
            return {
                results: Object.assign({}, state.results, { [tid]: result })
            }
        })
    }

    changeKLass = (event) => {
        this.setState({ klass: event.target.value })
    }

    changeView = (event) => {
        this.setState({ view: event.target.value })
    }

    exportData = (rows, columns, klass) => () => {
        let data = columns.map(c => c.title).join(";") + "\n";
        const regex = / \(.*\)/gi;
        rows.forEach(r => {
            data += columns.map(c => (r[c.name] || '').toString().replace(regex, '').replace(".", ",")).join(";") + "\n"
        });
        download(data, this.props.eventName + "_labimise_statistika_" + klass + ".csv", 'text/csv;charset=utf-8');
    }
    render() {
        const { handleClose,
            kpList,
            teamsList,
            answerResult,
            klassid,
            visitstats,
            sharedrakoef,
            answerscores,
            rakoef,
            wrongpenaltyenabled,
            firstkpvisitbonus,
            wrongcoef } = this.props;

        console.log(answerResult)
        const kpids = Object.keys(kpList).filter(i => !kpList[i].deleted).sort((l, r) => (isNaN(kpList[l].nr) || isNaN(kpList[r].nr)) ? kpList[l].nr.toString().localeCompare(kpList[r].nr) : kpList[l].nr - kpList[r].nr);
        const tableColumnExtensions = [
            { columnName: 'team', width: 150 },
            { columnName: 'kpsum', align: 'right', width: 100 }
        ];
        const leftColumns = ['team'];
        const rightColumns = ['kpsum'];
        if (!klassid)
            return null;

        let klass = this.state.klass || Object.keys(klassid)[0];
        const teams = Object.keys(teamsList).filter(tid => !teamsList[tid].disabled && (teamsList[tid].klassid || {})[klass]);
        let sharedrakoefids = {};
        if (sharedrakoef)
            sharedrakoef.split(",").forEach(sid => sharedrakoefids[sid] = true);
        const rows = teams.map(tid => {
            const team = teamsList[tid];
            let ob = { team: team.name };
            if (tid in this.state.results)
                ob.kpsum = this.state.results[tid].kpkokku;

            Object.entries(answerResult[tid] || {}).forEach(([k, v]) => {
                ob['KP' + k] = ((vtype) => {
                    switch (vtype) {
                        case 'visit':
                            return (<CheckCircleIcon fontSize="small" style={{ color: v.ok || kpList[k].allok ? green[500] : red[500] }} />);
                        case 'kpvalue': {
                            let val = 0;
                            let ra = kpList[k].ra;
                            let kpval = (sharedrakoefids[ra] ? Math.floor(rakoef[ra] / visitstats[tid][k]) : rakoef[ra])
                            let extra = [];
                            if (v.ok || answerscores) {
                                val += kpval;
                            }
                            if (wrongpenaltyenabled && !kpList[k].allok && !v.ok) {
                                val -= kpval * (wrongcoef || 1)
                                extra.push('V');
                            }
                            if (firstkpvisitbonus && visitstats[tid].firstkps[k]) {
                                val += kpval * firstkpvisitbonus;
                                extra.push('E');
                            }
                            return val + (extra.length > 0 ? " (" + extra.join(',') + ")" : '');
                        }
                        default:
                            return 'Unknown view type';
                    }
                })(this.state.view);
            })
            return ob;
        });
        const columns = [{ name: 'team', title: 'Team' }].concat(kpids.filter(kpid => kpList[kpid].nr !== 'F').map(kpid => { return { name: 'KP' + kpid, title: 'KP ' + kpList[kpid].nr } })).concat([{ name: 'kpsum', title: 'KP kokku' }]);

        return (
            <Dialog
                open={true}
                maxWidth={false}
            >
                <DialogContent>
                    <FormControl>
                        <InputLabel id="inputKlass">Klass</InputLabel>
                        <Select
                            labelId="inputKlass"
                            id="klassSelect"
                            value={klass}
                            onChange={this.changeKLass}
                        >
                            {Object.entries(klassid).map((([kid, klass]) => (
                                <MenuItem key={kid} value={kid}>{klass}</MenuItem>
                            )))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="inputShowType">Klass</InputLabel>
                        <Select
                            labelId="inputShowType"
                            id="showType"
                            value={this.state.view}
                            onChange={this.changeView}
                        >
                            <MenuItem value={"visit"}>Läbimised</MenuItem>
                            <MenuItem value={"kpvalue"}>KP väärtused</MenuItem>
                        </Select>
                    </FormControl>
                    {this.state.view === 'kpvalue' && (<Button onClick={this.exportData(rows, columns, klass)}>Export</Button>)}
                    <Paper>
                        <Grid rows={rows} columns={columns}>
                            <VirtualTable columnExtensions={tableColumnExtensions} />
                            <TableHeaderRow />
                            <TableFixedColumns
                                leftColumns={leftColumns}
                                rightColumns={rightColumns}
                            />
                        </Grid>
                    </Paper>

                    {teams.map(tid => (
                        <DummyValikCalculator
                            key={tid}
                            tid={tid}
                            klass={klass}
                            newResult={this.setTeamResult(tid)} />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary"> Close  </Button>
                </DialogActions>
            </Dialog >
        );
    }

}

const mapStateToProps = state => ({
    kpList: state.kpList,
    teamsList: state.teamsList,
    answerResult: state.answerResult,
    klassid: state.currentEvent.klassid,
    sharedrakoef: state.currentEvent.sharedrakoef,
    answerscores: state.currentEvent.answerscores,
    wrongpenaltyenabled: state.currentEvent.wrongpenaltyenabled,
    firstkpvisitbonus: state.currentEvent.firstkpvisitbonus,
    wrongcoef: state.currentEvent.wrongcoef,
    rakoef: state.currentEvent.rakoef,
    eventName: state.currentEvent.name,
    visitstats: getVisitStats(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(KPStatDialog));
