import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    appDrawer: {
        width: 320,
    },
    playControls: {
        height: '50px',
    },
    typography: {
        useNextVariants: true,
    },
});

export default theme;
