import { combineReducers } from 'redux';
import authUser from './reducerAuth';
import { firebaseObjectList, eventId, events, colorList, series, users, adminUsers, currentEvent, currentEventData, saveInProgress, snackBarState, locationData, speedingsData, speedConf, speedAreas, eventSpeedAreas, alertMsg, appState, replayData, eventAccess } from './reducerEvents';

const rootReducer = combineReducers({
    authUser,
    eventId,
    colorList,
    appState,
    events,
    series,
    users,
    adminUsers,
    currentEvent,
    eventAccess,
    teamAnswers: firebaseObjectList('teamAnswers'),
    kpList: firebaseObjectList('kpList'),
    kpData: firebaseObjectList('kpData'),
    kpAnswers: firebaseObjectList('kpAnswers'),
    spdtickets: firebaseObjectList('spdtickets'),
    answerResult: firebaseObjectList('answerResult'),
    teamsData: firebaseObjectList('teamsData'),
    teamsList: firebaseObjectList('teamsList'),
    currentEventData: currentEventData,
    saveInProgress,
    snackBarState,
    locationData,
    speedingsData,
    speedConf,
    speedAreas,
    eventSpeedAreas,
    alertMsg,
    replayData
});

export default rootReducer;
