import React from 'react';
import Badge from '@material-ui/core/Badge';


class CounterBadge extends React.Component {
    render() {
        if (this.props.count === 0)
            return this.props.content;
        else
            return(<Badge color="secondary" badgeContent={this.props.count}>
                {this.props.content}
                </Badge>)
    }
}

export default CounterBadge;
