import React, { Component } from 'react';
import GoogleMap from './GoogleMap';
import { withStyles } from '@material-ui/core/styles';
import { setEditKPId, setEditOverlayId, saveOverlayChanges, setAdjustOverlayLocState } from '../redux/actionsEvents';
import { connect } from 'react-redux';
import MapImageOverlay from '../utils/MapImageOverlay';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import KPIcon from './KPIcon';
import Slider from '@material-ui/core/Slider';
import TracksVisualizer from './TracksVisualizer';
import AreasVisualizer from './AreasVisualizer';

let overlayshost = "https://nutilogi.ee/fixed/overlays/";

const styles = theme => ({
	root: {
		width: '100%',
		height: '100%'
	},
	rootWithPlayControls: {
		width: '100%',
		height: 'calc(100% - ' + theme.playControls.height + ')'
	},
});

/*
const adjustOverlaySnackMessages = {
	click_img_1: 'Click on image on first landmark',
	click_map_1: 'Click on map on first landmark',
	click_img_2: 'Click on image on second landmark',
	click_map_2: 'Click on map on second landmark'
}
*/

class MapClickerOnce extends Component {
	componentDidMount() {
		console.log('Mount map clicker once');
		this.props.map.setOptions({ draggableCursor: 'crosshair' });
		console.log('adding listener once for map');
		window.google.maps.event.addListenerOnce(this.props.map, 'click', (e) => {
			console.log('Ja ongi siin', e.latLng.lat(), e.latLng.lng());
			this.props.onClick(e.latLng);
		})

	}
	componentWillUnmount() {
		console.log('unclick map clicker once');
		this.props.map.setOptions({ draggableCursor: null });
	}
	render() { return null }
}

class MapOverlay extends Component {

	componentDidMount() {
		const { overlay, map } = this.props;
		if (overlay.type === 'kml') {
			/*
			if (map.kmlLayers[o.id] === undefined) {
				map.kmlLayers[o.id] = new maps.KmlLayer(overlayshost + o.file);
			}
			map.kmlLayers[o.id].setMap(map);
			*/
		} else if (overlay.type === 'img') {
			/*
			this.overlay = new window.google.maps.GroundOverlay(overlayshost + overlay.file, overlay.latlngbounds);
			this.overlay.setMap(map);
			*/
			this.overlay = new MapImageOverlay(overlay.latlngbounds, overlayshost + overlay.file, map);
			this.overlay.setRotation(this.props.overlay.rotation);
			console.log('OverLay did mount', overlay)
			if (this.props.editable)
				this.addEditMarkers();
		}
	}
	componentWillUnmount() {
		this.overlay.setMap(null);
		delete this.overlay;
		console.log('Overlay did unmount');
		this.deleteEditMarkers();
	}
	deleteEditMarkers() {
		if (this.nw) {
			this.nw.setMap(null); delete this.nw;
			this.sw.setMap(null); delete this.sw;
			this.ne.setMap(null); delete this.ne;
			this.se.setMap(null); delete this.se;
			this.n.setMap(null); delete this.n;
			this.s.setMap(null); delete this.s;
			this.w.setMap(null); delete this.w;
			this.e.setMap(null); delete this.e;
			this.rotatemarker.setMap(null); delete this.rotatemarker;
		}
	}
	addEditMarkers() {
		console.log('Editable change', this.props.editable);
		this.overlay.setOpacity(this.props.opacity);
		this.rotation = this.props.overlay.rotation;
		this.newbounds = this.overlay.getBounds();

		/*
		var c = new window.google.maps.Circle({
			strokeColor: '#FF0000',
			strokeWeight: 2,
			fillColor: '#FFFFFF',
			center: { lat: b.north, lng: b.west },
			radius: 50,
			map: this.props.map
		})
		*/


		let self = this;
		let CornerMarker = (place) => {

			console.log('Corner marker constructor', this.props.overlay.latlngbounds);
			console.log('Corner marker constructor with self', self.props.overlay.latlngbounds);
			let bounds = this.props.overlay.latlngbounds;

			// [ [<uri> ,]* [ auto | crosshair | default | pointer | move | e-resize | ne-resize | nw-resize | n-resize | se-resize | sw-resize | s-resize | w-resize | text | wait | help | progress ] ] | inherit

			let icon = {
				path: "M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
				fillColor: '#FFFFFF',
				fillOpacity: (place.length === 2 ? 1 : 0.5),
				anchor: new window.google.maps.Point(0, 0),
				strokeWeight: 1,
				strokeColor: '#FF0000',
				scale: 1,
			}

			let mid = (p1, p2) => {
				return (p2 - p1) / 2 + p1;
			}
			let myCorner = (bounds) => {
				let ne = {}; let sw = {};
				if (bounds instanceof window.google.maps.LatLngBounds) {
					ne = { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() };
					sw = { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() };
				} else {
					ne = { lng: bounds.east, lat: bounds.north };
					sw = { lng: bounds.west, lat: bounds.south };
				}
				let proj = this.props.map.getProjection();

				let middle = { lng: mid(sw.lng, ne.lng), lat: mid(sw.lat, ne.lat) };
				let c = myCornerNoRotation(ne, sw, middle);
				let n = (this.rotation) * (Math.PI / 180);

				let pm = proj.fromLatLngToPoint(new window.google.maps.LatLng(middle));
				let p1 = proj.fromLatLngToPoint(new window.google.maps.LatLng(c));
				let p1m = { x: p1.x - pm.x, y: p1.y - pm.y };
				let p1mr = { x: p1m.x * Math.cos(n) - p1m.y * Math.sin(n), y: p1m.x * Math.sin(n) + p1m.y * Math.cos(n) };
				let p1r = { x: p1mr.x + pm.x, y: p1mr.y + pm.y };

				return proj.fromPointToLatLng(p1r);
			}

			let myCornerNoRotation = (ne, sw, middle) => {
				switch (place) {
					case 'nw':
						return { lng: sw.lng, lat: ne.lat };
					case 'ne':
						return ne;
					case 'sw':
						return sw;
					case 'se':
						return { lng: ne.lng, lat: sw.lat };
					case 'n':
						return { lat: ne.lat, lng: middle.lng };
					case 's':
						return { lat: sw.lat, lng: middle.lng };
					case 'w':
						return { lng: sw.lng, lat: middle.lat };
					case 'e':
						return { lng: ne.lng, lat: middle.lat };
					default:
						alert('unknown corner');
						return {}
				}
			}

			let marker = new window.google.maps.Marker({
				position: myCorner(bounds),
				cursor: place + '-resize',
				map: this.props.map,
				icon: icon,
				draggable: true,
				crossOnDrag: false,
			})
			window.google.maps.event.addListener(marker, 'mouseover', () => {
				if (this.dragging)
					return;
				console.log('mouse over got');
				let icon = marker.getIcon();
				icon.fillColor = '#FF7F7F';
				marker.setIcon(icon);
				//			this.marker.setOpacity(0.2);
			})
			window.google.maps.event.addListener(marker, 'mouseout', () => {
				if (this.dragging)
					return;
				let icon = marker.getIcon();
				icon.fillColor = '#FFFFFF';
				marker.setIcon(icon);
				marker.setOpacity(1);
			})
			window.google.maps.event.addListener(marker, 'dragstart', () => {
				console.log('dragstart');
				this.dragging = true;
			})
			window.google.maps.event.addListener(marker, 'dragend', () => {
				this.dragging = false;
				this.se.updateLocationFromOverlay();
				this.ne.updateLocationFromOverlay();
				this.sw.updateLocationFromOverlay();
				this.nw.updateLocationFromOverlay();
				this.n.updateLocationFromOverlay();
				this.s.updateLocationFromOverlay();
				this.w.updateLocationFromOverlay();
				this.e.updateLocationFromOverlay();
				console.log('dragend');
			})
			window.google.maps.event.addListener(marker, 'drag', () => {
				console.log('drag', this.nw, this.ne);
				let othercorner = {};
				switch (place) {
					case 'nw':
						othercorner = this.se.getPosition(); break;
					case 'sw':
						othercorner = this.ne.getPosition(); break;
					case 'ne':
						othercorner = this.sw.getPosition(); break;
					case 'se':
						othercorner = this.nw.getPosition(); break;
					case 'n':
						othercorner = this.sw.getPosition(); break;
					case 's':
						othercorner = this.nw.getPosition(); break;
					case 'w':
						othercorner = this.ne.getPosition(); break;
					case 'e':
						othercorner = this.nw.getPosition(); break;
					default:
						alert('unknown corner'); break;
				}
				let rotateback = (p) => {
					let proj = this.props.map.getProjection();
					let ob = this.props.overlay.latlngbounds;
					let nep = proj.fromLatLngToPoint(new window.google.maps.LatLng({ lng: ob.east, lat: ob.north }));
					let swp = proj.fromLatLngToPoint(new window.google.maps.LatLng({ lng: ob.west, lat: ob.south }));
					let tp = proj.fromLatLngToPoint(p);
					let middle = { x: mid(nep.x, swp.x), y: mid(nep.y, swp.y) };
					let n = (360 - this.rotation) * (Math.PI / 180);

					let p1m = { x: tp.x - middle.x, y: tp.y - middle.y };
					let p1mr = { x: p1m.x * Math.cos(n) - p1m.y * Math.sin(n), y: p1m.x * Math.sin(n) + p1m.y * Math.cos(n) };
					let p1r = { x: p1mr.x + middle.x, y: p1mr.y + middle.y };

					return proj.fromPointToLatLng(p1r);
				}
				self.newbounds = new window.google.maps.LatLngBounds(rotateback(marker.getPosition()));
				self.newbounds.extend(rotateback(othercorner));
				if (place.length === 1) {
					switch (place) {
						case 'n':
							self.newbounds.extend(rotateback(this.se.getPosition())); break;
						case 's':
							self.newbounds.extend(rotateback(this.ne.getPosition())); break;
						case 'w':
							self.newbounds.extend(rotateback(this.se.getPosition())); break;
						case 'e':
							self.newbounds.extend(rotateback(this.sw.getPosition())); break;
						default:
							break;
					}
				}
				this.props.onOverlayChanges({ bounds: self.newbounds, rotation: this.rotation })
				self.overlay.setBounds(self.newbounds);
			})
			marker.updateLocationFromOverlay = () => {
				marker.setPosition(myCorner(self.newbounds));
			}
			return marker;
		}

		this.nw = CornerMarker('nw');
		this.ne = CornerMarker('ne');
		this.se = CornerMarker('se');
		this.sw = CornerMarker('sw');
		this.n = CornerMarker('n');
		this.s = CornerMarker('s');
		this.w = CornerMarker('w');
		this.e = CornerMarker('e');

		let rotateicon = {
			path: "M0,-5L3,0L0,5L-3,0Z",
			fillColor: '#FFFFFF',
			fillOpacity: 1,
			anchor: new window.google.maps.Point(0, 0),
			strokeWeight: 1,
			strokeColor: '#FF0000',
			scale: 2,
		}
		let bounds = this.props.overlay.latlngbounds;
		this.rotatemarker = new window.google.maps.Marker({
			position: { lng: (bounds.east - bounds.west) / 4 + bounds.west, lat: (bounds.north - bounds.south) / 2 + bounds.south },
			cursor: 'crosshair',
			map: this.props.map,
			icon: rotateicon,
			draggable: true,
			crossOnDrag: false,
		})
		self.overlay.setRotation(this.rotation);
		let updateRotateMarkerPosition = () => {
			let bounds = this.props.overlay.latlngbounds;
			let norotp = { lng: (bounds.east - bounds.west) / 4 + bounds.west, lat: (bounds.north - bounds.south) / 2 + bounds.south };
			let middle = { lng: (bounds.east - bounds.west) / 2 + bounds.west, lat: (bounds.north - bounds.south) / 2 + bounds.south };
			let p = { lng: norotp.lng, lat: this.rotatemarker.getPosition().lat() };
			let angle = Math.atan2(p.lat - middle.lat, middle.lng - p.lng);
			//console.log('angle: ', angle, angle * 180 / Math.PI);
			this.rotatemarker.setPosition(p);
			this.rotation = angle * 180 / Math.PI;
			self.overlay.setRotation(this.rotation);
			this.se.updateLocationFromOverlay();
			this.ne.updateLocationFromOverlay();
			this.sw.updateLocationFromOverlay();
			this.nw.updateLocationFromOverlay();
			this.n.updateLocationFromOverlay();
			this.s.updateLocationFromOverlay();
			this.w.updateLocationFromOverlay();
			this.e.updateLocationFromOverlay();
			this.props.onOverlayChanges({ bounds: self.newbounds, rotation: this.rotation })
		}
		window.google.maps.event.addListener(this.rotatemarker, 'drag', () => {
			updateRotateMarkerPosition();
		});
		window.google.maps.event.addListener(this.rotatemarker, 'dragend', () => {
			updateRotateMarkerPosition();
		});

	}
	componentDidUpdate(prevProps) {
		//console.log(prevProps.adjuststate);
		if (prevProps.adjuststate !== this.props.adjuststate) {
			if (this.props.adjuststate === 'click_img_1' || this.props.adjuststate === 'click_img_2') {
				console.log('setup click listener');
				this.overlay.addClickListenerOnce((e) => {
					console.log('got our click', e);
					e.stopPropagation();
					let p = { width: parseInt(e.path[1].style.width), height: parseInt(e.path[1].style.height), x: e.layerX, y: e.layerY };
					if (this.props.adjuststate === 'click_img_1') {
						this.firstclick = p;
						this.props.setAdjustOverlayLocState('click_map_1');
					} else if (this.props.adjuststate === 'click_img_2') {
						this.secondclick = p;
						this.props.setAdjustOverlayLocState('click_map_2');
					}
				})
			}
			this.overlay.setOpacity(this.props.adjuststate === 'click_map_1' || this.props.adjuststate === 'click_map_2' ? 0.2 : 1);
		}
		if (prevProps.editable !== this.props.editable) {
			if (this.props.editable) {
				this.addEditMarkers();
			} else {
				this.deleteEditMarkers();
				let bounds = new window.google.maps.LatLngBounds();
				bounds.union(this.props.overlay.latlngbounds);
				this.overlay.setBounds(bounds);
				this.overlay.setOpacity(1);
			}
		}
		if (prevProps.opacity !== this.props.opacity && this.props.editable) {
			this.overlay.setOpacity(this.props.opacity);
		}
		if (prevProps.overlay.latlngbounds !== this.props.overlay.latlngbounds) {
			let bounds = new window.google.maps.LatLngBounds();
			bounds.union(this.props.overlay.latlngbounds);
			this.overlay.setBounds(bounds);
		}
		if (prevProps.overlay.rotation !== this.props.overlay.rotation) {
			this.overlay.setRotation(this.props.overlay.rotation);
			this.rotation = this.props.overlay.rotation;
		}
		//console.log('Overlay props change', prevProps, this.props);
	}
	/*
	        var sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

        var div = this.div;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
		div.style.height = (sw.y - ne.y) + 'px';
		*/

	mapClick = (ll) => {
		console.log('Click in map', ll);
		if (this.props.adjuststate === 'click_map_1') {
			this.firstmap = ll;
			let bounds = new window.google.maps.LatLngBounds();
			bounds.union(this.props.overlay.latlngbounds);
			let sw = bounds.getSouthWest();
			let ne = bounds.getNorthEast();
			let llw = ne.lng() - sw.lng();
			let llh = sw.lat() - ne.lat();
			let pxllw = llw / this.firstclick.width;
			let pxllh = llh / this.firstclick.height;
			let l1 = sw.lng() + (this.firstclick.width * pxllw);
			let l2 = ne.lat() + (this.firstclick.height * pxllh);
			console.log(ne, sw, llw, llh, pxllw, pxllh);
			console.log(l1, l2, ne.lng(), sw.lat());
			let tllx = sw.lng() + (this.firstclick.x * pxllw);
			let tlly = ne.lat() + (this.firstclick.y * pxllh);
			let xdiff = ll.lng() - tllx;
			let ydiff = ll.lat() - tlly;
			console.log(tllx, tlly, xdiff, ydiff);
			let nbounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(sw.lat() + ydiff, sw.lng() + xdiff), new window.google.maps.LatLng(ne.lat() + ydiff, ne.lng() + xdiff));
			this.overlay.setBounds(nbounds);


			console.log('First pair of points', this.firstclick, ll, nbounds);
			console.log(JSON.stringify(nbounds.toJSON()))
		} else if (this.props.adjuststate === 'click_map_2') {
			console.log('second click ready');
			console.log(this.firstclick, this.secondclick, this.firstmap, ll);

			let bounds = new window.google.maps.LatLngBounds();
			bounds.union(this.props.overlay.latlngbounds);
			let sw = bounds.getSouthWest();
			let ne = bounds.getNorthEast();
			let llw = ne.lng() - sw.lng();
			let llh = sw.lat() - ne.lat();
			let pxllw = llw / this.secondclick.width;
			let pxllh = llh / this.secondclick.height;
			let l1 = sw.lng() + (this.secondclick.width * pxllw);
			let l2 = ne.lat() + (this.secondclick.height * pxllh);
			console.log(ne, sw, llw, llh, pxllw, pxllh);
			console.log(l1, l2, ne.lng(), sw.lat());
			let tllx = sw.lng() + (this.secondclick.x * pxllw);
			let tlly = ne.lat() + (this.secondclick.y * pxllh);

			let ux = tllx - this.firstmap.lng();
			let uy = tlly - this.firstmap.lat();
			let vx = ll.lng() - this.firstmap.lng();
			let vy = ll.lat() - this.firstmap.lat();
			//ux = 2; uy = 2; vx = 0; vy = 3;
			let ul = Math.sqrt(ux * ux + uy * uy);
			let vl = Math.sqrt(vx * vx + vy * vy);
			let vdot = ux * vx + uy * vy;
			console.log(vdot / (ul * vl));
			let angle = Math.acos(vdot / (ul * vl)) * (180 / Math.PI);
			console.log('vecs:', this.firstmap.lng(), this.firstmap.lat(), tllx, tlly, ll.lng(), ll.lat());
			console.log('uv', ux, uy, vx, vy);
			console.log('uvl', ul, vl, vdot);
			console.log('angle: ', angle);
			this.overlay.setRotation(angle);
		}
		this.props.setAdjustOverlayLocState('click_img_2');
	}

	render() {
		return (this.props.adjuststate === 'click_map_1' || this.props.adjuststate === 'click_map_2') && (<MapClickerOnce map={this.props.map} onClick={this.mapClick} />)
	}
}

class BigMap extends Component {
	static defaultProps = {
		center: { lat: 59.412303, lng: 24.802180 },
		zoom: 11,
	};


	state = {
		kploc: { lat: 59.412303, lng: 24.802180 },
		currentTrack: [],
		scrollToIndex: undefined,
		map: null,
		editOpacity: 20
	}

	componentDidUpdate(prevProps) {
		if (prevProps.eventData.id !== this.props.eventData.id) {
			if (window.mainmap)
				this.zoomToEvent(this.props.eventData);
		}
	}


	mapref = map => {
		this.map = map;
		window.bigmap = this;
	}

	showAreas = (show) => {
		let target = (show ? this.map.map.map_ : null);
		for (let s of this.props.speedAreas)
			s.poly.setMap(target);
	}

	onChildClick = (key, childProps) => {
		console.log(childProps);
		if (childProps.onClick)
			childProps.onClick();
	}

	zoomToEvent(event) {
		if (event.mapinitcenterjson && event.mapinitzoom) {
			let c = JSON.parse(event.mapinitcenterjson);
			window.mainmap.setCenter({ lat: c.coordinates[1], lng: c.coordinates[0] })
			window.mainmap.setZoom(event.mapinitzoom);
		}
	}

	handleMapLoaded = (map, maps) => {
		map.trackzv = 1;
		//MapImageOverlay.prototype = new window.google.maps.OverlayView();

		map.testFunc = () => {
			console.log('in testf unc');
			console.log(this);
			console.log(map);
		}

		map.kmlLayers = {};

		map.showOverlay = (o) => {
			if (o.type === 'kml') {
				if (map.kmlLayers[o.id] === undefined) {
					map.kmlLayers[o.id] = new maps.KmlLayer(overlayshost + o.file);
				}
				map.kmlLayers[o.id].setMap(map);
			} else if (o.type === 'img') {
				var om = new maps.GroundOverlay(overlayshost + o.file, o.latlngbounds);
				om.setMap(map);
			}
		}

		map.hideOverlay = (o) => {
			if (o.type === 'kml') {
				if (map.kmlLayers[o.id] !== undefined) {
					map.kmlLayers[o.id].setMap(null);
				}
			}
		}

		window.mainmap = map;
		this.zoomToEvent(this.props.eventData);
		this.setState({ map: map });
	}

	changeOpacity = (e, v) => {
		this.setState({ editOpacity: v });
	}

	cancelEditOverlay = () => {
		this.props.setEditOverlayId(null);
	}

	overlayChanges = (changes) => {
		this.plannedOverlayChanges = changes;
	}
	saveEditOverlay = () => {
		let changes = {
			latlngbounds: this.plannedOverlayChanges.bounds.toJSON(),
			rotation: this.plannedOverlayChanges.rotation
		};
		this.props.saveOverlayChanges(this.props.editoverlayid, changes);
		this.props.setEditOverlayId(null);
	}
	render() {
		const { classes, kpList, kpData, eventData, editoverlayid, hiddenOverlays, trackType, eventAccess } = this.props;
		//console.log('Map render: ', hiddenOverlays, hiddenOverlays[1]);
		//console.log(this.state.editOpacity);
		console.log('evacc', eventAccess)
		return (
			<div className={trackType === 'replay' ? classes.rootWithPlayControls : classes.root}>
				<GoogleMap
					ref={this.mapref}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
					setdraggable={true}
					setoptions={{ fullscreenControl: true, mapTypeControl: true }}
					onGoogleApiLoaded={({ map, maps }) => { this.handleMapLoaded(map, maps) }}
					onChildClick={this.onChildClick}
				>
					{this.props.showKPs &&
						Object.entries(kpList).filter(([_, kp]) => !kp.deleted).filter(([kpid, _]) => kpData[kpid]?.loc).map(([kpid, kp]) => {
							return (<KPIcon key={kpid} kp={kp} lat={kpData[kpid].loc.lat} lng={kpData[kpid].loc.lng}
								onClick={eventAccess ? () => {  this.props.setEditKPId(kpid) } : undefined}
							/>);
						})
					}

				</GoogleMap>
				{this.state.map && !eventData.hidetracks && [(<TracksVisualizer key={1} map={this.state.map} />), (<AreasVisualizer key={2} map={this.state.map} />)]}
				{true && this.state.map && eventData.overlays && eventData.overlays.filter(o => (editoverlayid !== null ? o.id === editoverlayid : !hiddenOverlays[o.id])).map(o => (
					<MapOverlay key={o.id} overlay={o} map={this.state.map} editable={editoverlayid === o.id} opacity={this.state.editOpacity / 100} onOverlayChanges={this.overlayChanges} />
				))}

				{Boolean(editoverlayid) && (
					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						open={true}
						message={<div><Slider style={{ width: '200px' }} min={0} max={100} value={this.state.editOpacity} onChange={this.changeOpacity} /></div>}
						action={<div><Button color="secondary" onClick={this.cancelEditOverlay} >Cancel</Button><Button color="secondary" onClick={this.saveEditOverlay}>Save</Button></div>}
					/>)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		eventData: state.currentEvent,
		speedAreas: state.speedAreas,
		kpList: state.kpList,
		kpData: state.kpData || {},
		editoverlayid: state.appState.editoverlayid,
		hiddenOverlays: state.appState.hiddenOverlays,
		trackType: state.appState.trackType,
		showKPs: state.appState.showKPs,
		eventAccess: state.eventAccess,
	}
};
const mapDispatchToProps = (dispatch, ownProps) => ({
	setEditOverlayId: (id) => dispatch(setEditOverlayId(id)),
	saveOverlayChanges: (id, changes) => dispatch(saveOverlayChanges(id, changes)),
	setAdjustOverlayLocState: (state) => dispatch(setAdjustOverlayLocState(state)),
	setEditKPId: (id) => dispatch(setEditKPId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BigMap));
