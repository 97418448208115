import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TeamContextMenu from './TeamContextMenu';


class TeamEntryInMenu extends Component {

    lastcache = {};


    getSecondaryText = (teamid) => {
        if (!this.props.locationData)
            return null;


        let ld = this.props.locationData.points;
        if (ld.length === 0)
            return null;
        let point = ld[ld.length - 1];
        if (this.props.trkType === 'replay' && this.props.replayTime) {
            let sv = Math.floor(this.props.replayTime / 1000);

            let start = 0; let end = ld.length - 1;
            let key = teamid + '-' + this.props.devid;
            let middle = Math.floor((start + end) / 2);

            if (key in this.lastcache) {
                middle = this.lastcache[key];
                middle += sv - ld[middle].t;
                if (middle > end || middle < start)
                    middle = Math.floor((start + end) / 2);
            }

            if (ld[start].t > sv) {
                middle = start;
            } else if (ld[end].t < sv) {
                middle = end;
            } else {
                while (ld[middle].t !== sv && start < end) {
                    if (sv < ld[middle].t) {
                        end = middle - 1;
                    } else {
                        start = middle + 1;
                    }
                    middle = Math.floor((start + end) / 2);
                }
            }
            point = ld[middle];
            this.lastcache[key] = middle;
        }
        if (point) {
            let d = Number(point.d);
            let resp = (d < 1000 ? d.toFixed() + ' m' : (d / 1000).toFixed(1) + ' km');
            if (point.s !== -1 && point.t > Date.now() - 30000)
                resp += ', ' + (point.s * 3600 / 1000).toFixed() + ' km/h';

            return resp;
        }
        return null;
    }

    render() {
        const { teamName, teamColor, active, teamid } = this.props;

        //console.log('render team entry');
        //console.log(team);
        //  primary = team.name + ' (' + team.id + ',devid:' + team.devids[0].f1 + ')';
        return (
            <TeamContextMenu teamid={teamid}>
                <ListItem button={!!this.props.onClick} >

                    <ListItemText inset={true} primary={teamName} secondary={this.getSecondaryText(teamid)} onClick={this.props.onClick} />
                    <ListItemSecondaryAction>
                        <IconButton disabled={!this.props.secondaryClick} onClick={this.props.secondaryClick}>
                            <Icon style={{ color: teamColor }} >{active ? 'check_circle' : 'check_circle_outline'}</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>

                </ListItem></TeamContextMenu>);
    }

}

const mapStateToProps = (state, ownProps) => ({
    locationData: (state.locationData[ownProps.teamid] || {})[ownProps.devid],
    teamName: state.teamsList[ownProps.teamid].name,
    teamColor: state.teamsList[ownProps.teamid].col || state.teamsList[ownProps.teamid].color,
    trkType: state.appState.trackType,
    replayTime: state.replayData
})





export default connect(mapStateToProps)(TeamEntryInMenu);
