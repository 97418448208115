import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import KPDialog from './KPDialog';
import ValikTeamResultDialog from './ValikTeamResultDialog';
import EditTeamDialog from './EditTeamDialog';
import { setEditKPId, setViewTeamResult, setEditTeamId, closeDialog } from '../redux/actionsEvents';
import EditKPsDialog from './EditKPsDialog';
import SendMsgDialog from './SendMsgDialog';
import EditTeamsDialog from './EditTeamsDialog';
import RightDrawer from './RightDrawer';
import SpeedTrackConfDialog from './SpeedTrakcConfDialog';
import RestoreTeamDialog from './RestoreTeamDialog';


const styles = theme => ({
});

class DialogFactory extends React.Component {
    render() {
        const { editkpid, teamidforresult, editteamid, editkpsOpen, sendMsgOpen, editTeamsOpen, restoreTeamOpen, singleTrack, spdticket } = this.props;
        let dialogs = [];
        if (editkpsOpen)
            dialogs.push((<EditKPsDialog key='editkps' handleClose={this.props.closeDialog('editkps')} />));
        if (editkpid !== null)
            dialogs.push((<KPDialog key='kpdialog' kpid={editkpid} handleClose={() => { this.props.setEditKPId(null) }} />));
        if (teamidforresult !== null)
            dialogs.push((<ValikTeamResultDialog key='valikteamresult' handleClose={() => { this.props.clearViewTeamResult() }} teamid={teamidforresult} />));
        if (editteamid !== null)
            dialogs.push((<EditTeamDialog key='editteam' handleClose={() => { this.props.clearEditTeamId() }} tid={editteamid} />))
        if (sendMsgOpen)
            dialogs.push((<SendMsgDialog key="sendmsg" />))
        if (editTeamsOpen)
            dialogs.push((<EditTeamsDialog key="editteams" handleClose={this.props.closeDialog('editTeams')} />))
        if (restoreTeamOpen)
            dialogs.push((<RestoreTeamDialog key="restoreteam" handleClose={this.props.closeDialog('restoreTeam')} />))
        if (false && singleTrack !== null) {
            dialogs.push((<RightDrawer key="rightdrawer" tid={singleTrack.tid} devid={singleTrack.devid} />))
            if (false && spdticket === null)
                dialogs.push((<SpeedTrackConfDialog />));
        }
        return dialogs;
    }
}

const mapStateToProps = state => ({
    editkpid: state.appState.editkpid,
    teamidforresult: state.appState.teamidforresult,
    editteamid: state.appState.editteamid,
    editkpsOpen: state.appState.editkpsOpen,
    sendMsgOpen: state.appState.sendMsgOpen,
    editTeamsOpen: state.appState.editTeamsOpen,
    restoreTeamOpen: state.appState.restoreTeamOpen,
    singleTrack: state.appState.showSingleTrack,
    spdticket: state.appState.showSpeedingTicket,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setEditKPId: (kpid) => dispatch(setEditKPId(kpid)),
    clearEditTeamId: () => dispatch(setEditTeamId(null)),
    clearViewTeamResult: () => dispatch(setViewTeamResult(null)),
    closeDialog: (name) => () => dispatch(closeDialog(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DialogFactory));