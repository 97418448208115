
export function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            URL.revokeObjectURL(a.href);
        }, 0);
    }
}

export function isEmptyObj(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

/* Get nested object child. Not failing if some middle level is undefined
    const n = {hello: {world: 'Here'}};
    getNested(n, 'hello', 'world');
*/
export function getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
}

export function htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}


/*
function TestSound() {
    const [freq, setFreq] = useState(400);
    const [duration, setDuration] = useState(1);
    const [ramp, setRamp] = useState(0.00001);
    const [type, setType] = useState('sine');


    return (
        <Dialog open={true}>
            <DialogContent>
                Freq : <input value={freq} onChange={(ev) => setFreq(ev.target.value)} /> <br />
                Duration : <input value={duration} onChange={(ev) => setDuration(ev.target.value)} /> <br />
                Ramp : <input value={ramp} onChange={(ev) => setRamp(ev.target.value)} /> <br />
                Type : <input value={type} onChange={(ev) => setType(ev.target.value)} /> <br />
                <button onClick={() => { playBeep2(Number(freq), Number(duration), Number(ramp), type) }}>
                    Click me
        </button>
            </DialogContent>
        </Dialog>
    );
}
export function playBeep2(frequency = 440, duration = 1, rampvalue = 0.00001, type = 'sine') {
    if (duration === 1) {
        setTimeout(() => playBeep(1000, 0.1), 0)
        setTimeout(() => playBeep(1000, 0.1), 1000)
        setTimeout(() => playBeep(1000, 0.1), 2000)
        setTimeout(() => playBeep(1000, 0.1), 3000)
        setTimeout(() => playBeep(1000, 0.5), 4000)
    } else {
        setTimeout(() => playBeep(540, 0.2), 0)
        setTimeout(() => playBeep(540, 0.2), 1000)
        setTimeout(() => playBeep(540, 0.2), 2000)
        setTimeout(() => playBeep(540, 0.2), 3000)
        setTimeout(() => playBeep(940, 0.5), 4000)
    }
}
*/

let playContex = null;

export function playBeep(frequency = 440, duration = 1, type = 'sine') {
    if (!playContex) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext
        playContex = new window.AudioContext()
    }

    let currentTime = playContex.currentTime
    let osc = playContex.createOscillator()
    let gain = playContex.createGain()

    osc.connect(gain)
    gain.connect(playContex.destination)

    let currentvalue = gain.gain.value
    gain.gain.setValueAtTime(0.00001, currentTime)
    gain.gain.exponentialRampToValueAtTime(currentvalue, currentTime + 0.001)
    gain.gain.setValueAtTime(currentvalue, currentTime + duration - 0.01)
    gain.gain.exponentialRampToValueAtTime(0.00001, currentTime + duration)


    osc.onended = function () {
        gain.disconnect(playContex.destination)
        osc.disconnect(gain)
    }

    osc.type = type
    osc.frequency.value = frequency
    osc.start(currentTime)
    osc.stop(currentTime + duration)

}

export const estoniaCoords = { center: { lat: 58.88, lng: 25.14 }, zoom: 7 };

export const argouid = 'u31XLYs9Q0YjFmazxah3hlgholY2';
