import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect, ReactReduxContext } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import FlipMove from 'react-flip-move';
import './EventResult.css';
import { setViewTeamResult } from '../redux/actionsEvents';
import ValikTeamResult from './ValikTeamResult';
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'
import { orderrules, defaultorderrules } from '../utils/orderrules';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
});

class ValikEventResult extends Component {
    static contextType = ReactReduxContext;

    constructor(props, context) {
        super(props, context)
        this.state = {
            resultorder: props.teamsList.map(t => t.id),
            disableAnimations: true
        };
        this.results = {};
    }

    componentWillUnmount() {
        if (this.animationenabletimer !== undefined) {
            clearTimeout(this.animationenabletimer);
        }
        if (this.minutetimer !== undefined) {
            clearTimeout(this.minutetimer);
        }
    }

    componentDidUpdate(nextProps) {
        this.recalcOrder();
    }

    minuteUpdater = () => {
        if (this.minutetimer !== undefined)
            return;

        const { teamsList, eventResult } = this.props;
        if (eventResult === undefined || Object.keys(teamsList).length === 0)
            return;
        if (eventResult.data.find(r => (r.teamid in teamsList) && teamsList[r.teamid].finishms == null && !teamsList[r.teamid].ajayletus) === undefined)
            return;

        let ct = 60010 - (new Date().getTime() % 60000);
        this.minutetimer = setTimeout(() => {
            this.minutetimer = undefined;
            let changedkeys = this.testTimeTrahvChanges();
            if (changedkeys.length > 0) {
                this.setState((prevState, _) => {
                    return { changes: prevState.changes.concat(changedkeys) };
                });
            }
            this.minuteUpdater();
        }, ct);
    }

    displayKoht(koht) {
        if (koht === 1)
            return (<b>I</b>);
        else if (koht === 2)
            return (<b>II</b>);
        else if (koht === 3)
            return (<b>III</b>);
        return koht;
    }

    setTeamResult = (tid) => (result) => {
        this.results[tid] = result;
        if (this.recalctimeout)
            clearTimeout(this.recalctimeout);
        this.recalctimeout = setTimeout(() => {
            this.recalcOrder();
        }, 0);
    }

    recalcOrder = () => {
        let neworder = this.props.teamsList.sort((l, r) => {
            let lr = this.results[l.id];
            let rr = this.results[r.id];
            if (!lr || !rr)
                return 0;
            let resp = 0;
            this.props.orderrules.find(rule => (resp = rule.rule(lr, rr, l.id, r.id, this.context.store)))
            return resp;

            /*
            if (eventid < 118) {
                tmp = r.labitudkpsid - l.labitudkpsid;
                if (tmp !== 0) return tmp;
                tmp = l.goingtimes - r.goingtimes;
                if (tmp !== 0) return tmp;
            } else {
                tmp = l.goingtimes - r.goingtimes;
                if (tmp !== 0) return tmp;
                tmp = r.labitudkpsid - l.labitudkpsid;
                if (tmp !== 0) return tmp;
            }
            */

        }).map(t => t.id);
        if (isEqual(neworder, this.state.resultorder))
            return;

        this.setState({
            resultorder: neworder
        })
    }

    rendercount = 0;

    render() {
        const { classes, eventAccess, eventData, klass, haveSpdTicket } = this.props;

        //console.log('argo Rendercount', ++this.rendercount);

        if (!eventData || !eventData.rakoef)
            return null;

        if (eventData.hideresults && !eventAccess) {
            return "Tulemused ei ole veel avalikud";
        }
        let ajad;
        if ("normaalaeg" in eventData.ajad) ajad = eventData.ajad;
        else if (klass in eventData.ajad) ajad = eventData.ajad[klass];
        else return "Võistlus on veel seadistamata. Klassile ei ole pandud aegu.";

        const showwrongmarks = false; //(sortedresult.find(e => e.valekpcount > 0) !== undefined) && !eventData.wrongpenaltyenabled;
        let kpcolspan = 3 + (showwrongmarks ? 1 : 0) + eventData.rakoef.filter(e => e).length + (eventData.firstkpvisitbonus ? 1 : 0);
        let trahviajad =
            "normaalaeg" in ajad
            ? ajad.lisaajad || {}
            : Object.values(ajad)
            .filter((a) => a.penalty > 0)
            .sort((l, r) => l.order - r.order);
        let ajacolspan = 1 + trahviajad.length;
        let ajarowspan = 1;
        if (trahviajad.length === 1) {
            ajacolspan = 1;
            ajarowspan = 3;
        }
        let trahviajanumber = 1;
        let allowTeamClick = eventAccess || eventData.endtime < new Date().getTime();

        if (Object.keys(this.results).length > 0 && this.state.disableAnimations) {
            this.animationenabletimer = setTimeout(() => {
                this.setState({ disableAnimations: false });
            }, 800);
        }
        //console.log('render result', this.results);
        return (
            <Paper className={classes.root}>
                <table className={"rtable"}>
                    <thead>
                        <tr>
                            <td rowSpan={3}>KOHT</td>
                            <td rowSpan={3}>V&otilde;istkond</td>
                            <td colSpan={kpcolspan}>K P - P R E E M I A D</td>

                            {trahviajad.length === 1 && (
                                <td rowSpan={ajarowspan} colSpan={ajacolspan}><nobr>AJATRAHVID</nobr></td>
                            )}
                            {trahviajad.length > 1 && (
                                <td rowSpan={ajarowspan} colSpan={ajacolspan}><nobr>A J A T R A H V I D</nobr></td>
                            )}
                            {eventData.lisapunktidused && (
                                <td rowSpan={3}>Lisapunktid</td>
                            )}
                            {eventData.trahvidused && (
                                <td rowSpan={3}>Lisatrahvid</td>
                            )}
                            {Object.entries(eventData.ly || {}).map(([lyid, ly]) => (
                                <td rowSpan={3} key={lyid}>{ly.name}</td>
                            ))}
                            {haveSpdTicket && (
                                <td rowSpan={3}>Kiiruse<br />trahvid</td>
                            )}
                            <td rowSpan={3}><nobr>AEG</nobr></td>
                            <td rowSpan={3}>KOKKU</td>
                        </tr>
                        <tr>
                            <td colSpan={eventData.rakoef.filter(e => e).length}>Raskusastmed</td>
                            {eventData.firstkpvisitbonus && (<td rowSpan={2}>Esimene</td>)}
                            <td rowSpan={2}>L&auml;bitud<br />KP-sid</td>
                            {showwrongmarks && (<td rowSpan={2}>Vale<br />märked</td>)}
                            <td rowSpan={2}>Trahvid</td>
                            <td rowSpan={2}>Kokku</td>
                            {trahviajad.length > 1 && (
                                <td rowSpan={1} colSpan={trahviajad.length}>lisaajad</td>
                            )}
                            {trahviajad.length > 1 && (
                                <td rowSpan={2}>Kokku</td>
                            )}
                        </tr>
                        <tr>
                            {[...eventData.rakoef.keys()].filter(i => eventData.rakoef[i]).map(ra => (
                                <td key={ra}>{ra}</td>
                            ))}
                            {trahviajad.length > 1 && trahviajad.map((ta, idx) => (
                                <td key={idx}>{trahviajanumber++}</td>
                            ))}
                        </tr>
                    </thead>
                    <FlipMove appearAnimation='accordionVertical' staggerDurationBy={4} disableAllAnimations={this.state.disableAnimations} typeName='tbody' duration={800} leaveAnimation='none'>
                        {this.state.resultorder.map((e, idx) => (
                            <ValikTeamResult
                                onClick={allowTeamClick ? () => { this.props.setViewTeamResult(e) } : null}
                                key={e}
                                koht={idx + 1}
                                klass={klass}
                                tid={e}
                                havekiirustrahvid={haveSpdTicket}
                                newResult={this.setTeamResult(e)} />
                        ))}
                    </FlipMove>
                </table>
            </Paper>
        );
    }
}
/* FlipMove parameters playground : http://joshwcomeau.github.io/react-flip-move/examples/#/laboratory?_k=3vqm37 */

const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

const makeGetClassTeams = () => {
    return createDeepEqualSelector(
        (teamsList, klass) => {
            return Object.entries(teamsList).filter(([_, t]) => { return !t.disabled && (t.klassid || {})[klass] }).map(([tid, t]) => { t.id = tid; return t; });
        },
        values => {
            return values;
        }
    )
}

const makeGetHaveSpdTicket = () => {
    return createSelector([(state) => state.spdtickets], (t) => {
        if (!t)
            return null;
        return Object.values(t).find(teamtickets => Object.values(teamtickets).find(devtickets => Object.values(devtickets).find(ticket => ticket.state === "enabled"))) !== undefined;
    })
}

const makeGetOrderRules = () => {
    return createSelector(
        state => state.currentEvent.orderrules || defaultorderrules,
        rules => {
            return rules.split(",").map(r => orderrules[r])
        }
    );
}

const makeMapStateToProps = () => {
    const getClassTeams = makeGetClassTeams();
    const getHaveSpdTicket = makeGetHaveSpdTicket();
    const getOrderRules = makeGetOrderRules();
    const mapStateToProps = (state, props) => {
        return {
            haveSpdTicket: getHaveSpdTicket(state),
            eventData: state.currentEvent,
            eventAccess: state.eventAccess,
            teamsList: getClassTeams(state.teamsList, props.klass),
            orderrules: getOrderRules(state)
        }
    }
    return mapStateToProps
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    setViewTeamResult: (teamid) => dispatch(setViewTeamResult(teamid)),
});

ValikEventResult.propTypes = {
    klass: PropTypes.string.isRequired,
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withStyles(styles)(ValikEventResult));
