import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import { setReplayTime } from '../redux/actionsEventResult';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    speedControl: {
        width: '90px'
    }
});

var playSpeedList = [
    { name: '1x', interval: 100, step: 100 },
    { name: '10x', interval: 50, step: 500 },
    { name: '50x', interval: 50, step: 2500 },
    { name: '100x', interval: 50, step: 5000 },

]
class PlayControls extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentms: props.startms,
            timer: null,
            playSpeed: 2,
            age: 'kymme'
        }
        this.props.setReplayTime(props.startms);
    }

    sliderChanged = (m, t) => {
        let tv = Math.floor(t);
        this.setState({ currentms: tv });
        this.props.setReplayTime(tv);
    }

    playTimer = () => {
        this.setState((state, props) => {
            let resp = { currentms: state.currentms + playSpeedList[state.playSpeed].step }
            if (resp.currentms > props.endms) {
                clearInterval(state.timer);
                resp.currentms = props.endms;
                resp.timer = null;
            }
            this.props.setReplayTime(resp.currentms);
            return resp;
        });
    }

    togglePlay = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
            this.setState({ timer: null })
        } else {
            this.setState({ timer: setInterval(this.playTimer, playSpeedList[this.state.playSpeed].interval) })
        }
    }

    changePlaySpeed = (event) => {
        this.setState((state, props) => {
            let resp = { playSpeed: event.target.value };
            if (state.timer) {
                clearInterval(state.timer);
                resp.timer = setInterval(this.playTimer, playSpeedList[resp.playSpeed].interval);
            }
            return resp;
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidUpdate() {
        if (this.state.currentms < this.props.startms)
            this.sliderChanged(null, this.props.startms);
    }

    render() {
        const { classes, startms, endms } = this.props;
        const { currentms, timer, playSpeed } = this.state;

        return (
            <div className={classes.root}>
                <FormControl className={classes.speedControl}>
                    <Select
                        value={playSpeed}
                        onChange={this.changePlaySpeed}
                    >
                        {playSpeedList.map((s, idx) => (<MenuItem key={idx} value={idx}>{s.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <IconButton onClick={this.togglePlay}>
                    <Icon>{timer ? 'pause_circle_outline' : 'play_circle_outline'}</Icon>
                </IconButton>
                <Slider min={startms} max={endms} value={currentms} onChange={this.sliderChanged} />
                <Typography variant={'h4'}>{moment(currentms).format("HH:mm:ss")}</Typography>
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setReplayTime: (t) => dispatch(setReplayTime(t)),

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PlayControls));