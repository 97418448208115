import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import { addUndo } from '../redux/actionsEventResult';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';



const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    listSection: {
      background: 'inherit',
    },
    listSubHeader: {
        fontSize: 24,
        fontWeight: 700,
    }
});

  const columnData = [
    { id: 'nr', numeric: true, disablePadding: false, label: 'Nr' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 's', numeric: false, disablePadding: false, label: 'Kiirus' },
    { id: 'lastsec', numeric: false, disablePadding: false, label: 'Aeg viimasest' },
    { id: 'qp', numeric: false, disablePadding: false, label: 'Quality' },
  ];

class GPSStatusDialog extends Component {

    state =  {
      orderBy: 'nr',
      order: 'asc',
    }

  createSortHandler = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  render() {
    const { classes, teamsList, locationData } = this.props;
    const { orderBy, order } = this.state;
    
    console.log(teamsList);

    let ldata = {};
    Object.keys(teamsList).forEach( tk => {
        if (!teamsList[tk].enabled)
            return;
        if (!(tk in locationData)) {
            ldata[tk] = { s: 0, name: teamsList[tk].name, nr: teamsList[tk].nr , lastsec: 86400, lastsecd: '-', qp: '-'};
            return;
        }
        let larray = locationData[tk][Object.keys(locationData[tk])[0]];
        if (larray.length === 0) {
            ldata[tk] = { s: 0, name: teamsList[tk].name, nr: teamsList[tk].nr , lastsec: 86400, lastsecd: '-', qp: '-'};
            return;
        }
        let qp = 0;
        if (larray.length > 61 ) {
            let td = larray[larray.length-1].t-larray[larray.length-61].t;
            let p = 0;
            if (td < 120)
                p = (60 - (td-60));
            else {
                let i = larray.length-1;
                while( i > 0 && (larray[larray.length-1]-larray[i] < 60)) {
                    p++;i++;
                }
            }
            if (p > 60)
                p = 60;
            qp = (p*100/60)|0;
        }
        let last = larray[larray.length-1];
        let l1 = new Date().getTime()/1000 | 0;
        let lastsec = (l1 - last.t) | 0;
        let lastsecd = lastsec;
        if (lastsec > 3600)
            lastsecd = '> 1 tund'
        else if (lastsec > 300)
            lastsecd = '> 5 minutit'
        else if (lastsec > 60)
            lastsecd = '> 1 minut'
        if (lastsec < 10 )
            lastsec = 0;
        ldata[tk] = { s: last.s, name: teamsList[tk].name, nr: teamsList[tk].nr , lastsec: lastsec, lastsecd: lastsecd, qp: qp};
    });
    const sortedkeys = 
        order === 'desc'
          ? Object.keys(ldata).sort((a,b) => (ldata[b][orderBy] < ldata[a][orderBy] ? -1 : 1))
          : Object.keys(ldata).sort((a,b) => (ldata[a][orderBy] < ldata[b][orderBy] ? -1 : 1))

    return (
        <Dialog
            open={true}
            maxWidth={false}
            onClose={this.props.handleClose}
            aria-labelledby="open-teams-title"
        >
        <DialogTitle id="open-teams-title">GPS status</DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {columnData.map(column => (
                  <TableCell
                    key={column.id}
                    numeric={column.numeric}
                    padding={column.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ), this)}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedkeys.map( (k) => {
                  return(
                <TableRow hover key={k}>
                  <TableCell>
                    {ldata[k].nr}
                  </TableCell>
                  <TableCell>
                    {ldata[k].name}
                  </TableCell>
                  <TableCell>
                    {Math.round(ldata[k].s,2)}
                  </TableCell>
                  <TableCell>
                    {ldata[k].lastsecd}
                  </TableCell>
                  <TableCell>
                    {ldata[k].qp+" %"}
                  </TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
    currentEventData: state.currentEventData,
    locationData: state.locationData,
    teamsList: state.teamsList,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(GPSStatusDialog));
