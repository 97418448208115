import * as types from './actionTypes';
import initialState from './initialState';

export default function authReducer(state = initialState.authUser, action) {
    switch(action.type) {
      case types.AUTH_STATE_CHANGED:
        return action.user;
      default:
        return state;
    }
}
