import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { addUndo } from '../redux/actionsEventResult';
import GoogleMap from './GoogleMap';
import KPIcon from './KPIcon';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControlLabel, ListItem, List, Card, CardContent, CardHeader, ListItemText, IconButton, ListItemSecondaryAction, AppBar, Toolbar, DialogTitle, Tooltip, Checkbox, Select, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Switch from '@material-ui/core/Switch';
import { setEditKPId } from '../redux/actionsEvents';
import CheckCircle from '@material-ui/icons/CheckCircleTwoTone';
import ExifOrientationImg from 'react-exif-orientation-img'
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Slider from '@material-ui/core/Slider';
import firebase from 'firebase/app';
import Grid from '@material-ui/core/Grid';
import FBTextField from './FBTextField';
import { estoniaCoords } from '../utils/SmallUtils';


let proposeDependKP = null;
const KPPicker = (props) => {
  const kpList = Object.entries(useSelector(state => state.kpList)).filter(([id, kp]) => id !== props.kpid);
  if (!props.kp.dependkp) {
    if (proposeDependKP === null && kpList.length > 0)
      proposeDependKP = kpList[0][0];
  } else {
    proposeDependKP = props.kp.dependkp;
  }
  console.log(proposeDependKP)
  return <FormControl disabled={!Boolean(props.kp.dependkp) || kpList.length === 0}>
    <InputLabel>Sõltub KP-st</InputLabel>
    <Select
      value={props.kp.dependkp || ''}
      displayEmpty
      onChange={props.onChange}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {kpList.map(([id, kp]) => (
        <MenuItem key={id} value={id}>KP {kp.nr}</MenuItem>
      ))}
    </Select>
    <FormHelperText>KP mis tuleb vastata enne seda KP'd</FormHelperText>
  </FormControl>
}

const styles = theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    maxWidth: false,
  },
  successIcon: {
    color: 'green'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  longDesc: {
    paddingTop: '15px'
  }
});

const scaleSliderMarks = [
  {
    value: 100,
    label: '100%',
  },
  {
    value: 150,
    label: '150%',
  },
  {
    value: 200,
    label: '200%',
  },
  {
    value: 500,
    label: '500%',
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function FullScreenPhotoDialog(props) {
  const [values, setValues] = React.useState({
    scalevalue: 100,
    photoIndex: props.idx
  });

  return (
    <Dialog fullScreen open={true} TransitionComponent={Transition}>
      <DialogTitle>
        <AppBar >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close" className={props.classes.menuButton}>
              <Icon>close</Icon>
            </IconButton>
            <Slider color={'secondary'} marks={scaleSliderMarks} min={100} max={500} value={values.scalevalue} onChange={(_, value) => { setValues({ ...values, scalevalue: value }) }} />
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <div align="center">
          <ExifOrientationImg src={props.kp.photourls[values.photoIndex]} width={values.scalevalue + '%'} />
        </div>
      </DialogContent>
    </Dialog>);
}


function ValikVastusEditor(props) {

  const [values, setValues] = React.useState({
    newresp: props.value || '',
  });

  const submit = () => {
    props.submitValue(values.newresp);
    setValues({ ...values, newresp: '' });
  }
  let arialabel = (props.value === undefined ? "Add" : "Done");
  return (<ListItemText
    onClick={(e) => { e.stopPropagation() }}
    inset={props.inset !== undefined ? props.inset : true}
    primary={[(<TextField
      key="field"
      disabled={props.disabled}
      autoFocus={props.value !== undefined}
      onKeyDown={(e) => { if (e.keyCode === 13 && values.newresp.length > 0) submit() }}
      value={values.newresp}
      onChange={(event) => { setValues({ ...values, newresp: event.target.value }) }}
      label={props.value ? '' : 'Lisa valikvastus'} />),
    (<IconButton
      aria-label={arialabel}
      onClick={submit}
      disabled={props.disabled || values.newresp.length === 0}
      key="okbutton">
      <Icon aria-label={arialabel}>{props.value === undefined ? 'add_circle' : 'done'}</Icon>
    </IconButton>),
    props.value !== undefined && (<IconButton
      aria-label="Close"
      onClick={props.cancelEdit}
      key="closebutton">
      <Icon aria-label="Close">close</Icon>
    </IconButton>)
    ]}
  />);
}

function ValikVastusEditorListItem(props) {

  const [values, setValues] = React.useState({
    newresp: '',
  });

  const submit = () => {
    props.addKPResponse(props.kpid, values.newresp);
    setValues({ ...values, newresp: '' });
  }
  return (<ListItem >
    <ListItemText inset primary={(<TextField disabled={props.disabled} onKeyDown={(e) => { if (e.keyCode === 13) submit() }} value={values.newresp} onChange={(event) => { setValues({ ...values, newresp: event.target.value }) }} label="Lisa valikvastus" />)} ></ListItemText>
    <ListItemSecondaryAction>
      <IconButton
        edge="end"
        aria-label="Add"
        onClick={submit}
        disabled={props.disabled || values.newresp.length === 0}
        key="Add">
        <Icon aria-label="Add">add_circle</Icon>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>);

}

class KPDialog extends Component {

  constructor(props, context) {
    super(props, context);
    const { kp, kpData } = this.props;
    const loc = kpData.loc;
    this.state = {
      mapDraggable: true,
      defaultCenter: (kp !== undefined && loc !== undefined && loc.lat !== null && loc.lng !== null ? { lat: loc.lat, lng: loc.lng } : estoniaCoords.center),
      defaultZoom: (kp !== undefined && loc !== undefined && loc.lat !== null && loc.lng !== null ? 15 : estoniaCoords.zoom),
      kplatchange: 0,
      kplngchange: 0,
      fullScreenPhotoIndex: null,
    };
  }

  handleChange = name => event => {
    let value = event.target.value;
    const { kp, kpid, eventId, tahis } = this.props;
    if (name === 'tahis') {
      if (value.length === 1)
        value = value.toUpperCase();
      let ref = firebase.database().ref('eventsdata').child(eventId).child('kpanswer').child(kpid);
      ref.feedbackset(value);
      this.props.addUndo(name + kpid, tahis, 'KP tähis changed', v => {
        ref.feedbackset(v);
      });
      return;
    } else if (name === 'desc' || name === 'longdesc') {
      let ref = firebase.database().ref('eventsdata').child(eventId).child('kpdata').child(kpid).child(name);
      ref.feedbackset(value);
      this.props.addUndo(name + kpid, tahis, 'KP description changed', v => {
        ref.feedbackset(v);
      });
      return;
    } else if (name === 'ra') {
      value = Number(value);
    } else if (name === 'kohustuslik' || name === 'allok' || name === 'tyhistatud')
      value = value === 'true';

    console.log(this.props);
    let ref = firebase.database().ref('eventsdata').child(eventId).child('kp').child(kpid).child(name);
    ref.feedbackset(value);
    this.props.addUndo(name + kpid, kp[name], 'KP parameter ' + name + ' changed', v => {
      ref.feedbackset(v);
    });
  };

  handleKeyDown = name => event => {
    if (name === 'tahis') {
      console.log(event.key.length);
      if (event.target.value.length > 0 && event.key.length === 1) {
        event.target.value = "";
        //event.preventDefault();
      }
    } else if (name === 'nr') {
      let keyCode = event.keyCode;
      if (event.target.value.length > 0 && event.key.length === 1) {
        if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
          event.preventDefault();
        }
      }
    }
  }
  imgClicked = idx => () => {
    console.log('clicked on ', idx);
    this.setState({ fullScreenPhotoIndex: idx });
  }

  rmKPResponse = (respid, response) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('kpdata').child(this.props.kpid).child('responses').child(respid);
    ref.feedbackset(null);
    if (this.props.tahis === respid) {
      let ent = Object.entries(this.props.kpData.responses || {}).find(([k, _]) => k !== respid);
      if (ent !== undefined)
        firebase.database().ref('eventsdata').child(this.props.eventId).child('kpanswer').child(this.props.kpid).feedbackset(ent[0]);
      else
        firebase.database().ref('eventsdata').child(this.props.eventId).child('kpanswer').child(this.props.kpid).feedbackset(null);
    }
    this.props.addUndo(this.props.kpid + 'rmkpresponse', null, 'Removed ' + response + ' as a response from KP', v => {
      ref.feedbackset(response);
    });
  }

  updateKPResponse = (respid, value) => {
    const { eventId, kpid, kpData } = this.props;
    let ref = firebase.database().ref('eventsdata').child(eventId).child('kpdata').child(kpid).child('responses').child(respid);
    const oldvalue = kpData.responses[respid];
    ref.feedbackset(value);
    this.setState({ editrespid: undefined });
    this.props.addUndo(kpid + 'addresponse', null, 'Updated ' + oldvalue + ' to ' + value, v => {
      ref.feedbackset(oldvalue);
    });
  }

  addKPResponse = (kpid, response) => {
    console.log(kpid, response);

    const firstresponse = this.props.kpData.responses === undefined;
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('kpdata').child(kpid).child('responses').push();
    if (firstresponse) {
      firebase.database().ref('eventsdata').child(this.props.eventId).child('kpanswer').child(this.props.kpid).feedbackset(ref.key);
    }
    ref.feedbackset(response);
    this.props.addUndo(kpid + 'addresponse', null, 'Added ' + response + ' as a response to KP', v => {
      ref.feedbackset(null);
      if (firstresponse)
        firebase.database().ref('eventsdata').child(this.props.eventId).child('kpanswer').child(this.props.kpid).clear();
    });
  }
  dbref = (sec) => {
    return firebase.database().ref('eventsdata').child(this.props.eventId).child(sec).child(this.props.kpid);
  }
  dependChange = (event) => {
    const checked = event.target.checked;
    const ref = this.dbref('kp').child('dependkp');
    const value = proposeDependKP;
    if (value === null)
      return;
    ref.feedbackset((checked ? value : null));
    this.props.addUndo(this.props.kpid + 'dependkp', null, 'Sõltuv kp muudetud', v => {
      ref.feedbackset((checked ? null : value));
    });
  }
  changeDependKP = (event) => {
    const ref = this.dbref('kp').child('dependkp');
    const prevvalue = proposeDependKP;
    const value = event.target.value;
    ref.feedbackset(value);
    this.props.addUndo(this.props.kpid + 'dependkp', null, 'Sõltuv kp muudetud', v => {
      ref.feedbackset(prevvalue);
    });
  }
  render() {
    const { classes, kp, kpData, kpid, tahis, rakoef, eventId, archived } = this.props;
    console.log(this.props.isArchive);
    if (kp === undefined)
      return null;
    const loc = kpData.loc;

    if (rakoef === undefined) {
      alert('KP raskuastmed on puudu');
    }

    return (
      <Dialog
        className={classes.dialog}
        open={true}
        maxWidth={false}
        onClose={() => { this.props.setEditKPId(null) }}
      >
        <DialogContent>
          <Box justifyContent="center">
            <div style={{ height: '40vh', width: '110vh' }}>
              <GoogleMap
                defaultCenter={this.state.defaultCenter}
                onClick={(value) => {
                  if (loc === undefined) {
                    firebase.database().ref('eventsdata').child(this.props.eventId).child('kpdata').child(kpid).child("loc").feedbackset({
                      lat: value.lat, lng: value.lng
                    })
                  }

                }}
                onChildMouseDown={(hoverKey, childProps, mouseArgs) => {
                  console.log('child mouse down');
                  if (typeof (childProps.onMove) === 'function')
                    this.setState({ mapDraggable: false, options: { draggableCursor: 'crosshair' } })
                }}
                onChildMouseUp={(hoverKey, childProps, mouseArgs) => {
                  if (typeof (childProps.onMove) === 'function')
                    this.setState({ mapDraggable: true, options: { draggableCursor: 'url("https://maps.gstatic.com/mapfiles/openhand_8_8.cur"), auto' } })
                  if (typeof (childProps.onMouseUp) === 'function')
                    childProps.onMouseUp(mouseArgs);
                }}
                onChildMouseMove={(hoverKey, childProps, mouseArgs) => {
                  if (typeof (childProps.onMove) === 'function')
                    childProps.onMove(mouseArgs);
                }}
                draggable={this.state.mapDraggable}
                defaultZoom={this.state.defaultZoom}
                setoptions={{ fullscreenControl: false, mapTypeControl: true }}
                needclick={kp !== undefined && (loc === undefined || loc.lat === null || loc.lng === null)}
              >
                {kp !== undefined && loc !== undefined && loc.lat !== null && loc.lng !== null &&
                  (<KPIcon kp={kp} lat={loc.lat + this.state.kplatchange} lng={loc.lng + this.state.kplngchange}
                    onMove={(m) => {
                      let inloc = this.props.kpData.loc;
                      this.setState({ kplatchange: m.lat - inloc.lat, kplngchange: m.lng - inloc.lng })
                    }}
                    onMouseUp={(m) => {
                      if (m.lat !== undefined) {
                        let ref = firebase.database().ref('eventsdata').child(eventId).child('kpdata').child(kpid).child('loc');
                        ref.feedbackset({ lat: m.lat, lng: m.lng });
                        this.props.addUndo(new Date().getTime(), loc, 'KP location changed', v => {
                          ref.feedbackset(v);
                        });
                      }
                      this.setState({ kplatchange: 0, kplngchange: 0 })
                    }}
                  />)
                }
              </GoogleMap>
            </div>
          </Box>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row" wrap="nowrap" spacing={0}>
              <Grid item container direction="column" spacing={0}>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item xs={2}>
                    <TextField
                      id="nr"
                      label="Number"
                      value={kp.nr || ""}
                      onKeyDown={this.handleKeyDown('nr')}
                      onChange={this.handleChange('nr')}
                      inputProps={{ style: { textAlign: 'center' } }}
                      margin="normal"
                      variant="outlined"
                      disabled={Boolean(archived)}
                      inputProps={{
                        readOnly: archived,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={!!kpData.responses || kp.allok}
                      id="tahis"
                      label="Tähis"
                      value={(kpData.responses ? "" : tahis || "")}
                      onKeyDown={this.handleKeyDown('tahis')}
                      onChange={this.handleChange('tahis')}
                      inputProps={{ style: { textAlign: 'center' } }}
                      margin="normal"
                      variant="outlined"
                      disabled={Boolean(archived)}
                      inputProps={{
                        readOnly: archived,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FBTextField
                      id="descriptions"
                      label="Kirjeldus"
                      dbref={firebase.database().ref('eventsdata').child(eventId).child('kpdata').child(kpid).child('desc')}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      disabled={Boolean(archived)}
                      inputProps={{
                        readOnly: archived,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {kp.nr !== 'F' && (<TextField
                      select
                      label="Raskusaste"
                      value={kp.ra || ""}
                      onChange={this.handleChange('ra')}
                      margin="normal"
                      variant="outlined"
                      disabled={Boolean(archived)}
                      inputProps={{
                        readOnly: archived,
                      }}
                    >
                      {Object.keys(rakoef || {}).map(r => (<MenuItem key={r} value={r}>{r}</MenuItem>))}
                    </TextField>)}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justify="center" alignContent="center" spacing={3}>
                  <Grid item xs={3}>
                    {kp.nr !== 'F' && (<FormControlLabel
                      control={
                        <Switch
                          checked={kp.kohustuslik}
                          onChange={this.handleChange('kohustuslik')}
                          value={!kp.kohustuslik}
                          margin="normal"
                          disabled={Boolean(archived)}
                        />
                      }
                      label="Kohustuslik"
                    />)}
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Koha punktis võistelja saab ilma vastuseta kinnitada punkti läbimist.">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={kp.allok}
                            onChange={this.handleChange('allok')}
                            value={!kp.allok}
                            margin="normal"
                            disabled={Boolean(archived)}
                          />
                        }
                        label="Koha punkt"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    {kp.nr !== 'F' && (<FormControlLabel
                      control={
                        <Switch
                          checked={kp.tyhistatud}
                          onChange={this.handleChange('tyhistatud')}
                          disabled={Boolean(archived)}
                          value={!kp.tyhistatud} />
                      }
                      label="Tühistatud"
                      margin="normal"
                    />)}
                  </Grid>
                  <Grid item xs={3}>
                    <FBTextField
                      id="maxanswerdistance"
                      label="Max Answer distance"
                      onKeyDown={this.handleKeyDown('nr')}
                      dbref={firebase.database().ref('eventsdata').child(eventId).child('kp').child(kpid).child('maxanswerdistance')}
                      type="number"
                      margin="normal"
                      variant="outlined"
                      disabled={Boolean(archived)}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={5} className={classes.longDesc} >
                <FBTextField
                  label="Pikem kirjelus (mitu rida)"
                  multiline
                  variant="outlined"
                  rowsMax={5}
                  disabled={Boolean(archived)}
                  dbref={firebase.database().ref('eventsdata').child(eventId).child('kpdata').child(kpid).child('longdesc')}
                  fullWidth />
              </Grid>
            </Grid>

            <Grid item container direction="column" spacing={2}>
              <Grid item xs={10}>
                {kpData.responses && Object.keys(kpData.responses).length > 0 && (
                  <Card>
                    <CardHeader
                      title="Valikvastused"
                    />
                    <CardContent>
                      <List>
                        {kpData.responses && Object.keys(kpData.responses).map((respid) => (
                          <ListItem key={respid} button dense onClick={
                            () => {
                              firebase.database().ref('eventsdata').child(eventId).child('kpanswer').child(kpid).feedbackset(respid);
                            }
                          }>
                            {respid === tahis && (
                              <ListItemAvatar>
                                <CheckCircle className={classes.successIcon} />
                              </ListItemAvatar>)
                            }
                            {this.state.editrespid === respid ?
                              (<ValikVastusEditor inset={respid !== tahis} value={kpData.responses[respid]} submitValue={(v) => this.updateKPResponse(respid, v)} cancelEdit={() => this.setState({ editrespid: undefined })} />) :
                              (<ListItemText inset={respid !== tahis} primary={kpData.responses[respid]} />)}
                            {!archived && <ListItemSecondaryAction>
                              {this.state.editrespid !== respid && !kp.allok && (<IconButton
                                aria-label="Edit"
                                onClick={() => { this.setState({ editrespid: respid }) }}
                                key="Edit">
                                <Icon aria-label="Edit">edit</Icon>
                              </IconButton>)}
                              <IconButton
                                edge="end"
                                aria-label="Delete"
                                onClick={() => { this.rmKPResponse(respid, kpData.responses[respid]) }}
                                key="Delete">
                                <Icon aria-label="Delete">delete</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>}
                          </ListItem>
                        ))}
                        <ValikVastusEditor disabled={kp.allok} submitValue={(v) => this.addKPResponse(kpid, v)} />
                      </List>
                    </CardContent>
                  </Card>
                )}

                {!archived && !(kpData.responses && Object.keys(kpData.responses).length > 0) && (
                  <div style={{ display: "inline-block" }}>
                    <List dense={true}>
                      <ValikVastusEditorListItem disabled={kp.allok} addKPResponse={this.addKPResponse} kpid={kpid} />
                    </List>
                  </div>
                )}
              </Grid>
              {!archived && (<Grid item xs={10}>
                <FormControlLabel control={<Checkbox onChange={this.dependChange} name="dependKP" checked={Boolean(kp.dependkp)} value="dependKP" />}
                  label={<KPPicker kp={kp} kpid={kpid} onChange={this.changeDependKP} />} />
              </Grid>)}
            </Grid>
          </form>

          <Box>
            {kp.photourls && kp.photourls.map((u, idx) =>
              (<ExifOrientationImg onClick={this.imgClicked(idx)} src={u} width={500} key={u} />)
            )}
          </Box>
          {this.state.fullScreenPhotoIndex != null && (<FullScreenPhotoDialog kp={kp} classes={classes} idx={this.state.fullScreenPhotoIndex}
            handleClose={() => { this.setState({ fullScreenPhotoIndex: null }) }}
          />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.setEditKPId(null) }} color="primary">
            Close
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventId: state.eventId,
  archived: state.currentEvent.arch,
  rakoef: state.currentEvent.rakoef,
  kp: state.kpList[ownProps.kpid],
  kpData: state.kpData[ownProps.kpid] || {},
  tahis: (state.kpAnswers ? state.kpAnswers[ownProps.kpid] : null)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc)),
  setEditKPId: (kpid) => dispatch(setEditKPId(kpid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(KPDialog));
