import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import AlertDialog from './AlertDialog';
import InputLabel from '@material-ui/core/InputLabel';
import { Tooltip } from '@material-ui/core';


const styles = theme => ({
});

class RaCoefitsentEditor extends Component {


    state = {
        showAlert: false,
        kpvalue: null,
        addkp: null,
        availablekps: []
    }

    addKPValue = (addkp, kpvalue) => () => {
        this.setState({ addkp: null, kpvalue: null });
        this.props.addKPValue(addkp, kpvalue);
    }


    addClassical3 = () => {
        this.props.addKPValue(1, 3);
        this.props.addKPValue(2, 5);
        this.props.addKPValue(3, 7);
    }
    render() {
        const { classes, data, sharedrakoef } = this.props;

        if (data === undefined)
            return null;

        let availablekps = Array.from(Array(10), (x, idx) => idx + 1).filter(x => !data[x]);
        /*
        for (let i = 1; i < 10; i++) {
            if (Object.keys(this.props.data).findIndex((it) => { return Number(it) === i }) === -1)
                availablekps.push(i);
        }*/

        let addkp = this.state.addkp || availablekps[0];
        let kpvalue = this.state.kpvalue || 2 * addkp + 1;
        let sorteddata = Object.keys(data).sort((a, b) => { return a - b });
        let sharedrakoefids = {};
        (sharedrakoef || "").split(",").forEach(ra => sharedrakoefids[ra] = true);
        return (
            <div>
                {Object.keys(data).length === 0 &&
                    (<div><Button variant="contained" color="primary" onClick={this.addClassical3} className={classes.button}>
                        Add 1=3, 2=5, 3=7
                </Button></div>)}
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">KP</InputLabel>
                    <Select
                        value={addkp}
                        onChange={event => { this.setState({ addkp: event.target.value }) }}
                    >
                        {availablekps.map((it) => {
                            return (<MenuItem key={it} value={it}>{it}</MenuItem>)

                        })}
                    </Select>
                </FormControl>
                <TextField
                    id="kpvalue"
                    label="Value"
                    type="number"
                    value={kpvalue}
                    onChange={event => { this.setState({ kpvalue: Number(event.target.value) }) }}
                    style={{ width: 50 }}
                />
                <Button variant="contained" color="primary" onClick={this.addKPValue(addkp, kpvalue)} className={classes.button}>
                    Add KP value
            </Button>
                <Divider />
                <List>
                    {sorteddata.map((it) => {
                        return (
                            <ListItem key={it}>
                                <ListItemText primary={'KP ' + it + ' -- value: ' + data[it]} />
                                <ListItemSecondaryAction>
                                    <IconButton aria-label="Delete" onClick={() => this.props.removeKPValue(it)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                    <Tooltip title="Kas KP väärtus jagatakse võistlejate vahel ära">
                                        <IconButton aria-label="Shared point" onClick={() => this.props.toggleSharedRakoef(it)}>
                                            <Icon>{sharedrakoefids[it] ? "people" : "person"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
                {this.state.showAlert && <AlertDialog
                    open={this.state.showAlert}
                    onClose={() => this.setState({ showAlert: false })}
                    title="Classes with same name"
                    text="All classes should have a different name"
                />}
            </div>
        )
    }
}


export default withStyles(styles, { withTheme: true })(RaCoefitsentEditor);

