import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { addUndo } from '../redux/actionsEventResult';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appMessage: {
    minWidth: 400
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inLineTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
});

class SpeedingConfEditor extends Component {
  state = {
  }
  handleChange = name => event => {
    console.log('spd set ', name, event);
    let value = event.target.value;
    if (name === 'speedpenalty') {
      this.props.fieldChanged(name, Number(value));
      return;
    }
    if (name === 'openspddata') {
      this.props.fieldChanged(name, value === "true");
      return;
    }
    const doc = firebase.firestore().doc('events/' + this.props.eventId);
    doc.feedbackupdate({
      ['speed.' + name]: value
    });
    this.props.addUndo('spdcnf' + name, this.props.speedinfo[name], 'Changed speeding configuration', (undovalue) => {
      doc.feedbackupdate({
        ['speed.' + name]: undovalue
      });
    });

  }

  render() {
    const { classes, speedinfo, speedpenalty, openSpdData } = this.props;

    return (
      <form noValidate autoComplete="off">
        <div>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(openSpdData)}
                  onChange={this.handleChange('openspddata')}
                  value={!Boolean(openSpdData)}
                />
              }
              label="Kiirus andmed on avalikud"
            />
          </FormControl>
        </div>
        <TextField
          id="kiiuspiir"
          label="Üldine kiiruse piirang"
          value={speedinfo.limit}
          onChange={this.handleChange('limit')}
          type="number"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
          }}
          margin="normal"
          variant="outlined"
          helperText="Üldine kiiruse piirang km/h"
          style={{ width: '20ch' }}
        />
        <TextField
          id="kiirustrahv"
          label="Kiiruse ületuse trahv"
          value={speedpenalty}
          onChange={this.handleChange('speedpenalty')}
          type="number"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          helperText="Miinus punkte iga kiiruse ületamise eest"
          style={{ width: '20ch' }}
        />
        <br />
        <div style={{ alignItems: 'baseline', display: 'flex' }}>

          <Typography variant="h6" style={{ marginRight: '20px' }}> Trahv I: </Typography>
          <Typography variant="subtitle1"> Ületades piirkiirust </Typography>
          <TextField
            id="over1"
            value={speedinfo.over1}
            type="number"
            className={classes.inLineTextField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
            }}
            style={{ width: '10ch' }}
            onChange={this.handleChange('over1')}
            margin="normal"
          />
          <Typography variant="subtitle1"> (&gt;= {Number(speedinfo.limit) + Number(speedinfo.over1)} km/h üldalas)  </Typography>
          <TextField
            id="delay1"
            value={speedinfo.delay1}
            type="number"
            className={classes.inLineTextField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '5ch' }}
            onChange={this.handleChange('delay1')}
            margin="normal"
          />
          <Typography variant="subtitle1"> korda järjest </Typography>
        </div>


        <br />
        <div style={{ alignItems: 'baseline', display: 'flex' }}>
          <Typography variant="h6" style={{ marginRight: '20px' }}> Trahv II: </Typography>
          <Typography variant="subtitle1"> Ületades piirkiirust </Typography>
          <TextField
            id="over2"
            value={speedinfo.over2}
            type="number"
            className={classes.inLineTextField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">km/h</InputAdornment>,
            }}
            style={{ width: '10ch' }}
            onChange={this.handleChange('over2')}
            margin="normal"
          />
          <Typography variant="subtitle1"> (&gt;= {Number(speedinfo.limit) + Number(speedinfo.over2)} km/h üldalas)  </Typography>
          <TextField
            id="delay2"
            value={speedinfo.delay2}
            type="number"
            className={classes.inLineTextField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '5ch' }}
            onChange={this.handleChange('delay2')}
            margin="normal"
          />
          <Typography variant="subtitle1"> korda järjest </Typography>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.eventId,
  speedinfo: state.speedConf,
  speedpenalty: state.currentEvent.speedpenalty,
  openSpdData: state.currentEvent.openspddata,
  authUser: state.authUser
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SpeedingConfEditor));
