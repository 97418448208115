import React, { Component } from "react";

const Circle = props => (
  <svg
    {...props} width={40} height={40}
    style={props.nocenter ? props.style : Object.assign({ transform: 'translate(-50%,-50%)' }, props.style)}>
    <circle
      cx={20}
      cy={20}
      r={props.req ? 11 : 15}
      fill="none"
      stroke="#fd0000"
      strokeWidth={4}
    />
    {props.req && props.req === "true" &&
      <circle
        cx={20}
        cy={20}
        r={17}
        fill="none"
        stroke="#fd0000"
        strokeWidth={4}
      />
    }
  </svg>
);

const Rect = props => (
  <svg
    {...props} width={40} height={40} style={Object.assign({ transform: 'translate(-50%,-50%)' }, props.style)}>
    <rect
      x={props.req ? 8 : 2}
      y={props.req ? 8 : 2}
      width={props.req ? 24 : 36}
      height={props.req ? 24 : 36}
      fill="none"
      stroke="#fd0000"
      strokeLinejoin="round"
      strokeWidth={4}
    />
    {props.req && props.req === "true" &&
      <rect
        x={2}
        y={2}
        width={36}
        height={36}
        fill="none"
        stroke="#fd0000"
        strokeLinejoin="round"
        strokeWidth={4}
      />
    }
  </svg>
);

const th = Math.sqrt(36 * 36 - 18 * 18);
const tc = th / 3 * 2;
const Triangle = props => (
  <svg
    {...props} width={40} height={th + 2} style={Object.assign({ transform: 'translate(-50%, -' + tc + 'px)' }, props.style)}>
    <path
      d={"M2," + th + " L38," + th + ", L20,2 z"}
      fill="none"
      stroke="#fd0000"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeWidth={4}
    />
    {props.req && props.req === "true" &&
      <g transform-origin={"20px " + tc + "px"} transform="scale(0.5)">
        <path
          d={"M2," + th + " L38," + th + ", L20,2 z"}
          fill="none"
          stroke="#fd0000"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth={6}
        />
      </g>
    }
  </svg>
);

/*
const SquareTriangle = props => (
  <svg
  {...props} width={40} height={40} style={Object.assign({transform: 'translate(-50%,-50%)'}, props.style)}>
    <path
      d={"M2,36 L36,36, L20,2 z"}
      fill="none"
      stroke="#fd0000"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeWidth={4}
    />
  {props.req &&
      <path
        d={"M12,30 L27,30, L20,14 z"}
        fill="none"
        stroke="#fd0000"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={4}
      />
  }
  </svg>
);
*/

class KPIcon extends Component {

  render() {
    let svg = ((ra, props) => {
      switch (ra) {
        case 2:
          return (<Triangle {...props} />);
        case 3:
          return (<Rect {...props} />);
        default:
          return (<Circle {...props} />);
      }
    })(this.props.kp.ra, { req: String(this.props.kp.kohustuslik), style: { cursor: (this.props.onClick ? 'pointer' : undefined) } });

    return (svg);
  }
};

export default KPIcon;

