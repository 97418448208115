

class MapImageOverlay {
    constructor(bounds, imgurl, map) {
        this.bounds = new window.google.maps.LatLngBounds();
        this.bounds.union(bounds);
        this.imgurl = imgurl;
        this.map = map;
        this.div = null;
        this.rotation = null;
        this.setMap(map);
    }

    onAdd = () => {
        console.log('onadd');
        var div = document.createElement('div');
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        if (this.rotation)
            div.style.transform = 'rotate(' + this.rotation + 'deg)';
        div.style.position = 'absolute';
        //div.innerHTML = '<svg width=100 height=100 style="position: absolute" onclick="alert(\'got click\')"><circle cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" /></svg><img src=' + this.imgurl + ' style="width: 100%; height: 100%; position: absolute;" />';

        var img = document.createElement('img');
        img.src = this.imgurl;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        div.appendChild(img);

        this.div = div;

        var panes = this.getPanes();
        panes.overlayLayer.appendChild(div);
        //panes.overlayMouseTarget.appendChild(div);
        //this.getPanes().overlayMouseTarget.appendChild(div);

        /*
        window.google.maps.event.addDomListener(img, 'click', function (e) {
            console.log('click in img', e);
            //            window.google.maps.event.trigger(me, 'click');
        });
        */
    }
    setBounds = (b) => {
        let ne1 = b.getNorthEast();
        let sw1 = b.getSouthWest();
        let ne2 = this.bounds.getNorthEast();
        let sw2 = this.bounds.getSouthWest();
        console.log('1:', sw2.lng(), sw2.lat(), ne2.lng(), ne2.lat());
        console.log('2:', sw1.lng(), sw1.lat(), ne1.lng(), ne1.lat());
        console.log('set new bounds', b);
        this.bounds = b;
        this.draw();
    }

    getBounds = () => {
        return this.bounds;
    }

    setOpacity = (v) => {
        if (this.div)
            this.div.style.opacity = v;
    }

    addClickListenerOnce = (f) => {
        this.div.parentNode.removeChild(this.div);
        var panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.div);

        window.google.maps.event.addDomListener(this.div, 'mouseover', () => {
            console.log('mouseover in domlistern on div');
            this.map.setOptions({ draggableCursor: 'crosshair' });
        })
        window.google.maps.event.addDomListener(this.div, 'mouseout', () => {
            console.log('mouseout in domlistern on div');
            this.map.setOptions({ draggableCursor: null });
        })
        window.google.maps.event.addDomListenerOnce(this.div, 'click', (...args) => {
            window.google.maps.event.clearInstanceListeners(this.div);
            this.map.setOptions({ draggableCursor: null });
            f(...args)
        });
    }

    addDomListenerOnce = (eventname, f) => {
        // Switch always to mousetarget.. Gives only just small overhead.
        this.div.parentNode.removeChild(this.div);
        var panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.div);

        window.google.maps.event.addDomListenerOnce(this.div, eventname, f);
    }

    clearListeners = (eventname) => {
        window.google.maps.event.clearListeners(this.div, eventname);
    }

    draw = () => {
        var overlayProjection = this.getProjection();

        var sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

        var div = this.div;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';
        //div.style.transform = 'rotate(10deg)';

        //let ne2 = this.bounds.getNorthEast();
        //let sw2 = this.bounds.getSouthWest();
        //console.log('b:', sw2.lng(), sw2.lat(), ne2.lng(), ne2.lat());
        //console.log('draw:', sw.x, ne.y, (ne.x - sw.x), (sw.y - ne.y));

    }

    setRotation = (d) => {
        this.rotation = d;
        if (this.div)
            this.div.style.transform = 'rotate(' + d + 'deg)';
        //setTimeout(() => { this.setRotation(d + 5) }, 500);
    }
    rotat = () => {
        this.div.style.transformOrigin = 'top left';
        this.div.style.transform = 'rotate(' + this.d + 'deg)';
        this.d = this.d + 5;
        setTimeout(this.rotat, 500);
    }
    onRemove = () => {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
        console.log('onRemove');
    }
}

export default MapImageOverlay;