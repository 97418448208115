
import moment from 'moment';
import isEqual from 'lodash/isEqual'
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import firebase from "firebase/app";



export const calculateTimeResult = (result, starttime, finishtime) => {
    result.timepenaltytotal = 0;
    result.timepenaltys = [];
    let nextchange = finishtime ? undefined : null;

    const ajad = result.data.ajad;
    if (starttime) {
        let spent = Math.floor(((finishtime ? finishtime : new Date().getTime()) - starttime) / 1000);
        result.data.spent = spent;
            if ("normaalaeg" in ajad) {
      if (spent < ajad.normaalaeg) {
        nextchange = ajad.normaalaeg - spent;
        spent = 0;
      } else spent -= ajad.normaalaeg;
      if (ajad.lisaajad) {
      for (let t of (ajad.lisaajad)) {
        let used = spent > t.time ? t.time : spent;
        spent -= used;
        if (spent === 0 && nextchange === null) {
          if (t.per === 0) nextchange = t.time - used;
          else nextchange = t.per - (used % t.per);
        }
        let tp = Math.ceil(used / t.per) * t.penalty;
        result.timepenaltys.push(tp);
        result.timepenaltytotal += tp;
      }}
    } else {
        for (let t of Object.values(ajad).sort((l, r) => l.order - r.order)) {
            let used = spent > t.time ? t.time : spent;
            spent -= used;
            if (spent === 0 && nextchange === null) {
                if (t.per === 0)
                    nextchange = t.time - used;
                else
                    nextchange = t.per - used % t.per;
            }
            if (t.penalty === 0)
                continue;
            //let tp = Math.floor(used / t.per * t.penalty);
            let tp = Math.ceil(used / t.per) * t.penalty;
            result.timepenaltys.push(tp);
            result.timepenaltytotal += tp;
        }
    }
        if (spent > 0)
            result.data.overtime = true;
    } else {
            if ("normaalaeg" in ajad) {
      result.data.spent = 0;
      for (let i = 0; i < (ajad.lisaajad || {}).length; i++)
        result.timepenaltys.push(0);
    } else {
        for (let t of Object.values(ajad).sort((l, r) => l.order - r.order)) {
            result.data.spent = 0
            if (t.penalty === 0)
                continue;
            result.timepenaltys.push(0);
        }
    }
    }
    return nextchange;
}

const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

const makeGetSpdTickets = () => {
    return createDeepEqualSelector(
        (teamtickets) => {
            if (!teamtickets)
                return null;
            let resp = {};
            Object.entries(teamtickets).forEach(([dev, devtickets]) => {
                Object.entries(devtickets).forEach(([tstamp, ticket]) => {
                    if (ticket.state !== "enabled")
                        return;
                    if (!(dev in resp))
                        resp[dev] = {};
                    resp[dev][tstamp] = ticket;
                });
            });
            return resp;
        },
        values => {
            return values;
        }
    )
}

let kpstats = {};

export const getVisitStats = createSelector(
    [
        state => state.answerResult,
        state => state.teamAnswers,
        state => state.kpList,
        state => state.teamsList,
        (state) => state.currentEvent.answerscores,
    ],
    (answerResult, teamAnswers, kpList, teamsList, answerscores) => {
        const trace = firebase.performance().trace('kpstats');
        trace.incrementMetric('kps', Object.keys(kpList).length);
        trace.incrementMetric('teams', Object.keys(answerResult).length);
        trace.start();
        Object.keys(answerResult).filter(tid => !teamsList[tid].disabled).forEach(tid => { if (!(tid in kpstats)) kpstats[tid] = { firstkps: {} } });
        let teamupdate = {};
        Object.keys(kpList).forEach(kpid => {
            const kpvisticount = Object.entries(answerResult).reduce((total, [tid, teamkps]) => (teamsList[tid] && !teamsList[tid].disabled && teamkps[kpid] && (answerscores || teamkps[kpid].ok)) ? total + 1 : total, 0);
            const firstteam = Object.entries(teamAnswers).reduce((firstteam, [tid, teamkps]) => (teamsList[tid] && !teamsList[tid].disabled && teamkps[kpid] && (answerscores || (answerResult[tid][kpid] || {}).ok) && (firstteam == null || firstteam.aeg > teamkps[kpid].aeg)) ? { tid: tid, aeg: teamkps[kpid].aeg } : firstteam, null);
            //console.log('kp:', kpid, ', visitcount: ', kpvisticount);
            Object.entries(answerResult).filter(([tid, _]) => !teamsList[tid].disabled).forEach(([tid, teamkps]) => {
                if (firstteam) {
                    if (kpstats[tid].firstkps[kpid] && firstteam.tid !== tid) {
                        teamupdate[tid] = true;
                        delete kpstats[tid].firstkps[kpid];
                    } else if (firstteam.tid === tid && (!kpstats[tid].firstkps[kpid])) {
                        teamupdate[tid] = true;
                        kpstats[tid].firstkps[kpid] = firstteam.aeg
                    }
                }
                if (teamkps[kpid] && (teamkps[kpid].ok || answerscores)) {
                    if (kpstats[tid][kpid] !== kpvisticount) {
                        teamupdate[tid] = true;
                        kpstats[tid][kpid] = kpvisticount;
                        //console.log('assign kp stat', tid, kpid, kpvisticount);
                    }
                }
            })
        });
        Object.keys(teamupdate).forEach(tid => {
            kpstats[tid] = Object.assign({}, kpstats[tid]);
        })
        //console.log('final stats', kpstats, answerResult);
        trace.stop();
        return kpstats;
    }
)


export const makeGetResult = () => {
    const resultFuncHolder = {};
    const getSpdTickets = makeGetSpdTickets();

    const emptyobj = {};

    return createSelector(
        [
            (_, props) => props.tid,
            (_, props) => props.klass,
            (state, props) => state.answerResult[props.tid],
            (state, props) => state.teamAnswers[props.tid],
            (state) => state.kpAnswers,
            (state) => state.kpList,
            (state) => state.currentEvent.rakoef,
            (state) => state.currentEvent.sharedrakoef,
            (state) => state.currentEvent.answerscores,
            (state) => state.currentEvent.wrongpenaltyenabled,
            (state) => state.currentEvent.wrongcoef || 1,
            (state) => state.currentEvent.ly,
            (state) => state.currentEvent.firstkpvisitbonus,
            (state, props) => state.teamsList[props.tid].lypoints || emptyobj,
            (state, props) => state.teamsList[props.tid].starttime,
            (state, props) => state.teamsList[props.tid].finishtime,
            (state) => state.currentEvent.ajad,
            (state, props) => getSpdTickets(props.havekiirustrahvid ? state.spdtickets[props.tid] : null),
            (state) => state.currentEvent.speedpenalty,
            (state, props) => (state.currentEvent.sharedrakoef || state.currentEvent.firstkpvisitbonus ? getVisitStats(state, props)[props.tid] : emptyobj),
            (_, props) => { resultFuncHolder.newResult = props.newResult; return resultFuncHolder; }
        ],
        (tid, klass, answerResult, teamAnswers, kpAnswers, kpList, rakoef, sharedrakoef, answerscores, wrongpenaltyenabled, wrongcoef, ly, firstkpvisitbonus, lypoints, starttime, finishtime, ajad, spdtickets, spdpenalty, visitstats, reportNewResult) => {

            //console.log('Recalculatingf result for ', tid, sharedrakoef, starttime, visitstats);
            let sharedrakoefids = {};
            if (sharedrakoef)
                sharedrakoef.split(",").forEach(sid => sharedrakoefids[sid] = true);
            let newresult = {
                rasums: rakoef.map(_ => 0),
                timepenaltys: [],
                ly: lypoints,
                okcount: 0,
                wrongcount: 0,
                kppenalty: 0,
                data: {
                    starttime: starttime,
                    finishtime: finishtime,
                    ajad: "normaalaeg" in ajad ? ajad : ajad[klass],
                }
            };
            let okkps = {}
            let kpok = ([id, v]) => {
                if (kpList[id].tyhistatud)
                    return;
                let isok = kpList[id].allok;
                if (!isok)
                    isok = ('answer' in v ? v.answer === kpAnswers[id] : v.ok)
                if (isok === undefined) {
                    return;
                }
                let ra = kpList[id].ra || 1;
                if (isok || answerscores) {
                    if (sharedrakoef && (visitstats === undefined || !(id in visitstats))) {
                        console.error("visitstats is empty when it should not be. Might be there area entries in kpanswers but not in answerresult. team id: " + tid + ", kp id:" + id);
                    }
                    //console.log('hmm', ra, id, sharedrakoefids[ra], visitstats[id]);
                    newresult.rasums[ra] += (sharedrakoefids[ra] ? Math.floor(rakoef[ra] / visitstats[id]) : rakoef[ra]);
                    newresult.okcount++;
                    okkps[id] = true;
                }
                if (!isok) {
                    newresult.wrongcount++;
                    if (wrongpenaltyenabled)
                        newresult.kppenalty += (sharedrakoefids[ra] ? Math.floor(rakoef[ra] / visitstats[id]) : rakoef[ra]) * wrongcoef
                }
            }
            if (teamAnswers && Object.keys(teamAnswers).length > 0) {
                Object.entries(teamAnswers).forEach(kpok)
            } else {
                Object.entries(answerResult || {}).forEach(kpok);
            }

            calculateTimeResult(newresult, starttime, finishtime);
            Object.entries(kpList).filter(([id, e]) => e.kohustuslik && !e.tyhistatud).forEach(([id, e]) => {
                console.log(id, e);
                if (!okkps[id]) {
                    let ra = kpList[id].ra || 1;
                    newresult.kppenalty += rakoef[ra] * 2;
                }
            })
            if (visitstats && firstkpvisitbonus) {
                newresult.firstkpsbonus = Object.keys(visitstats.firstkps).reduce((total, fkpid) => {
                    let ra = kpList[fkpid].ra;
                    let kpval = (sharedrakoefids[ra] ? Math.floor(rakoef[ra] / visitstats[fkpid]) : rakoef[ra])
                    return total + kpval * firstkpvisitbonus;
                }, 0);
            }
            newresult.kpsum = newresult.rasums.reduce((a, v) => a + v);
            newresult.kpkokku = newresult.kpsum - newresult.kppenalty + (newresult.firstkpsbonus || 0);
            newresult.spdpenalty = 0;
            if (spdtickets) {
                Object.values(spdtickets).forEach((v) => {
                    newresult.spdpenalty += Object.values(v).length * spdpenalty;
                })
            }
            newresult.lypoints = Object.keys(ly || {}).reduce((total, current) => total + (lypoints[current] || 0), 0);
            newresult.sum = newresult.kpkokku - newresult.timepenaltytotal - newresult.spdpenalty + newresult.lypoints;

            newresult.spenttime = (!newresult.data.finishtime || newresult.data.spent === 0 ? "" : moment.utc(newresult.data.spent * 1000).format('HH:mm:ss'));

            reportNewResult.newResult(newresult);

            return newresult;
        }
    )
}
