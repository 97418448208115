import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';


import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { closeUndoSnackBar, popUndoTask } from '../redux/actionsEventResult';


const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

class UndoSnackBar extends Component {

  handleClose = (event, reason) => {
    if (reason === 'clickaway')
      return;
    const { snackbarstate } = this.props;
    snackbarstate.history[snackbarstate.history.length - 1].id = "";
    this.props.closeSnackBar()
  };
  undoClicked = (undotask, undovalue) => {
    undotask(undovalue);
    this.props.popUndoTask();
  }
  render() {
    const { classes, snackbarstate } = this.props;
    if (snackbarstate.history.length === 0)
      return null;
    let current = snackbarstate.history[snackbarstate.history.length - 1];
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          key: 'u' + snackbarstate.history.length,
        }}
        open={snackbarstate.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        message={<span id="message-id">{current.message}</span>}
        action={[
          <Button key="undo" color="inherit" size="small" onClick={() => this.undoClicked(current.undotask, current.value)}>
            UNDO
            </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />)
  }
}

const mapStateToProps = state => ({
  snackbarstate: state.snackBarState,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeSnackBar: () => dispatch(closeUndoSnackBar()),
  popUndoTask: () => dispatch(popUndoTask())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UndoSnackBar));
