import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setKPProp, setEditKPId } from '../redux/actionsEvents';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { addUndo, showAlert } from '../redux/actionsEventResult';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import csvparse from 'csv-parse/lib/sync';
import { Box, ButtonGroup, FormControlLabel, Grid } from '@material-ui/core';
import firebase from 'firebase/app';
import { download, htmlEntities } from '../utils/SmallUtils';
import RestoreDeletedKPs from './RestoreDeletedKPs';


const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing(3),

  },
  button: {
    margin: theme.spacing(1),
  },
  dialogtitle: {
    display: "inline"
  },
  title: {
    flexGrow: 1
  },
  listSection: {
    background: 'inherit',
  },
  listSubHeader: {
    fontSize: 24,
    fontWeight: 700,
  },
  jnrEdit: {
    width: 40
  },
  tahisEdit: {
    width: 20
  },
  divWithMinWidth: {
    width: 20,
    height: 20,
  },
  center: {
    align: "center"
  },
  exptypebuttons: {
    display: 'flex',
    margin: 'auto',
    width: 'fit-content'
  }
});

const columnData = [
  { id: 'nr', numeric: true, disablePadding: false, label: 'Nr' },
  { id: 'tahis', numeric: false, disablePadding: true, label: 'Tahis' },
  { id: 'desc', numeric: false, disablePadding: true, label: 'Kirjeldus' },
  { id: 'ra', numeric: true, disablePadding: false, label: 'Raskusaste' },
  { id: 'kohustuslik', numeric: false, disablePadding: true, label: 'Kohustuslik' },
  { id: 'voond', numeric: true, disablePadding: true, label: 'Vöönd' },
  { id: 'tyhistatud', numeric: false, disablePadding: true, label: 'Tühistatud' },
];

function EditField(props) {
  let value = props.parent.props.kpList[props.k][props.field];
  let kpdata = props.parent.props.kpData[props.k] || {};
  let clickForEdit = event => props.parent.startEdit(event, props.k, props.field);
  if (props.field === "tahis" && props.parent.props.kpAnswers) {
    value = props.parent.props.kpAnswers[props.k];
    if (kpdata.responses) {
      value = kpdata.responses[value];
      clickForEdit = null;
    }
  }
  if (props.field === "desc") {
    value = kpdata.desc;
  }
  if (value === null || value === undefined)
    value = "";
  let classForDiv = (value === "" ? "divWithMinWidth" : null);
  if (props.parent.state.editid === props.k && props.parent.state.editfield === props.field) {
    if (props.field === "ra")
      return (<Select value={value}
        onChange={props.parent.handleChange(props.field, props.k)}
        onClick={props.parent.stopClickPropagation}>
        {Object.keys(props.parent.props.rakoef).map(r => (<MenuItem key={r} value={r}>{r}</MenuItem>))}
      </Select>);
    return (<TextField
      type={props.type}
      className={props.parent.props.classes[props.field + "Edit"]}
      value={value}
      label={props.label}
      onChange={props.parent.handleChange(props.field, props.k)}
      onClick={props.parent.stopClickPropagation}
    />)
  } else
    return (<div className={props.parent.props.classes[classForDiv]} onClick={clickForEdit}>{value === "" ? "   " : value}</div>);
}

class EditKPsDialog extends Component {

  state = {
    orderBy: 'nr',
    order: 'asc',
    editid: undefined,
    editfield: undefined,
    importkplist: null,
    chooseExportFormatOpen: false,
    updateexisting: false,
    deletedkps: null,
    dorestorekp: false,
  }

  constructor(props, context) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.triggerImportSelect = this.triggerImportSelect.bind(this);
    this.triggerExport = this.triggerExport.bind(this);
    this.importKPs = this.importKPs.bind(this);
    this.dragKPGPXStart = this.dragKPGPXStart.bind(this);
    this.inputfile = React.createRef();
    this.eventref = firebase.database().ref('eventsdata').child(props.eventId);
  }

  componentDidMount() {
    this.deletedkpsref = this.eventref.child('deletedkps');
    this.deletedkpsref.on('value', (s) => {
      this.setState({ deletedkps: s.val() });
    });
  }
  componentWillUnmount() {
    this.deletedkpsref.off();
  }

  deleteKP = (kpid) => () => {
    const savekp = {
      id: kpid,
      kp: this.props.kpList[kpid],
    }
    if (this.props.kpAnswers[kpid])
      savekp.answer = this.props.kpAnswers[kpid]
    if (this.props.kpData[kpid])
      savekp.data = this.props.kpData[kpid]

    this.eventref.child('deletedkps').push(savekp);
    this.eventref.child('kp').child(kpid).remove();
    this.eventref.child('kpanswer').child(kpid).remove();
    this.eventref.child('kpdata').child(kpid).remove();
  }

  handleChange = (name, kpid) => event => {
    this.stopClickPropagation(event);
    let value = event.target.value;
    if (name === 'tahis') {
      let ref = this.eventref.child('kpanswer').child(kpid);
      ref.feedbackset(value)
      this.props.addUndo(name + kpid, this.props.kpAnswers[kpid], 'Tähis changed for KP nr ' + this.props.kpList[kpid].nr, v => {
        ref.feedbackset(v);
      });
      return;
    } else if (name === "desc") {
      let ref = this.eventref.child('kpdata').child(kpid).child('desc');
      ref.feedbackset(value)
      this.props.addUndo(name + kpid, this.props.kpAnswers[kpid], 'Description changed for KP nr ' + this.props.kpList[kpid].nr, v => {
        ref.feedbackset(v);
      });
      return;
    }
    let hname = {
      'ra': 'raskusaste',
      'tyhistatud': 'Tühistatud'
    }
    console.log(name, kpid, value);
    console.log('handleChange');
    console.log(value);
    let undomsg = (hname[name] || name) + ' changed for KP nr ' + this.props.kpList[kpid].nr;
    if (name === 'deleted') {
      value = true;
      undomsg = 'Deleted KP nr ' + this.props.kpList[kpid].nr;
    } else if (name === 'kohustuslik' || name === 'tyhistatud') {
      value = (value === "true");
    }
    let ref = this.eventref.child('kp').child(kpid).child(name);
    ref.feedbackset(value);
    this.props.addUndo(name + kpid, this.props.kpList[kpid][name], undomsg, v => {
      ref.feedbackset(v);
    });
    /*
  let value = undefined;
  if (event === null)
    value = null;
  else if (event.target !== undefined)
    value = event.target.value;
  else
    value = event;
  if (name === 'klasscheck') {
    value = (this.props.teamsList[tid].klassid || "").split('');
    if (event.target.checked)
      value.push(event.target.value);
    else
      value = value.filter( e => e != event.target.value);
    name = 'klassid';
  }
  let undomsg = name+' changed for '+this.props.teamsList[tid].name;
  if (name === 'enabled' || name === 'confirmedresult') {
      value = event.target.checked;
  } else if (name === 'startms') {
      value = this.applyTime(this.props.teamsList[tid].startms, value);
  } else if (name === 'finishms') {
      value = this.applyTime(this.props.teamsList[tid].startms, value);
      if (value < this.props.teamsList[tid].startms)
          value += 86400000;
  } else if (name === 'klassid') {
      value = value.join('');
  }
  this.props.setTeamProp(tid, name, value);    
  this.props.addUndo(name+tid, this.props.teamsList[tid][name], undomsg, (undovalue) => {
      this.props.setTeamProp(tid, name, undovalue);
  });
  */
  }

  createSortHandler = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, k) => {
    this.setState({ editid: undefined, editfield: undefined });
  }

  stopClickPropagation = e => {
    console.log('Stop it');
    e.stopPropagation();
  }

  /*
closeEditTeam = () => {
    this.setState({editTeamId: null});
}
*/

  startEdit = (e, kpid, f) => {
    e.stopPropagation();
    console.log(kpid);
    console.log(f);
    this.setState({ editid: kpid, editfield: f });
  }

  kplistToJson() {
    return JSON.stringify({
      kpData: this.props.kpData,
      kpList: this.props.kpList,
      kpAnswers: this.props.kpAnswers
    })
  }


  kplistToCsv() {
    const { kpList, kpAnswers, kpData } = this.props;
    let resp = "id;nr*;raskusaste*;kohustuslik;desc;longdesc;location;tyhistatud;maxanswerdistance;allok;tahis;responses...\n";
    let csvescaper = (s) => {
      if (s === undefined)
        return '';
      s = s.replace(/"/g, '""');
      if (s.search(/("|,|\n)/g) >= 0)
        return '"' + s + '"';
      else
        return s;
    }
    for (let [id, kp] of Object.entries(kpList)) {
      if (kp.deleted)
        continue;
      let row = [];
      row.push(id);
      row.push(kp.nr);
      row.push(kp.ra);
      row.push(kp.kohustuslik);
      if (kpData[id]) {
        row.push(csvescaper(kpData[id].desc));
        row.push(csvescaper(kpData[id].longdesc));
        if (kpData[id].loc)
          row.push(kpData[id].loc.lat + ' ' + kpData[id].loc.lng);
        else
          row.push('');
      }
      row.push(kp.tyhistatud);
      row.push(kp.maxanswerdistance);
      row.push((kp.allok ? true : ''));
      row.push(kpAnswers[id]);
      if (kpData[id] && kpData[id].responses) {
        Object.entries(kpData[id].responses).forEach(([rid, resp]) => {
          row.push(csvescaper(rid + ":" + resp));
        })
      }
      resp += row.join(";") + "\n";
    }
    return resp;
  }

  kplistToGpx() {
    var gpxdoc = document.implementation.createDocument(null, "gpx");
    var gpx = gpxdoc.childNodes[0];
    gpx.setAttribute("version", "1.1");
    gpx.setAttribute("creator", "trackerweb");
    var gpxmetadata = gpxdoc.createElement("metadata");
    // TODO Add event owner as author.
    var tmp = gpxdoc.createElement("name");
    tmp.innerHTML = htmlEntities(this.props.eventName);
    gpxmetadata.appendChild(tmp);
    gpx.appendChild(gpxmetadata);
    // TODO We can probably drop this assignment and use kp in square brackets.
    const { kpList, kpAnswers, kpData } = this.props;
    for (let [i, kp] of Object.entries(kpList)) {
      if (kp.deleted)
        continue;
      var kpelement = gpxdoc.createElement("wpt");
      var kpextensions = gpxdoc.createElement("extensions");
      tmp = gpxdoc.createElement("serverid");
      tmp.innerHTML = htmlEntities(i);
      kpextensions.appendChild(tmp);
      if (kp.maxanswerdistance) {
        tmp = gpxdoc.createElement("maxanswerdistance");
        tmp.innerHTML = Number(kp.maxanswerdistance);
        kpextensions.appendChild(tmp);
      }
      if (kp.tyhistatud) {
        tmp = gpxdoc.createElement("tyhistatud");
        tmp.innerHTML = true;
        kpextensions.appendChild(tmp);
      }
      if (kp.kohustuslik) {
        tmp = gpxdoc.createElement("kohustuslik");
        tmp.innerHTML = true;
        kpextensions.appendChild(tmp);
      }
      if (kp.allok) {
        tmp = gpxdoc.createElement("allok");
        tmp.innerHTML = true;
        kpextensions.appendChild(tmp);
      }
      if (kpData[i]) {
        if (kpData[i].loc) {
          kpelement.setAttribute("lat", kpData[i].loc.lat);
          kpelement.setAttribute("lng", kpData[i].loc.lng);
        }
        if (kpData[i].desc && kpData[i].desc.length !== 0) {
          tmp = gpxdoc.createElement("desc");
          tmp.innerHTML = htmlEntities(kpData[i].desc);
          kpelement.appendChild(tmp);
        }
        if (kpData[i].responses) {
          let tmp = gpxdoc.createElement("responses");
          Object.entries(kpData[i].responses).forEach(([id, v]) => {
            let resp = gpxdoc.createElement("response");
            resp.setAttribute("id", id);
            resp.innerHTML = htmlEntities(v);
            tmp.appendChild(resp);
          })
          kpextensions.appendChild(tmp);
        }
      }
      tmp = gpxdoc.createElement("name");
      tmp.innerHTML = htmlEntities(kp.nr);
      kpelement.appendChild(tmp);
      tmp = gpxdoc.createElement("sym");
      tmp.innerHTML = htmlEntities(kp.ra);
      kpelement.appendChild(tmp);
      if (i in kpAnswers) {
        tmp = gpxdoc.createElement("cmt");
        tmp.innerHTML = htmlEntities(kpAnswers[i]);
        kpelement.appendChild(tmp);
      }
      kpelement.appendChild(kpextensions);
      gpx.appendChild(kpelement);
    }
    var serializer = new XMLSerializer();
    return serializer.serializeToString(gpx);
  }

  triggerAddKP = () => {
    let uusnr = 1;
    Object.values(this.props.kpList).forEach(kp => {
      if (Number(kp.nr) >= uusnr)
        uusnr = Number(kp.nr) + 1;
    })

    let ref = this.eventref.child('kp').push();
    ref.set({
      nr: uusnr,
      ra: 1
    });
  }
  triggerExport() {
    this.setState({ chooseExportFormatOpen: true });
  }

  exportKPs(type) {
    let [data, mime] = ((t) => {
      switch (t) {
        case 'gpx':
          return [this.kplistToGpx(), 'application/gpx+xml']
        case 'csv':
          return [this.kplistToCsv(), 'text/csv;charset=utf-8']
        default: case 'json':
          return [this.kplistToJson(), 'applicaiton/json']
      }
    })(type)
    download(data, this.props.eventName + "_kps." + type, mime);
    this.setState({ chooseExportFormatOpen: false });
  }

  export() {
    // TODO Export to other formats
    // TODO csv export probabaly does not include maxanswerdistance-it
    // TODO add geojson
    download(this.kplistToGpx(), this.props.eventName + "_kps.gpx", 'application/gpx+xml');
  }

  triggerImportSelect(e) {
    this.setState({ updateexisting: false });
    this.inputfile.current.value = "";
    this.inputfile.current.click();
  }

  tryCSV(contents) {
    let options = { trim: true, delimiter: ',', relax_column_count: true };
    let entries = [];
    try {
      entries = csvparse(contents, options);
    } catch (e) {

    }
    if (entries.length === 0 || entries[0].length < 2) {
      options.delimiter = ';';
      try {
        entries = csvparse(contents, options);
      } catch (e) {
        this.props.showAlert('CSV fail on vigane', 'Vigane CSV');
        return false;
      }
    }
    let kplist = [];
    let fields = ['id', 'nr', 'ra', 'kohustuslik', 'desc', 'longdesc', 'location', 'tyhistatud', 'maxanswerdistance', 'allok', 'tahis'];
    entries.forEach(e => {
      if (e.length < 4)
        return;
      if (e[0] === 'id' && e[1].startsWith('nr'))
        return;
      let kp = { responses: [] };
      fields.forEach((f, idx) => {
        if (idx >= e.length)
          return;
        if (['kohustuslik', 'tyhistatud', 'allok'].includes(f)) {
          if (['true', '1', 'ok', 'jah', 'y', 'yes'].includes(e[idx].toLowerCase()))
            kp[f] = true;
        } else if (f === 'nr') {
          if (!isNaN(e[idx]))
            kp[f] = Number(e[idx])
          else
            kp[f] = e[idx];
        } else if (['ra', 'maxanswerdistance'].includes(f)) {
          if (!isNaN(e[idx]))
            kp[f] = Number(e[idx])
        } else if (f === 'location') {
          let ll = e[idx].split(' ');
          if (ll.length !== 2)
            return;
          kp.loc = {
            lat: Number(ll[0][0] === "S" ? "-" + ll[0].substring(1) : ll[0][0] === 'N' ? ll[0].substring(1) : ll[0]),
            lng: Number(ll[1][0] === "W" ? "-" + ll[1].substring(1) : ll[1][0] === 'E' ? ll[1].substring(1) : ll[1])
          }
          return;
        } else if (e[idx])
          kp[f] = e[idx];
      });
      for (let i = fields.length; i < e.length; i++) {
        if (e[i])
          kp.responses.push(e[i]);
      }

      if (kp.responses.length === 1) {
        const parts = kp.responses[0].split(",");
        if (parts.length > 1)
          kp.responses = parts;
      }

      if (kp.responses.length === 0) {
        kp.responses = null;
      } else if (kp.responses.every(k => k.includes(':'))) {
        let newresponses = {};
        kp.responses.forEach(k => {
          let pos = k.indexOf(':');
          newresponses[k.substring(0, pos)] = k.substring(pos + 1);
        })
        kp.responses = newresponses;
        if (kp.tahis && !(kp.tahis in newresponses)) {
          kp.tahis = null;
        }
      } else {
        if (kp.tahis && !(kp.responses.includes(kp.tahis))) {
          kp.responses.splice(Math.floor(Math.random() * (kp.responses.length + 1)), 0, kp.tahis);
        }
        if (!kp.tahis) {
          let sidx = kp.responses.findIndex(k => k.startsWith('*'));
          if (sidx !== -1) {
            kp.responses[sidx] = kp.responses[sidx].substring(1);
            kp.tahis = kp.responses[sidx];
          }
        }
      }
      if (kp.id === "#NAME?")
        delete kp.id;
      kplist.push(kp);
    });
    return kplist;
  }

  importKPs(e) {
    var files = e.target.files || e.dataTransfer.files;
    console.log('Import KPs');
    let usingiso8859 = false;
    let self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      var contents = e.target.result;
      var i = contents.length;
      let havebadcode = false;
      while (i--) {
        if (contents.charCodeAt(i) === 65533) {
          havebadcode = true;
        }
      }
      if (havebadcode) {
        if (usingiso8859) {
          alert('bad fail.....');
          return;
        }
        usingiso8859 = true;
        reader.readAsText(files[0], 'ISO-8859-1');
        return;
      }
      console.log(contents);
      self.importKPsFromString(contents);
    }
    reader.readAsText(files[0]);
  }

  importKPsFromString(contents) {

    try {
      const jdata = JSON.parse(contents);
      console.log('json successful');
      this.setState({ importkplist: jdata });
      return;
    } catch (e) { }

    let parser = new DOMParser();
    let doc = parser.parseFromString(contents, "text/xml");
    console.log(doc);
    let wpts = doc.getElementsByTagName("wpt");
    let kplist = [];
    for (let i = 0; i < wpts.length; i++) {
      let kp = { responses: [] }
      let tmp = wpts[i].getElementsByTagName("desc");
      if (tmp.length > 0)
        kp.desc = tmp[0].innerHTML;
      tmp = wpts[i].getElementsByTagName("name");
      if (tmp.length > 0)
        kp.nr = tmp[0].innerHTML;
      if (!isNaN(kp.nr))
        kp.nr = parseInt(kp.nr);
      tmp = wpts[i].getElementsByTagName("sym");
      if (tmp.length > 0) {
        kp.ra = tmp[0].innerHTML;
        if (kp.ra.includes('Triangle') || kp.ra === '2')
          kp.ra = 2;
        else if (kp.ra.includes('Circle') || kp.ra === '1')
          kp.ra = 1;
        else if (kp.ra.includes('Square') || kp.ra === '3')
          kp.ra = 3;
        else if (!isNaN(kp.ra))
          kp.ra = parseInt(kp.ra);
        else
          kp.ra = 1;
      }
      tmp = wpts[i].getElementsByTagName("cmt");
      if (tmp.length > 0)
        kp.tahis = tmp[0].innerHTML;
      kp.loc = {};
      tmp = wpts[i].attributes["lat"];
      if (tmp !== undefined)
        kp.loc.lat = parseFloat(tmp.value);
      tmp = wpts[i].attributes["lon"];
      if (tmp !== undefined)
        kp.loc.lng = parseFloat(tmp.value);
      let extensions = wpts[i].getElementsByTagName("extensions");
      if (extensions.length > 0) {
        tmp = extensions[0].getElementsByTagName("serverid");
        if (tmp.length > 0)
          kp.serverid = tmp[0].innerHTML;
        tmp = extensions[0].getElementsByTagName("maxanswerdistance");
        if (tmp.length > 0)
          kp.maxanswerdistance = parseFloat(tmp[0].innerHTML);
      }
      kplist.push(kp);
      console.log(wpts[i]);
      console.log(kp);
    };
    if (wpts.length === 0) {
      kplist = this.tryCSV(contents);
    }
    if (kplist !== false)
      this.setState({ importkplist: kplist });
  }

  dragKPGPXStart(e) {
    var file = new Blob([this.kplistToGpx()], { type: 'application/gpx+xml' });
    let url = "text/plain:testfail.txt:" + URL.createObjectURL(file);
    e.dataTransfer.setData("Dragging GPX", url);
  }

  handleCancelImport = () => {
    this.setState({ importkplist: null });
  }

  handleUpdate = () => {
    const importlist = this.state.importkplist;

    importlist.forEach(async kp => {
      let ekp = this.getExisting(kp);
      let kpid = kp.id ? kp.id : (ekp ? ekp.id : undefined);
      let kpref = (kpid ? this.eventref.child('kp').child(kpid) : await this.eventref.child('kp').push());
      kpid = kpref.key;
      let kpob = (await kpref.once('value')).val() || {};
      if (kp.nr && kpob.nr !== kp.nr)
        kpob.nr = kp.nr;
      if (kp.ra && kpob.ra !== kp.ra)
        kpob.ra = kp.ra;
      if (kp.tyhistatud)
        kpob.tyhistatud = kp.tyhistatud;
      if (kp.maxanswerdistance)
        kpob.maxanswerdistance = kp.maxanswerdistance;
      if (kp.allok)
        kpob.allok = kp.allok;
      kpref.set(kpob);
      kpob = (await this.eventref.child('kpdata').child(kpid).once('value')).val() || {}
      if (kp.desc)
        kpob.desc = kp.desc;
      if (kp.longdesc)
        kpob.longdesc = kp.longdesc;
      if (kp.loc)
        kpob.loc = kp.loc;
      this.eventref.child('kpdata').child(kpid).set(kpob);

      if (kp.responses) {
        if (Array.isArray(kp.responses)) {
          kp.responses.forEach(r => {
            let rref = this.eventref.child('kpdata').child(kpid).child('responses').push(r);
            if (r === kp.tahis)
              kp.tahis = rref.key;
          })
        } else {
          this.eventref.child('kpdata').child(kpid).child('responses').set(kp.responses);
        }
      }
      if (kp.tahis)
        this.eventref.child('kpanswer').child(kpid).set(kp.tahis);
    });
    this.setState({ importkplist: null });

  }
  doArryImport = async (type, evref) => {
    let evkp = {};
    let kpanswer = {};
    let kpdata = {};
    if (type !== 'merge') {
      evref.child('kp').remove();
      evref.child('kpanswer').remove();
      evref.child('kpdata').remove();
    } else {
      evkp = (await evref.child('kp').once('value')).val();
      kpanswer = (await evref.child('kpanswer').once('value')).val();
      kpdata = (await evref.child('kpdata').once('value')).val();
    }

    for (let kp of this.state.importkplist) {
      let kpref = (kp.id ? evref.child('kp').child(kp.id) : evref.child('kp').push());
      let kpid = kpref.key;
      let kpob = {
        nr: kp.nr,
        ra: kp.ra || 1
      };
      if (kp.tyhistatud)
        kpob.tyhistatud = kp.tyhistatud;
      if (kp.maxanswerdistance)
        kpob.maxanswerdistance = kp.maxanswerdistance;
      if (kp.allok)
        kpob.allok = kp.allok;
      evkp[kpid] = kpob;
      kpob = {}
      if (kp.desc)
        kpob.desc = kp.desc;
      if (kp.longdesc)
        kpob.longdesc = kp.longdesc;
      if (kp.loc)
        kpob.loc = kp.loc;
      kpdata[kpid] = kpob;
      if (kp.responses) {
        if (Array.isArray(kp.responses)) {
          kpdata[kpid].responses = [];
          kp.responses.forEach(r => {
            let rref = evref.child('kpdata').child(kpid).child('responses').push();
            kpdata[kpid].responses[rref.key] = r;
            if (r === kp.tahis)
              kp.tahis = rref.key;
          })
        } else {
          kpdata[kpid].responses = kp.responses;
        }
      }
      if (kp.tahis)
        kpanswer[kpid] = kp.tahis;

    }

    this.eventref.child('kp').set(evkp);
    this.eventref.child('kpdata').set(kpdata);
    this.eventref.child('kpanswer').set(kpanswer);
    console.log(evkp, kpdata, kpanswer);
  }

  handleSubmitImport = (type) => () => {
    const importlist = this.state.importkplist;
    if (Array.isArray(importlist)) {
      this.doArryImport(type, this.eventref);
    } else {
      if (type === 'merge') {
        if ('kpList' in importlist)
          this.eventref.child('kp').update(importlist['kpList']);
        if ('kpAnswers' in importlist)
          this.eventref.child('kpanswer').update(importlist['kpAnswers']);
        if ('kpData' in importlist)
          this.eventref.child('kpdata').update(importlist['kpData'])
      } else {
        if ('kpList' in importlist)
          this.eventref.child('kp').set(importlist['kpList']);
        if ('kpAnswers' in importlist)
          this.eventref.child('kpanswer').set(importlist['kpAnswers']);
        if ('kpData' in importlist)
          this.eventref.child('kpdata').set(importlist['kpData'])
      }
    }
    this.setState({ importkplist: null });
  }

  render() {
    const { classes, kpList, kpAnswers, kpData } = this.props;
    const { orderBy, order } = this.state;

    let nrconv = (n) => (isNaN(n) ? -1 : Number(n));
    let bconv = (b) => (!!b ? -1 : 1);

    const sortedkeys = ((ob) => {
      switch (ob) {
        case 'nr':
          return Object.keys(kpList).sort((a, b) => nrconv(kpList[a].nr) - nrconv(kpList[b].nr));
        case 'tahis':
          return Object.keys(kpList).sort((a, b) => (kpAnswers[a] < kpAnswers[b] ? -1 : 1))
        case 'tyhistatud': case 'kohustuslik':
          return Object.keys(kpList).sort((a, b) => (bconv(kpList[a][ob]) - bconv(kpList[b][ob])));
        case 'desc':
          return Object.keys(kpList).sort((a, b) => (kpData[b][orderBy] < kpData[a][orderBy] ? -1 : 1));
        default:
          return Object.keys(kpList).sort((a, b) => (kpList[b][orderBy] < kpList[a][orderBy] ? -1 : 1));
      }
    })(orderBy)

    if (order === "desc")
      sortedkeys.reverse();

    let voondenabled = false;

    return (
      <Dialog
        open={true}
        maxWidth={false}
        onClose={this.props.handleClose}
        aria-labelledby="open-kps-title"
      >
        <DialogTitle className={classes["dialogtitle"]} id="open-kps-title">
          <div>Edit KPs</div>
          <Box l={9}>
            <Grid container justify="space-between">
              <Grid item>
                <input ref={this.inputfile} type="file" style={{ "display": "none" }} onChange={this.importKPs} />
                <Button className={classes.button} color="primary" variant="contained" onClick={this.triggerAddKP}>Add KP</Button>
                <Button className={classes.button} color="primary" variant="contained" onClick={this.triggerImportSelect}>Import</Button>
                <Button className={classes.button} color="primary" variant="contained" onClick={this.triggerExport} draggable={true} onDragStart={this.dragKPGPXStart}>Export</Button>

              </Grid>
              <Grid item>
                {this.state.deletedkps && (
                  <Button className={classes.button} color="primary" variant="contained" onClick={() => {this.setState({dorestorekp: true})}}>Restore KP's</Button>
                  )}
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
        {this.state.dorestorekp && (
          <RestoreDeletedKPs deletedkps={this.state.deletedkps} eventref={this.eventref} onClose={() => {this.setState({dorestorekp: false})}}/>
        )}
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {columnData.filter(e => !(e.id === "voond" && !voondenabled)).map(column => (
                  <TableCell
                    key={column.id}
                    padding={column.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ), this)}
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedkeys.filter(k => !kpList[k].deleted).map((k) => {
                return (
                  <TableRow hover key={k} onClick={event => this.handleClick(event, k)}>
                    <TableCell>
                      <EditField parent={this} k={k} field="nr" />
                    </TableCell>
                    <TableCell>
                      <EditField parent={this} k={k} field="tahis" />
                    </TableCell>
                    <TableCell>
                      <EditField parent={this} k={k} field="desc" />
                    </TableCell>
                    <TableCell>
                      <EditField parent={this} k={k} field="ra" />
                    </TableCell>
                    <TableCell className={classes.center}>
                      {kpList[k].nr !== 'F' && (<Switch
                        onClick={this.stopClickPropagation}
                        checked={'kohustuslik' in kpList[k] ? kpList[k].kohustuslik : false}
                        onChange={this.handleChange('kohustuslik', k)}
                        value={kpList[k].kohustuslik ? false : true} />)}
                    </TableCell>
                    {voondenabled &&
                      (<TableCell>
                        {kpList[k].voond}
                      </TableCell>)}
                    <TableCell>
                      {kpList[k].nr !== 'F' && (<Switch
                        onClick={this.stopClickPropagation}
                        checked={'tyhistatud' in kpList[k] ? kpList[k].tyhistatud : false}
                        onChange={this.handleChange('tyhistatud', k)}
                        value={kpList[k].tyhistatud ? false : true} />)}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup variant='contained'>
                        <Button
                          aria-label="Edit"
                          onClick={() => { this.props.setEditKPId(k) }}
                          key="Edit">
                          <Icon aria-label="Edit">edit</Icon>
                        </Button>
                        <Button
                          aria-label="Delete"
                          onClick={this.deleteKP(k)}
                          key="Delete">
                          <Icon aria-label="Delete">delete</Icon>
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        {this.state.importkplist !== null && (
          <Dialog
            open={true}
          >
            <DialogTitle className={classes["dialogtitle"]} id="import-kps-title">
              <div>Import following KPs</div>
            </DialogTitle>
            <DialogContent>
              {Array.isArray(this.state.importkplist) && (<FormControlLabel
                control={
                  <Switch
                    checked={this.state.updateexisting}
                    onChange={(ev) => this.setState({ updateexisting: ev.target.checked })}
                  />
                }
                label="Update existing"
              />)}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Jnr</TableCell>
                    <TableCell>Raskusaste</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Long Description</TableCell>
                    <TableCell>Tähis</TableCell>
                    <TableCell>Lat/Long</TableCell>
                    <TableCell>Responses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(this.state.importkplist) ?
                    this.state.importkplist.map(skp => {
                      let kp = (this.state.updateexisting ? this.getExisting(skp) : skp);
                      if (kp === undefined)
                        kp = skp;
                      else {
                        if (skp.ra)
                          kp.ra = skp.ra;
                        if (skp.desc)
                          kp.desc = skp.desc;
                        if (skp.longdesc)
                          kp.longdesc = skp.longdesc;
                        if (skp.responses && Array.isArray(kp.responses) && skp.responses.length > 0)
                          kp.responses = skp.responses;
                        if (skp.tahis)
                          kp.tahis = skp.tahis;
                        if (skp.loc)
                          kp.loc = skp.loc;
                        console.log('need to update', skp);
                      }

                      return (<TableRow key={kp.nr}>
                        <TableCell>{kp.nr}</TableCell>
                        <TableCell>{kp.ra}</TableCell>
                        <TableCell>{kp.desc}</TableCell>
                        <TableCell>{kp.longdesc}</TableCell>
                        <TableCell>{((kp.responses && !Array.isArray(kp.responses)) ? kp.responses[kp.tahis] : kp.tahis)}</TableCell>
                        <TableCell>{kp.loc && kp.loc.lat + " / " + kp.loc.lng}</TableCell>
                        <TableCell>{(Array.isArray(kp.responses) ? kp.responses.join(",") : kp.responses && Object.values(kp.responses).join(","))}</TableCell>
                      </TableRow>
                      )
                    })
                    :
                    Object.keys(this.state.importkplist.kpList).map(k => (
                      <TableRow key={k}>
                        <TableCell>{this.state.importkplist.kpList[k].nr}</TableCell>
                        <TableCell>{this.state.importkplist.kpList[k].ra}</TableCell>
                        <TableCell>{((this.state.importkplist.kpData || {})[k] || {}).desc}</TableCell>
                        <TableCell>{((this.state.importkplist.kpData || {})[k] || {}).longdesc}</TableCell>
                        <TableCell>{this.answerFromJson(k)}</TableCell>
                        <TableCell>{(((this.state.importkplist.kpData || {})[k] || {}).loc || "").toString()}</TableCell>
                        <TableCell>{this.responsesFromJson(k)}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              {this.state.updateexisting ? [
                (<Button key="update" onClick={this.handleUpdate} color="primary">
                  Update
                </Button>),
                (<Button key="cancel" onClick={this.handleCancelImport} color="primary">
                  Cancel
                </Button>)
              ] : [
                  Object.keys(kpList).length === 0 &&
                  (<Button key="import" onClick={this.handleSubmitImport('import')} color="primary">
                    Import KP's
                  </Button>),
                  Object.keys(kpList).length > 0 &&
                  (<Button key="owerwrite" onClick={this.handleSubmitImport('import')} color="primary">
                    Overwrite existing KPs
                  </Button>),
                  Object.keys(kpList).length > 0 &&
                  (<Button key="add" onClick={this.handleSubmitImport('merge')} color="primary">
                    Add to Existing KPs
                  </Button>),
                  (<Button key="cancel" onClick={this.handleCancelImport} color="primary">
                    Cancel
                  </Button>)
                ]}

            </DialogActions>
          </Dialog>)}
        {this.state.chooseExportFormatOpen && (
          <Dialog open={true} maxWidth={"xs"}>
            <DialogTitle>Choose export format</DialogTitle>
            <DialogContent>
              <ButtonGroup className={classes.exptypebuttons} orientation="vertical" variant="contained" aria-label="vertical contained primary button group">
                <Button variant="contained" color="primary" onClick={() => { this.exportKPs('json') }}>JSON</Button>
                <Button variant="contained" color="primary" onClick={() => { this.exportKPs('csv') }}>CSV</Button>
                <Button variant="contained" color="primary" onClick={() => { this.exportKPs('gpx') }}>GPX</Button>
              </ButtonGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ chooseExportFormatOpen: false })}>Cancel</Button>
            </DialogActions>
          </Dialog>
        )}
      </Dialog>
    );
  }

  getExisting(kp) {
    const { kpList, kpData } = this.props;
    console.log(kpList, kpData);
    let key = kp.id ? kp.id : Object.keys(kpList).find(k => kpList[k].nr === kp.nr);
    if (key === undefined)
      return undefined
    let resp = kpList[key];
    if (key in kpData) {
      Object.assign(resp, kpData[key]);
    }
    resp.id = key;
    console.log('kp match', kp, resp);
    return resp;
  }
  answerFromJson(k) {
    const kpAnswers = this.state.importkplist.kpAnswers;
    const kpData = this.state.importkplist.kpData;
    if (k in kpAnswers) {
      if (k in kpData && 'responses' in kpData[k]) {
        const answer = kpData[k]['responses'][kpAnswers[k]];
        return answer;
      } else
        return kpAnswers[k];
    }
    return ""
  }

  responsesFromJson(k) {
    const kpData = this.state.importkplist.kpData;
    if (k in kpData) {
      if ('responses' in kpData[k]) {
        const resps = kpData[k]['responses'];
        return Object.values(resps).join(",")
      }
    }
    return ""
  }
}


const mapStateToProps = state => ({
  eventId: state.eventId,
  eventName: state.currentEvent.name,
  rakoef: state.currentEvent.rakoef,
  kpList: state.kpList,
  kpData: state.kpData,
  kpAnswers: state.kpAnswers || {},
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setKPProp: (kpid, name, value) => dispatch(setKPProp(kpid, name, value)),
  setEditKPId: (kpid) => dispatch(setEditKPId(kpid)),
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc)),
  showAlert: (title, msg) => dispatch(showAlert({ title: title, msg: msg }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditKPsDialog));
