import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';


class FBTextField extends Component {
    constructor(props, context) {
        super(props.context);
        this.state = {
            value: props.value || ""
        }
        let inconstructor = true;
        this.listene = props.dbref.on('value', snap => {
            if (!snap.exists())
                return;
            if (inconstructor)
                this.state.value = snap.val();
            else
                this.setState({ value: snap.val() });
        });
        inconstructor = false;
    }

    componentWillUnmount() {
        this.props.dbref.off('value', this.listener);
    }

    handleChange = (event) => {
        let v = event.target.value;
        this.setState({ value: v })
        if (this.timer)
            clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.dbref.feedbackset(v);
            this.timer = undefined;
        }, 1000);
    }

    render() {
        return (<TextField
            value={this.state.value}
            onChange={this.handleChange}
            {...this.props}>

        </TextField>)
    }
}

export default FBTextField;