import * as types from './actionTypes'

export function eventsList(data) {
  return { type: types.SET_EVENTS_LIST, data: data };
}

export function setSeries(data) {
  return { type: types.SET_SERIES, data: data }
}

export function usersList(data) {
  return { type: types.SET_USERS_LIST, data: data };
}

export function adminAccessList(data) {
  return { type: types.SET_ADMINUSERS_LIST, data: data };
}

export function changeComplete(data) {
  return { type: types.CHANGE_COMPLETE, data: data };
}

export function eventDataAvailable(data) {
  return { type: types.EVENT_DATA_AVAILABLE, data };
}

export function eventTeamsAvailable(data) {
  return { type: types.EVENT_TEAMS_AVAILABLE, data };
}

export function eventKPsAvailable(data) {
  return { type: types.EVENT_KPS_AVAILABLE, data };
}

export function eventTeamUpdate(data) {
  return { type: types.EVENT_TEAM_UPDATE, data };
}

export function eventKPUpdate(data) {
  return { type: types.EVENT_KP_UPDATE, data };
}

export function addSaveInProgress() {
  return { type: types.ADD_SAVE_STATE };
}

export function rmSaveInProgress() {
  return { type: types.RM_SAVE_STATE };
}

export function addUndo(id, value, msg, undofunc) {
  return { type: types.ADD_UNDO, message: msg, undotask: undofunc, id: id, value: value };
}

export function closeUndoSnackBar() {
  return { type: types.CLOSE_UNDO_SNACKBAR };
}

export function popUndoTask() {
  return { type: types.POP_UNDO_TASK };
}

export function locationData(data) {
  return { type: types.LOCATION_DATA, data: data };
}

export function locationUpdates(data) {
  return { type: types.LOCATION_UPDATES, data: data };
}

export function speedingsData(data, incremental) {
  return { type: types.SPEEDINGSDATA, data: data, incremental: incremental }
}

export function speedAreas(data) {
  return { type: types.SPEEDAREAS, data: data }
}

export function speedAreaUpdate(data) {
  return { type: types.SPEEDAREAUPDATE, data: data }
}

export function speedAreaDelete(data) {
  return { type: types.SPEEDAREADELETE, data: data }
}

export function eventSpeedAreas(data) {
  return { type: types.EVENTSPEEDAREAS, data: data }
}

export function eventSpeedAreasUpdate(data) {
  return { type: types.EVENTSPEEDAREAUPDATE, data: data }
}

export function eventSpeedAreasDelete(data) {
  return { type: types.EVENTSPEEDAREADELETE, data: data }
}

export function showAlert(alertObject) {
  return { type: types.SHOW_ALERT, alertMsg: alertObject }
}

export function clearAlert() {
  return { type: types.CLEAR_ALERT }
}

export function setReplayTime(t) {
  return { type: types.SET_REPLAY_TIME, data: t }
}

