
const MAP_SIZE = 20037508.34789244 * 2;
const TILE_ORIGIN_X = -20037508.34789244
const TILE_ORIGIN_Y = 20037508.34789244;
const ORIGIN_SHIFT = 2 * Math.PI * 6378137 / 2.0

export default function WMSMapType(tileSize, name, baseurl, queryParams, setOptions) {
    this.tileSize = tileSize;
    this.name = name;
    this.baseurl = baseurl;

    this.params = {
        service: 'WMS',
        version: '1.3.0',
        request: 'GetMap',
        format: 'image/png',
        crs: 'EPSG:4326',
        transparent: true,
        width: this.tileSize.width,
        height: this.tileSize.height,
        layers: '',
        styles: ''
    }

    for (let k in queryParams)
        this.params[k] = queryParams[k]


    this.showOffZoom = false;
    this.minZoom = 1;
    this.maxZoom = 22;

    Object.assign(this, setOptions);

    this.url = this.baseurl + '?' + Object.keys(this.params).map(k => k + '=' + this.params[k]).join("&");

}

WMSMapType.prototype.name = 'Tile #s';
WMSMapType.prototype.alt = 'Tile Coordinate Map Type';

WMSMapType.prototype.getBBox = function (coord, zoom) {
    let tileSize = MAP_SIZE / Math.pow(2, zoom);
    let minx = TILE_ORIGIN_X + coord.x * tileSize;
    let miny = TILE_ORIGIN_Y - (coord.y + 1) * tileSize;

    minx = (minx / ORIGIN_SHIFT) * 180.0;
    miny = (miny / ORIGIN_SHIFT) * 180.0
    miny = 180 / Math.PI * (2 * Math.atan(Math.exp(miny * Math.PI / 180.0)) - Math.PI / 2.0);

    let maxx = TILE_ORIGIN_X + (coord.x + 1) * tileSize;
    let maxy = TILE_ORIGIN_Y - coord.y * tileSize;

    maxx = (maxx / ORIGIN_SHIFT) * 180.0;
    maxy = (maxy / ORIGIN_SHIFT) * 180.0
    maxy = 180 / Math.PI * (2 * Math.atan(Math.exp(maxy * Math.PI / 180.0)) - Math.PI / 2.0);

    return miny + "," + minx + "," + maxy + "," + maxx;
}

WMSMapType.prototype.getTile = function (coord, zoom, ownerDocument) {
    var div = ownerDocument.createElement('div');
    if (zoom < this.minZoom || zoom > this.maxZoom) {
        if (!this.showOffZoom)
            return null;
        let suggest = (zoom < this.minZoom ? 'Zoom in for available tiles.' : 'Zoom out for avilable tiles.');
        div.innerHTML = "<center>No tiles on this zoom level. <br/>" + suggest + "</center>";
        return div;
    }
    div.innerHTML = '<img src="' + this.url + '&bbox=' + this.getBBox(coord, zoom) + '">';
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';
    return div;
};
