import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { setEventName, addRaCoef, rmRaCoef, setEditTeamId } from '../redux/actionsEvents';
import { addUndo } from '../redux/actionsEventResult';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import firebase from 'firebase/app';
import TeamContextMenu from './TeamContextMenu';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  listSection: {
    background: 'inherit',
  },
  listSubHeader: {
    fontSize: 24,
    fontWeight: 700,
  },
  timepicker: {
    fontSize: 42,
    width: 140,
    padding: '5px 8px',
  },
  datepicker: {
    padding: '5px 8px',
  }
});

const columnData = [
  { id: 'nr', numeric: true, disablePadding: true, label: 'Nr', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'starttime', numeric: false, disablePadding: true, label: 'Start time', sortable: true },
  { id: 'finishtime', numeric: false, disablePadding: true, label: 'Finish time', sortable: true },
  { id: 'disabled', numeric: false, disablePadding: true, label: 'Disabled', sortable: true },
  { id: 'classes', numeric: false, disablePadding: true, label: 'Classes', sortable: true },
  { id: 'katk', numeric: false, disablePadding: true, label: 'Katk', sortable: false },
  { id: 'dsq', numeric: false, disablePadding: true, label: 'DSQ', sortable: false },
  { id: 'allowed', numeric: false, disablePadding: true, label: 'Allowed', sortable: true },
];

class EditTeamsDialog extends Component {


  constructor(props, context) {
    super(props, context);
    let dv = null;
    let haveseconds = false;
    let minstartms = null; let maxstartms = null;
    let minfinishms = null; let maxfinishms = null;
    for (let tid in this.props.teamsList) {
      if (this.props.teamsList[tid].starttime) {
        if (minstartms === null) {
          minstartms = this.props.teamsList[tid].starttime;
          maxstartms = minstartms;
        } else {
          if (minstartms > this.props.teamsList[tid].starttime)
            minstartms = this.props.teamsList[tid].starttime;
          if (maxstartms < this.props.teamsList[tid].starttime)
            maxstartms = this.props.teamsList[tid].starttime;
        }
      }
      if (this.props.teamsList[tid].finishtime) {
        if (minfinishms === null) {
          minfinishms = this.props.teamsList[tid].finishtime;
          maxfinishms = minfinishms;
        } else {

          if (minfinishms > this.props.teamsList[tid].finishtime)
            minfinishms = this.props.teamsList[tid].finishtime;
          if (maxfinishms < this.props.teamsList[tid].finishtime)
            maxfinishms = this.props.teamsList[tid].finishtime;
        }
      }
      if (this.props.teamsList[tid].starttime % 60000) // Some start time has seconds
        haveseconds = true;
      if (dv === null) {
        dv = new Date(this.props.teamsList[tid].starttime).setHours(0, 0, 0, 0);
      } else {
        if (this.props.teamsList[tid].starttime && new Date(this.props.teamsList[tid].starttime).setHours(0, 0, 0, 0) !== dv) {
          dv = null;
          break;
        }
      }
    }
    if (!maxstartms)
      maxstartms = minstartms;
    if (!minstartms)
      minstartms = maxstartms;
    if (!maxfinishms)
      maxfinishms = maxstartms;
    let closetimes = (maxstartms - minstartms < 10800000 && maxfinishms - minstartms < 86400000); // starts in 3 hours and last finish less than 24 hours
    this.state = {
      smallTimeInterval: closetimes,
      orderBy: 'nr',
      order: 'asc',
      timetype: 'time',
      anchorEl: null,
      timePickerWithDate: !closetimes,
      timePickerWithSeconds: haveseconds,
      startTimeDate: (dv === null ? new Date(this.props.currentEvent.starttime) : new Date(dv)),
      askForDateChangeOnTeams: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  dateForNewTimes = () => {
    let d = new Date();
    d.setMilliseconds(0);
    d.setSeconds(0);
    return d;
  }
  applyTime = (val, timeval) => {
    if (timeval == null)
      return null;
    timeval = timeval.toDate();
    let dv = null;
    if (!this.state.timePickerWithDate) {
      dv = this.state.startTimeDate;
      dv.setHours(timeval.getHours());
      dv.setMinutes(timeval.getMinutes());
      if (this.state.timePickerWithSeconds) {
        dv.setSeconds(timeval.getSeconds());
      }
    } else
      dv = timeval;
    if (isNaN(dv.getTime()))
      return timeval;
    return dv.getTime();
  }

  handleLYChange = (lyid, teamid) => event => {
    firebase.database().ref('teams').child(this.props.eventId).child('list').child(teamid).child('lypoints').child(lyid)
      .setWithUndo(Number(event.target.value), 'Lisaülessande punktid teamile ' + this.props.teamsList[teamid].name)
  }

  handleChange = (name, tid) => (event, second) => {
    let value = undefined;
    if (event === null)
      value = null;
    else if (event.target !== undefined)
      value = event.target.value;
    else
      value = event;
    if (name === 'klasscheck') {
      let ref = firebase.database().ref('teams').child(this.props.eventId).child('list').child(tid).child('klassid').child(event.target.value);
      if (event.target.checked)
        ref.feedbackset(true);
      else
        ref.feedbackset(null)
      return;
    }
    let undomsg = name + ' changed for ' + this.props.teamsList[tid].name;
    if (name === 'disabled' || name === 'allowed' || name === "katk" || name === "dsq") {
      value = event.target.checked;
    } else if (name === 'starttime' || name === 'finishtime') {
      if (value && !value.isValid()) {

        //this.props.setTeamPropLocalOnly(tid, name, second);
        return;
      }
      value = this.applyTime(this.props.teamsList[tid].starttime, value);
      if (name === 'finishtime') {
        while (value !== null && value < this.props.teamsList[tid].starttime)
          value += 86400000;
      }
    } else if (name === 'klassid') {
      let v = {};
      const klassid = (this.props.currentEvent.klassid || {});
      value.forEach(k => {
        if (!klassid[k])
          return;
        v[k] = true;
      })
      value = v;
    }
    if (this.props.teamsList[tid][name] === value)
      return;
    let ref = firebase.database().ref('teams').child(this.props.eventId).child('list').child(tid).child(name);
    ref.feedbackset(value);
    this.props.addUndo(name + tid, this.props.teamsList[tid][name], undomsg, v => {
      ref.feedbackset(v);
    });/*
    this.props.setTeamProp(tid, name, value);
    this.props.addUndo(name + tid, this.props.teamsList[tid][name], undomsg, (undovalue) => {
      this.props.setTeamProp(tid, name, undovalue);
    });
    */
  }

  createSortHandler = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  timemstoString = (msval) => {
    if (this.state.timetype === 'time') {
      if (msval === null)
        return "";
      return new Date(msval).toTimeString().split(' ')[0].substring(0, 5);
    } else
      return new Date(msval);
  }

  stopClickPropagation = e => {
    e.stopPropagation();
  }

  openSettingsMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  closeSettingsMenu = () => {
    this.setState({ anchorEl: null });
  }

  menuTimeWithSeconds = () => {
    this.setState({ timePickerWithSeconds: !this.state.timePickerWithSeconds, anchorEl: null });
  }
  menuTimeWithDate = () => {
    this.setState({ timePickerWithDate: !this.state.timePickerWithDate, anchorEl: null });
  }

  startTimeDateChanged = (value) => {
    // TODO : If changing date propose to change the date for all existing times.
    this.setState({ startTimeDate: value.toDate(), askForDateChangeOnTeams: true });
  }
  updateDates = () => {
    console.log('Should update dates');
    console.log(this.state.startTimeDate);
    let undovalue = [];
    let setdate = moment(this.state.startTimeDate);
    for (let tid in this.props.teamsList) {
      let s = moment(this.props.teamsList[tid].starttime);
      let f = moment(this.props.teamsList[tid].finishtime);
      console.log(s, s.dayOfYear(), s.year());
      if (s.isValid() && (s.dayOfYear() !== setdate.dayOfYear() || s.year() !== setdate.year())) {
        s.dayOfYear(setdate.dayOfYear());
        s.year(setdate.year());

        undovalue.push({ tid: tid, what: 'starttime', setvalue: s.valueOf(), undovalue: this.props.teamsList[tid].starttime })
      }
      let finishchange = undefined;
      if (f.isValid() && (f.dayOfYear() !== setdate.dayOfYear() || f.year() !== setdate.year())) {
        f.dayOfYear(setdate.dayOfYear());
        f.year(setdate.year());
        finishchange = { tid: tid, what: 'finishtime', setvalue: f.valueOf(), undovalue: this.props.teamsList[tid].finishtime };
      }
      if (f.isBefore(s)) {
        f.add(1, 'day');
        finishchange = { tid: tid, what: 'finishtime', setvalue: f.valueOf(), undovalue: this.props.teamsList[tid].finishtime };
      }
      if (finishchange)
        undovalue.push(finishchange);
    }
    console.log(undovalue);
    undovalue.forEach(v => {
      this.props.setTeamProp(v.tid, v.what, v.setvalue);
    })
    this.props.addUndo('dateupdateonstartfinish', undovalue, 'Updateded teams start/finish times to new date', (undos) => {
      undos.forEach(v => {
        this.props.setTeamProp(v.tid, v.what, v.undovalue);
      })
    });
    this.setState({ askForDateChangeOnTeams: false });
  }

  render() {
    const { classes, currentEvent, teamsList } = this.props;
    const { orderBy, order, anchorEl, startTimeDate } = this.state;
    const menuopen = Boolean(anchorEl);

    //console.log(teamsList);

    const sortedkeys =
      order === 'desc'
        ? Object.keys(teamsList).sort((a, b) => (teamsList[b][orderBy] < teamsList[a][orderBy] ? -1 : 1))
        : Object.keys(teamsList).sort((a, b) => (teamsList[a][orderBy] < teamsList[b][orderBy] ? -1 : 1))

    const klassid = (currentEvent.klassid || {});

    let columnDataExt = columnData.slice();
    Object.entries(currentEvent.ly || []).forEach(([lyid, ly]) => {
      columnDataExt.push(
        { id: 'ly' + lyid, numeric: true, disablePadding: false, label: ly.name },
      );
    });

    var LocalTimePicker = (props) => {
      if (props.value === undefined)
        props.value = null;
      let formatstr = "HH:mm";
      let views = ["hours", "minutes"];
      if (this.state.timePickerWithSeconds) {
        formatstr += ":ss";
        views.push("seconds");
      }
      if (this.state.timePickerWithDate) {
        formatstr = "DD/MM/YYYY " + formatstr;
        return (
          <KeyboardDateTimePicker
            clearable
            ampm={false}
            views={views}
            placeholder=""
            format={formatstr}
            onChange={props.onChange}
            className={classes.datepicker}
            animateYearScrolling={true}
            showTodayButton={true}
            value={props.value}
          />
        );
      }
      return (
        <KeyboardTimePicker
          clearable
          placeholder=""
          ampm={false}
          views={views}
          format={formatstr}
          onChange={props.onChange}
          className={classes.timepicker}
          value={props.value}
        />
      );
    }

    return (
      <div>

        <Dialog
          open={true}
          maxWidth={false}
          onClose={this.props.handleClose}
          aria-labelledby="open-teams-title"
        >
          <DialogTitle id="open-teams-title">Edit teams
        <IconButton
              aria-label="More"
              aria-owns={menuopen ? 'long-menu' : null}
              aria-haspopup="true"
              onClick={this.openSettingsMenu}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={menuopen}
              onClose={this.closeSettingsMenu}
              PaperProps={{
                style: {
                  width: 200,
                },
              }}
            >
              <MenuItem onClick={this.menuTimeWithDate}>
                {this.state.timePickerWithDate &&
                  (<ListItemIcon className={classes.icon}>
                    <CheckIcon />
                  </ListItemIcon>)}
                Time with date
            </MenuItem>
              <MenuItem onClick={this.menuTimeWithSeconds}>
                {this.state.timePickerWithSeconds &&
                  (<ListItemIcon className={classes.icon}>
                    <CheckIcon />
                  </ListItemIcon>)}
                Time with seconds
            </MenuItem>

            </Menu>
          </DialogTitle>
          <DialogContent>
            {!this.state.timePickerWithDate &&
              (<div>
                <KeyboardDatePicker
                  label="Date for start times"
                  format="DD/MM/YYYY"
                  placeholder="30/12/2018"
                  onChange={this.startTimeDateChanged}
                  mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' '] : [])}
                  className={classes.datepicker}
                  animateYearScrolling={true}
                  showTodayButton={true}
                  value={startTimeDate}
                />
              </div>)
            }
            <Table className={classes.table} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columnDataExt.filter(c => currentEvent.joinrequiresconfirmation || c.id !== 'allowed').map(column => (
                    <TableCell
                      key={column.id}
                      padding={column.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={this.createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ), this)}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedkeys.map((k) => {
                  return (
                    <TableRow hover key={k} >
                      <TableCell>
                        <IconButton
                          aria-label="Edit"
                          onClick={() => { this.props.setEditTeamId(k) }}
                          key="Edit">
                          <Icon aria-label="Edit">edit</Icon>
                        </IconButton>
                      </TableCell>

                      <TableCell padding="none">
                        {teamsList[k].nr}
                      </TableCell>
                      <TableCell>
                        <TeamContextMenu teamid={k}>
                          {teamsList[k].name}
                        </TeamContextMenu>
                      </TableCell>
                      <TableCell padding="none">
                        <div onClick={this.stopClickPropagation}>
                          {LocalTimePicker({ value: teamsList[k].starttime, onChange: this.handleChange('starttime', k) })}
                        </div>
                      </TableCell>
                      <TableCell padding="none">
                        <div onClick={this.stopClickPropagation}>
                          {LocalTimePicker({ value: teamsList[k].finishtime, onChange: this.handleChange('finishtime', k) })}
                        </div>
                      </TableCell>
                      <TableCell padding="none">
                        <Switch
                          onClick={this.stopClickPropagation}
                          checked={teamsList[k].disabled}
                          onChange={this.handleChange('disabled', k)}
                          value="disabled" />
                      </TableCell>
                      <TableCell padding="none">
                        {Object.keys(klassid).length > 2 ?
                          (<FormControl>
                            <Select
                              multiple
                              value={Object.keys(teamsList[k].klassid || {})}
                              onChange={this.handleChange('klassid', k)}
                              onClick={this.stopClickPropagation}
                              renderValue={selected => (
                                <div>
                                  {selected.map(value => <Chip key={value} label={value} />)}
                                </div>
                              )}
                            >
                              {Object.entries(klassid).map(([kltahis, klname]) => (
                                <MenuItem key={kltahis} value={kltahis}>
                                  <Checkbox checked={(teamsList[k].klassid || {})[kltahis] || false} />
                                  <ListItemText primary={klname} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>) :
                          (<div>
                            <FormGroup row>
                              {Object.entries(klassid).map(([kltahis, klname]) => (
                                <FormControlLabel key={kltahis} control={<Checkbox style={{ paddingBottom: 0, paddingTop: 0 }} checked={!!(teamsList[k].klassid || {})[kltahis]} value={kltahis}
                                  onClick={this.stopClickPropagation}
                                  onChange={this.handleChange('klasscheck', k)} />} label={klname} />
                              ))}
                            </FormGroup>
                          </div>)}
                        {teamsList[k].classes}
                      </TableCell>
                      <TableCell padding="none">
                        <Switch
                          onClick={this.stopClickPropagation}
                          checked={teamsList[k].katk}
                          onChange={this.handleChange('katk', k)}
                          value="katk" />
                      </TableCell>
                      <TableCell padding="none">
                        <Switch
                          onClick={this.stopClickPropagation}
                          checked={teamsList[k].dsq}
                          onChange={this.handleChange('dsq', k)}
                          value="dsq" />
                      </TableCell>
                      {currentEvent.joinrequiresconfirmation && (
                        <TableCell padding="none">
                          <Switch
                            checked={teamsList[k].allowed}
                            onClick={this.stopClickPropagation}
                            onChange={this.handleChange('allowed', k)}
                            value={teamsList[k].allowed} />
                        </TableCell>)}
                      {Object.entries(currentEvent.ly || []).map(([lyid, ly]) => {
                        return (
                          <TableCell key={lyid} padding="default">
                            <TextField
                              type="number"
                              onClick={this.stopClickPropagation}
                              value={(teamsList[k].lypoints || {})[lyid]}
                              onChange={this.handleLYChange(lyid, k)}
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>
        {this.state.askForDateChangeOnTeams &&
          <Dialog open={true}>
            <DialogTitle>Muuda kuupäevad stardi ja finishi aegadel</DialogTitle>
            <DialogContent>Kas uuendan stardi ja finishi ajad uuele kuupäevale?</DialogContent>
            <DialogActions><Button color="primary" onClick={() => { this.setState({ askForDateChangeOnTeams: false }) }}>Ei</Button><Button onClick={this.updateDates} color="primary" autoFocus>Jah</Button></DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentEvent: state.currentEvent,
  eventId: state.eventId,
  klassid: state.currentEvent.klassid,
  teamsList: state.teamsList,
  authUser: state.authUser
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setEventName: ev => dispatch(setEventName(ev)),
  addRaCoef: (racoef) => dispatch(addRaCoef(racoef)),
  rmRaCoef: (aste) => dispatch(rmRaCoef(aste)),
  setEditTeamId: (tid) => dispatch(setEditTeamId(tid)),
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditTeamsDialog));
