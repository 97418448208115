import React from 'react';
import { connect } from 'react-redux';
import { playBeep } from './SmallUtils';


class StartBeeper extends React.Component {

    timers = [];

    componentWillUnmount() {
        this.clearTimers();
    }

    clearTimers = () => {
        this.timers.forEach((t) => {
            clearTimeout(t);
        });
    }

    render() {
        const { teamsList, eventdata } = this.props;

        let startorder = Object.keys(teamsList).filter(tid => !teamsList[tid].disabled).sort((a, b) => teamsList[a].starttime - teamsList[b].starttime);
        let ct = new Date().getTime() + global.fbOffsetRef;
        let nextstarter = startorder.find(tid => teamsList[tid].starttime > ct - 5000);

        if (nextstarter !== undefined) {
            let nextstartms = teamsList[nextstarter].starttime;
            if (this.timers.length === 0 || this.beeperstartms !== nextstartms) {
                let timetostart = (nextstartms - ct)
                this.timers.forEach((t) => {
                    clearTimeout(t);
                });
                this.timers = [];
                for (let t = timetostart - 4000; t <= timetostart; t += 1000) {
                    if (t < 0)
                        continue;
                    const sconf = eventdata.startconf || {};
                    this.timers.push(setTimeout(() => {
                        if (timetostart - t) {
                            playBeep(sconf.sfxsecfreq || 1000, sconf.sfxsecduration || 0.1);
                        } else {
                            playBeep(sconf.sfxlastsecfreq || 1000, sconf.sfxlastsecduration || 0.5);
                        }
                    }, t));
                }
                this.beeperstartms = nextstartms;
            }
        } else {
            this.clearTimers();
        }
        return null;
    }
}

const mapStateToProps = state => ({
    eventdata: state.currentEvent,
    teamsList: state.teamsList,
})


export default connect(mapStateToProps)(StartBeeper);