import React from 'react';
import { connect } from 'react-redux';
import TrackOnMap from './TrackOnMap';
import SpeedTrackOnMap from './SpeedTrackOnMap';

let TeamTracks = (props) => {
    if (!props.team.devs)
        return null;
    const devid = props.team.defaultdev || Object.keys(props.team.devs)[0];
    return (
        <TrackOnMap key={devid} map={props.map} devid={devid} teamid={props.teamid} firststart={props.firststart} />
    );

    /*
    return Object.keys(props.team.devs).map(devid => (
        <TrackOnMap key={devid} map={props.map} devid={devid} teamid={props.teamid} firststart={props.firststart} />
    ));
    */
}

class TracksVisualizer extends React.Component {


    /* Could improve performance, to only update when teams and devices are added or removed **
        shouldComponentUpdate(nextProps, nextState) {
            if (Object.keys(nextProps.teamsList).length != Object.keys(this.props.teamsList).length)
                return true;
            for(let i=0;i<nextProps.teamsList.length;i++) {
                if (nextProps.teamsList[])
            }
    
        }
        */

    render() {
        const { teamsList, hiddenTeams, map, singleTrack } = this.props;

        if (singleTrack !== null) {
            return (
                <SpeedTrackOnMap map={map} key={singleTrack.tid + '-' + singleTrack.devid} devid={singleTrack.devid} teamid={singleTrack.tid} speedTrack />
            );
        }
        let firststart = null;
        Object.keys(teamsList).filter(t => !teamsList[t].disabled).forEach(t => {
            let sms = teamsList[t].starttime;
            if (firststart) {
                if (sms < firststart)
                    firststart = sms;
            } else
                firststart = sms;
        })
        return Object.keys(teamsList).filter(tid => !hiddenTeams[tid]).map(tid => (
            <TeamTracks key={tid} team={teamsList[tid]} map={map} teamid={tid} firststart={firststart} />
        ));
    }
}

const mapStateToProps = state => ({
    teamsList: state.teamsList,
    hiddenTeams: state.appState.hiddenTeams,
    singleTrack: state.appState.showSingleTrack,
});


export default connect(mapStateToProps)(TracksVisualizer);
