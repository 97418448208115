import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Menu, MenuItem, Divider, CircularProgress, Button } from '@material-ui/core';
import { setEditTeamId, setViewTeamResult, openSendMsg, showSingleTrack } from '../redux/actionsEvents';
import firebase from 'firebase/app';
import { addSaveInProgress, rmSaveInProgress, addUndo } from '../redux/actionsEventResult';
import { withSnackbar } from 'notistack';
import { restoreTeam, saveTeam, deleteTeam } from '../utils/backups';


const styles = theme => ({
    card: {
        maxWidth: false,
    },
});

class TeamContextMenu extends Component {

    state = {
        mouseX: null,
        mouseY: null
    }

    handleContext = (event) => {
        event.preventDefault();

        this.setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    }


    deleteTeam = (teamid, team) => {
        let docancel = false;
        const action = key => (
            <Button color="secondary" onClick={() => { docancel = true; }}>
                Cancel
            </Button>
        );
        const snackkey = this.props.enqueueSnackbar((<span>Deleting team {team.name} <CircularProgress /></span>), {
            persist: true,
            variant: "info",
            action
        });
        this.props.addSaveInProgress();

        const tfix = Date.now();

        saveTeam(this.props.eventId, teamid, team, () => docancel).then(sdata => {
            if (docancel)
                return Promise.resolve();
            const data = sdata[0];
            const delteams = firebase.storage().ref('nutilogi').child(this.props.eventId).child('deletedteams');

            return delteams.child(teamid + '+' + tfix + '.json').putString(JSON.stringify(data), firebase.storage.StringFormat.RAW, { customMetadata: { team: team.name, tfix: tfix, id: teamid } }).then((usnap) => {
                if (usnap.state !== "success") {
                    alert('Failed to save a backup. Delete cancelled');
                    return Promise.reject();
                }
                return sdata[1];
            });
        }).then(firedocs => {
            if (docancel)
                return Promise.resolve();
            return deleteTeam(this.props.eventId, teamid).then(() => {
                let promlist = [];
                firedocs.forEach(fdoc => {
                    promlist.push(fdoc.delete());
                });
                return Promise.all(promlist);
            })
        }).then(() => {
            if (docancel)
                return Promise.resolve();
            this.props.addUndo('rmteam' + teamid, undefined, "Removed team " + team.name, () => {
                this.props.addSaveInProgress();
                restoreTeam(this.props.eventId, teamid, tfix).finally(() => {
                    this.props.rmSaveInProgress();
                });
            });
        }).finally(() => {
            this.props.rmSaveInProgress();
            this.props.closeSnackbar(snackkey);
        });

    }

    menuClick = (t) => (event, param) => {
        event.stopPropagation();
        this.setState({
            mouseX: null,
            mouseY: null,
        });
        let url = null;
        let genurl = (group) => `https://console.firebase.google.com/u/0/project/nutilogi/database/nutilogi/data/~2Fteams~2F${this.props.eventId}~2F${group}~2F${this.props.teamid}`;
        let genstorageurl = (group) => `https://console.firebase.google.com/u/0/project/nutilogi/storage/nutilogi.appspot.com/files~2F${group}~2F${this.props.teamid}`;
        const { teamid, team } = this.props;
        if (t === 'teamid')
            navigator.clipboard.writeText(teamid);
        else if (t === 'devid')
            navigator.clipboard.writeText(param);
        else if (t === 'result')
            this.props.openTeamResult();
        else if (t === 'sendmsg')
            this.props.openSendMsg();
        else if (t === 'teamlist')
            url = genurl('list');
        else if (t === 'teamdata')
            url = genurl('data');
        else if (t === 'teamlogs')
            url = genstorageurl('log');
        else if (t === 'teamgpx')
            url = genstorageurl('gpx');
        else if (t === 'teamdb')
            url = genstorageurl('db');
        else if (t === 'deleteteam')
            this.deleteTeam(this.props.teamid, team);
        else if (t === 'speedmap')
            this.props.showSingleTrack(team.defaultdev || Object.keys(team.devs)[0]);
        if (url)
            window.open(url, '_blank');
    }

    render() {
        const { authUser, eventAccess, team, teamid, openSpdData } = this.props;

        return (<div onContextMenu={this.handleContext}>
            {this.state.mouseX && (<Menu open={true}
                onClose={() => { this.setState({ mouseX: null, mouseY: null }); }}
                anchorReference="anchorPosition"
                anchorPosition={
                    this.state.mouseY !== null && this.state.mouseX !== null
                        ? { top: this.state.mouseY, left: this.state.mouseX }
                        : undefined
                }
            >
                <MenuItem><b>Team {team.name}</b></MenuItem>
                <MenuItem onClick={this.menuClick('result')}>Tulemus</MenuItem>
                {(eventAccess || openSpdData) && (
                    <MenuItem onClick={this.menuClick('speedmap')}>Kiiruse kaart</MenuItem>)}
                {eventAccess && [
                    (<Divider key="div1" />),
                    (<MenuItem key="sendmsg" onClick={this.menuClick('sendmsg')}>Send Message</MenuItem>),
                ]}
                {authUser && authUser.uid === 'u31XLYs9Q0YjFmazxah3hlgholY2' && [
                    (<Divider key="div2" />),
                    (<MenuItem key="deleteteam" onClick={this.menuClick('deleteteam')}>Delete team</MenuItem>),
                    (<Divider key="div3" />),
                    (<MenuItem key="teamlogs" onClick={this.menuClick('teamlogs')}>Storage: logs</MenuItem>),
                    (<MenuItem key="teamgpx" onClick={this.menuClick('teamgpx')}>Storage: GPX</MenuItem>),
                    (<MenuItem key="teamdb" onClick={this.menuClick('teamdb')}>Storage: db</MenuItem>),

                    (<MenuItem key="teamList" onClick={this.menuClick('teamlist')}>DB: team/list</MenuItem>),
                    (<MenuItem key="teamData" onClick={this.menuClick('teamdata')}>DB: team/data</MenuItem>),
                    (<Divider key="div4" />),
                    (<MenuItem key="teamid" onClick={this.menuClick('teamid')}>Copy teamid: <i> {teamid}</i></MenuItem>),
                ]}
                {authUser && authUser.uid === 'u31XLYs9Q0YjFmazxah3hlgholY2' &&
                    Object.keys(team.devs || {}).map(devid =>
                        (<MenuItem key="devid" onClick={this.menuClick('devid', devid)}>Copy devid: <i>{devid}</i></MenuItem>)
                    )}

            </Menu>)}
            {this.props.children}
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    eventId: state.eventId,
    eventAccess: state.eventAccess,
    team: state.teamsList[ownProps.teamid],
    authUser: state.authUser,
    openSpdData: state.currentEvent.openspddata
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc)),
    addSaveInProgress: () => dispatch(addSaveInProgress()),
    rmSaveInProgress: () => dispatch(rmSaveInProgress()),
    openEditTeam: () => dispatch(setEditTeamId(ownProps.teamid)),
    openTeamResult: () => dispatch(setViewTeamResult(ownProps.teamid)),
    showSingleTrack: (devid) => dispatch(showSingleTrack(ownProps.teamid, devid)),
    openSendMsg: () => dispatch(openSendMsg(ownProps.teamid))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withSnackbar(TeamContextMenu)));
