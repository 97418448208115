import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { setEventName, updateValikAeg, addValikAeg, rmValikAeg } from '../redux/actionsEvents';
import { addUndo } from '../redux/actionsEventResult';
import BasicEventDataEditor from './BasicEventDataEditor'
import AdvancedEventDataEditor from './AdvancedEventDataEditor'
import SpeedingConfEditor from './SpeedingConfEditor'
import EventClassesEditor from './EventClassesEditor';
import ValikPropsEditor from './ValikPropsEditor';
import ValikTimesEditor from './ValikTimesEditor';
import RaCoefitsentEditor from './RaCoefitsentEditor';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import EditEventLisaylessanded from './EditEventLisaylessanded';
import firebase from 'firebase/app';
import StartConfEditor from './StartConfEditor';

const styles = theme => ({
  root: {
    height: '80vh',
  },
  tabBar: {
    marginBottom: '20px',
  },
  listSection: {
    background: 'inherit',
  },
  listSubHeader: {
    fontSize: 24,
    fontWeight: 700,
  }
});

let tabs = {
  "Start": ["valik", "suund", "track"],
  "Kiirused": ["valik", "suund", "track"],
  "Klassid": ["valik", "suund", "paevak"],
  "Valik": ["valik", "suund"],
  "Ajad": ["valik", "suund"],
  "Raskusastmed": ["valik", "suund"],
  "Lisaülessanded": ["valik", "suund"],
}

class EditEventDialog extends Component {

  state = {
    currentTab: "Yldine"
  }

  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.deleteValikAeg = this.deleteValikAeg.bind(this);
  }

  tabChange = (event, value) => {
    this.setState({ currentTab: value });
  }

  handleChange = (name, value) => {
    console.log(name);

    if (!name || name === "") {
      console.error('Missing name of changed property');
      return;
    }
    let listprop = (name === "endtime" || name === "starttime" || name === "name");
    let ref = listprop ?
      firebase.database().ref('events').child(this.props.eventId).child(name)
      : firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child(name);

    let snackbarmessage = 'Event ' + name + ' changed';
    if (name === 'starttime') {
      snackbarmessage = 'Event starttime change';
    }
    if (name === 'endtime') {
      snackbarmessage = 'Event endtime change';
    }
    let oldval = this.props.currentEvent[name];
    ref.feedbackset(value);
    this.props.addUndo(name, oldval, snackbarmessage, (undovalue) => {
      ref.feedbackset(undovalue)
    });
  }

  addRaCoef = (aste, koefitsent) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('rakoef').child(aste);
    ref.set(koefitsent);
    this.props.addUndo('add' + aste, null, 'Added KP coefitsent', (v) => {
      ref.set(null)
    })
  }

  toggleShareRakoef = (ra) => {
    let existing = (this.props.currentEvent.sharedrakoef || "").split(",");
    if (existing.includes(ra))
      existing = existing.filter(e => e !== ra);
    else
      existing.push(ra);
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('sharedrakoef');
    if (existing.length === 0) {
      ref.remove();
    } else {
      ref.set(existing.join(",").toString());
    }
  }

  rmRaCoef = (aste) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('rakoef').child(aste);
    ref.set(null);
    this.props.addUndo('rm' + aste, this.props.currentEvent.rakoef[aste], 'Removed KP coefitsent ' + aste, (v) => {
      ref.set(v)
    })
  }
  addClass = (classname) => {
    let len = 0;
    let tahis = "";
    do {
      tahis = classname.substring(0, ++len).toUpperCase();
    } while ((this.props.currentEvent.klassid || {})[tahis] !== undefined);
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('klassid').child(tahis);
    ref.feedbackset(classname);
    this.props.addUndo('add' + classname, null, 'Added contestant Class', (v) => {
      ref.set(null);
    })
  }
  removeClass = (classid) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('klassid').child(classid);
    ref.set(null);
    this.props.addUndo('rm' + classid, this.props.currentEvent.klassid[classid], 'Removed contestant Class', (v) => {
      ref.set(v);
    });
  }

  addValikAeg = (classname, aeg, penalty, per, ord) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('ajad').child(classname).push();
    let setv = {
      order: ord,
      penalty: penalty,
      per: per,
      time: aeg
    };
    ref.feedbackset(setv);
    this.props.addUndo('ev' + ref.key, null, 'Updated valikaeg', (v) => {
      ref.feedbackset(null);
    });
  }

  deleteValikAeg = (klass, id) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('ajad').child(klass).child(id);
    ref.feedbackset(null);
    this.props.addUndo('rmva' + id, this.props.currentEvent.ajad[klass][id], 'Removed valikaeg', v => {
      ref.feedbackset(v);
    });
  }

  editValikAeg = (editklass, editid, aeg, penalty, per, ord) => {
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('ajad').child(editklass).child(editid);
    let setv = {
      order: ord,
      penalty: penalty,
      per: per,
      time: aeg
    };
    ref.feedbackset(setv);

    this.props.addUndo('ev' + editid, this.props.currentEvent.ajad[editklass][editid], 'Updated valikaeg', (v) => {
      ref.feedbackset(v);
    });
  }

  render() {
    const { currentEvent, eventId, classes } = this.props;
    const { currentTab } = this.state;

    return (
      <Dialog
        open={true}
        maxWidth={'xl'}
        onClose={this.props.handleClose}
        aria-labelledby="open-event-title"
      >
        <DialogTitle id="open-event-title">Edit event</DialogTitle>
        <DialogContent
          className={classes.root}
        >
          <BasicEventDataEditor
            name={currentEvent.name}
            starttime={currentEvent.starttime}
            endtime={currentEvent.endtime}
            hideresults={currentEvent.hideresults}
            hidetracks={currentEvent.hidetracks}
            privateevent={currentEvent.private}
            type={currentEvent.type}
            fieldChanged={this.handleChange}
            eventId={eventId}
          />
          <AppBar position="static" className={classes.tabBar}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons="on" onChange={this.tabChange}>
              <Tab label="Üldine" value="Yldine" />
              {Object.keys(tabs).filter(t => tabs[t].includes(currentEvent.type)).map(t => (<Tab key={t} label={t} value={t} />))}
            </Tabs>
          </AppBar>
          {currentTab === "Yldine" && <AdvancedEventDataEditor
            eventdata={currentEvent}
            eventId={eventId}
            fieldChanged={this.handleChange}
          />}
          {currentTab === "Start" && <StartConfEditor
            eventdata={currentEvent}
            massstarttimems={currentEvent.massstarttimems}
            starttime={currentEvent.starttime}
            joinstartinterval={currentEvent.joinstartinterval}
            fieldChanged={this.handleChange}
          />}
          {currentTab === "Kiirused" && <SpeedingConfEditor
            fieldChanged={this.handleChange}
          />}
          {currentTab === "Klassid" && <EventClassesEditor
            klassid={currentEvent.klassid}
            eventdata={currentEvent}
            autojoinclass={currentEvent.autojoinclass}
            teamchoosesclass={currentEvent.teamchoosesclass}
            fieldChanged={this.handleChange}
            addClass={this.addClass}
            removeClass={this.removeClass}
          />}
          {(currentTab === "Valik") && <ValikPropsEditor
            data={currentEvent}
          />}
          {(currentTab === "Ajad") && <ValikTimesEditor
            addValikAeg={this.addValikAeg}
            editValikAeg={this.editValikAeg}
            deleteValikAeg={this.deleteValikAeg}
            data={currentEvent.ajad || {}}
            klassid={currentEvent.klassid} />}
          {(currentTab === "Raskusastmed") && <RaCoefitsentEditor
            addKPValue={this.addRaCoef}
            removeKPValue={this.rmRaCoef}
            toggleSharedRakoef={this.toggleShareRakoef}
            sharedrakoef={currentEvent.sharedrakoef}
            data={currentEvent.rakoef || {}} />}
          {(currentTab === "Lisaülessanded") && <EditEventLisaylessanded
            fieldChanged={this.handleChange}
          />}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  currentEvent: state.currentEvent,
  eventId: state.eventId,
  authUser: state.authUser
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setEventName: ev => dispatch(setEventName(ev)),
  updateValikAeg: (editid, aeg, penalty, per) => dispatch(updateValikAeg(editid, aeg, penalty, per)),
  addValikAeg: (classname, aeg, penalty, per, ord) => dispatch(addValikAeg(classname, aeg, penalty, per, ord)),
  rmValikAeg: (id) => dispatch(rmValikAeg(id)),
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditEventDialog));
