import React from 'react';
import update from 'immutability-helper';
import { ReactReduxContext } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import OpenEventDialog from './OpenEventDialog';
import EditEventDialog from './EditEventDialog';
import GPSStatusDialog from './GPSStatusDialog';
import CreateEventDialog from './CreateEventDialog';
import EventResultDialog from './EventResultDialog';
import StartListDialog from './StartListDialog';
import CounterBadge from './CounterBadge';
import Speedings from './Speedings';
import { setKPProp, setEditKPId, setShowKPs, openDialog } from '../redux/actionsEvents';
import SpeedAreaEdit from './SpeedAreaEdit';
import { connect } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import { Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import AccessRightsDialog from './AccessRightsDialog';
import TracksMenu from './TracksMenu';
import OverlaysMenu from './OverlaysMenu';
import GetStartProtocolDialog from './GetStartProtocolDialog';
import firebase from 'firebase/app';
import { download } from '../utils/SmallUtils';
import KPStatDialog from './KPStatDialog';
import { addSaveInProgress, rmSaveInProgress } from '../redux/actionsEventResult';



const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: theme.appDrawer.width,
  },
  drawerPaperWithControls: {
    position: 'relative',
    width: theme.appDrawer.width,
    height: 'calc(100% - ' + theme.playControls.height + ')'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  headerWrapper: {
    position: 'sticky',
    top: '0px',
    backgroundColor: 'white',
    zIndex: '10'
  },
  drawerTitle: {
    alignItems: 'center',
    width: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(12),
  },
  hidden: {
    display: 'none',
  },
});

class AppDrawer extends React.Component {
  static contextType = ReactReduxContext;

  state = {
    openEventOpen: false,
    editEventOpen: false,
    editAccessRightsOpen: false,
    createEventOpen: false,
    resultOpen: false,
    kpstatOpen: false,
    editMenuExpanded: false,
    tracksMenuExpanded: false,
    overlayMenuExpanded: false,
    gpsStatusOpen: false,
    speedingsOpen: false,
    startListOpen: false,
    editkpid: undefined,
    editSpeedAreasOpen: false,
    disabledOverlays: {},
  };
  inputfile = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.currentEvent.id !== this.props.currentEvent.id) {
      if (!this.props.currentEvent.isAdmin && !this.props.currentEvent.eventended)
        this.setState({ showKPs: false })
    }
  }

  handleEditSpeedAreas = () => {
    this.setState({ editSpeedAreasOpen: true })
  }
  handleCloseEditEventDialog = () => {
    this.setState({ editEventOpen: false })
  }
  handleCloseOpenEventDialog = (event) => {
    if (event !== null) {
      this.props.onOpenEvent(event.id);
    }
    this.setState({ openEventOpen: false });
  }

  handleEditClick = () => {
    this.setState({ editMenuExpanded: !this.state.editMenuExpanded });
  }

  closeSubDrawer = () => {
    if (this.state.speedingsOpen)
      this.closeSpeedings();
    if (this.state.editSpeedAreasOpen) {
      this.setState({ editSpeedAreasOpen: false })
    }
  }
  closeSpeedings = () => {
    this.props.speedings.forEach(s => s.seen = true);
    this.setState({ speedingsOpen: false })
  }

  triggerAddKP = () => {
    window.bigmap.map.map.map_.setOptions({ draggableCursor: 'crosshair' });
    let self = this;
    window.google.maps.event.addListenerOnce(window.bigmap.map.map.map_, 'click', function (event) {
      window.bigmap.map.map.map_.setOptions({ draggableCursor: null });
      const evref = firebase.database().ref('eventsdata').child(self.props.eventId);
      let uusnr = 1;
      Object.values(self.context.store.getState().kpList).forEach(kp => {
        if (Number(kp.nr) >= uusnr)
          uusnr = Number(kp.nr) + 1;
      })
      let kpref = evref.child('kp').push();
      kpref.set({ ra: 1, nr: uusnr });
      evref.child('kpdata').child(kpref.key).child('loc').set({
        lat: event.latLng.lat(), lng: event.latLng.lng()
      });

      self.props.setEditKPId(kpref.key);
    });
  }

  showKPs = (ev, checked) => {
    this.props.setShowKPs(checked);
  }

  onOpen = (name) => () => {
    this.setState({ [name + 'Open']: true });
  }

  handleOpenPopUpMenu = (name) => event => {
    this.setState({ [name]: event.currentTarget });
  };

  toggleOverlay = (o) => () => {
    if (this.state.disabledOverlays[o.id]) {
      window.mainmap.showOverlay(o);
    } else {
      window.mainmap.hideOverlay(o);
    }
    this.setState({
      disabledOverlays: update(this.state.disabledOverlays, { [o.id]: { $set: !this.state.disabledOverlays[o.id] } })
    })
  }

  toggleMapOverlay = (mapname, enable) => () => {
    if (enable) {
      let m = window.google.maps.customMapTypes.find(m => m.name === mapname);
      if (m) {
        window.mainmap.overlayMapTypes.insertAt(window.mainmap.overlayMapTypes.getLength(), m);
        m.useAsOverlay = true;
      }
    } else {
      window.mainmap.overlayMapTypes.forEach((m, idx) => {
        if (m.name === mapname) {
          m.useAsOverlay = false;
          window.mainmap.overlayMapTypes.removeAt(idx);
        }
      })
    }
    this.forceUpdate();
  }

  setOpenBounds = () => {
    /* TODO setOpenBounds
    let c = window.mainmap.getCenter().toJSON();
    let z = window.mainmap.getZoom();
    */
  }

  uploadImageSelected = (event) => {
    let bounds = window.mainmap.getBounds().toJSON();
    let wreduce = (bounds.east - bounds.west) / 10;
    let hreduce = (bounds.north - bounds.south) / 10;
    bounds.north = bounds.north - hreduce;
    bounds.south = bounds.south + hreduce;
    bounds.west = bounds.west + wreduce;
    bounds.east = bounds.east - wreduce;

    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      /*
      let ob = { type: 'addOverlay', overlay: 'img', id: this.props.currentEventData.id, name: file.name, bounds: bounds };
      ob.data = btoa(reader.result);
      ws.sendCommand(JSON.stringify(ob));
      */
    }
    reader.readAsBinaryString(file);
  }

  render() {
    const { classes, eventId, currentEvent, eventAccess, authUser, speedings, teamsList } = this.props;
    let drawerTitle = undefined;
    let archived = Boolean(currentEvent.arch);
    if (this.state.speedingsOpen)
      drawerTitle = "Kiiruse ületused"
    else if (this.state.editSpeedAreasOpen)
      drawerTitle = "Speed areas"

    const SimpleListItem = props => {
      let itemtext = (<ListItemText inset={props.icon === undefined && !props.nested} primary={props.text} secondary={props.secondary} />);
      return (
        <ListItem button={props.onClick !== null} className={props.nested ? classes.nested : null} onClick={props.onClick}>
          {props.icon !== undefined && (
            <ListItemIcon>
              <Icon>{props.icon}</Icon>
            </ListItemIcon>
          )}
          {props.firstaction !== undefined && props.firstaction}
          {(props.counter !== undefined && props.counter > 0) ? (
            <CounterBadge content={itemtext} count={props.counter} />
          ) : itemtext}
          {props.expanded !== undefined &&
            (<Icon>{props.expanded ? "expand_less" : "expand_more"}</Icon>)
          }
          {props.secondaryAction &&
            (<ListItemSecondaryAction>{props.secondaryAction}</ListItemSecondaryAction>)
          }
        </ListItem>
      )
    };
    let mainListElements = [];

    mainListElements.push((<SimpleListItem key={2} text='Open event' onClick={this.onOpen('openEvent')} icon='event' />));
    if (authUser !== null && authUser !== 'unknown') {
      mainListElements.push((<SimpleListItem key={1} text='Create event' onClick={this.onOpen('createEvent')} icon='library_add' />));
    }

    if (eventId) { // Have open event
      mainListElements.push((<Divider key={'overdiv'} />));
      mainListElements.push((<ListSubheader key={'overtitle'} disableSticky={true} >Map layers</ListSubheader>));
      if (window.google && window.google.maps.customMapTypes) {
        window.google.maps.customMapTypes.forEach((m, idx) => {
          let enabled = false;
          if (window.mainmap) {
            window.mainmap.overlayMapTypes.forEach(om => {
              if (om.name === m.name)
                enabled = true;
            })
          }
          mainListElements.push(<SimpleListItem key={'map' + idx} text={m.menuEntry !== undefined ? m.menuEntry : m.name}
            onClick={this.toggleMapOverlay(m.name, !enabled)}
            secondaryAction={
              <Icon>{enabled ? 'check_circle' : 'check_circle_outline'}</Icon>
            } />);
        })
      }

      mainListElements.push((<Divider key={3} />));
      mainListElements.push((<ListSubheader key={4} disableSticky={true} >Data viewing</ListSubheader>));
      let ct = new Date().getTime() - 600000;
      let eventended = currentEvent.endtime < ct;
      if (Object.keys(teamsList).find(tkey => teamsList[tkey].starttime > ct) !== undefined) { // have unstarted contestants, enable start menu
        mainListElements.push((<SimpleListItem key={5} text='Start list' onClick={this.onOpen('startList')} icon='outlined_flag' />));
      }
      if ((!currentEvent.hideresults || eventAccess) && currentEvent.type !== 'track' && currentEvent.type !== 'paevak') {
        mainListElements.push((<SimpleListItem key={6} text='Result' onClick={this.onOpen('result')} icon='format_list_numbered' />));
        mainListElements.push((<SimpleListItem key={"kpstat"} text='KP stats' onClick={this.onOpen('kpstat')} icon='view_comfy' />));
      }
      if (false && currentEvent.overlays.length > 0)
        mainListElements.push(<OverlaysMenu key={'overlays'} handleDrawerClose={this.props.handleDrawerClose} />);
      if (!currentEvent.hidetracks || eventAccess){
        mainListElements.push((<SimpleListItem key={7} text='Tracks' onClick={() => { this.setState({ tracksMenuExpanded: !this.state.tracksMenuExpanded }) }} icon='swap_calls' expanded={this.state.tracksMenuExpanded} />));
        mainListElements.push((
          <Collapse key={8} in={this.state.tracksMenuExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <TracksMenu classes={classes} eventended={eventended} />
              <Divider />
            </List>
          </Collapse>
        ));
      }
      if ((eventAccess || currentEvent.kpsinweb || eventended) && this.props.haveKPs) {
        mainListElements.push((
          <ListItem key={9}>
            <ListItemIcon><Icon></Icon></ListItemIcon>
            <ListItemText primary="Show KPs" />
            <ListItemSecondaryAction>
              <Switch onChange={this.showKPs} />
            </ListItemSecondaryAction>
          </ListItem>
        ));
      }

      if (currentEvent.type !== 'paevak' && (currentEvent.openspddata || eventAccess))
        mainListElements.push((<SimpleListItem key={10} text='Speedings' onClick={this.onOpen('speedings')} counter={speedings === "querying" ? 0 : speedings.filter(s => !s.seen).length} />));

      if (!eventended)
        mainListElements.push((<SimpleListItem key={11} text='GPS status' onClick={this.onOpen('gpsStatus')} />));
      if (eventAccess || (authUser && authUser.uid === 'u31XLYs9Q0YjFmazxah3hlgholY2')) {
        mainListElements.push((<Divider key={12} />));
        mainListElements.push((<ListSubheader key={13} disableSticky={true} >Administrator tools</ListSubheader>));
        mainListElements.push((<SimpleListItem key={'acr'} text='Access rights' onClick={this.onOpen('editAccessRights')} />));
        if (eventAccess && !archived) {
          if (currentEvent.type === 'paevak')
            mainListElements.push((<SimpleListItem key={"getstart"} text="Ajad splittidest" onClick={() => this.setState({ getStartProtocol: true })} />))
          //mainListElements.push((<SimpleListItem text='Messages' onClick={this.onOpen('messageDialog')} icon='message' counter={6} />));
          mainListElements.push((<SimpleListItem key={14} text='Edit' onClick={this.handleEditClick} icon='edit' expanded={this.state.editMenuExpanded} />));
          mainListElements.push((
            <Collapse key={15} in={this.state.editMenuExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <SimpleListItem nested text='Event data' onClick={this.onOpen('editEvent')} />
                <SimpleListItem nested text='Set Initial location' secondary='Set Map zoom and center' onClick={this.setOpenBounds} />
                <SimpleListItem nested text='Speed areas' onClick={this.handleEditSpeedAreas} />
                <SimpleListItem nested text='Teams' onClick={this.props.openDialog('editTeams')} counter={
                  currentEvent.joinrequiresconfirmation ? Object.values(teamsList).filter(team => !team.allowed && !team.disabled).length : 0
                } />
                <SimpleListItem nested text='Edit KP data' onClick={this.props.openDialog('editkps')} />
                <SimpleListItem nested text='Add KP' onClick={this.triggerAddKP} />
                <input
                  accept="image/*"
                  className={classes.hidden}
                  id="raised-button-file"
                  type="file"
                  onChange={this.uploadImageSelected}
                />
                <label htmlFor="raised-button-file">
                  <SimpleListItem nested text="Add image overlay" />
                </label>

              </List>
            </Collapse>
          ));
          if (authUser && authUser.uid === 'u31XLYs9Q0YjFmazxah3hlgholY2') {
            mainListElements.push((<SimpleListItem key={'impev'} text='Import event' onClick={this.triggerImportEvent} />));
            mainListElements.push((<SimpleListItem key={'restoreteam'} text='Restore deleted team' onClick={this.props.openDialog('restoreTeam')} />));
          }
          mainListElements.push((<SimpleListItem key='exportEvent' text='Export event' onClick={this.triggerExportEvent} />))
          if (authUser && authUser.uid === 'u31XLYs9Q0YjFmazxah3hlgholY2') {
            mainListElements.push((<SimpleListItem key='gentracks' text='Generate final tracks ...' onClick={this.generateFinalTrakcs} />))
            mainListElements.push((<SimpleListItem key='exportState' text='Export Current state ...' onClick={() => {
              let state = this.context.store.getState();
              download(JSON.stringify(state), 'state.json', 'application/json');
            }} />))
          }
          /*
          mainListElements.push((
            <ListItem key={16}>
              <ListItemIcon><Icon>satellite</Icon></ListItemIcon>
              <ListItemText primary="GPS tracking enabled" />
              <ListItemSecondaryAction>
                <Switch onChange={this.toggleGPSTracking} />
              </ListItemSecondaryAction>
            </ListItem>
          ));*/
        }
      }
    }

    return (
      <Drawer
        variant="persistent"
        classes={{
          paper: (this.props.trackType === 'replay' ? classes.drawerPaperWithControls : classes.drawerPaper),
        }}
        anchor='left'
        open={this.props.drawerOpen}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.drawerHeader}>
            {drawerTitle && (
              <Typography className={classes.drawerTitle} color='primary' variant='h6' align="center">{drawerTitle}
                <IconButton className={classes.closebutton} onClick={this.closeSubDrawer} aria-label="Close" >
                  <Clear />
                </IconButton>
              </Typography>
            )}
            <IconButton onClick={this.props.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
        </div>
        {this.state.speedingsOpen && <Speedings onClose={() => this.setState({ speedingsOpen: false })} />}
        {this.state.editSpeedAreasOpen && <SpeedAreaEdit onClose={() => this.setState({ editSpeedAreasOpen: false })} />}
        {(!this.state.speedingsOpen && !this.state.editSpeedAreasOpen) && (
          <List>{mainListElements}</List>
        )}
        <Divider />
        {this.state.openEventOpen && (<OpenEventDialog
          handleClose={this.handleCloseOpenEventDialog} />)}
        {this.state.editEventOpen && (<EditEventDialog
          handleClose={() => { this.setState({ editEventOpen: false }) }} />)}
        {this.state.createEventOpen && (<CreateEventDialog
          handleClose={() => { this.setState({ createEventOpen: false }) }}
          OpenEvent={(eid) => { this.props.onOpenEvent(eid) }}
        />)}
        {this.state.resultOpen && (<EventResultDialog eventid={eventId} eventname={currentEvent.name}
          handleClose={() => { this.setState({ resultOpen: false }) }} />)}
        {this.state.kpstatOpen && (<KPStatDialog handleClose={() => { this.setState({ kpstatOpen: false }) }} />)}
        {this.state.startListOpen && (<StartListDialog handleClose={() => { this.setState({ startListOpen: false }) }} />)}
        {this.state.gpsStatusOpen && (<GPSStatusDialog handleClose={() => this.setState({ gpsStatusOpen: false })} />)}
        {this.state.editAccessRightsOpen && (<AccessRightsDialog handleClose={() => this.setState({ editAccessRightsOpen: false })} />)}
        {this.state.getStartProtocol && (<GetStartProtocolDialog handleClose={() => this.setState({ getStartProtocol: false })} />)}
        <input ref={this.inputfile} type="file" style={{ "display": "none" }} onChange={this.importEvent} />
      </Drawer>

    );
  }

  importEvent = (e) => {
    var files = e.target.files || e.dataTransfer.files;

    var reader = new FileReader();
    reader.onload = function (e) {
      var evdata = JSON.parse(e.target.result);
      let db = firebase.database();
      db.ref('events').child(evdata.eventId).set(evdata.events);
      db.ref('eventsdata').child(evdata.eventId).set(evdata.eventsdata);
      db.ref('teams').child(evdata.eventId).set(evdata.teams);
      firebase.firestore().collection('events').doc(evdata.eventId).set(evdata.firestoredoc);
    }
    reader.readAsText(files[0]);
  }

  triggerImportEvent = () => {
    this.inputfile.current.value = "";
    this.inputfile.current.click();
  }

  triggerExportEvent = async () => {
    let evid = this.props.eventId;
    let evdata = {
      eventId: evid
    }
    let db = firebase.database();
    evdata['events'] = (await db.ref('events').child(evid).once('value')).val();
    evdata['eventsdata'] = (await db.ref('eventsdata').child(evid).once('value')).val();
    evdata['teams'] = (await db.ref('teams').child(evid).once('value')).val();
    evdata['firestoredoc'] = (await firebase.firestore().collection('events').doc(evid).get()).data()

    download(JSON.stringify(evdata), evid + '_event.json', 'applicaiton/json');
  }
  generateFinalTrakcs = () => {
    this.props.addSaveInProgress();
    this.props.authUser.getIdToken().then(token => {
      fetch('https://europe-west1-nutilogi.cloudfunctions.net/api/genfinaltracks', {
        headers: {
          'Authorization': 'Bearer ' + token,
          'X-EventId': this.props.eventId
        }
      }).then(data => {
        return data.text();
      }).then(data => {
        this.props.rmSaveInProgress();
        console.log('fetch complete', data);
      }).catch(e => {
        this.props.rmSaveInProgress();
        console.log('got error on fetch');
      })
    });
  }
}


const mapStateToProps = state => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
  teamsList: state.teamsList,
  speedings: state.speedingsData,
  haveKPs: Object.keys(state.kpList).length > 0,
  trackType: state.appState.trackType,
  authUser: state.authUser,
  eventAccess: state.eventAccess
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setShowKPs: (showkps) => dispatch(setShowKPs(showkps)),
  setKPProp: (kpid, name, value) => dispatch(setKPProp(kpid, name, value)),
  setEditKPId: (kpid) => dispatch(setEditKPId(kpid)),
  openDialog: (name) => () => dispatch(openDialog(name)),
  addSaveInProgress: () => dispatch(addSaveInProgress()),
  rmSaveInProgress: () => dispatch(rmSaveInProgress())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AppDrawer));
