import React, { Component } from 'react';
import { connect } from 'react-redux';
import TeamEntryInMenu from './TeamEntryInMenu';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { hideTeam, zoomToTrack, setHiddenTeams, setTrackTailLength, setReplayType, setTrackType } from '../redux/actionsEvents';


const trackTypes = {
    'no': 'No tracks',
    'live': 'Live tracking',
    'replay': 'Replay',
    'full': 'Full track'
};

const tailTypes = {
    '0': 'No tail',
    '60000': '1 minute',
    '300000': '5 minutes',
    '86400000': '1 day'
};

const replayTypes = {
    'ownstart': 'Oma stardiajad',
    'massstart': 'Ühisstart',
};

class TracksMenu extends Component {

    state = {
        replayType: 'ownstart'
    }

    teamClicked = (tid, devid) => () => {
        this.props.showTeam(tid, true);
        const devid = this.props.teamsList[tid].defaultdev || Object.keys(this.props.teamsList[tid].devs || {})[0];
        this.props.zoomToTrack(tid, devid);
        return;
    };


    toggleTeam = (tid) => () => {
        this.props.showTeam(tid, this.props.hiddenTeams[tid]);
    }

    activateAllTeams = () => {
        this.props.setHiddenTeams([]);
        return;
    }

    deactivateAllTeams = () => {
        const { teamsList } = this.props;
        this.props.setHiddenTeams(Object.keys(teamsList));
    }

    selectTrackType = (type) => () => {
        this.props.setTrackType(type);
        this.setState({ trackViewTypeAnchorEl: null });
    }

    selectTailType = (type) => () => {
        this.props.setTrackTailLength(type);
        this.setState({ tailTypeAnchorEl: null });
    }
    selectReplayType = (type) => () => {
        this.props.setReplayType(type);
        this.setState({ replayType: type, replayTypeAnchorEl: null });
    }

    render() {
        const { classes, teamsList, trackType, trackTailLength, eventended, hiddenTeams } = this.props;
        const { replayType } = this.state;
        let active = trackType !== 'no';

        return (
            <div>
                <ListItem button className={classes.nested} onClick={(e) => { this.setState({ trackViewTypeAnchorEl: e.currentTarget }) }} >
                    <ListItemText primary="Track view type" secondary={trackTypes[trackType]} />
                </ListItem>
                {(trackType === 'live' || trackType === 'replay') && (<ListItem button className={classes.nested} onClick={(e) => { this.setState({ tailTypeAnchorEl: e.currentTarget }) }} >
                    <ListItemText primary="Tail length" secondary={tailTypes[trackTailLength]} />
                </ListItem>)}
                {trackType === 'replay' && (
                    <ListItem button className={classes.nested} onClick={(e) => { this.setState({ replayTypeAnchorEl: e.currentTarget }) }}>
                        <ListItemText primary="Replay type" secondary={replayTypes[replayType]} />
                    </ListItem>
                )}
                <Divider />
                <ListSubheader className={classes.nested} disableSticky={true} >Teams</ListSubheader>
                {active && (<ListItem>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.activateAllTeams}>
                        Show all
                    </Button>
                    <Button variant="contained" style={{ marginLeft: '16px' }} color="primary" className={classes.button} onClick={this.deactivateAllTeams}>
                        Show none
                     </Button>
                </ListItem>)}
                {Object.keys(teamsList).filter(t => !teamsList[t].disabled).sort((l, r) => (teamsList[l].name || "").localeCompare(teamsList[r].name)).map((tkey) => (
                    <TeamEntryInMenu
                        key={tkey}
                        teamid={tkey}
                        devid={teamsList[tkey].defaultdev || Object.keys(teamsList[tkey].devs || { 'dummy': '' })[0]}
                        onClick={active ? this.teamClicked(tkey) : undefined}
                        secondaryClick={active ? this.toggleTeam(tkey) : undefined}
                        active={active && !hiddenTeams[tkey]}
                    />
                ))}
                <Menu anchorEl={this.state.trackViewTypeAnchorEl} open={Boolean(this.state.trackViewTypeAnchorEl)} onClose={() => { this.setState({ trackViewTypeAnchorEl: null }) }} >
                    {Object.keys(trackTypes).filter(t => !(t === 'live' && eventended)).map(t => (
                        <MenuItem key={t} selected={t === trackType} onClick={this.selectTrackType(t)}>{trackTypes[t]}</MenuItem>
                    ))}
                </Menu>
                <Menu anchorEl={this.state.tailTypeAnchorEl} open={Boolean(this.state.tailTypeAnchorEl)} onClose={() => { this.setState({ tailTypeAnchorEl: null }) }} >
                    {Object.keys(tailTypes).map(t => (
                        <MenuItem key={t} selected={t === trackTailLength} onClick={this.selectTailType(t)}>{tailTypes[t]}</MenuItem>
                    ))}
                </Menu>
                <Menu anchorEl={this.state.replayTypeAnchorEl} open={Boolean(this.state.replayTypeAnchorEl)} onClose={() => { this.setState({ replayTypeAnchorEl: null }) }} >
                    {Object.keys(replayTypes).map(t => (
                        <MenuItem key={t} selected={t === replayType} onClick={this.selectReplayType(t)}>{replayTypes[t]}</MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentEventData: state.currentEventData,
    teamsList: state.teamsList,
    trackType: state.appState.trackType,
    trackTailLength: state.appState.trackTailLength,
    hiddenTeams: state.appState.hiddenTeams,

})

const mapDispatchToProps = (dispatch) => ({
    setTrackType: (type) => dispatch(setTrackType(type)),
    setTrackTailLength: l => dispatch(setTrackTailLength(l)),
    setReplayType: (type) => dispatch(setReplayType(type)),
    showTeam: (tid, show) => dispatch(hideTeam(tid, !show)),
    setHiddenTeams: (teamids) => dispatch(setHiddenTeams(teamids)),
    zoomToTrack: (tid, devid) => dispatch(zoomToTrack(tid, devid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TracksMenu);
