import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { addUndo } from '../redux/actionsEventResult';
import firebase from 'firebase/app';
import { Card, CardHeader, CardContent, List, ListSubheader, ListItem, ListItemText, Paper, Grid } from '@material-ui/core';
import { defaultorderrules, orderrules } from '../utils/orderrules';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  smallnr: {
    width: '2em',
    textAlign: 'center'
  },
  simpleInput: {
    whiteSpace: 'nowrap'
  },
  ruleitem: {
    backgroundColor: 'white'
  }
});


class ValikPropsEditor extends Component {

  handleChange = (name) => event => {
    let value = event.target.value;
    let snackbarmessage = "";
    console.log('settt', name, event.target.checked, event.target.value);

    if (name === 'wrongpenaltyenabled') {
      value = event.target.checked;
      snackbarmessage = "Vale KP trahvitakse changed";
    } else if (name === 'answerscores') {
      value = event.target.checked;
      snackbarmessage = "Vastamine annab punkte changed";
    } else if (name === 'kpsinweb') {
      value = event.target.checked;
      snackbarmessage = "Show KP's in web changed";
    } else if (name === 'kpdataclosed') {
      value = event.target.checked;
      snackbarmessage = (value ? "Client's cant see KP data" : "KP data is set to public");
    } else if (name === 'maxmarkingdistance' || name === 'wrongcoef' || name === 'firstkpvisitbonus') {
      value = Number(value);
      if (value === 0)
        value = null;
    }


    //console.log('settt', name, value, event.target.value);
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child(name);

    let oldval = this.props.currentEvent[name];
    ref.feedbackset(value);
    this.props.addUndo(name, oldval, snackbarmessage, (undovalue) => {
      ref.feedbackset(undovalue)
    });
  };

  onRuleDragEnd = (result) => {
    if (result.destination === null)
      return;
    let usedrules = (this.props.currentEvent.orderrules || defaultorderrules).split(",");
    let newrules = [];
    if (result.destination.droppableId === "unused") {
      newrules = usedrules.filter(v => v !== result.draggableId);
    } else if (result.destination.droppableId === "used") {
      newrules = usedrules.filter(v => v !== result.draggableId);
      newrules.splice(result.destination.index, 0, result.draggableId);
    }
    if (newrules.length === 0)
      return;
    let ref = firebase.database().ref('eventsdata').child(this.props.eventId).child('data').child('orderrules');
    const newrulestring = newrules.join(",");
    if (newrulestring === defaultorderrules) {
      ref.remove();
    } else {
      ref.feedbackset(newrulestring);
    }
  }

  render() {
    const { currentEvent, classes } = this.props;


    let wrongpenaltyenabled = Boolean(currentEvent.wrongpenaltyenabled);
    let answerscores = Boolean(currentEvent.answerscores);
    let kpsinweb = Boolean(currentEvent.kpsinweb);
    let kpdataclosed = Boolean(currentEvent.kpdataclosed);
    let usedrules = (currentEvent.orderrules || defaultorderrules).split(",");
    return (
      <div>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={wrongpenaltyenabled}
                onChange={this.handleChange('wrongpenaltyenabled')}
                value={wrongpenaltyenabled ? false : true}
              />
            }
            label="Vale KP trahvitakse"
          />
          <FormControlLabel
            control={
              <Switch
                checked={answerscores}
                onChange={this.handleChange('answerscores')}
                value={answerscores ? false : true}
              />
            }
            label="Vastamine annab alati punkte"
          />
          <FormControlLabel
            control={
              <Switch
                checked={kpsinweb}
                onChange={this.handleChange('kpsinweb')}
                value={kpsinweb ? false : true}
              />
            }
            label="Näita webi's KP'sid"
          />
          <FormControlLabel
            control={
              <Switch
                checked={kpdataclosed}
                onChange={this.handleChange('kpdataclosed')}
                value={kpdataclosed ? false : true}
              />
            }
            label="Võistlejad ei näe KP kirjeldust, asukohta ja vastuseid"
          />
          <DragDropContext onDragEnd={this.onRuleDragEnd}>
            <Card>
              <CardHeader title="Järjekorra reeglid" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item >
                    <Droppable droppableId={"used"}>
                      {(provided) => (
                        <Paper innerRef={provided.innerRef} {...provided.droppableProps}>
                          <List>
                            <ListSubheader>Kasutuses</ListSubheader>
                            {usedrules.map((item, idx) => (
                              <Draggable key={item} draggableId={item} index={idx}>
                                {(provided) => (
                                  <ListItem className={classes.ruleitem} key={item} {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
                                    <ListItemText primary={`${item}`} secondary={orderrules[item].desc} />
                                  </ListItem>
                                )}
                              </Draggable>
                            ))}
                          </List>
                          {provided.placeholder}
                        </Paper>)
                      }
                    </Droppable>
                  </Grid>
                  <Grid item>
                    <Droppable droppableId="unused">
                      {(provided) => (
                        <Paper innerRef={provided.innerRef} {...provided.droppableProps}>
                          <List>
                            <ListSubheader>Kasutamata</ListSubheader>
                            {Object.keys(orderrules).filter(r => !usedrules.includes(r)).map((item, idx) => (
                              <Draggable key={item} draggableId={item} index={idx}>
                                {(provided) => (
                                  <ListItem key={item} {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
                                    <ListItemText primary={`${item}`} secondary={orderrules[item].desc} />
                                  </ListItem>
                                )}
                              </Draggable>
                            ))}
                          </List>
                          {provided.placeholder}
                        </Paper>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DragDropContext>
        </FormControl>
        <TextField
          id="maxmarkingdistance"
          label="Max marking distance"
          value={currentEvent.maxmarkingdistance}
          onChange={this.handleChange('maxmarkingdistance')}
          type="number"
          className={classes.textField}
          margin="normal"
          helperText="If set to 0 then no limit"
        />
        <TextField
          id="valekoef"
          label="Vale koefitsent"
          value={currentEvent.wrongcoef === null ? 1 : currentEvent.wrongcoef}
          onChange={this.handleChange('wrongcoef')}
          type="number"
          className={classes.textField}
          margin="normal"
          helperText="wrong penalty is multiplied with this value"
        />
        <TextField
          id="firstbonus"
          label="Esimese boonus"
          value={currentEvent.firstkpvisitbonus === null ? 0 : currentEvent.firstkpvisitbonus}
          onChange={this.handleChange('firstkpvisitbonus')}
          type="number"
          className={classes.textField}
          margin="normal"
          helperText="Team who's first to take a KP will get this additional bonus"
        />
      </div>
    );
  }
}

ValikPropsEditor.propTypes = {
};

const mapStateToProps = state => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles))(ValikPropsEditor);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ValikPropsEditor));
