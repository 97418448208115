import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app';
import { FirebaseAuth } from 'react-firebaseui';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { clearAlert } from '../redux/actionsEventResult';
import SearchBox from '../components/SearchBox';
import * as firebaseui from 'firebaseui';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.appDrawer.width}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.appDrawer.width,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
});

class SimpleDialog extends React.Component {

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      firebase.auth.OAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccess: () => {
        this.props.onClose(true)
        return false; // Avoid redirects after sign-in.
      }
    },
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
  };

  render() {
    const { classes, ...other } = this.props;

    return (
      <Dialog onClose={() => this.props.onClose(false)} aria-labelledby="simple-dialog-title" {...other}>
        <FirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
      </Dialog>
    );
  }
}

class NutiLogiAppBar extends React.Component {
  state = {
    anchorEl: null,
    loginWindowOpen: false,
  };


  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSignOut = () => {
    firebase.auth().signOut();
    this.handleClose();
  }

  handleOpenLoginWindow = () => {
    this.setState({ loginWindowOpen: true })
  }
  handleCloseLoginWindow = (signedIn) => {
    this.setState({ loginWindowOpen: false })
  }
  render() {
    const { classes, drawerOpen, eventName, alertMsg, authUser, authStateKnown } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar className={classNames(classes.appBar, {
          [classes.appBarShift]: this.props.drawerOpen,
        })} position="static" >

          <Toolbar disableGutters={!drawerOpen}>
            <IconButton className={classNames(classes.menuButton, drawerOpen && classes.hide)}
              color="inherit" aria-label="open drawer"
              onClick={this.props.handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" color="inherit" noWrap className={classes.flex}>
              {eventName === undefined ? "Nutilogi" : eventName}
            </Typography>



            {this.props.saveInProgress > 0 && (<CircularProgress className={classes.progress} color="inherit" />)}

            <SearchBox />

            {authStateKnown && (authUser ?
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </div>
              :
              <Button color="default" onClick={this.handleOpenLoginWindow}>Login</Button>)
            }
          </Toolbar>
        </AppBar>
        <SimpleDialog
          open={this.state.loginWindowOpen}
          onClose={this.handleCloseLoginWindow} />
        {alertMsg.msg !== undefined &&
          (<Dialog
            open={true}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{alertMsg.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {alertMsg.msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {alertMsg.button !== undefined &&
                (<Button onClick={alertMsg.button.func} color="primary" autoFocus>
                  {alertMsg.button.text}
                </Button>)
              }
              <Button onClick={this.props.clearAlert} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>)}
      </div>
    );
  }
}

AppBar.propTypes = {
  updateAuthState: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    saveInProgress: state.saveInProgress,
    authStateKnown: state.authUser !== 'unknown',
    authUser: state.authUser,
    eventName: state.currentEvent.name,
    alertMsg: state.alertMsg,

  }
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  clearAlert: ev => dispatch(clearAlert()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NutiLogiAppBar));
