import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AlertDialog extends React.Component {
  
    render() {
      if (!this.props.open)
        return null;
      return (
        <div>
          <Dialog
            open={true}
            transition={Transition}
            keepMounted
            onClose={this.props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
          {this.props.title !== undefined && 
            (<DialogTitle id="alert-dialog-slide-title">
              {this.props.title}
            </DialogTitle>)
          }
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {this.props.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  
  AlertDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string
  };
  export default AlertDialog;