import firebase from 'firebase/app';


const teamkeys = ['answerresult', 'data', 'kpanswers', 'list', 'spdtickets'];

const tref = (eventId, teamid, sec) => {
    return firebase.database().ref('teams').child(eventId).child(sec).child(teamid);
}

export function deleteTeam(eventId, teamid) {
    let promlist = [];
    teamkeys.forEach(k => {
        promlist.push(tref(eventId, teamid, k).remove());
    });

    return Promise.all(promlist);
}

export async function restoreTeam(eventId, teamid, tfix) {
    const delteams = firebase.storage().ref('nutilogi').child(eventId).child('deletedteams');
    const url = await delteams.child(teamid + '+' + tfix + '.json').getDownloadURL();
    const data = await fetch(url).then(r => r.json());
    let promlist = [];
    teamkeys.forEach(k => {
        if (k in data) {
            promlist.push(tref(eventId, teamid, k).set(data[k]));
        }
    });
    const stcoll = firebase.firestore().collection('events').doc(eventId).collection('tracks').doc(teamid).collection('devices');
    stcoll.parent.set({});
    Object.entries(data.store.tracks).forEach(([devid, devdata]) => {
        promlist.push(stcoll.doc(devid).set(devdata.doc).catch(e => {
            console.log('Failed to restore team', e);
        }));
        Object.entries(devdata.points).forEach(([docid, pdoc]) => {
            promlist.push(stcoll.doc(devid).collection('points').doc(docid).withConverter(pointConverter).set(pdoc));
        });
    });
    return Promise.all(promlist);
}

const pointConverter = {
    toFirestore: (data) => {
        Object.entries(data.points).forEach(([tprefix, tgroup]) => {
            Object.entries(tgroup).forEach(([tsuffix, point]) => {
                const jp = point.p;
                point.p = new firebase.firestore.GeoPoint(jp.lat, jp.lon);
            })
        })
        return data;
    },
    fromFirestore: (snap, opts) => {
        let data = snap.data();
        Object.entries(data.points).forEach(([tprefix, tgroup]) => {
            Object.entries(tgroup).forEach(([tsuffix, point]) => {
                const gp = point.p;
                point.p = { lat: gp.latitude, lon: gp.longitude };
            })
        })
        return data;
    }
}

export async function saveTeam(eventId, teamid, team, isCancelled) {

    let data = { store: { tracks: {} } };
    let fdocs = [];
    const token = await firebase.auth().currentUser.getIdToken();
    const fresult = await fetch('https://europe-west1-nutilogi.cloudfunctions.net/api/movetrackpoints', {
        headers: {
            'Authorization': 'Bearer ' + token,
            'X-EventId': eventId,
            'X-TeamId': teamid
        }
    }).then(data => data.text());
    if (fresult !== "Completed") {
        alert('Failed to pocess gps data');
        return Promise.reject();
    }
    await teamkeys.reduce((prevprom, k) => {
        return prevprom.then(() => {
            if (isCancelled())
                return Promise.resolve();
            return tref(eventId, teamid, k, false).once('value').then(v => {
                data[k] = v.val();
            });
        });

    }, Promise.resolve());
    if (isCancelled())
        return Promise.resolve();
    const teamdoc = firebase.firestore().collection('events').doc(eventId).collection('tracks').doc(teamid);
    fdocs.push(teamdoc);
    const devsnaps = await teamdoc.collection('devices').get({ source: "server" });
    await devsnaps.docs.reduce((prevprom, devdoc) => {
        fdocs.push(devdoc.ref);
        return prevprom.then(() => {
            if (isCancelled())
                return Promise.resolve();
            data.store.tracks[devdoc.id] = { doc: devdoc.data(), points: {} };
            return devdoc.ref.collection('points').withConverter(pointConverter).get({ source: "server" }).then(points => {
                points.forEach(pdoc => {
                    fdocs.push(pdoc.ref);
                    data.store.tracks[devdoc.id].points[pdoc.id] = pdoc.data();
                });
            });
        });

    }, Promise.resolve());
    return [data, fdocs];
}
