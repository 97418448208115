import firebase from "firebase/app";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
//import fbslices, { eventSlice } from "redux/fbListSlices";
//import { addPoints } from "redux/tracksSlice";
import { TrackDecoder } from "trzip";
import { addFinalTrackData } from "../redux/actionsEvents";
import CheapRuler from 'cheap-ruler';


export const ArchiveReader = ({ archive, eventId, resultOnly }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const applyEvData = (evdata) => {
      if (evdata.eventsdata?.data?.ajad) {
        Object.keys(evdata.eventsdata.data.ajad).forEach(ak => {
          if (ak === "lisaajad")
            return;
          for (let i in evdata.eventsdata.data.ajad[ak]) {
            console.log(ak, i, evdata.eventsdata.data.ajad[ak][i]);
            if (evdata.eventsdata.data.ajad[ak][i] === null) {
              delete evdata.eventsdata.data.ajad[ak][i];
            }
          }
        })
      }
      console.log('apple ev data', evdata);
      dispatch({ type: 'teamsList' + '_value', v: evdata.teams.list || null });
      dispatch({ type: 'eventlist' + '_value', v: evdata.events  || null});
      dispatch({ type: 'eventdata' + '_value', v: evdata.eventsdata.data  || null});
      dispatch({ type: 'kpList' + '_value', v: evdata.eventsdata.kp  || null});
      dispatch({ type: 'answerResult' + '_value', v: evdata.teams.answerresult  || null});
      dispatch({ type: 'spdtickets' + '_value', v: evdata.teams.spdtickets || null });
      dispatch({ type: 'kpData' + '_value', v: evdata.eventsdata.kpdata  || null});
      
      /*
              firebaseObjectSync(db.ref('events').child(eventId), 'eventlist');
        firebaseObjectSync(db.ref('eventsdata').child(eventId).child('data'), 'eventdata');
        firebaseObjectSync(db.ref('eventsdata').child(eventId).child('kp'), 'kpList');
        firebaseObjectSync(db.ref('teams').child(eventId).child('spdtickets'), 'spdtickets');
        firebaseObjectSync(db.ref('teams').child(eventId).child('answerresult'), 'answerResult');
        
        <FBListener key="kpdata" dbref={fdb.ref('eventsdata').child(eventId).child('kpdata')} prefix="kpData" />

        */

      /*
      dispatch(eventSlice.actions.combineValue(evdata.eventsdata.data));
      Object.entries(fbslices).forEach(([k, slice]) => {
        dispatch(slice.actions.replaceValue(evdata[slice.group][slice.path]));
      });
      */
    };
    if (archive === "a1") {
      console.log('its a1 archivee', eventId);
      const sref = firebase.storage().ref("archive").child(eventId);
      sref
        .child("evdata.json")
        .getDownloadURL()
        .then((url) => fetch(url))
        .then((r) => r.json())
        .then((evdata) => {
          applyEvData(evdata);
        });
      if (!resultOnly) {
        sref
          .child("tracks.dat")
          .getDownloadURL()
          .then((url) => fetch(url))
          .then((r) => r.arrayBuffer())
          .then((buf) => {
            let decodeddata = TrackDecoder.decode(Buffer.from(buf));
            Object.entries(decodeddata).forEach(([tid, tdata]) => {
              Object.entries(tdata).forEach(([devid, points]) => {
                const usepoints = points.map(p =>
                    Object.assign(new window.google.maps.LatLng(p.y, p.x), {t: p.t, s: p.s, a: p.a, g: p.g, l: p.l})
                ).filter(p => p.a < 30);
                if (usepoints.length > 0) {
                  let ruler = null;
                  usepoints[0].d = 0;
                  for (let i = 1; i < usepoints.length; i++) {
                                    if (!ruler)
                                        ruler = new CheapRuler(usepoints[0].lat(), 'meters');
                                    usepoints[i].d = usepoints[i - 1].d + ruler.distance([usepoints[i - 1].lng(), usepoints[i - 1].lat()], [usepoints[i].lng(), usepoints[i].lat()]);
                  }
                  dispatch(addFinalTrackData(tid, devid, usepoints));
                }
                /*
                                const nob = Object.assign(new window.google.maps.LatLng(
                    prevob.lat() + getnum('lat'),
                    prevob.lng() + getnum('lon')), {
                    t: prevob.t + getnum('t') + 1000,
                    s: prevob.s + getnum('s'),
                    a: prevob.a + getnum('a'),
                    g: prevob.g + getnum('g'),
                    l: prevob.l + getnum('l'),
                });
                if (points.length > 0)
                  dispatch(addPoints({ tid, devid, points }));
                  */
              });
            });
          });
      }
    }
  }, []);
  return null;
};
