import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SimpleTextInput from './SimpleTextInput';

import { connect } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { toggleOverlay, setEditOverlayId, setAdjustOverlayLocState, saveOverlayChanges } from '../redux/actionsEvents';


class OverlaysMenu extends React.Component {
    state = {
        overlayMenuExpanded: false,
        overlayPopUp: null,
        menuoverlay: null,
        asknameopen: false
    }
    render() {
        const { appState, eventData } = this.props;
        const { overlayMenuExpanded } = this.state;
        return (
            <div>
                <ListItem button={true} onClick={() => { this.setState({ overlayMenuExpanded: !overlayMenuExpanded }) }}>
                    <ListItemIcon>
                        <Icon>layers</Icon>
                    </ListItemIcon>
                    <ListItemText primary={'Map overlays'} />
                    <Icon>{overlayMenuExpanded ? "expand_less" : "expand_more"}</Icon>
                </ListItem>
                <Collapse key={'overlay-m'} in={overlayMenuExpanded} timeout="auto" unmountOnExit>
                    <List component="div" >
                        <ListItem button={true} onClick={() => {
                            eventData.overlays.forEach(o => {
                                if (!appState.hiddenOverlays[o.id])
                                    this.props.toggleOverlay(o.id)
                            });
                        }}>
                            <ListItemText inset={true} primary={'Hide all overlays'} />
                        </ListItem>
                        {eventData.overlays.map((o) => (
                            <ListItem key={o.id} button={true} onClick={() => this.props.toggleOverlay(o.id)}>
                                <ListItemIcon><Icon>{appState.hiddenOverlays[o.id] ? 'check_circle_outline' : 'check_circle'}</Icon></ListItemIcon>
                                <ListItemText primary={o.name} />
                                {eventData.isAdmin &&
                                    (<ListItemSecondaryAction>
                                        <IconButton onClick={(event) => { this.setState({ menuoverlay: o, overlayPopUp: event.currentTarget }) }}>
                                            <Icon>more_horiz</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>)
                                }
                            </ListItem>
                        ))}
                        <Divider />
                    </List>
                    <Menu anchorEl={this.state.overlayPopUp} open={Boolean(this.state.overlayPopUp)} onClose={() => { this.setState({ overlayPopUp: null }) }} >
                        <MenuItem onClick={() => {
                            this.props.setEditOverlayId(this.state.menuoverlay.id);
                            this.props.setAdjustOverlayLocState('click_img_1')
                            this.props.handleDrawerClose();
                            this.setState({ overlayPopUp: null });
                        }}>Adjust location</MenuItem>
                        <MenuItem onClick={() => {
                            console.log('oige');
                            console.log(eventData);
                            this.setState({ overlayPopUp: null, asknameopen: true })
                        }}
                        >Rename</MenuItem>
                        {eventData.overlays.length > 1 && (
                            <MenuItem onClick={() => {
                                this.setState({ locationFromMapPopUp: this.state.overlayPopUp, overlayPopUp: null });
                            }}
                            >Loction from other map</MenuItem>

                        )}
                    </Menu>
                    {this.state.locationFromMapPopUp && (
                        <Menu anchorEl={this.state.locationFromMapPopUp} open={Boolean(this.state.locationFromMapPopUp)} onClose={() => { this.setState({ locationFromMapPopUp: null }) }} >
                            {eventData.overlays.filter(o => o.id !== this.state.menuoverlay.id).map(o => (
                                <MenuItem key={o.id} onClick={() => {
                                    this.props.saveOverlayChanges(this.state.menuoverlay.id, {
                                        latlngbounds: o.latlngbounds,
                                        rotation: o.rotation
                                    });
                                    this.setState({ locationFromMapPopUp: null })
                                }}
                                >{o.name}</MenuItem>
                            ))}
                        </Menu>)}
                </Collapse>
                {this.state.asknameopen && (<SimpleTextInput
                    title="Overlay nimi"
                    desc="Sisesta uus nimi overlayle"
                    value={this.state.menuoverlay.name}
                    handleClose={(value) => (event) => {
                        this.props.saveOverlayChanges(this.state.menuoverlay.id, {
                            name: value
                        });
                        this.setState({ asknameopen: false })

                    }}
                />)}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    eventData: state.currentEventData,
    teamsList: state.teamsList,
    appState: state.appState,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleOverlay: (id) => dispatch(toggleOverlay(id)),
    setEditOverlayId: (id) => dispatch(setEditOverlayId(id)),
    saveOverlayChanges: (id, changes) => dispatch(saveOverlayChanges(id, changes)),
    setAdjustOverlayLocState: (state) => dispatch(setAdjustOverlayLocState(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(OverlaysMenu);
