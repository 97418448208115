import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

const styles = theme => ({
    mapicon: {
        transform: 'translate(-50%,-50%)'
    }

});

class KPIcon extends React.Component {

    render() {
        const { classes, lat, lng, kp, ra, color, fontSize } = this.props;

        const forwardprops = {
            color: color,
            fontSize: fontSize
        }
        const usera = (ra ? ra : ((kp && kp.ra) ? kp.ra : 1));
        const koh = (kp && kp.kohustuslik);
        let svgelements = [];
        switch (usera) {
            case 2:
                svgelements.push((<path key="1" d="M12 4L18.925 15.998h-13.85L12 4zM12 0L1.61 17.998h20.78L12 0z" />));
                if (koh) {
                    svgelements.push((<path key="2" d="M12 6.23L17 14.89h-10L12 6.23zM12 9.98L10.25 13.01h3.5L12 9.98z" />));
                }
                break;
            case 3:
                if (koh) {
                    svgelements.push((<path key="out" d="M20 2H4c-1.1 0-2 0.9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-2 18h-12c-1.1 0-2-.9-2-2v-12c0 -1.1 0.9 -2 2-2h12c1.1 0 2 0.9 2 2v12c0 1.1  -0.9 2-2 2" />));
                    // Without inner corners
                    //svgelements.push((<path key="out" d="M20 2H4c-1.1 0-2 0.9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm0 18H4V4h16v16z" />));
                    svgelements.push((<path key="in" d="M16 6H8c-1.1 0-2 0.9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10H8V8h8v8z" />));
                    // Little bit bigger inner square
                    //svgelements.push((<path key="in" d="M17 5H7c-1.1 0-2 0.9-2 2v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12H7V7h10v10z" />));
                } else
                    svgelements.push((<path key="1" d="M18 4H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H6V6h12v12z" />));
                break;
            default:
                if (koh) {
                    svgelements.push((<path key="out" d="M12 0c-6.63 0 -12 5.37 -12 12s5.37 12 12 12 12 -5.37 12 -12 -5.37 -12 -12 -12zm0 22c-5.52 0-10-4.48-10-10s4.48-10 10-10 10 4.48 10 10-4.48 10-10 10z" />));
                    svgelements.push((<path key="in" d="M12 4c-4.42 0 -8 3.58 -8 8s3.58 8 8 8 8 -3.58 8 -8 -3.58 -8 -8 -8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />));
                } else
                    svgelements.push((<path key="1" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />));
        }
        return (
            <SvgIcon className={(lat && lng) ? classes.mapicon : ""}
                style={{ cursor: (this.props.onClick ? 'pointer' : undefined) }}
                viewBox="0, 0, 24, 24" {...forwardprops} >
                {svgelements}
            </SvgIcon>
        );
    }
}

export default withStyles(styles, { withTheme: true })(KPIcon);
