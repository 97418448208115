import React from 'react';
import GoogleMap from './GoogleMap';
import { estoniaCoords } from '../utils/SmallUtils';
import KPIcon from './KPIcon';

class MapForSingleLocation extends React.Component {

    constructor(props) {
        super();
        let location = props.kp.loc;
        // This can be removed if lon->lng migration completed.
        if (location && location.lon && !location.lng)
            location.lng = location.lon;

        this.state = {
            mapDraggable: true,
            loc: location,
            defaultCenter: location ? location : estoniaCoords.center,
            defaultZoom: location ? 11 : estoniaCoords.zoom
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.kp.loc !== this.props.kp.loc) {
            let location = this.props.kp.loc;
            // This can be removed if lon->lng migration completed.
            if (location && location.lon && !location.lng)
                location.lng = location.lon;
            this.setState({
                loc: location
            });
        }

    }

    render() {
        const { kp } = this.props;

        return (
            <GoogleMap
                defaultCenter={this.state.defaultCenter}
                defaultZoom={this.state.defaultZoom}
                onClick={(value) => {
                    if (kp.loc === undefined) {
                        this.props.setLoc({ lat: value.lat, lon: value.lng });
                    }
                }}
                onChildMouseDown={() => {
                    this.setState({ mapDraggable: false })
                }}
                onChildMouseUp={(_hoverKey, _childProps, mouseArgs) => {
                    this.props.setLoc({ lat: mouseArgs.lat, lon: mouseArgs.lng });
                    this.setState({ mapDraggable: true })
                }}
                onChildMouseMove={(_hoverKey, _childProps, mouseArgs) => {
                    this.setState({ loc: mouseArgs });
                }}
                draggable={this.state.mapDraggable}
                setoptions={{ fullscreenControl: true, mapTypeControl: true }}
                needclick={!kp.loc}
            >
                {kp.loc &&
                    (<KPIcon kp={kp} latLng={this.state.loc} />)
                }
            </GoogleMap>
        )
    }
};

export default MapForSingleLocation;