import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { addUndo } from '../redux/actionsEventResult';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Divider, Switch, FormControl, FormControlLabel } from '@material-ui/core';
import firebase from 'firebase/app';
import FBTextField from './FBTextField';

const styles = theme => ({
  card: {
    maxWidth: false,
  },
  actions: {
    display: 'flex'
  },
  closebutton: {
    marginLeft: 'auto',
  },
  content: {
  }
});

class EditTeamDialog extends Component {

  state = {
    teamNameEditable: false
  }

  handleDateChange = name => (d) => {
    this.saveValue(name, new Date(d).getTime())();
  }

  saveValue = (name, value, undomsg) => () => {
    const { eventId, team, tid } = this.props;
    let ref = firebase.database().ref('teams').child(eventId).child('list').child(tid).child(name);
    ref.feedbackset(value);
    this.props.addUndo(name + tid, team[name], undomsg || `Team property ${name} for team ${team.name} changed`, v => {
      ref.feedbackset(v);
    });
  }

  handleChange = (name) => ev => {
    console.log(name);
    console.log(ev.target.value);
    this.saveValue(name, ev.target.value)();
  }
  handleClearedDateChange = (date) => {
    this.setState({ clearedDate: date });
  }

  handleNameClick = () => {
    this.setState({ teamNameEditable: true });
  }

  render() {
    const { classes, currentEvent, team, eventId, tid } = this.props;

    const finishiaeg = team.finishms ? new Date(team.finishms) : null;
    const stardiaeg = team.startms ? new Date(team.startms) : null;

    console.log(currentEvent);
    return (
      <Dialog
        open={true}
        maxWidth={false}
        aria-labelledby="open-team-title"
        onClose={this.props.handleClose}
      >
        <Card className={classes.card}>
          <CardHeader
            onClick={this.doTheClick}
            title={this.state.teamNameEditable ?
              <FBTextField
                id="name"
                dbref={firebase.database().ref('teams').child(eventId).child('list').child(tid).child('name')}
                fullWidth
                margin="normal"
              />
              :
              <div onClick={this.handleNameClick}>{team.name}</div>
            }
            subheader={team.displayname}
            avatar={
              team.photourl === "null" ? null :
                <Avatar aria-label="Recipe" className={classes.avatar}
                  src={team.photourl}
                />
            }
          />
          <CardContent className={classes.content}>
            <FBTextField
              dbref={firebase.database().ref('teams').child(eventId).child('list').child(tid).child('vehicle')}
              className={classes.textField}
              id="vehicle"
              label="Liikumisvahend"
              variant="outlined"
            />
            <br />
            <KeyboardDateTimePicker
              clearable
              ampm={false}
              showTodayButton
              label="Start time"
              onError={console.log}
              views={["hours", "minutes", "seconds"]}
              minDate={new Date(currentEvent.startms)}
              value={stardiaeg}
              onChange={this.handleDateChange('startms')}
              format="DD/MM/YYYY HH:mm:ss"
              variant="outlined"
            />
            <KeyboardDateTimePicker
              clearable
              ampm={false}
              showTodayButton
              label="Finish time"
              onError={console.log}
              views={["hours", "minutes", "seconds"]}
              placeholder=""
              minDate={stardiaeg ? stardiaeg : new Date(currentEvent.startms)}
              value={finishiaeg}
              onChange={this.handleDateChange('finishms')}
              format="DD/MM/YYYY HH:mm:ss"
              variant="outlined"
            />
            <Divider />
            {currentEvent.joinrequiresconfirmation && (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={team.allowed}
                      onChange={this.saveValue('allowed', !team.allowed)}
                    />
                  }
                  label="Allowed to join event"
                />
              </FormControl>
            )}
          </CardContent>
          <CardActions className={classes.actions} >
            <IconButton
              aria-label="Close"
              onClick={this.props.handleClose}
              className={classes.closebutton}>
              <Icon>close</Icon>
            </IconButton>
          </CardActions>

        </Card>
      </Dialog >
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
  team: state.teamsList[ownProps.tid],
  teamData: state.teamsData[ownProps.tid],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditTeamDialog));
