import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ReactReduxContext } from 'react-redux'
import classNames from 'classnames';
import AppBar from './components/AppBar';
import AppDrawer from './components/AppDrawer';
import BigMap from './components/BigMap';
import UndoSnackBar from './components/UndoSnackBar';
import PlayControlsWrapper from './components/PlayControlsWrapper';
import DialogFactory from './components/DialogFactory';
import firebase from 'firebase/app';
import { createBrowserHistory } from 'history'
import * as actions from './redux/actionsEvents';
import EventDataListener from './utils/EventDataListener';
import 'firebase/storage';
import 'firebase/database';
var zlib = require('zlib');

const styles = theme => ({
  appFrame: {
    position: 'absolute',
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 64px)',
    marginTop: 64,
    [theme.breakpoints.up('sm')]: {
      content: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
    marginLeft: -theme.appDrawer.width,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class App extends React.Component {
  static contextType = ReactReduxContext;
  state = {
    drawerOpen: false,
  };

  componentDidMount() {
    const dispatch = this.context.store.dispatch;
    let db = firebase.database();
    db.ref('conf').child('colors').on('value', snap => {
      dispatch(actions.setColorList(snap.val()));
    });

    if (this.props.match.params.evname !== undefined) {
      this.onOpenEvent(this.props.match.params.evname);
    } else if (this.props.match.params.id !== undefined)
      this.onOpenEvent(this.props.match.params.id);
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  onOpenEvent = async (eid) => {
    const history = createBrowserHistory()
    history.replace(eid);

    let db = firebase.database();
    let firestore = firebase.firestore();

    let evlistdata = await db.ref('events/').child(eid).once('value');
    if (!evlistdata.exists()) {
      let secondaryid = await db.ref('secondarylink').child(eid).once('value');
      if (secondaryid.exists()) {
        eid = secondaryid.val();
      }
    }

    const dispatch = this.context.store.dispatch;
    dispatch(actions.clearLocationData());
    dispatch(actions.eventAccess(null));
    dispatch(actions.setEventId(eid));



    if (this.eventunsub)
      this.eventunsub();
    this.eventunsub = firebase.firestore().collection('events').doc(eid).onSnapshot(snap => {
      if (snap.exists)
        dispatch(actions.setEventDoc(snap.data()))
    });

    if (this.eventaccessref)
      this.eventaccessref.off();

    this.eventaccessref = db.ref('events').child(eid).child('admins');
    this.eventaccessref.on('value', snap => {

      let acc = (firebase.auth().currentUser && snap.exists() ? snap.val()[firebase.auth().currentUser.uid] : null);
      if (acc === undefined) {
        acc = null;
      }
      dispatch(actions.eventAccess(acc));
    });

    if (false) {
      const storetracks = firestore.collection('events').doc(eid).collection('tracks');

      storetracks.get().then(snapshot => {
        snapshot.forEach(doc => {
          const devref = storetracks.doc(doc.id).collection('devices');
          devref.get().then(devsnap => {
            devsnap.forEach(devdoc => {
              let processkey = null;
              Object.entries(devdoc.data()).forEach(([k, v], idx) => {
                processkey = k;
                if (processkey) {
                  firebase.storage().ref('tracks/' + eid + '/' + doc.id + '/' + devdoc.id + '/' + processkey + '.data').getDownloadURL().then((url) => {
                    fetch(url).then(response => response.arrayBuffer())
                      .then(data => {
                        var buf = new Buffer(data.byteLength);
                        var view = new Uint8Array(data);
                        for (let i = 0; i < buf.length; i++) {
                          buf[i] = view[i];
                        }
                        let decdata = JSON.parse(zlib.inflateSync(buf).toString());
                        dispatch(actions.addLocationData(doc.id, devdoc.id, decdata.points));
                      }).catch((e) => {
                        console.log('failed to fetch data', e);
                        debugger;
                      });
                  });
                }
                processkey = k;
              });
              /* Reading lot of data from firestore is not really working. Pollutes memory.
              devref.doc(devdoc.id).collection('points').doc(processkey).get().then(lastpoints => {
                dispatch(actions.addLocationData(doc.id, devdoc.id, lastpoints.data().points));
              });
              */
              //this.pdoc = devref.doc(devdoc.id).collection('points').get();
              /*             const pointsref = devref.doc(devdoc.id).collection('points');
                          pointsref.get().then(pointssnap => {
                            pointssnap.forEach(pointsdoc => {
                              //dispatch(actions.addLocationData(doc.id, devdoc.id, pointsdoc.data().points));
                            })
                          }) */
            })
          })
        })
      })
    }

    /*
    let teamTracksAddedOrChanged = snap => {
      Object.entries(snap.val()).forEach(([k, v]) => {
        dispatch(actions.addLocationData(snap.key, k, v));
      });
    }
    this.trackref = db.ref('teams').child(eid).child('track');
    this.trackref.on('child_added', teamTracksAddedOrChanged);
    this.trackref.on('child_changed', teamTracksAddedOrChanged);
    this.trackref.on('child_removed', snap => {
      dispatch(actions.clearTeamTracks(snap.key));
    });*/
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.appFrame}>
        <AppBar
          drawerOpen={this.state.drawerOpen}
          handleDrawerOpen={this.handleDrawerOpen}
        />
        <AppDrawer
          drawerOpen={this.state.drawerOpen}
          onOpenEvent={this.onOpenEvent}
          handleDrawerClose={this.handleDrawerClose}
        />
        <DialogFactory />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: false,
          })}
        >
          <BigMap />
          <UndoSnackBar />
          <PlayControlsWrapper />
          <EventDataListener />
        </main>
      </div>
    );
  }
}


App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);
