import React, { Component, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { addUndo } from '../redux/actionsEventResult';
import Switch from '@material-ui/core/Switch';
import { DialogContent, ListItemSecondaryAction, FormControlLabel, DialogTitle, Card, IconButton, Icon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import KPDialog from './KPDialog';
import './EventResult.css';
import firebase from 'firebase/app';
import moment from 'moment';
import { TimePicker } from '@material-ui/pickers';
import TeamContextMenu from './TeamContextMenu';



const styles = theme => ({
  root: {
    maxWidth: false,
  },
  horizontalList: {
    flexDirection: 'row',
    padding: 0,
    display: 'inline-flex',
    backgroundColor: theme.palette.background.paper
  },
  kplist: {
    columnWidth: "200px"
  },
  kpok: {
    backgroundColor: green[500]
  },
  kpbad: {
    backgroundColor: red[500]
  },
  kptyhistatud: {
    backgroundColor: blue[500]
  },
  kpnr: {
    padding: "7px",
    fontSize: "xx-large",
  },
  kp: {
    fontWeight: "bold",
    textAlign: "center",
  },
  listitem: {
    displayer: "20px"
  },
  '@global': {
    '#kplist > li:nth-child(odd)': {
      backgroundColor: '#FAFAFA'
    },
    '#kplist > li:nth-child(even)': {
      backgroundColor: '#F5F5F5'
    },
  },
  nobr: {
    whiteSpace: 'nowrap'
  },
});

/*
              <GridListTile>
                <Paper>
                  <Typography variant="h5" component="h3">
                    {kpList[kpid].jnr}
                  </Typography>
                </Paper>
              </GridListTile>
              */

const TimeDialog = (myprops) => {

  let key = myprops.finishtimedialog ? "finishtime" : "starttime";
  let setkey = "set" + key;

  const [tv, setTv] = useState(moment(this.state[setkey]));


  let onClose = () => {
    this.setState({ [setkey]: null });
  }

  let teamref = firebase.database().ref(`/teams/${this.props.eventId}/list/${this.props.teamid}/`).child(key);
  let onSet = () => {
    teamref.set(tv.valueOf())
    onClose();
  }

  let onClear = () => {
    teamref.remove();
    onClose();
  }

  return (
    <Dialog open={true} >
      <DialogTitle>{myprops.finishtimedialog ? "Finishi aeg" : "Stardi aeg"}</DialogTitle>
      <DialogContent style={{ padding: '0px 0px 0px 0px' }}>
        <TimePicker
          variant="static"
          clearable
          placeholder=""
          openTo="minutes"
          ampm={false}
          views={["hours", "minutes", "seconds"]}
          format={"HH:mm:ss"}
          onChange={(v, o, k) => { setTv(v); console.log(v, o, k) }}
          value={tv}
        />
      </DialogContent>
      <DialogActions>
        {this.props.team[key] &&
          (<Button color="primary" onClick={onClear}>clear</Button>)}
        <div style={{ flex: '1 0 0' }} />
        <Button color="primary" onClick={onClose}>cancel</Button>
        <Button color="primary" onClick={onSet}>set</Button>
      </DialogActions>
    </Dialog>
  );
}

class RunningTime extends Component {
  constructor(props) {
    super(props);
    this.state = { runtime: Date.now() - props.starttime }
  }
  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ runtime: Date.now() - this.props.starttime })
    }, 1000);

  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (<ListItemText primary="Rajal olnud" classes={{ primary: this.props.classes.nobr }} secondary={moment.utc(this.state.runtime).format('HH:mm:ss')} />);
  }
}

/* Sample implemtation abouf RunningTime as a function component. Need to include useEffect from react to use this.
function RunningTimeFuncComponent(props) {

  const [runtime, setRuntime] = useState(Date.now() - props.starttime);

  useEffect(() => {
    let timer = setTimeout(() => {
      timer = undefined
      setRuntime(Date.now() - props.starttime);
    }, 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  });
  return (<ListItemText primary="Rajal olnud" classes={{ primary: props.classes.nobr }} secondary={moment.utc(runtime).format('HH:mm:ss')} />)
}
*/


class ValikTeamResultDialog extends Component {
  state = {
    kpdialogOpen: false,
    easyUpdate: false,
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  }

  handleKPClick = kpid => event => {
    if (!this.state.easyUpdate)
      return;
    const { teamid, eventId, kpAnswers, answerResult } = this.props;

    const c = answerResult[kpid];

    let db = firebase.database();
    let kpanswerref = db.ref(`/teams/${eventId}/kpanswers/${teamid}/${kpid}`)
    let aresultref = db.ref(`/teams/${eventId}/answerresult/${teamid}/${kpid}/ok`)
    if (c === undefined || !c.ok) {
      const answerob = {
        korraldaja: true
      };
      if (kpAnswers[kpid])
        answerob.answer = kpAnswers[kpid];
      kpanswerref.feedbackupdate(answerob);
      aresultref.set(true);
    } else {
      kpanswerref.feedbackset(null);
      aresultref.remove();
    }
    return;
  }

  render() {
    const { classes, handleClose, kpList, kpData, eventAccess, labitudkpd, kpAnswers, answerResult, team, teamid } = this.props;
    let orderedkpkeys = Object.keys(kpList).sort((l, r) => kpList[l].nr - kpList[r].nr);
    return (
      <Dialog
        open={true}
        maxWidth={'xl'}
        fullWidth={true}
      >
        <DialogTitle>
          <TeamContextMenu teamid={teamid}>{team.name} </TeamContextMenu>
        </DialogTitle>
        <DialogContent>
          <Card>
            <List className={classes.horizontalList}>

              <ListItem>
                <ListItemText primary="Stardiaeg" secondary={team.starttime ? moment(team.starttime).format('HH:mm:ss') : 'startimata'} />
                {eventAccess && (
                  <ListItemSecondaryAction style={{ right: '0px' }}>
                    <IconButton onClick={() => { this.setState({ setstarttime: (team.starttime ? team.starttime : moment().seconds(0).milliseconds(0)) }) }}><Icon>edit</Icon></IconButton>
                  </ListItemSecondaryAction>)}
              </ListItem>

              {team.starttime && !team.finishtime && (
                <ListItem>
                  <RunningTime classes={classes} starttime={team.starttime} />
                </ListItem>
              )}
              {team.finishtime && (
                <ListItem>
                  <ListItemText primary="Finishiaeg" secondary={moment(team.finishtime).format('HH:mm:ss')} />
                  {eventAccess && (
                    <ListItemSecondaryAction style={{ right: '0px' }}>
                      <IconButton onClick={() => { this.setState({ setfinishtime: team.finishtime }) }}><Icon>edit</Icon></IconButton>
                    </ListItemSecondaryAction>)}
                </ListItem>
              )}
              {team.starttime && team.finishtime && (
                <ListItem>
                  <ListItemText primary="Aeg" secondary={moment.utc(team.finishtime - team.starttime).format('HH:mm:ss')} />
                </ListItem>
              )}
              {(eventAccess && !team.finishtime) && (
                <ListItem>
                  <Button variant="contained" onClick={() => { this.setState({ setfinishtime: Date.now() }) }}>Finisheeri!</Button>
                </ListItem>)}
            </List>
          </Card>
          {eventAccess && (<FormControlLabel
            control={<Switch
              checked={this.state.easyUpdate}
              onChange={this.handleChange('easyUpdate')}
              value="easyUpdate"
            />}
            label="Easy KP changing"
          />)}
          <List id="kplist" className={classes.kplist}>
            {orderedkpkeys.filter(kpid => (kpList[kpid].nr !== 'F' && !kpList[kpid].deleted)).map(kpid => {
              let l = labitudkpd[kpid];
              const ok = answerResult[kpid] && (kpList[kpid].allok || answerResult[kpid].ok);
              if (!l)
                l = { answer: "", aeg: null };
              let aeg = (!l.aeg ? "--:--:--" : moment(l.aeg).format('HH:mm:ss'));
              let kpclass = classes.kpbad;
              if (kpList[kpid].tyhistatud)
                kpclass = classes.kptyhistatud;
              else if (ok)
                kpclass = classes.kpok;
              else if (ok === undefined || l.answer === "-")
                kpclass = classes.kpunvisited;
              let kp = kpData[kpid]
              if (kp && kp.responses) {
                return (
                  <ListItem button={true} onClick={this.handleKPClick(kpid)} key={kpid} dense={false} disableGutters={false} divider={true}>
                    <Avatar className={kpclass + " " + classes.kpnr} >{kpList[kpid].nr}</Avatar>
                    <ListItemText primary={kp.responses[l.answer]} primaryTypographyProps={{ variant: "h4", color: "textPrimary" }} secondary={aeg} />
                    <ListItemSecondaryAction>
                    </ListItemSecondaryAction>
                  </ListItem>);
              }
              return (
                <ListItem classes={{ container: 'classes-onekp-container-vv' }} button={true} onClick={this.handleKPClick(kpid)} className={classes.listitem} key={kpid} dense={true} disableGutters={false} divider={true}>
                  <Avatar className={kpclass + " " + classes.kpnr} >{kpList[kpid].nr}</Avatar>
                  <ListItemText primary={l.answer} primaryTypographyProps={{ className: classes.kp, variant: "h3", color: "textPrimary" }} secondary={aeg} />
                  <ListItemSecondaryAction>
                    <Typography className={classes.kp} variant="h4" color="textSecondary">{kpAnswers[kpid]}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>);
            })}
          </List>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        {this.state.kpdialogOpen && (<KPDialog />)}
        {this.state.setfinishtime && (<TimeDialog finishtimedialog />)}
        {this.state.setstarttime && (<TimeDialog starttimedialog />)}



      </Dialog>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  eventId: state.eventId,
  eventResult: state.eventresult,
  labitudkpd: state.teamAnswers[ownProps.teamid] || {},
  answerResult: state.answerResult[ownProps.teamid] || {},
  kpList: state.kpList,
  kpData: state.kpData,
  kpAnswers: state.kpAnswers,
  team: state.teamsList[ownProps.teamid],
  eventAccess: state.eventAccess,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ValikTeamResultDialog));
