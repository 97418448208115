import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ValikEventResult from './ValikEventResult';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
});

class EventResult extends Component {

  constructor(props, context) {

    super(props, context)
    this.state = {
      editTeamOpen: false
    }
  }

  onTeamClick(event, teamdata) {
    this.setState({ editTeamOpen: true, editTeam: teamdata });
    console.log('teamclick');
  }

  render() {
    const { eventId, klassid } = this.props;
    if (!eventId || !klassid)
      return null;


    return (
      <div>
        {Object.entries(klassid).map(([tahis, name]) => (
          <div key={tahis}>
            {Object.keys(klassid).length > 1 && (<h1>{name}</h1>)}
            <ValikEventResult klass={tahis} />
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    eventId: state.eventId,
    klassid: state.currentEvent.klassid,
  }
};
export default connect(mapStateToProps)(withStyles(styles)(EventResult));
