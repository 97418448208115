import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { updateSpeedAreaDoc } from '../redux/actionsEvents';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import firebase from 'firebase/app';
import { CircularProgress } from '@material-ui/core';
import AreaOnMap from './AreaOnMap';


const styles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		flex: 1
	},
});


class AllSpeedAreasList extends Component {


	componentDidMount() {
		this.speedareasunsub = firebase.firestore().collection('speedareas').onSnapshot(snap => {
			snap.forEach(docsnap => {
				this.props.updateSpeedAreaDoc(docsnap.id, docsnap.data());
			})
		});
	}

	componentWillUnmount() {
		this.speedareasunsub();
	}

	render() {
		const { classes, speedAreas, eventSpeedAreas, currentAreaId, showAllAreas } = this.props;

		return (
			<List className={classes.root}
				subheader={<ListSubheader inset={true} color="primary" component="div">All areas</ListSubheader>}
			>
				{Object.keys(speedAreas).length === 0 ? (<Box width={1} textAlign="center"><CircularProgress className={classes.progress} color="inherit" /></Box>) :
					speedAreas.sortedlist.map(s => {
						let key = s.owner + ':' + s.id;
						let isselected = currentAreaId && currentAreaId.owner === s.owner && currentAreaId.id === s.id;
						if (key in eventSpeedAreas)
							return null;
						return (
							<ListItem
								key={key}
								button
								selected={isselected}
								onClick={() => this.props.handleSelectArea(s)}
							>
								{(isselected || showAllAreas) && (<AreaOnMap akey={s} map={window.bigmap.map.map.map_} selected={isselected} />)}
								<ListItemText primary={speedAreas[s.owner][s.id].name} />
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => this.props.addAreaToEvent(s, speedAreas[s.owner][s.id])}
										aria-label="Add">
										<Icon aria-label="Add">add_circle</Icon>
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						)
					})}
			</List>

		);
	}
}

const mapStateToProps = state => ({
	speedAreas: state.speedAreas
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateSpeedAreaDoc: (key, doc) => dispatch(updateSpeedAreaDoc(key, doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AllSpeedAreasList));
