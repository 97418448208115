import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import NutiLogiAppInWrapper from './App';
import Results from './components/Results';
import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './muitheme';
import store from './redux/store';
import { authStateChanged } from './redux/actionsAuth';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/dafe-fns';
import MomentUtils from '@date-io/moment';
import { addSaveInProgress, rmSaveInProgress, showAlert, addUndo } from './redux/actionsEventResult';
import { eventAccess, setEventId } from './redux/actionsEvents';
import { SnackbarProvider } from 'notistack'
import "firebase/performance";
import reportWebVitals from './reportWebVitals';


var config = {
  apiKey: "AIzaSyDIGG0DLBM_HOzM9gubl9ckeYZT05slo58",
  authDomain: "nutilogi.firebaseapp.com",
  databaseURL: "https://nutilogi.firebaseio.com",
  projectId: "nutilogi",
  storageBucket: "nutilogi.appspot.com",
  messagingSenderId: "891410042817",
  appId: "1:891410042817:web:43553af7c8de15d15b2940",
  measurementId: "G-QT3RZP47K9"
};
firebase.initializeApp(config);
const perf = firebase.performance();
perf.instrumentationEnabled = false;
perf.dataCollectionEnabled = false;

global.fbOffsetRef = 0;
firebase.database().ref(".info/serverTimeOffset").on('value', (s) => {
  global.fbOffsetRef = s.val();
})

firebase.auth().onAuthStateChanged(function (user) {
  store.dispatch(authStateChanged(user));

  let eid = store.getState().eventId;
  if (eid) {
    if (!user) {
      store.dispatch(eventAccess(null));
    } else {
      firebase.database().ref('events').child(eid).child('admins').child(user.uid).once('value', snap => {
        store.dispatch(eventAccess((snap.exists ? snap.val() : null)));
      });
    }
  }
});

firebase.database.Reference.prototype.setWithUndo = function (value, undomsg) {
  this.once('value', (oldvalue) => {
    this.feedbackset(value);
    store.dispatch(addUndo(this.key + (new Date().toString()), oldvalue.val(), undomsg, (undovalue) => {
      this.feedbackset(undovalue);
    }));
  });
}

firebase.database.Reference.prototype.feedbackset = function (v) {
  store.dispatch(addSaveInProgress());
  return this.set(v, (err) => {
    store.dispatch(rmSaveInProgress());
    if (err) {
      if (err.code === "PERMISSION_DENIED")
        store.dispatch(showAlert({ msg: 'Sul ei ole õigusi antud muudatuse tegemiseks', title: 'Pole õigusi' }));
      else
        store.dispatch(showAlert({ msg: 'Andmete salvestamisel tekkis viga: ' + err.message, error: err, stack: err.stack, title: 'Viga' }));
    }
  });
}

firebase.database.Reference.prototype.feedbackupdate = function (v) {
  store.dispatch(addSaveInProgress());
  return this.update(v, (err) => {
    store.dispatch(rmSaveInProgress());
    if (err) {
      if (err.code === "PERMISSION_DENIED")
        store.dispatch(showAlert({ msg: 'Sul ei ole õigusi antud muudatuse tegemiseks', title: 'Pole õigusi' }));
      else
        store.dispatch(showAlert({ msg: 'Andmete salvestamisel tekkis viga: ' + err.message, error: err, stack: err.stack, title: 'Viga' }));
    }
  });
}

const later = (delay, value) => {
  let timer = 0;
  let reject = null;
  const promise = new Promise((resolve, _reject) => {
    reject = _reject;
    timer = setTimeout(resolve, delay, value);
  });
  return {
    get promise() { return promise; },
    cancel() {
      if (timer) {
        clearTimeout(timer);
        timer = 0;
        reject();
        reject = null;
      }
    }
  };
};

let laterpromises = {};
firebase.firestore.DocumentReference.prototype.groupfeedbackupdate = function (key, data) {
  store.dispatch(addSaveInProgress());
  if (key in laterpromises) {
    laterpromises[key].cancel();
  }
  let self = this;
  laterpromises[key] = later(1000);
  return laterpromises[key].promise.then(() => {
    laterpromises[key] = undefined;
    delete laterpromises[key];

    return self.feedbackupdate(data).then(() => {
      store.dispatch(rmSaveInProgress());

      return laterpromises[key] === undefined;
    });
  }).catch(() => {
    store.dispatch(rmSaveInProgress());
    return false;
  });
}

firebase.firestore.DocumentReference.prototype.feedbackupdate = function (data) {
  store.dispatch(addSaveInProgress());
  return this.update(data).then(() => {
    store.dispatch(rmSaveInProgress());
  })
    .catch(err => {
      if (err) {
        if (err.code === "permission-denied")
          store.dispatch(showAlert({ msg: 'Sul ei ole õigusi antud muudatuse tegemiseks', title: 'Pole õigusi' }));
        else
          store.dispatch(showAlert({ msg: 'Andmete salvestamisel tekkis viga: ' + err.message, error: err, stack: err.stack, title: 'Viga' }));
      }
    });
}

// Hack... Will remove one error
class NutiLogiApp extends React.Component { render() { return <NutiLogiAppInWrapper {...this.props} /> } };

function RouteResult(props) {
  const db = firebase.database();
  let eid = props.match.params.evname;
  db.ref('events/').child(eid).once('value').then((snap) => {
    if (snap.exists()) {
      store.dispatch(setEventId(eid));
    } else {
      db.ref('secondarylink').child(eid).once('value').then((snap) => {
        if (snap.exists()) {
          store.dispatch(setEventId(snap.val()));
        }
      })
    }
  });
  return <Results />
}

function App() {
  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} maxSnack={4}>
            <MuiThemeProvider theme={theme}>
              <Switch>
                <Route exact path="/" component={NutiLogiApp} />
                <Route path="/ev/:id" component={NutiLogiApp} />
                <Route path="/ao/:evname" component={RouteResult} />
                <Route path="/ao" component={Results} />
                <Route path="/results" component={Results} />
                <Route path="/:evname/result" component={RouteResult} />
                <Route path="/:evname/start" component={NutiLogiApp} />
                <Route path="/:evname" component={NutiLogiApp} />
              </Switch>
            </MuiThemeProvider>
          </SnackbarProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  );
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


// Needs setting up:
// https://developers.google.com/analytics/devguides/collection/analyticsjs
/*
function sendToAnalytics({ id, name, value }) {
console.log('hmm');
ga('send', 'event', {
eventCategory: 'Web Vitals',
eventAction: name,
eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
eventLabel: id, // id unique to current page load
nonInteraction: true, // avoids affecting bounce rate
});
}
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(sendToAnalytics);
reportWebVitals(console.log);
