import React from 'react';
import { connect } from 'react-redux';
import { Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Draggable from 'react-draggable';
import { showSpeedingTicket } from '../redux/actionsEvents';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: { pointerEvents: "none", },
  paper: { pointerEvents: "auto" },
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

class SpeedTrackConfDialog extends React.Component {

  state = {

  }

  handleClose = () => {
    this.props.closeDialog();
  }

  render() {
    const { classes } = this.props;
    console.log('conf render', this.state.secondrow);
    return (
      <Dialog hideBackdrop open={true} PaperComponent={PaperComponent} aria-labelledby="Speed track conf" classes={this.props.classes}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Kiiruse kaart
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel id="second-row-type-label">Teine rida</InputLabel>
            <Select
              labelId="second-row-type-label"
              id="second-row-type"
              value={this.props.secondrow}
              onChange={this.props.handleChange("secondrow")}
            >
              <MenuItem value={"speedareas"}>Kiiruse alad</MenuItem>
              <MenuItem value={"accuracy"}>GPS täpsus</MenuItem>
              <MenuItem value={"missinggps"}>Puuduvad punktid</MenuItem>
              <MenuItem value={"timestamp"}>Aja tempel</MenuItem>
              <MenuItem value={"loc"}>Koordinaadid</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeDialog: () => dispatch(showSpeedingTicket(null)),
});

/*
const StyledDialog = withStyles({ root: { pointerEvents: "none", }, paper: { pointerEvents: "auto" } })(props => <SpeedTrackConfDialog open={true} hideBackdrop {...props} />);
export default StyledDialog;
*/
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeedTrackConfDialog));