import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import firebase from 'firebase/app';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';


const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

class BasicEventDataEditor extends Component {

  state = {
    secondaryeditval: ""
  }

  componentDidMount = () => {
	if (this.props.eveentId) {
      firebase.database().ref('secondarylink').orderByValue().equalTo(this.props.eventId).on('value', (snap) => {
        let val = snap.val();
        let editval = "";
        if (val)
          editval = Object.keys(val)[0];
        this.setState({ secondarylink: snap.val(), secondaryeditval: editval })
      });
	}
  }

  componentWillUnmount = () => {
    firebase.database().ref('secondarylink').off();
  }
  handleChange = name => event => {
    let value = event;
    if (name === "secondarylink") {
      let editval = event.target.value;
      this.setState({ secondaryeditval: editval });
      if (this.secondaryLinkTimeout)
        clearTimeout(this.secondaryLinkTimeout);
      this.secondaryLinkTimeout = setTimeout(() => {
        this.secondaryLinkTimeout = null;
        console.log('should actually save');
        const sref = firebase.database().ref('secondarylink');
        if (this.state.secondarylink) {
          Object.keys(this.state.secondarylink).forEach((k) => {
            sref.child(k).remove();
          })
        }
        sref.child(editval).set(this.props.eventId);
      }, 2000);
      return;
    }
    if (name === 'starttime' || name === 'endtime') {
      if (!value.isValid())
        return;
      value.set({ second: 0, millisecond: 0 })
      value = value.valueOf(); // moment
      //value = value.getTime(); // date-fns
    } else {
      value = event.target.value;
    }
    if (name === 'starttime') {
      if (this.props.endtime < value)
        this.props.fieldChanged('endtime', value + 60000);
    }
    if (name === 'privateevent') {
      name = 'private';
      value = event.target.checked;
    }
    if (name === 'hideresults') {
      value = !event.target.checked;
    }
    if (name === 'hidetracks') {
      value = !event.target.checked;
    }
    this.props.fieldChanged(name, value);
  }

  render() {
    const { classes, name, starttime, endtime, newevent, type, hideresults, hidetracks, privateevent, shortlink, shortlinkhelper, eventId } = this.props;

    var typevalue = type;
    if (type === undefined)
      typevalue = "valik";

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <TextField
          required
          value={name}
          onChange={this.handleChange('name')}
          id="eventName"
          variant="outlined"
          label="Event Name" />
        {newevent && (<Select
          value={typevalue}
          onChange={this.handleChange('type')}
          inputProps={{
            name: 'type',
            id: 'type-simple',
          }}>
          <MenuItem value="valik">Valik</MenuItem>
          <MenuItem value="suund">Suundorienteerumine</MenuItem>
          <MenuItem value="track">Tracking only</MenuItem>
          <MenuItem value="paevak">Päevak</MenuItem>
        </Select>)}
        <TextField
          required
          error={shortlinkhelper}
          helperText={shortlinkhelper}
          value={eventId ? "https://nutilogi.web.app/" + eventId : shortlink}
          disabled={Boolean(eventId)}
          onChange={this.handleChange('shortlink')}
          id="eventid"
          variant="outlined"
          label={eventId ? "Link to event" : "Short link"} />
		{this.props.eventId && (
        <FormControl className={classes.margin} variant="outlined">
          <InputLabel>Lisa link</InputLabel>
          <OutlinedInput
            id="secondarylink"
            value={this.state.secondaryeditval}
            onChange={this.handleChange('secondarylink')}
            startAdornment={<InputAdornment position="start">https://nutilogi.web.app/</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>)}
        <br />
        <KeyboardDateTimePicker
          ampm={false}
          showTodayButton
          label="Event Start time"
          onError={console.log}
          value={starttime}
          onChange={this.handleChange('starttime')}
          format="DD/MM/YYYY HH:mm"
          inputVariant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <KeyboardDateTimePicker
          ampm={false}
          showTodayButton
          label="Event End time"
          onError={console.log}
          value={endtime}
          onChange={this.handleChange('endtime')}
          format="DD/MM/YYYY HH:mm"
          inputVariant="outlined"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={privateevent}
                onChange={this.handleChange('privateevent')}
              />
            }
            label="Private event"
          />
        </FormControl>
        {(typevalue !== 'paevak' && typevalue !== 'track') && (<FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={!hideresults}
                onChange={this.handleChange('hideresults')}
              />
            }
            label="Avalikud tulemused"
          />
        </FormControl>)}
      {(typevalue !== 'paevak' && typevalue !== 'track') && (<FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={!hidetracks}
                onChange={this.handleChange('hidetracks')}
              />
            }
            label="Näita GPS radu"
          />
        </FormControl>)}
      </form >
    )
  }
}


export default withStyles(styles, { withTheme: true })(BasicEventDataEditor);
