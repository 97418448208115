import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EventResult from './EventResult';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import NoSleep from 'nosleep.js';


const styles = theme => ({
  root: {
    width: '100%',
    background: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    background: 'inherit',
  },
  listSubHeader: {
    fontSize: 24,
    fontWeight: 700,
  }
});

class EventResultDialog extends Component {

  state = {
    anchorEl: null,
    fullScreen: false,
    hideSettingsAndClose: false,
    showQR: false
  }

  componentDidMount() {
    this.noSleep = new NoSleep();
    this.noSleep.enable();
  }

  componentWillUnmount() {
    this.noSleep.disable();
  }

  openSettingsMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  }
  render() {
    const { classes, eventid, eventname } = this.props;

    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={true}
        maxWidth={false}
        onClose={() => this.props.handleClose()}
        aria-labelledby="event-result-title"
      >
        <DialogTitle id="event-result-title">{eventname}
          {!this.state.hideSettingsAndClose &&
            (<IconButton
              aria-label="More"
              aria-owns={this.state.anchorEl === null ? 'long-menu' : null}
              aria-haspopup="true"
              onClick={this.openSettingsMenu}
            >
              <MoreVertIcon />
            </IconButton>)}
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.anchorEl !== null}
            onClose={this.closeSettingsMenu}
            PaperProps={{
              style: {
                width: 200,
              },
            }}
          >
            <MenuItem onClick={() => this.setState({ fullScreen: !this.state.fullScreen, anchorEl: null })}>
              {this.state.fullScreen &&
                (<ListItemIcon className={classes.icon}>
                  <CheckIcon />
                </ListItemIcon>)}
			  Full Screen
            </MenuItem>
            <MenuItem onClick={() => this.setState({ showQR: !this.state.showQR, anchorEl: null })}>
              {this.state.showQR &&
                (<ListItemIcon className={classes.icon}>
                  <CheckIcon />
                </ListItemIcon>)}
		      Show QR to result
            </MenuItem>
            <MenuItem onClick={() => this.setState({ hideSettingsAndClose: true, anchorEl: null })}>
              {this.state.timePickerWithSeconds &&
                (<ListItemIcon className={classes.icon}>
                  <CheckIcon />
                </ListItemIcon>)}
				Hide settings and close
            </MenuItem>

          </Menu>
        </DialogTitle>
        <DialogContent>
          <EventResult />
        </DialogContent>
        <DialogActions>
          {!this.state.hideSettingsAndClose &&
            (<Button onClick={() => this.props.handleClose()} color="primary">
              Close
            </Button>)}
        </DialogActions>
        {this.state.showQR &&
          (<div style={{ position: 'fixed', bottom: '30px', left: '30px', zIndex: '99' }}>
            <QRCode value={"http://nutilogi.web.app/" + eventid + "/result"} />
          </div>)}
      </Dialog>
    );
  }
}

EventResultDialog.propTypes = {
  eventid: PropTypes.string.isRequired,
}

export default connect()(withStyles(styles)(EventResultDialog));
