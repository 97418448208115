import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { closeDialog } from '../redux/actionsEvents';
import { FormControl, FormLabel, DialogContent, RadioGroup, Radio, FormControlLabel, InputLabel, Select, MenuItem, Grid, TextField } from '@material-ui/core';
import firebase from 'firebase/app';
import { showAlert } from '../redux/actionsEventResult';


const styles = theme => ({
    card: {
        maxWidth: false,
    },
});

class SendMsgDialog extends Component {


    constructor(props) {
        console.log('constr', props);
        super(props);

        this.state = {
            target: (props.targetTeamId ? "team" : "everyone"),
            type: "message",
            targetTeam: props.targetTeamId,
            command: "sendLog",
            title: "",
            msg: ""
        }
    }

    targetChange = (event) => {
        this.setState({ target: event.target.value });
    }

    typeChange = (event) => {
        this.setState({ type: event.target.value });
    }

    targetTeamChange = (event) => {
        this.setState({ targetTeam: event.target.value });
    }

    commandChange = (event) => {
        this.setState({ command: event.target.value });
    }

    sendMessage = () => {
        console.log(this.props);
        console.log(firebase.auth.currentUser);

        let queryparams = []
        if (this.state.target === "team") {
            const devs = this.props.teamsData[this.state.targetTeam].devs;
            const tokens = Object.values(devs).map(d => d.fcmtoken).filter(fcmtoken => fcmtoken);
            queryparams.push("token=" + tokens.join(","));
        } else {
            queryparams.push("topic=event-" + this.props.eventId.replace(RegExp('[^a-zA-Z0-9-_.~%]'), ""));
        }
        if (this.state.type === "command") {
            queryparams.push("cmnd=" + this.state.command);
        } else {
            if (this.state.title)
                queryparams.push("title=" + this.state.title)
            if (this.state.msg)
                queryparams.push("body=" + this.state.msg)
        }

        console.log('query params: ', queryparams.join("&"));
        this.props.authUser.getIdToken().then(token => {
            fetch('https://europe-west1-nutilogi.cloudfunctions.net/api/sendmsg?' + queryparams.join("&"), {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'X-EventId': this.props.eventId
                }
            }).then(data => {
                console.log('fetch complete');
                return data.json();
            }).then(data => {
                if (data.successCount === 0 && data.failureCount > 0) {
                    let msgtext = ""
                    data.results.forEach(res => {
                        if (!res.error)
                            return;
                        msgtext += res.error.message;
                    });
                    this.props.showAlert('Failed to send message', msgtext);
                } else {
                    console.log('send message: ', data);
                    // TODO close message dialog ( Should we show some message that it was successfull)
                }
                console.log(data);
            }).catch(e => {
                console.log('got error on fetch');
            })
        });
        this.props.closeDialog();
    }

    render() {
        const { classes, closeDialog, teamsList } = this.props;
        return (
            <Dialog
                open={true}
                maxWidth={"lg"}
                fullWidth={true}
            >
                <DialogContent>
                    <form noValidate autoComplete="off">
                        <Grid container direction="column" spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <FormLabel component="legend">Target</FormLabel>
                                    <RadioGroup aria-label="target" name="target" value={this.state.target} onChange={this.targetChange}>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item sm={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item sm={6}>
                                                        <FormControlLabel value="team" control={<Radio />} label="Team" />
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <FormControl className={classes.formControl} disabled={this.state.target !== "team"}>
                                                            <InputLabel id="targetTeamLabel">Team:</InputLabel>
                                                            <Select
                                                                labelId="targetTeamLabel"
                                                                id="targetTeam"
                                                                value={this.state.targetTeam}
                                                                onChange={this.targetTeamChange}
                                                            >
                                                                {Object.entries(teamsList).map(([tid, team]) => (
                                                                    <MenuItem key={tid} value={tid}>{team.name}</MenuItem>
                                                                ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <FormControlLabel value="everyone" control={<Radio />} label="Everyone" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormLabel component="legend">Message type</FormLabel>
                                    <RadioGroup aria-label="type" name="type" value={this.state.type} onChange={this.typeChange}>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item sm={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item sm={6}>
                                                        <FormControlLabel value="command" control={<Radio />} label="Command" />
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <FormControl className={classes.formControl} disabled={this.state.type !== "command"}>
                                                            <InputLabel id="commandLabel">Command:</InputLabel>
                                                            <Select
                                                                labelId="commandLabel"
                                                                id="command"
                                                                value={this.state.command}
                                                                onChange={this.commandChange}
                                                            >
                                                                <MenuItem value={"sendLog"}>Saada Logi</MenuItem>
                                                                <MenuItem value={"sendGPX"}>Saada GPX</MenuItem>
                                                                <MenuItem value={"sendDB"}>Saada andmebaas</MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <FormControlLabel value="message" control={<Radio />} label="Message" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>


                            <Grid>
                                <TextField
                                    disabled={this.state.type !== "message"}
                                    name="title"
                                    type="text"
                                    label="Title"
                                    variant="outlined"

                                    margin="normal"
                                    value={this.state.title}
                                    onChange={(event) => { this.setState({ title: event.target.value }) }}
                                />
                                <TextField
                                    disabled={this.state.type !== "message"}
                                    name="message"
                                    type="text"
                                    label="Message"
                                    variant="outlined"
                                    rowsMax={10}
                                    rows={20}
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    value={this.state.msg}
                                    onChange={(event) => { this.setState({ msg: event.target.value }) }}

                                />
                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.sendMessage}>
                        Send
          </Button>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    eventId: state.eventId,
    authUser: state.authUser,
    currentEventData: state.currentEventData,
    teamsList: state.teamsList,
    teamsData: state.teamsData,
    targetTeamId: state.appState.targetTeamId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    closeDialog: () => dispatch(closeDialog('sendMsg')),
    showAlert: (title, msg) => dispatch(showAlert({ title: title, msg: msg }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SendMsgDialog));
