import { makeGetResult } from "./ValikResultCalc";
import { connect } from 'react-redux';

function DummyValikCalculator(props) {
    return null;
}
const makeMapStateToProps = () => {
    const getResult = makeGetResult();
    const mapStateToProps = (state, props) => {
        return {
            result: getResult(state, props),
        }
    }
    return mapStateToProps;
}


export default connect(makeMapStateToProps)(DummyValikCalculator);
