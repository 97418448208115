import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { updateSpeedAreaDoc } from '../redux/actionsEvents';
import { addUndo } from '../redux/actionsEventResult';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import firebase from 'firebase/app';
import AreaOnMap from './AreaOnMap';
import SpeedAreaPropsEditor from './SpeedAreaPropsEditor';
import AllSpeedAreasList from './AllSpeedAreasList';



// TODO maxHeight for root is not very good at the moment. should depend on page height
const styles = theme => ({
	container: {
		paddingLeft: '6px',
	},
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		flex: 1
	},
	listSection: {
		backgroundColor: 'inherit',
		fontWeight: 'bold'
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0,
	},
	wrongTicket: {
		backgroundColor: 'pink',
	},
	top: {
		position: 'relative',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		display: 'block',
		margin: 'auto',
		marginTop: 12,
		marginBottom: 20,
		flex: 1
	},
	closebutton: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
	unseen: {
		background: '#EDE7F6'
	},
	gridforprogress: {
		marginTop: 12,
	}
});

class SpeedAreaEdit extends Component {

	constructor(props, context) {

		super(props, context)
		this.state = {
			currentAreaId: null,
			newArea: null,
			showAllAreas: false,
		}
	}
	listenerforareaclicks = null;


	handleChange = name => event => {
		//console.log('got handleChange ', name, event.target.value);
		let value = event.target.value;
		if (name === "restricted")
			value = (value === "true");
		if (this.state.newArea !== null) {
			let no = Object.assign({}, this.state.newArea);
			no[name] = value;
			this.setState({ newArea: no });
			return;
		}
		//console.log(name, event.target.value);



		let akey = this.state.currentAreaId;
		let firestore = firebase.firestore();
		const docref = (akey.owner ?
			firestore.collection('speedareas').doc(akey.owner) :
			firestore.collection('events').doc(this.props.eventId));
		const setkey = (akey.owner ? 'areas.' + akey.id + '.' + name : 'speedareas.' + akey.id + '.' + name);
		docref.feedbackupdate({
			[setkey]: value
		});

		// TODO - use Undo system
	}

	handleExpand = (tid) => (e) => {
		this.setState({ ['teamexp-' + tid]: !this.state['teamexp-' + tid] });
	}

	handleSpdClick = (item) => (e) => {
		window.bigmap.showSpeedingTicket(item);
	}

	toggleShowAreas = (e) => {
		window.bigmap.showAreas(e.target.checked);
	}

	handleTicketChange = (item) => (e) => {
		if (item.ignoreid !== null)
			this.props.rmSpeedingTicketsAndIgnores(item.tid, [item.aid]);
		else if (item.ticketid === null)
			this.props.addSpeedingTickets(item.tid, [item.aid]);
		else
			this.props.addSpeedingIgnores(item.tid, [item.aid]);
	}

	pointUpdated(index) {
		function getButton(imageUrl) {
			var e = document.getElementsByTagName("IMG");
			for (let el of e) {
				if (el.src.endsWith(imageUrl))
					return el;
			}
			return null;
			//return  $("img[src$='" + imageUrl + "']");
		}

		var path = this;
		var btnDelete = getButton(path.btnDeleteImageUrl);
		if (btnDelete === null) {
			var undoimg = getButton('//maps.gstatic.com/mapfiles/undo_poly.png');
			undoimg.parentElement.style.height = '21px !important';
			let element = document.createElement('template');
			element.innerHTML = '<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="' + path.btnDeleteImageUrl + '" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>';
			undoimg.parentElement.parentElement.appendChild(element.content.firstChild);

			// now get that button back again!
			btnDelete = getButton(path.btnDeleteImageUrl);
			if (btnDelete.addEventListener)
				console.log('on event listener');
			btnDelete.addEventListener('mouseover', (e) => { e.stopPropagation(); btnDelete.style.left = '-30px'; });
			btnDelete.addEventListener('mouseleave', (e) => { e.stopPropagation(); btnDelete.style.left = '0px'; });
			btnDelete.addEventListener('mousedown', (e) => { e.stopPropagation(); btnDelete.style.left = '-60px'; });
		}

		// if we've already attached a handler, remove it
		if (path.btnDeleteClickHandler)
			btnDelete.onclick = undefined;

		// now add a handler for removing the passed in index
		path.btnDeleteClickHandler = function (e) {
			e.stopPropagation();
			path.removeAt(index);
		};
		btnDelete.onclick = path.btnDeleteClickHandler;
	}

	addDeleteButton(poly, imageUrl) {
		var path = poly.getPath();
		path["btnDeleteClickHandler"] = {};
		path["btnDeleteImageUrl"] = imageUrl;
		window.google.maps.event.addListener(poly.getPath(), 'set_at', this.pointUpdated);
		window.google.maps.event.addListener(poly.getPath(), 'insert_at', this.pointUpdated);
	}

	handleSelectEventArea = (akey) => {
		this.setState({ currentAreaId: { id: akey, bound: true } });
		//		this.setState({ currentAreaId: { id: akey } });
	}

	handleSelectArea = (akey) => {
		//console.log(akey);
		this.setState({ currentAreaId: akey });

	}

	addNewArea = () => {
		//console.log("Add new area clicked");
		this.setState({ currentAreaId: null });
		window.bigmap.map.map.map_.setOptions({ draggableCursor: 'crosshair' });
		let self = this;
		this.setState({ newArea: { name: "", restricted: false, limit: "", area: null, points: 0, parentForceUpdate: () => { self.forceUpdate(); } }, newAreaPoints: 0 });
		this.listenerforareaclicks = window.google.maps.event.addListener(window.bigmap.map.map.map_, 'click', function (event) {
			if (self.state.newArea.area == null) {
				self.state.newArea.area = new window.google.maps.Polygon({
					paths: [event.latLng],
					strokeColor: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: '#FF0000',
					fillOpacity: 0.35,
					clickable: true,
					editable: true
				});
				self.state.newArea.area.setMap(window.bigmap.map.map.map_);
			} else {
				self.state.newArea.area.getPath().push(event.latLng);
				self.setState({ newAreaPoints: self.state.newArea.area.getPath().getLength() });
			}
		});
	}

	completeNewArea = async () => {
		//console.log("got complete!");
		let uid = firebase.auth().currentUser.uid
		let areaid = 1;
		Object.keys(this.props.eventSpeedAreas).forEach(sid => {
			const [auid, aid] = sid.split(':');
			if (auid !== uid)
				return;
			if (Number(aid) >= areaid)
				areaid = Number(aid) + 1;
		})
		let self = this;
		const newArea = this.state.newArea;
		const newpath = newArea.area.getPath().getArray().map(e => new firebase.firestore.GeoPoint(e.lat(), e.lng()));
		const firestore = firebase.firestore();
		const globdocref = firestore.collection('speedareas').doc(uid);

		const globdoc = await globdocref.get();
		if (!globdoc.exists)
			await globdocref.set({});
		let areaobject = {
			area: newpath,
			name: newArea.name,
		};
		if (!isNaN(newArea.limit))
			areaobject['limit'] = Number(newArea.limit);
		if (newArea.restricted)
			areaobject['restricted'] = true;
		await globdocref.update({
			['speedareas.' + areaid.toString()]: areaobject
		});
		const eventdocref = firestore.collection('events').doc(this.props.eventId);
		await eventdocref.update({
			['speedareas.' + uid + ':' + areaid.toString()]: areaobject
		})
		window.google.maps.event.removeListener(this.listenerforareaclicks);
		if (this.state.newArea.area !== null) {
			this.state.newArea.area.setMap(null);
			delete this.state.newArea.area;
		}
		self.setState({ currentAreaId: { bound: true, id: uid + ':' + areaid.toString() }, newArea: null });
		return;
	}

	cancelNewArea = () => {
		window.google.maps.event.removeListener(this.listenerforareaclicks);
		if (this.state.newArea.area !== null) {
			this.state.newArea.area.setMap(null);
			delete this.state.newArea.area;
		}
		this.setState({ newArea: null });
	}

	toggleShowAllAreas = (ev, checked) => {
		this.setState({ showAllAreas: checked });
	}

	addAreaToEvent = (akey, area) => {
		let self = this;
		firebase.firestore().collection('events').doc(this.props.eventId).feedbackupdate({
			['speedareas.' + akey.owner + ':' + akey.id]: area
		}).then(() => {
			//console.log('completed add area', self.state.currentAreaId, akey);
			if (self.state.currentAreaId && self.state.currentAreaId.owner === akey.owner && self.state.currentAreaId.id === akey.id) {
				self.setState({ currentAreaId: { id: akey.owner + ':' + akey.id } });
				//console.log('should have set new current area');
			}
		});
		//console.log('adding area to event', akey);
	}

	removeAreaFromEvent = (aid) => {
		//console.log('rm', aid)
		if (this.state.currentAreaId && this.state.currentAreaId.id === aid) {
			this.setState({ currentAreaId: null });
		}
		firebase.firestore().collection('events').doc(this.props.eventId).feedbackupdate({
			['speedareas.' + aid]: firebase.firestore.FieldValue.delete()
		})
	}

	render() {
		const { classes, eventSpeedAreas } = this.props;
		const { newArea, currentAreaId, showAllAreas } = this.state;

		return (
			<div className={classes.top}>
				<div align="center">
					<Button size="small"
						disabled={newArea !== null && (this.state.newAreaPoints < 3 || !newArea.name || (!newArea.limit && !newArea.restricted)) ? true : false}
						variant="contained" onClick={newArea !== null ? this.completeNewArea : this.addNewArea} color="primary" >
						{newArea !== null ? "Complete" : "Add new area"}
					</Button>
					{newArea !== null && (
						<Button size="small" variant="contained" onClick={this.cancelNewArea} color="primary" >
							Cancel
						</Button>)}
					<br />
					<FormControlLabel
						control={
							<Switch
								checked={this.state.showAllAreas}
								onChange={this.toggleShowAllAreas}
							/>
						}
						label="Show all areas"
					/>
				</div>
				<SpeedAreaPropsEditor akey={currentAreaId} newArea={newArea} bound={!Boolean(currentAreaId && currentAreaId.owner)} />
				<Divider />
				<List className={classes.root}
					subheader={<ListSubheader inset={true} color="primary" component="div">Areas used in event</ListSubheader>}
				>
					{Object.entries(eventSpeedAreas).map(([k, a]) => (
						<ListItem
							key={k}
							button
							selected={currentAreaId && currentAreaId.id === k}
							onClick={() => this.handleSelectEventArea(k)}
						>
							<ListItemText primary={a.name} />
							<ListItemSecondaryAction>
								<IconButton
									onClick={() => this.removeAreaFromEvent(k)}
									aria-label="Remove">
									<Icon aria-label="Remove">remove_circle</Icon>
								</IconButton>
								<AreaOnMap akey={{ id: k }} map={window.bigmap.map.map.map_} bound={currentAreaId ? currentAreaId.bound : false} selected={currentAreaId ? currentAreaId.id === k : false} />
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
				<Divider />
				<AllSpeedAreasList
					handleSelectArea={this.handleSelectArea}
					addAreaToEvent={this.addAreaToEvent}
					eventSpeedAreas={eventSpeedAreas}
					currentAreaId={currentAreaId}
					showAllAreas={showAllAreas} />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		eventId: state.eventId,
		eventSpeedAreas: state.eventSpeedAreas,
	}
};
const mapDispatchToProps = (dispatch, ownProps) => ({
	updateSpeedAreaDoc: (key, doc) => dispatch(updateSpeedAreaDoc(key, doc)),
	addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeedAreaEdit));
