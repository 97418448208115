import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import WMSMapType from './WMSMapType';

class GoogleMap extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      options: this.props.setoptions,
    };
  }

  handleApiLoaded = (args) => {

    const { map, maps } = args;

    if (maps.Polygon.prototype.getBounds === undefined) {
      maps.Polygon.prototype.getBounds = function () {
        var bounds = new maps.LatLngBounds();
        var paths = this.getPaths();
        var path;
        for (var i = 0; i < paths.getLength(); i++) {
          path = paths.getAt(i);
          for (var ii = 0; ii < path.getLength(); ii++) {
            bounds.extend(path.getAt(ii));
          }
        }
        return bounds;
      }
      maps.Polyline.prototype.getBounds = function () {
        var bounds = new maps.LatLngBounds();
        let path = this.getPath();
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
        return bounds;
      }

      maps.customMapTypes = [];
      maps.customMapTypes.push(new WMSMapType(new maps.Size(256, 256), 'Maaamet', 'http://kaart.maaamet.ee/wms/alus-geo', { layers: 'CORINE,TEED,HALDUSPIIRID,MAANTEED,pohi_vr2' }, { minZoom: 4, showOffZoom: true, addAsMap: true, menuEntry: 'Maaameti põhikaart' }));
      maps.customMapTypes.push(new WMSMapType(new maps.Size(256, 256), 'Maaamet foto', 'http://kaart.maaamet.ee/wms/alus-geo', { layers: 'CORINE,TEED,HALDUSPIIRID,MAANTEED,of10000,HYBRID' }, { minZoom: 4, showOffZoom: true, addAsMap: true, menuEntry: 'Maaameti hübriidkaart' }));

    }

    // Some WMS map testing
    //map.mapTypes.set('coordinate', new CoordMapType(new maps.Size(256, 256)));

    //map.overlayMapTypes.insertAt(0, new WMSMapType(new maps.Size(256, 256), 'Maaamet', 'http://kaart.maaamet.ee/wms/kaart', { crs: 'EPSG:3301', layers: 'MA-KAART' }, { minZoom: 4, showOffZoom: true }));

    //map.overlayMapTypes.insertAt(0, new WMSMapType(new maps.Size(256, 256), 'Maaamet', 'http://kaart.maaamet.ee/wms/alus-geo', { layers: 'CORINE,TEED,HALDUSPIIRID,MAANTEED,pohi_vr2' }, { minZoom: 4, showOffZoom: true }));
    //map.overlayMapTypes.insertAt(0, new WMSMapType(new maps.Size(256, 256), 'Maaamet', 'http://kaart.maaamet.ee/wms/alus-geo', { layers: 'pohi_vr2' }, { minZoom: 15, showOffZoom: true }));
    //map.overlayMapTypes.insertAt(0, new WMSMapType(new maps.Size(256, 256), 'Maaamet', 'http://kaart.maaamet.ee/wms/alus-geo', { layers: 'KAART24' }));

    let mapTypeIds = ['roadmap', 'terrain', 'satellite', 'hybrid'];
    maps.customMapTypes.forEach((m, idx) => {
      if (m.addAsMap) {
        map.mapTypes.set('custom' + idx, m);
        mapTypeIds.push('custom' + idx);
      }
      if (m.useAsOverlay)
        map.overlayMapTypes.insertAt(map.overlayMapTypes.getLength(), m);
    })
    //    map.mapTypes.set('mapohi', this.maaametmap);

    map.setOptions({
      mapTypeControlOptions: {
        mapTypeIds: mapTypeIds,
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
      }
    })

    if (this.props.onGoogleApiLoaded) {
      this.props.onGoogleApiLoaded(args);
    }
  }

  mapref = map => {
    this.map = map;
  }

  render() {
    let clickoptions = {};
    if (this.props.needclick) {
      clickoptions = { draggableCursor: 'crosshair' }
      this.needsCursorOption = true;
    }

    let options = Object.assign({}, this.state.options, this.props.setoptions, clickoptions);
    if (this.needsCursorOption) {
      if (!options.draggableCursor)
        options.draggableCursor = 'url("https://maps.gstatic.com/mapfiles/openhand_8_8.cur"), auto'
    }
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <GoogleMapReact
          {...this.props}
          ref={this.mapref}

          bootstrapURLKeys={{
            key: 'AIzaSyDsi4FGy8yrcXHmhz7PMDJnBsTFE8y2d-o',
            libraries: 'geometry'
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={(args) => this.handleApiLoaded(args)}
          options={options}
          onChildClick={(key, child) => {
            if (typeof child.onClick === 'function')
              child.onClick(key);
          }}

        >
          {this.props.children}
        </GoogleMapReact>
      </div >
    );
  }
}

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
