import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});

const arrowspeed = 5 * 1000 / 3800; // convert into km/h into m/s

let calcMiddlePoint = (p1, p2, tv) => {
    let t = (tv - p1.t) / ((p2.t - p1.t));
    let resp = new window.google.maps.LatLng((1 - t) * p1.lat() + t * p2.lat(), (1 - t) * p1.lng() + t * p2.lng());
    resp.istmp = true;
    resp.t = tv;
    resp.s = p2.s;
    return resp;
}

class TrackOnMap extends React.Component {

    componentDidMount() {
        this.shouldComponentUpdate(this.props);
    }

    componentWillUnmount() {
        if (this.track) {
            this.track.setMap(null);
            delete this.track;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (!nextProps.enabled || nextProps.trackType === 'no') {
            if (this.track && this.track.map)
                this.track.setVisible(false);
            return false;
        }
        if (!this.track) {
            if (nextProps.locationData !== undefined && nextProps.locationData.points.length > 1)
                this.createTrack(nextProps);
            else
                return false;
        }


        if (this.trackType !== nextProps.trackType || this.props.color !== nextProps.color
            // This conditionas can maybe doine in improved performance way, not with full recreate track.
            || this.props.trackTailLength !== nextProps.trackTailLength
        ) {
            this.track.setMap(null);
            delete this.track;
            this.createTrack(nextProps);
        } else if (this.track && !this.track.visible)
            this.track.setOptions({ visible: true, zIndex: this.props.map.trackzv++ });


        if (this.props.locationData && nextProps.locationData !== this.props.locationData && nextProps.locationData) {
            const points = nextProps.locationData.points;
            const tpath = this.track.getPath();
            let i = points.length - 1;
            const lastp = tpath.getAt(tpath.getLength() - 1);
	    if (lastp === undefined) {
		    console.log('Something bad happend');
		    console.log('tpath len:', tpath.getLength());
	    } else {
            	while (i > 0 && points[i].t !== lastp.t) i--;
	    }
            /*
            console.log("live i:", i, "lastpt;:", lastp.t, tpath.getLength());
            let str = "";
            for (let j = 0; j < points.length; j++) {
                if (j == 0)
                    str += points[0].t.toString();
                if (j > 0) {
                    if ((points[j].t - points[j - 1].t) < 0) {
                        console.log('live brokern before.......... at ', j);
                        debugger;
                    }
                }
            }
            for (let j = 0; j < tpath.getLength(); j++) {
                if (j > 0) {
                    if ((tpath.getAt(j).t - tpath.getAt(j - 1).t) < 0) {
                        console.log('live path broken before at : ', j)
                        debugger;
                    }
                }
                str += ",";
            }
            */
            for (i++; i < points.length; i++)
                tpath.push(points[i]);

            /*
            console.log(i);

            str = "";
            for (let j = 0; j < points.length; j++) {
                if (j == 0)
                    str += points[0].t.toString();
                if (j > 0) {
                    if ((points[j].t - points[j - 1].t) < 0) {
                        console.log('live brokern.......... at ', j);
                        debugger;
                    }
                }
            }
            for (let j = 0; j < tpath.getLength(); j++) {
                if (j > 0) {
                    if ((tpath.getAt(j).t - tpath.getAt(j - 1).t) < 0) {
                        console.log('live path broken at : ', j)
                        debugger;
                    }
                }
                str += ",";
            }

            */

            if (nextProps.trackType === 'live') {
                let ct = new Date().getTime();
                while (tpath.getLength() > 2 && (ct - tpath.getAt(0).t) > nextProps.trackTailLength)
                    tpath.removeAt(0);
                /*
            console.log('live track end', tpath, tpath.getLength(), tpath.getAt(0).t, ct, ct - tpath.getAt(0).t, nextProps.trackTailLength, points.length);


            console.log("live: ", str);
            for (let j = 0; j < tpath.getLength(); j++) {
                if (j > 0) {
                    if ((tpath.getAt(j).t - tpath.getAt(j - 1).t) < 0) {
                        console.log('live path broken at : ', j)
                        debugger;
                    }
                }
                str += ",";
            }
            */
                /*
                let str = "";
                for (let j = 0; j < tpath.getLength(); j++) {
                    if (j == 0)
                        str += tpath.getAt(j).t.toString();
                    if (j > 0)
                        str += " < " + (tpath.getAt(j).t - tpath.getAt(j - 1).t).toString();
                    str += ",";
                }
                console.log("live: ", str);
                */
                /*
                let atpath = [];
                for (let j = 0; j < tpath.getLength(); j++)
                    atpath.push(tpath.getAt(j));
                console.log('live', atpath.map((v, idx) => {
                    if (idx === 0)
                        return "0";
                    else
                        return (v.t - atpath[idx - 1].t).toString()
                }).join(","));
                */
            }
        }

        if (nextProps.zoomToTrack > this.props.zoomToTrack) {
            if (this.track) {
                if (nextProps.trackType === 'full')
                    this.props.map.fitBounds(this.track.getBounds());
                else {
                    let p = this.track.getPath();
                    if (p.getLength() > 0)
                        this.props.map.panTo(p.getAt(p.getLength() - 1)); // Better with replay and live
                }
            }
            //					map.panTo(livepath.getAt(livepath.length - 1)); // Better with replay and live
        }
        if ((nextProps.replayTime !== this.props.replayTime || nextProps.massstart !== this.props.massstart) && nextProps.trackType === "replay") {
            const { replayTime, startms, firststart, trackTailLength, massstart } = nextProps;
            const locationData = nextProps.locationData.points;
            let diff = replayTime - this.props.replayTime;
            let s = replayTime;
            if (diff > 0 && diff < 6000) {
                let steam = (massstart ? s + (startms - firststart) : s);
                if (locationData.length > 0) {
                    let tteam = (massstart ? replayTime + (startms - firststart) : replayTime);

                    let i = this.replayIdx + 1;
                    let path = this.track.getPath();
                    if (path.getLength() > 0 && path.getAt(path.getLength() - 1).istmp) {
                        if (locationData[this.replayIdx].t <= steam) {
                            path.setAt(path.getLength() - 1, locationData[this.replayIdx]);
                        } else {
                            // update lastpoint
                            path.setAt(path.getLength(), calcMiddlePoint(locationData[this.replayIdx - 1], locationData[this.replayIdx], tteam));

                        }
                    }
                    while (i < locationData.length && locationData[i].t < steam) {
                        path.push(locationData[i++]);
                    }

                    let rt = replayTime;
                    let teamReplayTime = (massstart ? rt + (startms - firststart) : rt);

                    while (path.getLength() > 0 && (teamReplayTime - path.getAt(0).t) > trackTailLength)
                        path.removeAt(0);
                    this.replayIdx = i - 1;
                    if (teamReplayTime > locationData[this.replayIdx].t && this.replayIdx + 1 < locationData.length) {
                        this.replayIdx++;
                        path.push(calcMiddlePoint(locationData[this.replayIdx - 1], locationData[this.replayIdx], tteam));
						/*
											let tmpp = new window.google.maps.LatLng(ld[track.replayIdx].lat(), ld[track.replayIdx].lng());
											tmpp.istmp = true;
											path.push(tmpp);*/
                    }
                }
            } else {
                this.track.setMap(null);
                delete this.track;
                this.createTrack(nextProps);
            }
        }

        if (this.track.icons) {
            let path = this.track.getPath();
            let lastp = path.getAt(path.getLength() - 1);
            if (lastp !== undefined) {
                let ct = new Date().getTime();

                let timetolastseen = ct - lastp.t;
                if (timetolastseen > 300 && nextProps.trackType !== 'replay') {
                    if (this.track.icons[0].icon.scale !== 4) {
                        this.track.icons[0].icon.scale = 4;
                        this.track.icons[0].icon.fillOpacity = 0.5;
                        this.track.icons[0].icon.strokeWeight = 0;
                        this.track.setOptions('icons', this.track.icons);
                    }
                } else {
                    if (this.track.icons[0].icon.scale !== 5) {
                        this.track.icons[0].icon.scale = 5;
                        this.track.icons[0].icon.fillOpacity = 0.9;
                        this.track.icons[0].icon.strokeWeight = 1;
                        this.track.setOptions('icons', this.track.icons);
                    }
                }
                let sicon = (lastp.s > arrowspeed && (timetolastseen < 300 || nextProps.trackType === 'replay') ? window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW : window.google.maps.SymbolPath.CIRCLE);
                if (this.track.icons[0].path !== sicon) {
                    this.track.icons[0].icon.path = sicon;
                    this.track.set('icons', this.track.icons);
                }
            }
        }

        return false;
    }

    createTrack(props) {
        let ld = [];
        let addicon = false;
        const points = props.locationData.points;
        if (props.trackType === "full") {
            if (!props.finishms)
                addicon = true;
            ld = points;
        } else if (props.trackType === "live") {
            addicon = true;
            if (points.length > 0) {
                let ct = new Date().getTime();
                for (let i = points.length - 1; i > 0 && ct - points[i].t < props.trackTailLength; i--)
                    ld.push(points[i]);
                if (ld.length < 1)
                    ld.push(points[points.length - 1]);
                if (ld.length < 2) {
                    let fakepoint = points[points.length - 1];
                    fakepoint.t--;
                    ld.push(fakepoint);
                }
                ld.reverse();
            }
        } else if (props.trackType === "replay") {
            addicon = true;
            if (points.length > 0) {
                let sv = (props.massstart ? props.replayTime + (props.startms - props.firststart) : props.replayTime);

                let start = 0; let end = points.length - 1; let middle = Math.floor((start + end) / 2);
                if (points[start].t > sv) {
                    middle = start;
                } else if (points[end].t < sv) {
                    middle = end;
                } else {
                    while (points[middle].t !== sv && start < end) {
                        if (sv < points[middle].t) {
                            end = middle - 1;
                        } else {
                            start = middle + 1;
                        }
                        middle = Math.floor((start + end) / 2);
                    }
                }
                for (let i = middle; i > 0 && (sv - points[i].t) < props.trackTailLength; i--)
                    ld.push(points[i]);
                ld.reverse();
                this.replayIdx = middle;
            }
        }
        this.track = new window.google.maps.Polyline({
            path: ld,
            strokeColor: props.color,
            strokeWeight: 5,
            zIndex: this.props.map.trackzv++,
            map: props.map,
            icons: (addicon ? [{
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    strokeColor: 'black',
                    fillColor: props.color,
                    fillOpacity: 0.9,
                    strokeWeight: 1,
                    strokeOpacity: 1,
                    scale: 5
                },
                offset: '100%',
            }] : undefined)
        })
        this.trackType = props.trackType;

    }

    render() {
        return null;
    }
}

const mapStateToProps = (state, ownProps) => ({
    locationData: (state.locationData[ownProps.teamid] || {})[ownProps.devid],
    enabled: !state.teamsList[ownProps.teamid].disabled,
    startms: state.teamsList[ownProps.teamid].starttime,
    finishms: state.teamsList[ownProps.teamid].finishtime,
    color: state.teamsList[ownProps.teamid].col || state.teamsList[ownProps.teamid].color,
    zoomToTrack: (state.appState.zoomToTrack[ownProps.teamid] || {})[ownProps.devid] || 0,
    trackType: state.appState.trackType,
    trackTailLength: state.appState.trackTailLength,
    replayTime: state.replayData,
    massstart: state.appState.replayType === 'massstart',
})

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(TrackOnMap));
