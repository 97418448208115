import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showSpeedingTicket } from '../redux/actionsEvents';
import { setReplayTime, addSaveInProgress, rmSaveInProgress } from '../redux/actionsEventResult';
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AreaOnMap from './AreaOnMap';
import firebase from 'firebase/app';


// TODO maxHeight for root is not very good at the moment. should depend on page height
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        flex: 1
    },
    listSection: {
        backgroundColor: 'inherit',
        fontWeight: 'bold'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    wrongTicket: {
        backgroundColor: 'pink',
    },
    top: {
        position: 'relative',
        height: '100%',
        display: 'flex'
    },
    title: {
        display: 'block',
        margin: 'auto',
        marginTop: 12,
        marginBottom: 20,
        flex: 1
    },
    closebutton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    gridforprogress: {
        marginTop: 12,
    }
});

class Speedings extends Component {

    constructor() {
        super()
        this.state = {
            nestedGroups: true,
            groupByTeams: true,
            showAreas: false
        }
    }

    componentWillUnmount() {
        this.props.showSpeedingTicket(null);
    }

    handleExpand = (tid) => (e) => {
        this.setState({ ['teamexp-' + tid]: !this.state['teamexp-' + tid] });
    }

    handleSpdClick = (item) => (e) => {
        //console.log(item, this.props.setReplayTime);
        this.props.setReplayTime(null);
        this.props.showSpeedingTicket(item);
    }

    toggleShowAreas = (e) => {
        this.setState({ showAreas: e.target.checked });
    }

    handleTicketChange = (item) => (e) => {
        let newstate = 'enabled';
        if (item.state === 'enabled')
            newstate = 'ignored';
        else if (item.state === 'ignored')
            newstate = null;
        firebase.database().ref(`teams/${this.props.eventId}/spdtickets/${item.tid}/${item.dev}/${item.t}/state`).feedbackset(newstate);
    }

    handleTeamChange = (group) => (event) => {
        if (group.length === 0)
            return;
        let aidlist = group.filter(e => event.target.checked ? e.ticketid === null : e.ticketid !== null).map(t => t.aid);
        if (event.target.checked)
            this.props.addSpeedingTickets(group[0].tid, aidlist);
        else
            this.props.rmSpeedingTicketsAndIgnores(group[0].tid, group.map(t => t.aid));

        console.log(aidlist);
        console.log(event.target.checked);
    }

    render() {
        const { classes, speedings, showTicket, teamsList, areas, eventAccess } = this.props;

        this.props.setReplayTime(null);
        let listitems = [];
        Object.entries(speedings || []).forEach(([tid, v]) => {
            let spdpoints = [];
            Object.entries(v).forEach(([dev, devpoints]) => {
                Object.entries(devpoints).forEach(([tstamp, entry]) => {
                    spdpoints.push(Object.assign({ dev: dev, t: Number(tstamp), tid: tid }, entry));
                })
            });
            listitems.push((
                <ListItem key={tid} onClick={this.handleExpand(tid)}>
                    {this.state['teamexp-' + tid] ? <ExpandLess /> : <ExpandMore />}
                    <ListItemText color='primary' inset primary={(teamsList[tid] || {}).name + " (" + spdpoints.length + ")"} />
                </ListItem>

            ));

            listitems.push((
                <Collapse key={'collapse-' + tid} in={this.state['teamexp-' + tid]} timeout='auto' unmountOnExit>
                    <List component="div" disablePadding>
                        {spdpoints.sort((a, b) => b.t - a.t).map(item => (
                            <ListItem key={item.t + item.dev} selected={false && showTicket && showTicket === item.aid} button className={(item.wrong ? classes.wrongTicket : "")} onClick={this.handleSpdClick(item)}>
                                <ListItemText
                                    primary={Math.floor(item.spd) + " km/h" + (item.area ? " alas " + (item.area in areas ? areas[item.area].name : "<deleted>") : "")}
                                    secondary={new Date(item.t).toLocaleString(undefined, { hour12: false })}
                                />
                                {eventAccess && (
                                    <ListItemSecondaryAction>
                                        <Checkbox checked={item.state === "enabled"} indeterminate={item.state === 'ignored'} onChange={this.handleTicketChange(item)} value={'ticketfor' + item.aid} />
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            ))


        });

        return (
            <div className={classes.top}>
                {speedings === null && (
                    <Grid className={classes.gridforprogress} container justify="center">
                        Nothing detected
                    </Grid>
                )}
                <List className={classes.root} >
                    <ListItem>
                        {eventAccess && (<Button size="small" variant="contained" onClick={this.props.recalcTickets} color="primary" >
                            Recalculate
                        </Button>)}
                        <FormControlLabel style={{ padding: 10 }}
                            control={
                                <Switch
                                    onChange={this.toggleShowAreas}
                                />
                            }
                            label="Show areas"
                        />
                    </ListItem>
                    {listitems}
                </List>
                {this.state.showAreas && Object.entries(areas).map(([key, a]) => (<AreaOnMap key={key} akey={{ id: key }} map={window.bigmap.map.map.map_} selected={false} />))}
            </div>
        );

    }
}

function recalcTickets() {
    return (dispatch, getState) => {
        dispatch(addSaveInProgress());
        var state = getState();
        const db = firebase.database();
        const teamsref = db.ref(`teams/${state.eventId}/`);
        const archref = teamsref.child('sticketarchive');
        archref.child(new Date().getTime()).set(state.spdtickets);
        const sref = teamsref.child('spdtickets');
        sref.remove();
        const spconf = state.speedConf;
        spconf.limit = Number(spconf.limit);
        Object.entries(state.locationData).forEach(([tid, devs]) => {
            if (tid === "upcount")
                return;
            const team = state.teamsList[tid];
            if (!team || !team.starttime)
                return;
            const start = team.starttime;
            const finisht = team.finishtime;
            Object.entries(devs).forEach(([devid, pdata]) => {
                let oc1 = 0; let oc2 = 0;
                const ref = sref.child(tid).child(devid);
                const addTicket = (l, p, type) => {
                    const oldstate = (((state.spdtickets[tid] || {})[devid] || {})[p.t] || {}).state || null
                    ref.child(p.t).set({
                        limit: l,
                        spd: Math.floor(p.s * 3600 / 1000),
                        type: type,
                        state: oldstate
                    })
                }
                pdata.points.forEach(p => {
                    if (p.t < start || (finisht && p.t > finisht))
                        return;
                    const sp = Math.floor(p.s * 3600 / 1000);
                    let area = Object.values(state.eventSpeedAreas).find(s => window.google.maps.geometry.poly.containsLocation(p, s.poly));
                    let limit = (area ? area.limit : spconf.limit);
                    if (sp >= limit + spconf.over2) {
                        oc2 += 1;
                        if (oc2 >= spconf.delay2) {
                            addTicket(limit, p, 'co2');
                            oc2 = 0; oc1 = 0;
                        }
                    } else
                        oc2 = 0;
                    if (sp >= limit + spconf.over1) {
                        //console.log('o1', oc1, new Date(p.t).toString(), sp);
                        oc1 += 1;
                        if (oc1 >= spconf.delay1) {
                            //console.log('and ticket', tid, devid, state.teamsList[tid].name);
                            addTicket(limit, p, 'co1');
                            oc2 = 0; oc1 = 0;
                        }
                    } else
                        oc1 = 0;
                });
            });
        });
        dispatch(rmSaveInProgress());
    }
}

function mapStateToProps(state, ownProps) {
    return {
        eventId: state.eventId,
        teamsList: state.teamsList,
        speedings: state.spdtickets,
        showTicket: state.appState.showSpeedingTicket,
        areas: state.eventSpeedAreas,
        eventAccess: state.eventAccess
    }
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    showSpeedingTicket: (ticket) => dispatch(showSpeedingTicket(ticket)),
    setReplayTime: (t) => dispatch(setReplayTime(t)),
    recalcTickets: () => dispatch(recalcTickets())

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Speedings));
