import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SimpleTextInput from './SimpleTextInput';
import firebase from 'firebase/app';


const styles = theme => ({
});

class EditEventLisaylessanded extends Component {


    state = {
        addLisaYlessanneDialogOpen: false,
        editLisaYlessaneId: null,
        editLisaYlessanneName: ""
    }
    constructor(props) {
        super(props);
        this.lyref = firebase.database().ref('eventsdata').child(props.eventId).child('data').child('ly');
    }
    toggleEditLy = (lyid, lyname) => event => {
        this.setState({ editLisaYlessaneId: lyid, editLisaYlessanneName: lyname });
    }
    confirmEditLy = () => {
        this.lyref.child(this.state.editLisaYlessaneId).child('name').setWithUndo(this.state.editLisaYlessanneName, 'Lisaülessane muudetud');
        this.setState({ editLisaYlessaneId: undefined });
    }
    handleChange = name => event => {
        if (name === 'lyname') {
            this.setState({ editLisaYlessanneName: event.target.value });
            return;
        }
    }

    addLisaYlessanne = () => {
        this.setState({ addLisaYlessanneDialogOpen: true });
    }

    handleAddLisaYlessanne = value => event => {
        this.lyref.push().setWithUndo({ name: value }, 'Lisaülessanne lisatudd');
        this.setState({ addLisaYlessanneDialogOpen: false });
    }
    deleteLisaYlessanne = (lyid) => event => {
        this.lyref.child(lyid).setWithUndo(null, 'Lisaülessanne kustutatud');
        return;
    }

    render() {
        const { eventdata } = this.props;
        return (
            <div>
                {this.state.addLisaYlessanneDialogOpen && (<SimpleTextInput
                    title="Lisa ülessanne"
                    desc="Sisesta ülessande nimi"
                    handleClose={this.handleAddLisaYlessanne}
                />)}
                <List dense={true} elevation={2}>
                    {Object.entries(eventdata.ly || {}).map(([lyid, ly]) => (
                        <ListItem key={lyid}>
                            {this.state.editLisaYlessaneId === lyid ?
                                (<IconButton
                                    aria-label="Check"
                                    onClick={this.confirmEditLy}
                                    key="Check">
                                    <Icon aria-label="Check">check</Icon>
                                </IconButton>) :
                                (<IconButton
                                    aria-label="Edit"
                                    onClick={this.toggleEditLy(lyid, ly.name)}
                                    key="Edit">
                                    <Icon aria-label="Edit">edit</Icon>
                                </IconButton>)}
                            {this.state.editLisaYlessaneId === lyid ?
                                (<TextField value={this.state.editLisaYlessanneName} onChange={this.handleChange('lyname')} />) :
                                (<ListItemText primary={ly.name} />)}
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={this.deleteLisaYlessanne(lyid)}
                                    key="Delete">
                                    <Icon aria-label="Delete">delete</Icon>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <Button variant="contained" onClick={this.addLisaYlessanne} color="primary">Lisa ülessane</Button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    eventId: state.eventId,
    eventdata: state.currentEvent
})

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditEventLisaylessanded));

