const calcAvgSpeed = (tid, state) => {
    const st = state.teamsList[tid].starttime;
    const ft = state.teamsList[tid].finishtime;
    if (st === undefined || ft === undefined) {
        console.warn('starttime or finishtime missing for ', tid);
        return null;
    }
    const lloc = Object.values(state.locationData[tid]);
    if (lloc.length === 0) {
        console.warn('Team ' + tid + ' does not have any location data');
        return null;
    }
    let firstp = null; let lastp = null;
    lloc[0].points.forEach(p => {
        if (p.t < st || p.t > ft)
            return;
        if (firstp === null) {
            firstp = p;
        }
        lastp = p;
    });
    if (firstp === null || lastp === null)
        return null;
    const th = ((ft - st) / 1000 / 60 / 60);
    const dkm = (lastp.d - firstp.d) / 1000;
    return dkm / th;
}

export const orderrules = {
    "points": {
        desc: "Rohkem punkte",
        rule: (l, r) => {
            return r.sum - l.sum;
        }
    },
    "okcount": {
        desc: "Rohkem läpitud KP-sid",
        rule: (l, r) => {
            return r.okcount - l.okcount;
        }
    },
    "spent": {
        desc: "Vähem aega kulutanud",
        rule: (l, r) => {
            return l.data.spent - r.data.spent;
        }
    },
    "intime": {
        desc: "Jõudnud aega",
        rule: (l, r) => ((l.data.overtime ? 1 : 0) - (r.data.overtime ? 1 : 0))
    },
    "katk": {
        desc: "Ei ole katkestanud",
        rule: (l, r, lid, rid, store) => {
            const state = store.getState();
            return ((Boolean(state.teamsList[lid].katk) ? 1 : 0) - (Boolean(state.teamsList[rid].katk) ? 1 : 0))
        }
    },
    "dsq": {
        desc: "Ei ole diskvalifitseeritud",
        rule: (l, r, lid, rid, store) => {
            const state = store.getState();
            return ((Boolean(state.teamsList[lid].dsq) ? 1 : 0) - (Boolean(state.teamsList[rid].dsq) ? 1 : 0))
        }
    },
    "highkp": {
        desc: "Rohkem väärtuslikemaid KP'sid",
        rule: (l, r) => {
            if (l.rasums.length !== r.rasums.length) {
                console.error("rasums lengths should match");
                return 0;
            }
            for (let i = l.rasums.length; i--; i >= 0) {
                let diff = r.rasums[i] - l.rasums[i];
                if (diff !== 0)
                    return diff;
            }
            return 0;
        }
    },
    "highkp": {
        desc: "Rohkem väärtuslikemaid KP'sid",
        rule: (l, r) => {
            if (l.rasums.length !== r.rasums.length) {
                console.error("rasums lengths should match");
                return 0;
            }
            for (let i = l.rasums.length; i--; i >= 0) {
                let diff = r.rasums[i] - l.rasums[i];
                if (diff !== 0)
                    return diff;
            }
            return 0;
        }
    },
    "spdpenalty": {
        desc: "Vähem kiiruse trahve",
        rule: (l, r) => l.spdpenalty - r.spdpenalty
    },
    "spdavg": {
        desc: "Suurem keskmine kiirus.(Ei ole dünaamiline)",
        rule: (l, r, lid, rid, store) => {
            const state = store.getState();
            const lavg = calcAvgSpeed(lid, state);
            const ravg = calcAvgSpeed(rid, state);
            if (lavg === null || ravg === null) {
                console.warn('Missing average', lid, lavg, rid, ravg);
                return 0;
            }
            return ravg - lavg;
        }
    }
};

export const defaultorderrules = "dsq,katk,intime,points,spent,okcount";
