import React from 'react';
import { connect } from 'react-redux';
import { setReplayTime } from '../redux/actionsEventResult';
import SpeedTrackConfDialog from './SpeedTrakcConfDialog';
import RightDrawer from './RightDrawer';


const kmtoms = (v) => v * 1000 / 3600;
var speedranges = [
    { i: -10, x: kmtoms(10), c: '#b4e7ff' },
    { i: kmtoms(10), x: kmtoms(30), c: '#00aeff' },
    { i: kmtoms(30), x: kmtoms(50), c: '#0ec21b' },
    { i: kmtoms(50), x: kmtoms(70), c: '#ffc27a' },
    { i: kmtoms(70), x: kmtoms(80), c: '#fa8411' },
    { i: kmtoms(80), x: kmtoms(90), c: '#e30000' },
    { i: kmtoms(90), x: kmtoms(110), c: '#f600ff' },
    { i: kmtoms(110), x: kmtoms(5000), c: '#000000' }
];

class SpeedTrackOnMap extends React.Component {

    state = {
        secondrow: "speedareas"
    }
    componentDidMount() {
        this.shouldComponentUpdate(this.props);
    }

    componentWillUnmount() {
        if (this.polylines) {
            this.polylines.forEach(p => {
                window.google.maps.event.clearListeners(p, 'click');
                p.setMap(null);
            });
            this.polylines = undefined;
        }
        if (this.speedpoint) {
            this.speedpoint.setMap(null);
            delete this.speedpoint;
            this.speedpoint = undefined;
        }
        if (this.selectedpoint) {
            this.selectedpoint.setMap(null);
            delete this.selectedpoint;
            this.selectedpoint = undefined;
        }
    }

    handleClick = pl => (ev) => {
        let path = pl.getPath();
        let closesti = 0;
        let closestd = null;
        for (let i = 0; i < path.getLength(); i++) {
            let d = window.google.maps.geometry.spherical.computeDistanceBetween(ev.latLng, path.getAt(i));
            if (i === 0 || d < closestd) {
                closesti = i; closestd = d;
            }
        }
        this.selectedpoint.setPosition(path.getAt(closesti));
        this.props.setReplayTime(path.getAt(closesti).t);
    }
    shouldComponentUpdate(nextProps) {
        if (!this.polylines) {
            if (nextProps.locationData !== undefined) {
                this.polylines = [];
                let plist = [];
                let cspr = 0;
                let pa = null;
                //console.log(nextProps.locationData);
                for (let p of nextProps.locationData) {
                    plist.push(p);
                    if (p.s > speedranges[cspr].i && p.s <= speedranges[cspr].x)
                        continue;
                    pa = new window.google.maps.Polyline({
                        path: plist,
                        strokeColor: speedranges[cspr].c,
                        strokeWeight: 5,
                        map: nextProps.map,
                    });
                    this.polylines.push(pa);
                    window.google.maps.event.addListener(pa, 'click', this.handleClick(pa));
                    plist = [];
                    plist.push(p);
                    while (p.s <= speedranges[cspr].i)
                        cspr--;
                    while (p.s > speedranges[cspr].x)
                        cspr++;
                }
                pa = new window.google.maps.Polyline({
                    path: plist,
                    strokeColor: speedranges[cspr].c,
                    strokeWeight: 5,
                    map: nextProps.map,
                });
                this.polylines.push(pa);
                window.google.maps.event.addListener(pa, 'click', this.handleClick(pa));
            } else
                return false;
        }
        if (nextProps.ticket) {
            if (!nextProps.ticket.ticketIndex) {
                nextProps.ticket.ticketIndex = nextProps.locationData.findIndex(i => i.t >= nextProps.ticket.t);
            }
            if (!this.speedpoint) {
                this.speedpoint = new window.google.maps.Marker({
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        strokeColor: 'black',
                        fillColor: 'pink',
                        fillOpacity: 0.9,
                        strokeWeight: 1,
                        strokeOpacity: 1,
                        scale: 6
                    },
                    map: nextProps.map,
                    position: nextProps.locationData[nextProps.ticket.ticketIndex]
                });
                nextProps.map.panTo(this.speedpoint.position);
            }
        }
        if (!this.selectedpoint) {
            this.selectedpoint = new window.google.maps.Marker({
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    strokeColor: 'black',
                    fillColor: 'green',
                    fillOpacity: 0.9,
                    strokeWeight: 1,
                    strokeOpacity: 1,
                    scale: 5
                },
                map: nextProps.map,
            });
        }
        if (nextProps.ticket !== this.props.ticket) {
            this.speedpoint.setPosition(nextProps.locationData[nextProps.ticket.ticketIndex]);
            console.log('set speedpoint');
            nextProps.map.panTo(this.speedpoint.position);
        }
        if (this.props.locationData && nextProps.locationData.length > this.props.locationData.length) {
        }
        if (nextProps.replayTime !== this.props.replayTime) {
            if (nextProps.replayTime !== null) {
                this.selectedpoint.setPosition(nextProps.locationData.find(i => i.t >= nextProps.replayTime));
            }
        }

        return true;
    }

    confChange = (what) => (event) => {
        if (what === "secondrow") {
            this.setState({ [what]: event.target.value })
        }
    }
    render() {
        console.log('in re', this.state.secondrow);
        return ([
            <SpeedTrackConfDialog key="spdconf"
                secondrow={this.state.secondrow}
                handleChange={this.confChange} />,
            <RightDrawer key="pointlist"
                secondrow={this.state.secondrow}
                tid={this.props.teamid}
                devid={this.props.devid} />]);
    }
}

const mapStateToProps = (state, ownProps) => ({
    locationData: ((state.locationData[ownProps.teamid] || {})[ownProps.devid] || {}).points,
    ticket: state.appState.showSpeedingTicket,
    replayTime: state.replayData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setReplayTime: (t) => dispatch(setReplayTime(t)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeedTrackOnMap);