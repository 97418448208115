import * as types from './actionTypes';
import { createBrowserHistory } from 'history'
import update, { extend } from 'immutability-helper';
import CheapRuler from 'cheap-ruler';
import isEqual from 'lodash/isEqual'
const initialState = require('./initialState.json');

extend('$auto', function (value, object) {
    return object ?
        update(object, value) :
        update({}, value);
});
extend('$autoArray', function (value, object) {
    return object ?
        update(object, value) :
        update([], value);
});

export function eventId(state = initialState.eventId, action) {
    switch (action.type) {
        case types.SET_EVENT_ID:
            return action.eid;
        default:
            return state;
    }
}

export function appState(state = initialState.appState, action) {
    switch (action.type) {
        case types.OPEN_DIALOG:
            return Object.assign({}, state, { [action.name + 'Open']: true });
        case types.CLOSE_DIALOG:
            return Object.assign({}, state, { [action.name + 'Open']: false });
        case types.OPEN_SEND_MSG:
            return Object.assign({}, state, { sendMsgOpen: true, targetTeamId: action.teamid });
        case types.SET_EDIT_TEAM_ID:
            return Object.assign({}, state, { editteamid: action.teamid });
        case types.SET_EDIT_KP_ID:
            return Object.assign({}, state, { editkpid: action.kpid });
        case types.SET_VIEW_TEAM_RESULT:
            return Object.assign({}, state, { teamidforresult: action.teamid });
        case types.TOGGLE_OVERLAY:
            let ho = Object.assign({}, state.hiddenOverlays);
            ho[action.id] = !ho[action.id];
            return Object.assign({}, state, { hiddenOverlays: ho })
        case types.SET_SELECTED_AREA:
            return Object.assign({}, state, { selectedArea: action.akey });
        case types.HIDE_TEAM:
            return update(state, { hiddenTeams: { [action.id]: { $set: action.hide } } });
        case types.SET_HIDDEN_TEAMS:
            let v = {};
            action.teamids.forEach(tid => {
                v[tid] = true;
            })
            return update(state, { hiddenTeams: { $set: v } });
        case types.ZOOM_TO_TRACK:
            let a = update(state, {
                zoomToTrack: {
                    $auto: {
                        [action.tid]: {
                            $auto: {
                                [action.devid]: {
                                    $apply: (x) => (x ? x : 0) + 1
                                }
                            }
                        }
                    }

                }
            })
            return a;
        case types.EDIT_OVERLAY_ID:
            return Object.assign({}, state, { editoverlayid: action.id });
        case types.ADJUST_OVERLAY_STATE:
            return Object.assign({}, state, { adjustoverlaylocstate: action.state });
        case types.SET_TRACK_TYPE:
            return Object.assign({}, state, { trackType: action.tracktype });
        case types.SET_REPLAY_TYPE:
            return Object.assign({}, state, { replayType: action.replaytype });
        case types.SET_TRACK_TAIL_LENGTH:
            return Object.assign({}, state, { trackTailLength: action.length });
        case types.SHOW_SPEEDING_TICKET:
            if (action.ticket === null)
                return Object.assign({}, state, { showSingleTrack: null, showSpeedingTicket: null });
            return Object.assign({}, state, { showSpeedingTicket: action.ticket, showSingleTrack: { tid: action.ticket.tid, devid: action.ticket.dev } });
        case types.SHOW_SINGLE_TRACK:
            if (action.stop)
                return Object.assign({}, state, { showSingleTrack: null });
            return Object.assign({}, state, { showSingleTrack: { tid: action.tid, devid: action.devid } });
        case types.SET_SHOW_KPS:
            return Object.assign({}, state, { showKPs: action.showKPs });
        default:
            return state;
    }
}

export function users(state = initialState.events, action) {
    switch (action.type) {
        case types.SET_USERS_LIST:
            return action.data;
        default:
            return state;
    }
}

export function adminUsers(state = initialState.events, action) {
    switch (action.type) {
        case types.SET_ADMINUSERS_LIST:
            return action.data;
        default:
            return state;
    }
}

export function events(state = initialState.events, action) {
    switch (action.type) {
        case types.GET_EVENTS:
            return state;
        case types.SET_EVENTS_LIST:
            let currentGroup = []
            let newEventList = [];
            action.data.forEach(element => {
                if (currentGroup.length > 0 && currentGroup[0].year !== element.year) {
                    newEventList.push(currentGroup);
                    currentGroup = [];
                }
                currentGroup.push(element)
            });
            newEventList.push(currentGroup);
            return newEventList;
        default:
            return state;
    }
}

export function series(state = initialState.series, action) {
    switch (action.type) {
        case types.SET_SERIES:
            return action.data;
        default:
            return state;
    }
}

export function currentEvent(state = initialState.currentEvent, action) {
    switch (action.type) {
        case 'eventlist_value':
            return Object.assign({}, state, action.v);
        case 'eventlist_added': case 'eventlist_changed': case 'eventdata_added': case 'eventdata_changed':
            return Object.assign({}, state, { [action.k]: action.v });
        case 'eventlist_removed':
            // should actually never remove keys in event list item
            return state;
        case 'eventdata_value':
            return Object.assign({ eventdataready: true }, state, action.v);
        case 'eventdata_removed':
            const { [action.k]: value, ...newstate } = state;
            return newstate;
        case types.CURRENT_EVENT_CLEAR:
            return {};
        default:
            return state;
    }
}

export function colorList(state = initialState.colorList, action) {
    switch (action.type) {
        case types.SET_COLOR_LIST:
            return action.list;
        default:
            return state;
    }
}

export function eventAccess(state = initialState.eventAccess, action) {
    switch (action.type) {
        case types.EVENT_ACCESS:
            return action.acc;
        default:
            return state;
    }
}

export function alertMsg(state = initialState.alertMsg, action) {
    switch (action.type) {
        case types.SHOW_ALERT:
            return action.alertMsg;
        case types.CLEAR_ALERT:
            return {};
        default:
            return state;
    }
}

export function firebaseObjectList(prefix) {
    return (state = initialState[prefix], action) => {
        switch (action.type) {
            case prefix + '_added': case prefix + '_changed':
                return Object.assign({}, state, { [action.k]: action.v });
            case prefix + '_removed':
                delete state[action.k];
                return Object.assign({}, state);
            case prefix + '_value':
                if (action.v === null)
                    return {};
                return action.v;
            default:
                return state;
        }
    }
}

export function speedConf(state = initialState.speedConf, action) {
    switch (action.type) {
        case types.SET_EVENT_DOC:
            return Object.assign({}, state, action.doc.speed);
        default:
            return state;
    }
}

export function eventSpeedAreas(state = initialState.eventSpeedAreas, action) {
    switch (action.type) {
        case types.SET_EVENT_DOC:
            let changed = false;
            if ('speedareas' in action.doc) {
                if (!window.google) {
                    console.log('Speedareas is broken !!!!!!!!!!!!! Fix me!!!!!!!!!!!!!')
                    return state;
                }
                // There is a bug. we might get here before we have window.google.maps
                Object.entries(action.doc.speedareas).forEach(([k, v]) => {
                    let path = v.area.map(e => { return { lat: e.latitude, lng: e.longitude } });
                    if (path[0].lat === path[path.length - 1].lat &&
                        path[0].lon === path[path.length - 1].lon) {
                        path.pop();
                    }
                    v.poly = new window.google.maps.Polygon({
                        paths: path,
                        map: window.bigmap.map.map.map_,
                        visible: false
                    });
                    if (k in state && isEqual(v, state[k]))
                        return;
                    state[k] = v;
                    changed = true;
                })
                Object.keys(state).forEach(k => {
                    if (k in action.doc.speedareas)
                        return;
                    delete state[k];
                    changed = true;
                })
            }
            if (!changed)
                return state;
            return Object.assign({}, state);
        case types.EVENTSPEEDAREAS:
            return action.data;
        case types.EVENTSPEEDAREAUPDATE: {
            console.log("In reducer eevntspeed update: ", action);
            let found = false;
            let ns = state.map(e => {
                if (e.id !== action.data.id)
                    return e;
                found = true;
                return action.data;
            });
            if (!found)
                ns.push(action.data);
            return ns;
        }
        case types.EVENTSPEEDAREADELETE:
            return state.filter(e => (!action.data.includes(e.id)));
        default:
            return state;
    }
}

export function speedAreas(state = initialState.speedAreas, action) {
    switch (action.type) {
        case types.UPDATE_SPEEDAREA_DOC: {
            if (!(action.key in state)) {
                state[action.key] = {};
            }
            let d = state[action.key];
            Object.entries(action.doc.speedareas).forEach(([k, a]) => {
                if (k in d) {
                    if (!isEqual(d[k], a)) {
                        d[k] = a;
                    }
                } else {
                    d[k] = a;
                }
            });
            let slist = []
            Object.entries(state).forEach(([k, v]) => {
                if (k === 'sortedlist')
                    return;
                Object.keys(v).forEach((aid) => {
                    slist.push({ owner: k, id: aid });
                })
            });
            slist.sort((l, r) => ('' + state[l.owner][l.id].name).localeCompare(state[r.owner][r.id].name));
            state.sortedlist = slist;
            return Object.assign({}, state);
        }
        case types.SPEEDAREAS:
            state.forEach(a => {
                a.poly.setMap(null);
                delete a.poly;
            });
            for (let it of action.data) {
                let path = it.area.coordinates[0].map(e => { return { lat: e[1], lng: e[0] } });
                if (path[0].lat === path[path.length - 1].lat &&
                    path[0].lon === path[path.length - 1].lon) {
                    path.pop();
                }
                it.poly = new window.google.maps.Polygon({
                    paths: path,
                    map: window.bigmap.map.map.map_,
                    visible: false
                });
            }
            return action.data;
        case types.SPEEDAREAUPDATE:
            let ns = state.map(e => {
                if (e.id !== action.data.id)
                    return e;
                Object.assign(e, action.data);
                let p = e.poly.getPath();
                p.disableUpdates = true;
                p.clear();
                action.data.area.coordinates[0].forEach(c => p.push(new window.google.maps.LatLng(c[1], c[0])));
                p.disableUpdates = false;
                return e;
            });
            // TODO when new area is added should this be shown ?
            return ns;
        case types.SPEEDAREADELETE:
            return state.filter(e => {
                if (!action.data.includes(e.id))
                    return true;
                e.poly.setMap(null);
                delete e.poly;
                return false;
            });
        default:
            return state;
    }
}

export function speedingsData(state = initialState.speedingsData, action) {
    switch (action.type) {
        case types.SPEEDINGSDATA:
            if (action.data === "querying")
                return action.data;
            let newstate = (action.incremental ? JSON.parse(JSON.stringify(state)) : action.data);
            if (action.incremental) {
                action.data.forEach(ne => {
                    let index = newstate.findIndex(e => e.aid === ne.aid);
                    if (index === -1)
                        newstate.push(ne);
                    else
                        newstate[index] = Object.assign(ne, { seen: true });
                })
            } else {
                newstate.forEach(e => e.seen = true);
            }
            return newstate.sort((l, r) => r.tstamps - l.tstamps);
        default:
            return state;
    }
}

export function teamsListOld(state = initialState.teamsList, action) {
    switch (action.type) {
        case types.LOCATION_DATA_CLEAR: {
            return {}
        }
        case types.EVENT_TEAM_UPDATE:
            console.log(action);
            return Object.assign({}, state, action.data);
        case types.EVENT_TEAMS_AVAILABLE:
            return action.data.teams;
        default:
            return state;
    }
}

/*
function decode(s) {
    if (s === undefined)
        return s;
    var resp = [];
    var v = 0;
    var c = 0;
    for (var i = 0; i < s.length; i++) {
        var t = s.charCodeAt(i) - 63;
        if (t === 0) {
            i++;
            if (i < s.length) {
                var nl = s.charCodeAt(i) - 63;
                while (nl--)
                    resp.push(0);
                continue;
            }
        } else if (t & 0x20) {
            v |= (t & 0x1f) << 5 * c;
            c++;
        } else {
            if (c > 5)
                v += (t * Math.pow(2, 5 * c));
            else
                v |= t << 5 * c;
            if (v & 1)
                v = ~v;
            if (c > 5)
                v /= 2;
            else
                v = v >> 1;
            resp.push(v);
            v = 0;
            c = 0;
        }
    }
    return resp;
}*/


function insertpoints(arr, at, points) {
    if (points.length === 0)
        return;
    arr.splice(at, 0, ...points);
    const ruler = new CheapRuler(arr[0].lat(), 'meters');
    if (at === 0) {
        arr[0].d = 0;
        at++;
    }
    for (let i = at; i < arr.length; i++) {
        arr[i].d = arr[i - 1].d + ruler.distance([arr[i - 1].lng(), arr[i - 1].lat()], [arr[i].lng(), arr[i].lat()]);
    }
}

let ruler = null;
export function locationData(state = initialState.locationData, action) {
    switch (action.type) {
        case types.LOCATION_DATA_CLEAR: {
            return {};
        }
        case types.FINAL_LOCATION_DATA:
            if (!(action.tid in state)) {
                state[action.tid] = {}
                Object.assign(state, { [action.tid]: { [action.devid]: { points: [] } } })
            }
            if (!(action.devid in state[action.tid])) {
                state[action.tid][action.devid] = { points: [] };
            }

            state[action.tid][action.devid] = Object.assign({}, { points: action.points });
            return Object.assign({}, state);
        case types.TRACK_SEGMENT:
            if (!(action.tid in state)) {
                state[action.tid] = {}
                Object.assign(state, { [action.tid]: { [action.devid]: { points: [] } } })
            }
            if (!(action.devid in state[action.tid])) {
                state[action.tid][action.devid] = { points: [] };
            }
            let points = state[action.tid][action.devid].points;
            const startt = action.points[0].t;
            let idx = 0;
            while (idx < points.length && points[idx].t < startt)
                idx++;
            const endt = (idx + 1 < points.length ? points[idx + 1].t : null);

            insertpoints(points, idx, action.points);
            if (endt && action.points[action.points.length - 1].t > endt) {
                points = points.sort((l, r) => Number(l.t) - Number(r.t));
                const lruler = new CheapRuler(points[0].lat(), 'meters');
                points[0].d = 0;
                for (let i = 1; i < points.length; i++) {
                    points[i].d = points[i - 1].d + lruler.distance([points[i - 1].lng(), points[i - 1].lat()], [points[i].lng(), points[i].lat()]);
                }
            }
            state[action.tid][action.devid] = Object.assign({}, { points: points });
            return Object.assign({}, state);
        case types.LOCATION_POINT:
            if (!(action.tid in state)) {
                state[action.tid] = {}
                Object.assign(state, { [action.tid]: { [action.devid]: { points: [] } } })
            }
            if (!(action.devid in state[action.tid])) {
                state[action.tid][action.devid] = { points: [] };
            }
            const lda = state[action.tid][action.devid].points;

            const t = parseInt(action.time, 10);
            let lng = action.point.x; let lat = action.point.y;
            delete action.point.x; delete action.point.y;
            if (!ruler)
                ruler = new CheapRuler(lat, 'meters');
            const point = Object.assign(new window.google.maps.LatLng(lat, lng), action.point, { t: t });
            if (lda.length === 0 || t > lda[lda.length - 1].t) {
                // At the end
                if (lda.length > 0)
                    point.d = lda[lda.length - 1].d + ruler.distance([lda[lda.length - 1].lng(), lda[lda.length - 1].lat()], [lng, lat]);
                else
                    point.d = 0;
                lda.push(point);
            } else {
                let at = lda.findIndex(el => el.t >= t);
                lda.splice(at, 0, point);
                if (at === 0) {
                    lda[0].d = 0;
                    at++;
                }
                for (let i = at; i < lda.length; i++) {
                    lda[i].d = lda[i - 1].d + ruler.distance([lda[i - 1].lng(), lda[i - 1].lat()], [lda[i].lng(), lda[i].lat()]);
                }
            }
            //console.log('lda len:', lda.length);
            state[action.tid][action.devid] = Object.assign({}, state[action.tid][action.devid]);
            return Object.assign({}, state);
        case types.LOCATION_DATA:
            /*
            if (action.tid === 'u31XLYs9Q0YjFmazxah3hlgholY2')
                debugger;
                */
            if (!(action.tid in state)) {
                state[action.tid] = {}
                Object.assign(state, { [action.tid]: { [action.devid]: { points: [] } } })
            }
            if (!(action.devid in state[action.tid])) {
                state[action.tid][action.devid] = { points: [] };
            }
            let la = state[action.tid][action.devid].points;

            Object.entries(action.points).forEach(([prefix, v]) => {
                let parr = []; let insertpoint = undefined; let nextt = undefined;
                Object.entries(v).sort(([l, _vl], [r, _vr]) => Number(l) - Number(r)).forEach(([sufix, p]) => {

                    p.t = Number(prefix + sufix);
                    let lng = p.x; let lat = p.y;
                    if (p.x !== undefined) {
                        delete p.x; delete p.y;
                    } else {
                        lng = p.p.longitude || p.p._longitude;
                        lat = p.p.latitude || p.p._latitude;
                        delete p.p;
                    }
                    if (nextt !== undefined && p.t > nextt) {
                        insertpoints(la, insertpoint, parr);
                        parr = [];
                        insertpoint = undefined; nextt = undefined;
                    }
                    if (insertpoint === undefined) {
                        insertpoint = la.findIndex(el => el.t >= p.t);
                        if (insertpoint === -1) {
                            insertpoint = la.length;
                        } else if (la[insertpoint].t === p.t) {
                            insertpoint = undefined;
                            return;
                        } else if (insertpoint !== la.length) {
                            nextt = la[insertpoint].t;
                        }
                    }
                    parr.push(Object.assign(new window.google.maps.LatLng(lat, lng), p));
                });
                insertpoints(la, insertpoint, parr);
            })
            state[action.tid][action.devid] = Object.assign({}, state[action.tid][action.devid]);
            return Object.assign({}, state);
        //            return Object.assign({}, state, {[action.tid]: []})
        case types.SET_TEAM_TRACKS:
            return Object.assign({}, state, { [action.tid]: action.tracks });
        case types.CLEAR_TEAM_TRACKS:
            return ({ ...state, [action.tid]: undefined });
        default:
            return state;
    }
}

export function currentEventData(state = initialState.currentEventData, action) {
    //console.log(action);
    switch (action.type) {
        case types.EVENT_DATA_AVAILABLE:
            const history = createBrowserHistory()
            if (history.location.pathname.indexOf(action.data.id) === -1)
                history.replace('/ev/' + action.data.id);
            return action.data;
        default:
            return state;
    }
}

export function snackBarState(state = initialState.snackBarState, action) {
    switch (action.type) {
        case types.ADD_UNDO: {
            let hist = state.history.slice();
            if (hist.length > 0 && hist[hist.length - 1].id === action.id) {
                // Do we want to update anything. Mayb everything except value
            } else {
                hist.push(action);
            }
            return Object.assign({}, state, { open: true, history: hist });
        }
        case types.POP_UNDO_TASK: {
            let hist = state.history.slice();
            hist.pop();
            return Object.assign({}, state, { open: (hist.length > 0), history: hist });
        }
        case types.CLOSE_UNDO_SNACKBAR:
            return Object.assign({}, state, { open: false });
        default:
            return state;
    }
}

export function saveInProgress(state = initialState.saveInProgress, action) {
    switch (action.type) {
        case types.ADD_SAVE_STATE:
            return state + 1;
        case types.RM_SAVE_STATE:
            return state - 1;
        default:
            return state;
    }
}

export function replayData(state = initialState.replayData, action) {
    switch (action.type) {
        case types.SET_REPLAY_TIME:
            return action.data;
        default:
            return state;
    }
}
