import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import firebase from 'firebase/app';

import moment from 'moment';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    background: 'inherit',
  },
  listSubHeader: {
    fontSize: 24,
    fontWeight: 700,
  }
});

class OpenEventDialog extends Component {

  state = {
    selectedseries: "-",
    events: []
  }
  constructor(props, context) {
    super(props, context);
    this.eventsref = firebase.database().ref('events');
    this.eventsref.on('value', snap => {
      let currentGroup = []
      let newEventList = [];
      const dd = snap.val();
      Object.keys(dd).sort((l, r) => dd[r].starttime - dd[l].starttime).forEach(eid => {
        dd[eid].year = new Date(dd[eid].starttime).getFullYear();
        dd[eid].id = eid;
        if (currentGroup.length > 0 && currentGroup[0].year !== dd[eid].year) {
          newEventList.push(currentGroup);
          currentGroup = [];
        }
        currentGroup.push(dd[eid])
      });
      newEventList.push(currentGroup);
      this.setState({ events: newEventList });
    }, e => {
      console.log('got error', e);
    });
  }

  componentWillUnmount() {
    if (this.eventsref)
      this.eventsref.off();
  }

  selectSeries = (v) => {
    this.setState({ selectedseries: v.target.value });
  }
  render() {
    const { classes, series } = this.props;

    let s = series.find(s => s.id === this.state.selectedseries);
    let events = (s ? this.state.events.map(y => {
      return y.filter(ev => s.events.includes(ev.id))
    }).filter(y => y.length > 0) : this.state.events);
    console.log(events);
    return (
      <Dialog
        open={true}
        onClose={() => this.props.handleClose(null)}
        aria-labelledby="open-event-title"
      >
        <DialogTitle id="open-event-title">Open event</DialogTitle>
        <DialogContent>
          <FormControl>
            <Select
              value={this.state.selectedseries}
              onChange={this.selectSeries}
            >
              <MenuItem value="-">
                <em>Kõik võistlused</em>
              </MenuItem>
              {series.map(e => (
                <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
              ))}

            </Select>
          </FormControl>
          {events.length === 0 ?
            (<div><CircularProgress /></div>) :
            (<List className={classes.root} subheader={<div />}>
              {events.map(yearevents => (
                <div key={`year-${yearevents[0].year}`} className={classes.listSection}>
                  <ListSubheader className={classes.listSubHeader}>{yearevents[0].year}</ListSubheader>
                  {yearevents.map(event => (
                    <ListItem button key={event.id} onClick={() => this.props.handleClose(event)}>
                      <ListItemText primary={event.name} secondary={moment(event.starttime).format('D MMM')} />
                    </ListItem>
                  ))}
                </div>
              ))}
            </List>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose(null)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  series: state.series
})


export default connect(mapStateToProps)(withStyles(styles)(OpenEventDialog));
