import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AlertDialog from './AlertDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


const styles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

class EventClassesEditor extends Component {

    constructor(props) {
        super(props);
        var defclassname = "Osalejad";
        if (props.klassid && Object.keys(props.klassid).some(it => props.klassid[it] === defclassname))
            defclassname = "";
        this.state = {
            newclassname: defclassname,
            showAlert: false
        }
    }

    addClass = () => {
        console.log('add class');
        console.log(this.state.newclassname);
        if (this.props.klassid && Object.keys(this.props.klassid).some(it => this.props.klassid[it] === this.state.newclassname)) {
            this.setState({ showAlert: true })
            return;
        }
        this.props.addClass(this.state.newclassname);
        this.setState({ newclassname: "" });
    }
    render() {
        const { classes, klassid, autojoinclass, teamchoosesclass } = this.props;


        return (
            <div className={classes.root}>
                <TextField
                    id="newclassname"
                    label="Class name"
                    value={this.state.newclassname}
                    onChange={event => { this.setState({ newclassname: event.target.value }) }}
                />
                <Button variant="contained" color="primary" onClick={this.addClass} className={classes.button}>
                    Add class
            </Button>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={teamchoosesclass}
                            checked={Boolean(autojoinclass)}
                            onChange={() => this.props.fieldChanged('autojoinclass', !Boolean(autojoinclass))}
                        />
                    }
                    label="Teamid lisatakse klassidesse automaatselt"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={Boolean(teamchoosesclass)}
                            onChange={() => {
                                if (autojoinclass)
                                    this.props.fieldChanged('autojoinclass', false);
                                this.props.fieldChanged('teamchoosesclass', !Boolean(teamchoosesclass))
                            }}
                        />
                    }
                    label="Teamid saavad ise valida klassi"
                />
                {klassid && (
                    <List>
                        {Object.entries(klassid).map(([tahis, name]) => {
                            return (
                                <ListItem key={tahis}>
                                    <ListItemText primary={name} />
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="Delete" onClick={() => this.props.removeClass(tahis)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>)}
                {this.state.showAlert && <AlertDialog
                    open={this.state.showAlert}
                    onClose={() => this.setState({ showAlert: false })}
                    title="Classes with same name"
                    text="All classes should have a different name"
                />}
            </div>
        )
    }
}


export default withStyles(styles, { withTheme: true })(EventClassesEditor);

