export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const CURRENT_EVENT_CLEAR = 'CURRENT_EVENT_CLEAR';
export const GET_EVENTS = 'GET_EVENTS';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const SET_SERIES = 'SET_SERIES';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_ADMINUSERS_LIST = 'SET_ADMINUSERS_LIST';
export const EVENT_DATA_AVAILABLE = 'EVENT_DATA_AVAILABLE';
export const EVENT_TEAMS_AVAILABLE = 'EVENT_TEAMS_AVAILABLE';
export const EVENT_KPS_AVAILABLE = 'EVENT_KPS_AVAILABLE';
export const EVENT_TEAM_UPDATE = 'EVENT_TEAM_UPDATE';
export const EVENT_KP_UPDATE = 'EVENT_KP_UPDATE';
export const SET_EVENT_NAME = 'SET_EVENT_NAME';
export const SET_KP_PROP = 'SET_KP_PROP';
export const SET_KP_LIST = 'SET_KP_LIST';
export const SET_TEAM_TRACKS = 'SET_TEAM_TRACKS';
export const CLEAR_TEAM_TRACKS = 'CLEAR_TEAM_TRACKS';
export const SET_TEAM_LIST = 'SET_TEAM_LIST';
export const ADD_SAVE_STATE = 'ADD_SAVE_STATE';
export const RM_SAVE_STATE = 'RM_SAVE_STATE';
export const CLOSE_UNDO_SNACKBAR = 'CLOSE_UNDO_SNACKBAR';
export const ADD_UNDO = 'ADD_UNDO';
export const POP_UNDO_TASK = 'POP_UNDO_TASK';
export const ADD_RACOEF = 'ADD_RACOEF';
export const RM_RACOEF = 'RM_RACOEF';
export const ADD_KLASS = 'ADD_KLASS';
export const RM_KLASS = 'RM_KLASS';
export const SET_LY_POINTS = 'SET_LY_POINTS';
export const CHANGE_COMPLETE = 'CHANGE_COMPLETE';
export const UPDATE_VALIKAEG = 'UPDATE_VALIKAEG';
export const ADD_VALIKAEG = 'ADD_VALIKAEG';
export const RM_VALIKAEG = 'RM_VALIKAEG';
export const LOCATION_DATA = 'LOCATION_DATA';
export const LOCATION_DATA_CLEAR = 'LOCATION_DATA_CLEAR';
export const FINAL_LOCATION_DATA = 'FINAL_LOCATION_DATA';
export const TRACK_SEGMENT = 'TRACK_SEGMENT';
export const LOCATION_POINT = 'LOCATION_POINT';
export const LOCATION_UPDATES = 'LOCATION_UPDATES';
export const UPDATE_SPEEDAREA_DOC = 'UPDATE_SPEEDAREA_DOC';
export const SPEEDINGSDATA = 'SPEEDINGSDATA';
export const SPEEDAREAS = 'SPEEDAREAS';
export const SPEEDAREAUPDATE = 'SPEEDAREAUPDATE';
export const SPEEDAREADELETE = 'SPEEDAREADELETE';
export const EVENTSPEEDAREAS = 'EVENTSPEEDAREAS';
export const EVENTSPEEDAREAUPDATE = 'EVENTSPEEDAREAUPDATE';
export const EVENTSPEEDAREADELETE = 'EVENTSPEEDAREADELETE';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SET_EDIT_KP_ID = 'SET_EDIT_KP_ID';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_EDIT_TEAM_ID = 'SET_EDIT_TEAM_ID';
export const SET_REPLAY_TIME = 'SET_REPLAY_TIME';
export const SET_TRACK_TYPE = 'SET_TRACK_TYPE';
export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const EDIT_OVERLAY_ID = 'EDIT_OVERLAY_ID';
export const ADJUST_OVERLAY_STATE = 'ADJUST_OVERLAY_STATE';
export const SAVE_OVERLAY_CHANGES = 'SAVE_OVERLAY_CHANGES';
export const ADD_KP_RESPONSE = 'ADD_KP_RESPONSE';
export const RM_KP_RESPONSE = 'RM_KP_RESPONSE';
export const SET_VIEW_TEAM_RESULT = 'SET_VIEW_TEAM_RESULT';
export const HIDE_TEAM = 'HIDE_TAEM';
export const SET_HIDDEN_TEAMS = 'SET_HIDDEN_TEAMS';
export const ZOOM_TO_TRACK = 'ZOOM_TO_TRACK';
export const SET_TRACK_TAIL_LENGTH = 'SET_TRACK_TAIL_LENGTH';
export const SET_REPLAY_TYPE = 'SET_REPLAY_TYPE';
export const SHOW_SPEEDING_TICKET = 'SHOW_SPEEDING_TICKET';
export const SHOW_SINGLE_TRACK = 'SHOW_SINGLE_TRACK';
export const EVENT_ACCESS = 'EVENT_ACCESS';
export const SET_COLOR_LIST = 'SET_COLOR_LIST';
export const SET_SHOW_KPS = 'SET_SHOW_KPS';
export const SET_SELECTED_AREA = 'SET_SELECTED_AREA';
export const SET_EVENT_DOC = 'SET_EVENT_DOC';
export const OPEN_SEND_MSG = 'OPEN_SEND_MSG';
