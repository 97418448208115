import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tooltip } from '@material-ui/core';
import firebase from 'firebase/app';

const appMessagePrefix = '<style>\n#root {width:100%; height: 100%;}\n#container {position: absolute; top: 40%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%); padding: 20px}\n</style>\n\n<div id="root">\n <div id="container">\n';
const appMessageSuffix = '</div>\n</div>';

const evconfContext = React.createContext();

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    margin: theme.spacing(1),
  },
  appMessage: {
    minWidth: 400
  },
  editor: {
    border: '1px solid #F1F1F1 !important',
    padding: '5px !important',
    borderRadius: '2px !important',
    height: '275px',
  }
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class FeatureConf extends React.Component {
  static contextType = evconfContext;
  render() {

    return (<HtmlTooltip title={this.props.tooltip}><FormControlLabel
      control={
        <Checkbox disabled={this.context.conf === undefined} checked={Boolean((this.context.conf || {})[this.props.var])} onChange={(event) => {
          this.context.ref.child(this.props.var).feedbackset(event.target.checked);
        }} />
      }
      label={this.props.label}
    /></HtmlTooltip>);
  }
}

/*
.demo-editor {

  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}*/
class BasicEventDataEditor extends Component {

  htmlToEditorState(html) {
    if (!html)
      return EditorState.createEmpty();

    const blocksFromHTML = convertFromHTML(html);
    return EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    ));
  }
  constructor(props) {
    super();
    let centerAppMessage = false;
    let msg = props.eventdata.appmessage;

    if (msg && msg.startsWith(appMessagePrefix)) {
      centerAppMessage = true;
      msg = msg.slice(appMessagePrefix.length);
      if (msg.endsWith(appMessageSuffix))
        msg = msg.slice(0, -appMessageSuffix.length);
    }
    this.state = {
      evconf: undefined,
      editorState: this.htmlToEditorState(msg),
      centerAppMessage: centerAppMessage
    }
  }

  componentDidMount() {
    this.confreff = firebase.database().ref('eventsdata').child(this.props.eventId).child('conf');

    this.confreff.on('value', (snap) => {
      this.setState({ evconf: snap.val() || {} });
    });
  }

  componentWillUnmount() {
    this.confreff.off();
  }

  componentDidUpdate(prevProps) {
    if (this.props.eventdata.appmessage !== prevProps.eventdata.appmessage) {
      let centerAppMessage = false;
      let msg = this.props.eventdata.appmessage;
      if (msg && msg.startsWith(appMessagePrefix)) {
        centerAppMessage = true;
        msg = msg.slice(appMessagePrefix.length);
        if (msg.endsWith(appMessageSuffix))
          msg = msg.slice(0, -appMessageSuffix.length);
      }
      if (this.state.centerAppMessage !== centerAppMessage) {
        this.setState({ centerAppMessage: centerAppMessage })
      }
      let editortext = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      if (editortext !== msg) {
        // TODO... Do update only after asking user if it's ok. Or if there are no changes
        const blocksFromHTML = convertFromHTML(msg);
        const foreditor = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        this.setState({
          editorState: EditorState.createWithContent(foreditor),
        });
      }
    }
  }

  onEditorStateChange = (editorState) => {
    //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    this.setState({
      editorState,
    });
  };

  saveAppMessage = () => {
    let msg = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    if (this.state.centerAppMessage)
      msg = appMessagePrefix + msg + appMessageSuffix;
    this.props.fieldChanged('appmessage', msg);
  }

  appMessageCenterChange = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      let msg = appMessagePrefix + this.props.eventdata.appmessage + appMessageSuffix;
      this.props.fieldChanged('appmessage', msg);
    } else {
      let msg = this.props.eventdata.appmessage;
      if (msg.startsWith(appMessagePrefix)) {
        msg = msg.slice(appMessagePrefix.length);
        if (msg.endsWith(appMessageSuffix))
          msg = msg.slice(0, -appMessageSuffix.length);
      }
      this.props.fieldChanged('appmessage', msg);
    }
    this.setState({ centerAppMessage: event.target.checked });
  }

  render() {
    const { classes, eventId } = this.props;

    //const appmessage = (eventdata.appmessage ? eventdata.appmessage : "<style>\n#root {width:100%; height: 100%;}\n#container {position: absolute; top: 40%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%); padding: 20px}\n</style>\n\n<div id=\"root\">\n <div id=\"container\">\n<center><h1>Pealkiri</h1></center>\nSiia saab kirjutada mida kasutajad peavad edasi tegema kui app on käivitatud ja võistlusega ühinetud\n </div>\n</div>");

    let appMessageChanged = (draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) !== this.props.eventdata.appmessage)

    return (
      <form noValidate autoComplete="off">
        <evconfContext.Provider value={{ eventId: eventId, conf: this.state.evconf, ref: this.confreff }}>
          <FeatureConf
            label="Uus Info widget"
            var="featuretimetogo"
            tooltip={(<React.Fragment><h3>Uus Info widget</h3>Info widget mis näitab palju aega finishini või aeg stardist,
          mittu kp-d on võistlusel ja mittu KP-d on võistleja ära võtunud,
          võistleja poolt läbitud teekonna pikkus.
          Info widget on kp nimekirja peal.</React.Fragment>)}
          />
        </evconfContext.Provider>
        {/*
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="gpspriority">GPS priority</InputLabel>
          <Select
            value={eventdata.gpspriority}
            onChange={this.handleChange('gpspriority')}
            input={<Input name="gpspriority" id="gpspriority" />}
          >
            <MenuItem value="high">High Accuracy</MenuItem>
            <MenuItem value="balancedpower">Balanced Power Accuracy</MenuItem>
          </Select>
          <FormHelperText>Check Androids LocationRequest</FormHelperText>
        </FormControl>
        <TextField
          id="gpsinterval"
          label="GPS Interval"
          value={eventdata.gpsinterval}
          onChange={this.handleChange('gpsinterval')}
          type="number"
          className={classes.textField}
          inputProps={{ step: "1000" }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          helperText="GPS tracking frequency in ms"
        />

        <TextField
          id="gpsinterval"
          label="GPS Fastest Interval"
          value={eventdata.gpsfastinterval}
          onChange={this.handleChange('gpsfastinterval')}
          type="number"
          className={classes.textField}
          inputProps={{ step: "1000" }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          helperText="Fastest frequency in ms"
        />
        */}
        <div>
          <Button variant="contained" disabled={!appMessageChanged} onClick={this.saveAppMessage}>Save app messag</Button>
          <FormControlLabel
            control={
              <Checkbox checked={this.state.centerAppMessage} onChange={this.appMessageCenterChange} />
            }
            label="Sõnum on telefoni keskel"
          />
          <Editor
            wrapperClassName="demo-wrapper"
            editorClassName={classes.editor}
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>
      </form>
    )
  }
}

export default withStyles(styles, { withTheme: true })(BasicEventDataEditor);
