import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  smallnr: {
    width: '2em',
    textAlign: 'center'
  },
  simpleInput: {
    whiteSpace: 'nowrap'
  }
});


class ValikTimesEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chosenClass: (Object.keys(props.klassid || {}).length > 0 ? Object.keys(props.klassid || {})[0] : ""),
      addnormaalaeg: '02:30:00',
      addnlisaaeg: 3,
      addlisaaeg: '00:30:00',
      aeg: '00:30:00',
      trahvipunkte: 1,
      trahviaeg: '00:01:00',
      editvalikaegid: null,
      editvalikaegklass: null
    }
  }

  handleChange = event => {
    console.log(event.target.name);
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state);
  };

  handleLocalChange = name => event => {
    console.log(this.state);
    // TODO Maybe should do some time validation here. for all the time fields.
    let value = event.target.value;
    if (name === "aeg" && value.length === 5)
      value = value + ":00";
    this.setState({
      [name]: value,
    });
  };

  handleQuickAdd = () => {
    this.props.addValikAeg(this.state.chosenClass, this.HHMMSSToSec(this.state.addnormaalaeg), 0, 0, 1);
    for (let i = 0; i < this.state.addnlisaaeg; i++) {
      this.props.addValikAeg(this.state.chosenClass, this.HHMMSSToSec(this.state.addlisaaeg), 1, (this.state.addnlisaaeg - i) * 60, 2 + i);
    }
  }

  handleAddAeg = () => {
    let diff = this.HHMMSSToSec(this.state.trahviaeg);
    let d = this.props.data[this.state.chosenClass];
    let ord = (d ? this.props.data[this.state.chosenClass][Object.keys(this.props.data[this.state.chosenClass]).sort((l, r) => d[r].order - d[l].order)[0]].order + 1 : 1);
    this.props.addValikAeg(this.state.chosenClass, this.HHMMSSToSec(this.state.aeg), Number(this.state.trahvipunkte), diff, ord);
  }

  handleSaveEdit = () => {
    let diff = this.HHMMSSToSec(this.state.trahviaeg);
    this.props.editValikAeg(this.state.editvalikaegklass, this.state.editvalikaegid, this.HHMMSSToSec(this.state.aeg), Number(this.state.trahvipunkte), diff, this.state.editord);
    this.setState({
      editvalikaegid: null,
      editvalikaegklass: null
    })
  }

  handleCancelEdit = () => {
    this.setState({
      editvalikaegid: null,
      editvalikaegklass: null
    })
  }

  editValikAeg = (klass, id) => {
    var el = this.props.data[klass][id];
    this.setState({
      editvalikaegid: id,
      editvalikaegklass: klass,
      aeg: this.secToHHMMSS(el.time),
      trahvipunkte: el.penalty,
      editord: el.order,
      trahviaeg: new Date(1000 * el.per).toISOString().substr(11, 8)
    })
  }

  secToHHMMSS = seconds => `${(Math.floor(seconds / 3600) + '').padStart(2, '0')}:${(Math.floor((seconds % 3600) / 60) + '').padStart(2, '0')}:${(Math.floor((seconds % 3600) % 60) + '').padStart(2, '0')}`
  HHMMSSToSec = str => { let a = str.split(':'); if (a.length === 2) { a.push(0) }; return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]) }

  formatValikTime = (it) => {
    if (Number(it.penalty) === 0)
      return 'Normaalaeg ' + this.secToHHMMSS(it.time);
    /*
    var datetime1 = new Date('1970-01-01T00:00:00Z');
    var datetime = new Date('1970-01-01T' + it.per + 'Z');
    var diff = (datetime - datetime1) / 60000;
    */
    let igastr = "";
    if (it.per === 60)
      igastr = "minuti eest";
    else if (it.per < 3600)
      igastr = new Date(it.per * 1000).toISOString().substr(14, 5) + ' eest'
    else
      igastr = new Date(it.per * 1000).toISOString().substr(11, 8) + ' eest'
    return 'Lisaaeg ' + this.secToHHMMSS(it.time) + '. ' + it.penalty + ' trahvi punkt' + (it.penalty > 1 ? 'i' : '') + ' iga algava ' + igastr;
  }

  render() {
    const { classes, data } = this.props;

    if (!this.props.klassid) {
        return "Use nutilogidev for times editing.";
    }
    const inputProps = {
      step: 1,
    };

    let classHasItems = (data[this.state.chosenClass] !== undefined && Object.keys(data[this.state.chosenClass]).length > 0);

    let className = (this.props.klassid || {})[this.state.editvalikaegklass || this.state.chosenClass];
    let editnr = -1;
    if (this.state.editvalikaegid) {
      let d = data[this.state.editvalikaegklass];
      editnr = Object.keys(d).sort((l, r) => d[l].order - d[r].order).findIndex(it => it === this.state.editvalikaegid) + 1;
    }

    // TODO classname tegelt on vajalik aind aja lisamisel, Võibolla on mõistlik class input box mujale tõsta.

    let editing = (this.state.editvalikaegid != null);
    let aegnum = 1;
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Klass</InputLabel>
          <Select
            value={this.state.chosenClass}
            onChange={this.handleChange}
            inputProps={{
              name: 'chosenClass',
              id: 'class',
            }}
          >
            {Object.keys(this.props.klassid || {}).map(it => (
              <MenuItem value={it} key={it}>{this.props.klassid[it]}</MenuItem>
            ))}
          </Select>

        </FormControl>

        <br /><br /><Divider light={true} /><br />

        <div className={classes.simpleInput}>
          <Button disabled={classHasItems} size="small" variant="contained" onClick={this.handleQuickAdd} color="primary" className={classes.cancelbutton}>
            Lisa
          </Button>
          Üks normaalaeg <TextField id="addNormaalaeg" onChange={this.handleLocalChange('addnormaalaeg')} type="time" value={this.state.addnormaalaeg} inputProps={inputProps} />
          ja <TextField id="addnlisaaeg" type="number" onChange={this.handleLocalChange('addnlisaaeg')} value={this.state.addnlisaaeg} className={classes.smallnr} inputProps={{ min: 0, max: 9 }} />
          lisaaega kestusega <TextField id="addlisaaeg" onChange={this.handleLocalChange('addlisaaeg')} type="time" value={this.state.addlisaaeg} inputProps={{ step: 1 }} />
        </div>
        <br />
        <Divider light={true} />
        {editing ? "Edit aeg nr. " + editnr + " for  class " + className : "Lisa aeg klassile " + className}
        <br />
        <FormControl className={classes.formControl}>
          <div className={classes.simpleInput}>
            <TextField id="aeg" onChange={this.handleLocalChange('aeg')} type="time" value={this.state.aeg} inputProps={inputProps} />
            Trahvipunkte <TextField id="trahvipunkte" type="number" onChange={this.handleLocalChange('trahvipunkte')} value={this.state.trahvipunkte} className={classes.smallnr} inputProps={{ min: 0, max: 9 }} />
            iga algava <TextField id="trahviaeg" onChange={this.handleLocalChange('trahviaeg')} type="time" value={this.state.trahviaeg} inputProps={{ step: 1 }} /> eest.
        </div>
        </FormControl>
        <br />
        {editing ?
          (<div>
            <Button variant="contained" onClick={this.handleSaveEdit} color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={this.handleCancelEdit} color="primary">
              Cancel
            </Button>
          </div>) :
          (<Button variant="contained" onClick={this.handleAddAeg} color="primary">
            Add
          </Button>)}
        <Divider />
        <List className={classes.root} subheader={<li />}>
          {Object.keys(data).map(key => (
            <li key={key} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader color='primary' inset>{this.props.klassid[key]}</ListSubheader>
                {Object.keys(data[key]).sort((l, r) => data[key][l].order - data[key][r].order).map(it => (
                  <ListItem key={it} button onClick={() => this.editValikAeg(key, it)}>
                    <div>{aegnum++}. </div>
                    <ListItemText primary={this.formatValikTime(data[key][it])} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => this.props.deleteValikAeg(key, it)}
                        key="Delete">
                        <Icon aria-label="Delete">delete</Icon>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </div>
    );
  }
}

ValikTimesEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  klassid: PropTypes.object.isRequired
};

export default withStyles(styles)(ValikTimesEditor);
