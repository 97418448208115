import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { addUndo } from '../redux/actionsEventResult';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Grid, List, ListItem, Paper, Switch, TextField } from '@material-ui/core';
import firebase from 'firebase/app';

import MapForSingleLocation from './MapForSingleLocation';
import LabelledOutline from './LabelledOutline';
import { Alert } from '@material-ui/lab';


const styles = theme => ({
});

class StartConfEditor extends Component {
  state = {
  }
  handleChange = name => event => {
    let value = event;
    if (name === 'massstarttimems') {
      if (!value.isValid())
        return;
      value = value.valueOf();
    } else if (name === 'joinstartinterval') {
      if (!value.isValid())
        return;
      value = (value.hours() * 3600 + value.minutes() * 60 + value.seconds()) * 1000;
    } else if (name === 'joinrequiresconfirmation') {
      value = event.target.checked
    } else
      value = event.target.value;
    if (name === 'maxstartdistance') {
      if (value === '')
        value = null;
      else
        value = Number(value);
    }
    this.props.fieldChanged(name, value);
  }

  changeStartTimeManagment = (event) => {
    if (event.target.value === "join") {
      if (this.props.massstarttimems)
        this.props.fieldChanged('massstarttimems', null);
      if (this.props.eventdata.clientstart)
        this.props.fieldChanged('clientstart', false);
      this.props.fieldChanged('joinstartinterval', 60000);
    } else if (event.target.value === "mass") {
      if (this.props.joinstartinterval)
        this.props.fieldChanged('joinstartinterval', null);
      this.props.fieldChanged('massstarttimems', this.props.starttime);
      if (this.props.eventdata.clientstart)
        this.props.fieldChanged('clientstart', false);
    } else if (event.target.value === "client") {
      this.props.fieldChanged('clientstart', true);
      if (this.props.joinstartinterval)
        this.props.fieldChanged('joinstartinterval', null);
      if (this.props.massstarttimems)
        this.props.fieldChanged('massstarttimems', null);
    } else if (event.target.value === "server") {
      if (this.props.massstarttimems)
        this.props.fieldChanged('massstarttimems', null);
      if (this.props.joinstartinterval)
        this.props.fieldChanged('joinstartinterval', null);
      if (this.props.eventdata.clientstart)
        this.props.fieldChanged('clientstart', false);
    }
  }

  handleStartConfChange = (ev) => {
    console.log('Should change');
  }

  render() {
    const { classes, massstarttimems, eventdata, eventId, startconf } = this.props;

    let startTimeType = "server";
    if (eventdata.joinstartinterval)
      startTimeType = "join"
    else if (eventdata.clientstart)
      startTimeType = "client"
    else if (massstarttimems)
      startTimeType = "mass"

    var massstarttime = new Date();
    if (massstarttimems !== undefined) {
      massstarttime = new Date(massstarttimems);
      let tzoffset = massstarttime.getTimezoneOffset() * 60000; //offset in milliseconds
      massstarttime = (new Date(massstarttime - tzoffset));
    }
    var massstarttimevalue = massstarttime.toISOString().substr(0, 16); //format("yyyy-MM-ddThh:mm");
    const [fkp] = Object.entries(this.props.kpList).find(([_k, e]) => e.nr === 'F') || [];
    const startlocref = firebase.database().ref('/eventsdata/').child(eventId).child('data').child('startloc');
    return (
      <div>
        <form noValidate autoComplete="off">
          {eventdata.type !== 'paevak' && (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="stardiajad">Stardiajad</InputLabel>
              <Select
                value={startTimeType}
                onChange={this.changeStartTimeManagment}
              >
                <MenuItem value="server">Korraldaja määratud</MenuItem>
                <MenuItem value="join">Ühinemisjärjekorraga interval</MenuItem>
                <MenuItem value="client">Võistleja määrab ise</MenuItem>
                <MenuItem value="mass">Ühisstart</MenuItem>

              </Select>
            </FormControl>
          )}
          {
            this.props.massstarttimems != null && (
              <KeyboardDateTimePicker
                className={classes.formControl}
                ampm={false}
                showTodayButton
                label="Mass start time"
                onError={console.log}
                value={massstarttimevalue}
                onChange={this.handleChange('massstarttimems')}
                format="DD/MM/YYYY HH:mm"
                disableOpenOnEnter
                InputLabelProps={{
                  shrink: true,
                }}
              />)
          }
          {
            this.props.joinstartinterval != null && (
              <KeyboardTimePicker
                className={classes.formControl}
                views={["minutes", "seconds"]}
                ampm={false}
                label="Stardi interval"
                onError={console.log}
                value={this.props.joinstartinterval}
                onChange={this.handleChange('joinstartinterval')}
                format="mm:ss"
                disableOpenOnEnter
                InputLabelProps={{
                  shrink: true,
                }}
              />)
          }
          <FormControlLabel
            control={
              <Switch
                checked={eventdata.joinrequiresconfirmation || false}
                onChange={this.handleChange('joinrequiresconfirmation')}
                value={eventdata.joinrequiresconfirmation}
              />
            }
            label="Osalejad tuleb kinnitada"
          />
        </form>
        <Grid container>
          <Grid item>
            <div style={{ height: '40vh', width: '60vh', padding: '20px' }}>
              <MapForSingleLocation kp={{ loc: eventdata.startloc }} setLoc={(loc) => {
                startlocref.feedbackset(loc);
              }} />
            </div>
          </Grid>
          <Grid item style={{ padding: '20px' }}>
            <List>
              {fkp && (<ListItem><Button variant="contained" onClick={() => {
                console.log(this.props.kpData[fkp]);
                startlocref.setWithUndo(this.props.kpData[fkp].loc, "Stardi asukoht määratud");
              }}>Kasuta Finishit</Button></ListItem>)}
              <ListItem><Button variant="contained" onClick={() => {
                startlocref.setWithUndo(null, "Kustutatud stardi asukoht");
              }}>Kustuta stardi koht</Button></ListItem>
              <ListItem>
                <TextField
                  id="maxstartdistance"
                  label="Kaugus stardist"
                  value={eventdata.maxstartdistance || ""}
                  onChange={this.handleChange('maxstartdistance')}
                  type="number"
                  className={classes.textField}
                  margin="normal"
                  helperText={eventdata.maxmarkingdistance > 0 && (eventdata.maxstartdistance || 0) === 0 ? eventdata.maxmarkingdistance + "m väärtus punkti märkimis kaugusest" : "Kui kaugel stardist saab seda märkida"}
                />
              </ListItem>
              {eventdata.startloc && !eventdata.maxstartdistance && !eventdata.maxmarkingdistance && (
                <Alert severity="error" style={{ width: "40vh" }}>Punkti märkimiskaugust ei ole määratud ja ka stardili ei ole kaugust määratud. Võistlejad saavad startida igalt poolt.</Alert>
              )}
            </List>
          </Grid>
          <Grid>
            <LabelledOutline label="Startlist configuration">
              <List>
                <ListItem>
                  <TextField
                    id="beepfreq"
                    label="Beep frequency"
                    value={startconf.sfxsecfreq || ""}
                    onChange={this.handleStartConfChange('sfxsecfreq')}
                    type="number"
                    placeholder="1000"
                    className={classes.textField}
                    margin="normal"
                    helperText={"Läheneva stardi piiksu sagedus"}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="beepduration"
                    label="Beep duration"
                    value={startconf.sfxsecduration || ""}
                    onChange={this.handleStartConfChange('sfxsecduration')}
                    type="number"
                    placeholder="0.1"
                    className={classes.textField}
                    margin="normal"
                    helperText={"Läheneva stardi piiksu pikkus"}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="startbeepfreq"
                    label="Start Beep frequency"
                    value={startconf.sfxlastsecfreq || ""}
                    onChange={this.handleStartConfChange('sfxlastsecfreq')}
                    type="number"
                    placeholder="1000"
                    className={classes.textField}
                    margin="normal"
                    helperText={"Stardi piiksu sagedus"}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="startbeepduration"
                    label="Start beep duration"
                    value={startconf.sfxlastsecduration || ""}
                    onChange={this.handleStartConfChange('sfxlastsecduration')}
                    type="number"
                    placeholder="0.5"
                    className={classes.textField}
                    margin="normal"
                    helperText={"Stardi piiksu pikkus"}
                  />
                </ListItem>
              </List>
            </LabelledOutline>
          </Grid>
        </Grid>

      </div>
    )
  }
}
/*
                            playBeep(sconf.sfxsecfreq || 1000, sconf.sfxsecduration || 0.1);
                        } else {
          playBeep(sconf.sfxlastsecfreq || 1000, sconf.sfxlastsecduration || 0.5);
                            */

const mapStateToProps = state => ({
  eventId: state.eventId,
  speedinfo: state.speedConf,
  speedpenalty: state.currentEvent.speedpenalty,
  startconf: state.currentEvent.startconf || {},
  authUser: state.authUser,
  kpList: state.kpList,
  kpData: state.kpData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addUndo: (id, value, msg, undofunc) => dispatch(addUndo(id, value, msg, undofunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(StartConfEditor));
