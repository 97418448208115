import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField, FormControlLabel, Switch } from '@material-ui/core';
import firebase from 'firebase/app';

const styles = theme => ({
    container: {
        paddingLeft: '6px',
    },
    speedlimit: {
        width: '13ch'
    },
});

class SpeedAreaPropsEditor extends React.Component {

    timers = [];

    state = {};

    handleChange = name => event => {

        let value = event.target.value;

        if (name === "restricted") {
            value = (value === "true");
        }

        const { akey, newArea } = this.props;
        if (newArea) {
            newArea[name] = value;
            this.forceUpdate();
            newArea.parentForceUpdate();
            return;
        }
        this.setState({ [name]: value });
        const firestore = firebase.firestore();
        const docref = (akey.owner ?
            firestore.collection('speedareas').doc(akey.owner) :
            firestore.collection('events').doc(this.props.eventId));
        const setkey = (akey.owner ? 'speedareas.' + akey.id + '.' + name : 'speedareas.' + akey.id + '.' + name);

        let self = this;
        docref.groupfeedbackupdate('areas' + akey.owner || '' + akey.id + name, {
            [setkey]: value
        }).then(nopendingupdates => {
            if (nopendingupdates) {
                self.setState({ [name]: undefined });
                if (this.props.bound) {
                    let parts = akey.id.split(':');
                    firestore.collection('speedareas').doc(parts[0]).feedbackupdate({ ['speedareas.' + parts[1] + '.' + name]: value });
                }
            }
        });


    }

    render() {
        const { classes, akey, area, newArea } = this.props;
        return (
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="standard-name"
                    style={{ width: "98%" }}
                    disabled={!akey && !newArea}
                    value={newArea ? newArea.name || '' : !akey ? '' : (this.state.name !== undefined ? this.state.name : area.name)}
                    label="Name"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange('name')}
                />
                <div style={{ display: 'flex' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={!akey && !newArea}
                                checked={newArea ? Boolean(newArea.restricted) : !akey ? false : Boolean(area.restricted)}
                                onChange={this.handleChange('restricted')}
                                value={newArea ? Boolean(!newArea.restricted) : !akey ? false : Boolean(!area.restricted)}
                            />
                        }
                        label="Restricted area"
                    />
                    <TextField
                        className={classes.speedlimit}
                        id="speedlimit"
                        disabled={(!akey || area.restricted) && (!newArea || newArea.restricted)}
                        value={newArea ? newArea.limit : !akey ? "" : (this.state.limit !== undefined ? this.state.limit : area.limit || "")}
                        onChange={this.handleChange('limit')}
                        label="Speed limit"
                        margin="normal"
                        variant="outlined"
                    />
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    eventId: state.eventId,
    area: (ownProps.akey ? (ownProps.akey.owner ?
        state.speedAreas[ownProps.akey.owner][ownProps.akey.id] :
        state.eventSpeedAreas[ownProps.akey.id]) : null)
})

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(SpeedAreaPropsEditor));