import React from 'react';
import { connect } from 'react-redux';
import { fade, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import { setEditKPId, setEditTeamId, setViewTeamResult, openDialog } from '../redux/actionsEvents';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { isEmptyObj } from '../utils/SmallUtils';


const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'white',

    },
    inputInput: {
        color: 'white',
        borderBottomColor: 'white',
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
});

class SearchBox extends React.Component {
    state = {
        chosen: ""
    }
    render() {
        const { classes, searchitems } = this.props;

        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <Icon>search</Icon>
                </div>
                <Autocomplete
                    autoComplete={false}
                    autoHighlight
                    clearOnEscape
                    freeSolo
                    openOnFocus={true}
                    groupBy={option => option.group}
                    filterOptions={(options, state) => {
                        return options.filter(o => {
                            let fv = state.inputValue.toLowerCase()
                            if (o.title.toLowerCase().includes(fv))
                                return true;
                            if (o.team !== undefined && o.team.displayname) {
                                if (o.team.displayname.toLowerCase().includes(fv))
                                    return true;
                            }
                            if (o.searchstring !== undefined && o.searchstring.includes(fv))
                                return true;
                            return false;
                        });
                    }}
                    value={this.state.chosen}
                    onChange={(event, v) => {
                        if (v) {
                            if (v.group === 'KP') {
                                this.props.setEditKPId(v.kpid);
                            } else if (v.group === 'Tulemused') {
                                this.props.setViewTeamResult(v.teamid);
                            } else if (v.group === 'Tiimid') {
                                this.props.setEditTeamId(v.teamid);
                            } else if (v.group === 'Commands') {
                                v.run(this.props);
                            }
                            this.setState({ chosen: searchitems[0] });
                            setTimeout(() => {
                                this.setState({ chosen: "" });
                            });
                        }
                    }}

                    options={searchitems}
                    getOptionLabel={option => {
                        return option.title || "";
                    }}
                    renderInput={params => {
                        params.inputProps.className = classes.inputInput;
                        return (
                            <TextField {...params} placeholder="Search…" className={classes.inputRoot} />)
                    }
                    }
                />

            </div>
        );
    }
}


const kplistItems = createSelector(
    [
        (state) => state.kpList,
        (state) => state.kpData,
        (state) => state.eventAccess,
    ],
    (kpList, kpData, access) => {
        if (!access || isEmptyObj(kpList) || kpData.length === 0)
            return [];
        return Object.keys(kpList).sort((a, b) => kpList[a].nr - kpList[b].nr).map(kpid => {
            let kp = kpList[kpid];
            return {
                kp: kp,
                kpid: kpid,
                title: 'KP ' + (kp.nr || '') + ' ' + (kpData[kpid]?.desc || ''),
                group: 'KP'
            };
        });
    }
);
const teamslistItems = createSelector(
    [
        (state) => state.teamsList,
        (state) => state.eventAccess
    ],
    (teamsList, access) => {
        let entries = [];
        if (access) {
            entries.push(...Object.keys(teamsList).map(tid => {
                let team = teamsList[tid];
                return {
                    team: team,
                    teamid: tid,
                    title: 'TEAM ' + team.name,
                    group: 'Tiimid',
                }
            }));
            entries.push(...Object.keys(teamsList).map(tid => {
                let team = teamsList[tid];
                return {
                    team: team,
                    teamid: tid,
                    title: team.name,
                    group: 'Tulemused'
                }
            }));
        }
        return entries;
    }
);

const commandItems = createSelector(
    [
        (state) => state.eventAccess
    ],
    (access) => {
        let entries = [];
        if (access) {
            entries.push(
                {
                    run: (fullprops) => { fullprops.openDialog('editkps') },
                    title: "Edit KP's",
                    group: "Commands",
                },
                {
                    run: (fullprops) => { fullprops.openDialog('editTeams') },
                    searchstring: 'allowed, lubatud',
                    title: "Edit Teams",
                    group: "Commands",
                }
            )
        }
        return entries;
    }
);
const getSearchItems = createSelector(
    [
        kplistItems,
        teamslistItems,
        commandItems,
    ],
    (commands, kpitems, teamsitems) => {
        return (commands).concat(kpitems).concat(teamsitems);
    }
)

const mapStateToProps = (state, props) => ({
    searchitems: getSearchItems(state, props),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setEditKPId: (kpid) => dispatch(setEditKPId(kpid)),
    setEditTeamId: (kpid) => dispatch(setEditTeamId(kpid)),
    setViewTeamResult: (teamid) => dispatch(setViewTeamResult(teamid)),
    openDialog: (name) => dispatch(openDialog(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SearchBox));
